import { motion } from "framer-motion"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faClipboard, faClipboardCheck } from '@fortawesome/free-solid-svg-icons'
import React from "react"

/****************************************************************************************************
All constant variables ending with "Variants" are used to control the framer motion (see above) 
animations for certain sections.

modalVariants:: Variants for the inner section of the modal.
backgroundVariants:: Variants for the darkened background for the modal.
******************************************************************************************************/
const modalVariants = {
    visible: {
        y: 0,
        transition: {
            delay: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        }
    },
    hidden: { y: '100vh' },
    exit: { y: '100vh' },
}

const backgroundVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
    exit: {
        opacity: 0,
        transition: {
            delay: 0.5
        }
    },
}

function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
}

const ShareModal = ({ setShowModal, URL }) => {

    const [justCopied, setJustCopied] = React.useState(false)

    const copyValue = async() => {
        setJustCopied(true)
        navigator.clipboard.writeText(URL)
        await timeout(2000)
        setJustCopied(false)
    }

    return (
        <motion.div initial="hidden" animate="visible" exit="exit" variants={backgroundVariants} className="modal__bg">
            <motion.div initial="hidden" animate="visible" exit="exit" variants={modalVariants} className="share-modal">
                <div className="share-modal-title__wrapper">
                    <h5 className="share-modal-form-title">Share Dashboard</h5>
                    <FontAwesomeIcon icon={faTimes} size="lg" className="modal-closer" onClick={() => {setShowModal(false)}} />
                </div>
                <div className="share-modal-body">
                    <div className="share-bar__wrapper">
                        <input className="share-bar" type="text" value={URL} readOnly />
                        <div className='share-bar-end' onClick={() => copyValue()}>
                            <FontAwesomeIcon className="icon" icon={justCopied ? faClipboardCheck : faClipboard}/>
                        </div>
                    </div>
                </div>
            </motion.div>
        </motion.div>
    )
}

export default ShareModal