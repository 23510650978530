const n4list = [
    "Accounting, Tax Preparation, Bookkeeping, and Payroll Services",
    "Activities Related to Credit Intermediation",
    "Activities Related to Real Estate",
    "Administration of Economic Programs",
    "Administration of Environmental Quality Programs",
    "Administration of Housing Programs, Urban Planning, and Community Development",
    "Administration of Human Resource Programs",
    "Advertising, Public Relations, and Related Services",
    "Aerospace Product and Parts Manufacturing",
    "Agencies, Brokerages, and Other Insurance Related Activities",
    "Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures",
    "Agriculture, Construction, and Mining Machinery Manufacturing",
    "Alumina and Aluminum Production and Processing",
    "Amusement Parks and Arcades",
    "Animal Food Manufacturing",
    "Animal Slaughtering and Processing",
    "Apparel Accessories and Other Apparel Manufacturing",
    "Apparel Knitting Mills",
    "Apparel, Piece Goods, and Notions Merchant Wholesalers",
    "Aquaculture",
    "Architectural and Structural Metals Manufacturing",
    "Architectural, Engineering, and Related Services",
    "Audio and Video Equipment Manufacturing",
    "Automobile Dealers",
    "Automotive Equipment Rental and Leasing",
    "Automotive Parts, Accessories, and Tire Stores",
    "Automotive Repair and Maintenance",
    "Bakeries and Tortilla Manufacturing",
    "Basic Chemical Manufacturing",
    "Beer, Wine, and Distilled Alcoholic Beverage Merchant Wholesalers",
    "Beer, Wine, and Liquor Stores",
    "Beverage Manufacturing",
    "Boiler, Tank, and Shipping Container Manufacturing",
    "Book Stores and News Dealers",
    "Building Equipment Contractors",
    "Building Finishing Contractors",
    "Building Material and Supplies Dealers",
    "Business Schools and Computer and Management Training",
    "Business Support Services",
    "Business, Professional, Labor, Political, and Similar Organizations",
    "Cable and Other Subscription Programming",
    "Cattle Ranching and Farming",
    "Cement and Concrete Product Manufacturing",
    "Charter Bus Industry",
    "Chemical and Allied Products Merchant Wholesalers",
    "Child Day Care Services",
    "Civic and Social Organizations",
    "Clay Product and Refractory Manufacturing",
    "Clothing Stores",
    "Coal Mining",
    "Coating, Engraving, Heat Treating, and Allied Activities",
    "Colleges, Universities, and Professional Schools",
    "Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance",
    "Commercial and Industrial Machinery and Equipment Rental and Leasing",
    "Commercial and Service Industry Machinery Manufacturing",
    "Communications Equipment Manufacturing",
    "Community Food and Housing, and Emergency and Other Relief Services",
    "Computer Systems Design and Related Services",
    "Computer and Peripheral Equipment Manufacturing",
    "Consumer Goods Rental",
    "Continuing Care Retirement Communities and Assisted Living Facilities for the Elderly",
    "Converted Paper Product Manufacturing",
    "Couriers and Express Delivery Services",
    "Cut and Sew Apparel Manufacturing",
    "Cutlery and Handtool Manufacturing",
    "Dairy Product Manufacturing",
    "Data Processing, Hosting, and Related Services",
    "Death Care Services",
    "Deep Sea, Coastal, and Great Lakes Water Transportation",
    "Department Stores",
    "Depository Credit Intermediation",
    "Direct Selling Establishments",
    "Drinking Places (Alcoholic Beverages)",
    "Drugs and Druggists' Sundries Merchant Wholesalers",
    "Drycleaning and Laundry Services",
    "Educational Support Services",
    "Electric Lighting Equipment Manufacturing",
    "Electric Power Generation, Transmission and Distribution",
    "Electrical Equipment Manufacturing",
    "Electronic Shopping and Mail-Order Houses",
    "Electronic and Precision Equipment Repair and Maintenance",
    "Electronics and Appliance Stores",
    "Elementary and Secondary Schools",
    "Employment Services",
    "Engine, Turbine, and Power Transmission Equipment Manufacturing",
    "Executive, Legislative, and Other General Government Support",
    "Fabric Mills",
    "Facilities Support Services",
    "Farm Product Raw Material Merchant Wholesalers",
    "Fiber, Yarn, and Thread Mills",
    "Fishing",
    "Florists",
    "Footwear Manufacturing",
    "Forest Nurseries and Gathering of Forest Products",
    "Forging and Stamping",
    "Foundation, Structure, and Building Exterior Contractors",
    "Foundries",
    "Freight Transportation Arrangement",
    "Fruit and Tree Nut Farming",
    "Fruit and Vegetable Preserving and Specialty Food Manufacturing",
    "Furniture Stores",
    "Furniture and Home Furnishing Merchant Wholesalers",
    "Gambling Industries",
    "Gasoline Stations",
    "General Freight Trucking",
    "General Medical and Surgical Hospitals",
    "General Merchandise Stores, including Warehouse Clubs and Supercenters",
    "General Rental Centers",
    "Glass and Glass Product Manufacturing",
    "Grain and Oilseed Milling",
    "Grantmaking and Giving Services",
    "Greenhouse, Nursery, and Floriculture Production",
    "Grocery Stores",
    "Grocery and Related Product Merchant Wholesalers",
    "Hardware Manufacturing",
    "Hardware, and Plumbing and Heating Equipment and Supplies Merchant Wholesalers",
    "Health and Personal Care Stores",
    "Highway, Street, and Bridge Construction",
    "Hog and Pig Farming",
    "Home Furnishings Stores",
    "Home Health Care Services",
    "Household Appliance Manufacturing",
    "Household Appliances and Electrical and Electronic Goods Merchant Wholesalers",
    "Household and Institutional Furniture and Kitchen Cabinet Manufacturing",
    "Hunting and Trapping",
    "Independent Artists, Writers, and Performers",
    "Individual and Family Services",
    "Industrial Machinery Manufacturing",
    "Inland Water Transportation",
    "Insurance Carriers",
    "Insurance and Employee Benefit Funds",
    "Interurban and Rural Bus Transportation",
    "Investigation and Security Services",
    "Iron and Steel Mills and Ferroalloy Manufacturing",
    "Jewelry, Luggage, and Leather Goods Stores",
    "Junior Colleges",
    "Justice, Public Order, and Safety Activities",
    "Land Subdivision",
    "Lawn and Garden Equipment and Supplies Stores",
    "Leather and Hide Tanning and Finishing",
    "Legal Services",
    "Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)",
    "Lessors of Real Estate",
    "Lime and Gypsum Product Manufacturing",
    "Local Messengers and Local Delivery",
    "Logging",
    "Lumber and Other Construction Materials Merchant Wholesalers",
    "Machine Shops; Turned Product; and Screw, Nut, and Bolt Manufacturing",
    "Machinery, Equipment, and Supplies Merchant Wholesalers",
    "Management of Companies and Enterprises",
    "Management, Scientific, and Technical Consulting Services",
    "Manufacturing and Reproducing Magnetic and Optical Media",
    "Medical Equipment and Supplies Manufacturing",
    "Medical and Diagnostic Laboratories",
    "Metal Ore Mining",
    "Metal and Mineral (except Petroleum) Merchant Wholesalers",
    "Metalworking Machinery Manufacturing",
    "Miscellaneous Durable Goods Merchant Wholesalers",
    "Miscellaneous Nondurable Goods Merchant Wholesalers",
    "Monetary Authorities-Central Bank",
    "Motion Picture and Video Industries",
    "Motor Vehicle Body and Trailer Manufacturing",
    "Motor Vehicle Manufacturing",
    "Motor Vehicle Parts Manufacturing",
    "Motor Vehicle and Motor Vehicle Parts and Supplies Merchant Wholesalers",
    "Museums, Historical Sites, and Similar Institutions",
    "National Security and International Affairs",
    "Natural Gas Distribution",
    "Navigational, Measuring, Electromedical, and Control Instruments Manufacturing",
    "Newspaper, Periodical, Book, and Directory Publishers",
    "Nondepository Credit Intermediation",
    "Nonferrous Metal (except Aluminum) Production and Processing",
    "Nonmetallic Mineral Mining and Quarrying",
    "Nonresidential Building Construction",
    "Nonscheduled Air Transportation",
    "Nursing Care Facilities (Skilled Nursing Facilities)",
    "Office Administrative Services",
    "Office Furniture (including Fixtures) Manufacturing",
    "Office Supplies, Stationery, and Gift Stores",
    "Offices of Dentists",
    "Offices of Other Health Practitioners",
    "Offices of Physicians",
    "Offices of Real Estate Agents and Brokers",
    "Oil and Gas Extraction",
    "Oilseed and Grain Farming",
    "Other Ambulatory Health Care Services",
    "Other Amusement and Recreation Industries",
    "Other Animal Production",
    "Other Chemical Product and Preparation Manufacturing",
    "Other Crop Farming",
    "Other Electrical Equipment and Component Manufacturing",
    "Other Fabricated Metal Product Manufacturing",
    "Other Financial Investment Activities",
    "Other Food Manufacturing",
    "Other Furniture Related Product Manufacturing",
    "Other General Purpose Machinery Manufacturing",
    "Other Heavy and Civil Engineering Construction",
    "Other Information Services",
    "Other Investment Pools and Funds",
    "Other Leather and Allied Product Manufacturing",
    "Other Miscellaneous Manufacturing",
    "Other Miscellaneous Store Retailers",
    "Other Motor Vehicle Dealers",
    "Other Nonmetallic Mineral Product Manufacturing",
    "Other Personal Services",
    "Other Pipeline Transportation",
    "Other Professional, Scientific, and Technical Services",
    "Other Residential Care Facilities",
    "Other Schools and Instruction",
    "Other Specialty Trade Contractors",
    "Other Support Activities for Transportation",
    "Other Support Services",
    "Other Telecommunications",
    "Other Textile Product Mills",
    "Other Transit and Ground Passenger Transportation",
    "Other Transportation Equipment Manufacturing",
    "Other Wood Product Manufacturing",
    "Outpatient Care Centers",
    "Paint, Coating, and Adhesive Manufacturing",
    "Paper and Paper Product Merchant Wholesalers",
    "Performing Arts Companies",
    "Personal Care Services",
    "Personal and Household Goods Repair and Maintenance",
    "Pesticide, Fertilizer, and Other Agricultural Chemical Manufacturing",
    "Petroleum and Coal Products Manufacturing",
    "Petroleum and Petroleum Products Merchant Wholesalers",
    "Pharmaceutical and Medicine Manufacturing",
    "Pipeline Transportation of Crude Oil",
    "Pipeline Transportation of Natural Gas",
    "Plastics Product Manufacturing",
    "Postal Service",
    "Poultry and Egg Production",
    "Printing and Related Support Activities",
    "Private Households",
    "Professional and Commercial Equipment and Supplies Merchant Wholesalers",
    "Promoters of Performing Arts, Sports, and Similar Events",
    "Psychiatric and Substance Abuse Hospitals",
    "Pulp, Paper, and Paperboard Mills",
    "RV (Recreational Vehicle) Parks and Recreational Camps",
    "Radio and Television Broadcasting",
    "Rail Transportation",
    "Railroad Rolling Stock Manufacturing",
    "Religious Organizations",
    "Remediation and Other Waste Management Services",
    "Residential Building Construction",
    "Residential Intellectual and Developmental Disability, Mental Health, and Substance Abuse Facilities",
    "Resin, Synthetic Rubber, and Artificial and Synthetic Fibers and Filaments Manufacturing",
    "Restaurants and Other Eating Places",
    "Rooming and Boarding Houses, Dormitories, and Workers' Camps",
    "Rubber Product Manufacturing",
    "Satellite Telecommunications",
    "Sawmills and Wood Preservation",
    "Scenic and Sightseeing Transportation, Land",
    "Scenic and Sightseeing Transportation, Other",
    "Scenic and Sightseeing Transportation, Water",
    "Scheduled Air Transportation",
    "School and Employee Bus Transportation",
    "Scientific Research and Development Services",
    "Seafood Product Preparation and Packaging",
    "Securities and Commodity Contracts Intermediation and Brokerage",
    "Securities and Commodity Exchanges",
    "Semiconductor and Other Electronic Component Manufacturing",
    "Services to Buildings and Dwellings",
    "Sheep and Goat Farming",
    "Ship and Boat Building",
    "Shoe Stores",
    "Soap, Cleaning Compound, and Toilet Preparation Manufacturing",
    "Social Advocacy Organizations",
    "Software Publishers",
    "Sound Recording Industries",
    "Space Research and Technology",
    "Special Food Services",
    "Specialized Design Services",
    "Specialized Freight Trucking",
    "Specialty (except Psychiatric and Substance Abuse) Hospitals",
    "Specialty Food Stores",
    "Spectator Sports",
    "Sporting Goods, Hobby, and Musical Instrument Stores",
    "Spring and Wire Product Manufacturing",
    "Steel Product Manufacturing from Purchased Steel",
    "Sugar and Confectionery Product Manufacturing",
    "Support Activities for Air Transportation",
    "Support Activities for Animal Production",
    "Support Activities for Crop Production",
    "Support Activities for Forestry",
    "Support Activities for Mining",
    "Support Activities for Rail Transportation",
    "Support Activities for Road Transportation",
    "Support Activities for Water Transportation",
    "Taxi and Limousine Service",
    "Technical and Trade Schools",
    "Textile Furnishings Mills",
    "Textile and Fabric Finishing and Fabric Coating Mills",
    "Timber Tract Operations",
    "Tobacco Manufacturing",
    "Travel Arrangement and Reservation Services",
    "Traveler Accommodation",
    "Urban Transit Systems",
    "Used Merchandise Stores",
    "Utility System Construction",
    "Vegetable and Melon Farming",
    "Vending Machine Operators",
    "Veneer, Plywood, and Engineered Wood Product Manufacturing",
    "Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing",
    "Vocational Rehabilitation Services",
    "Warehousing and Storage",
    "Waste Collection",
    "Waste Treatment and Disposal",
    "Water, Sewage and Other Systems",
    "Wholesale Electronic Markets and Agents and Brokers",
    "Wired and Wireless Telecommunications Carriers",
]

export default n4list