import RangeRepresentation from "./rangeRepresentation";

/****************************************************************************************************
This component is responsible for any field that only sends contains data to looker which looks like
">value" or "<value" or [value1-value2].

minRef && minState + minStateSetter:: These three values perform different functions but all track
the lower range value. When not defined only the upper value is used as the upper bound. The States
track the value for UI and the Ref tracks the value for sending to the dashboard.
maxRef && maxState + maxStateSetter:: These three values perform different functions but all track
the upper range value. When not defined only the lower value is used as the lower bound. The States
track the value for UI and the Ref tracks the value for sending to the dashboard.
maxVal:: sent to rangeRepresentation.js. See that file for explanation
monetary:: Tells the code whether to use a solklar sign signifier or not -- boolean
******************************************************************************************************/
const RangeFilter = ({ minRef, maxRef, minState, minStateSetter, maxState, maxStateSetter, maxVal, monetary }) => {

    if (monetary) {
        return(
            <div className='range-filter__outer-wrapper'>
                <div className='range-filter-input__wrapper'>
                    <div className="monetary-range-input__wrapper">
                        <div className="monetary-input-signifier">
                            <div>$</div>
                        </div>
                        <input className="range-single-input lower" type="number" ref={minRef} placeholder="Min" onChange={(e) => { minStateSetter(e.target.value); minRef.current.value = (e.target.value)}}/>
                    </div>
                    <div className="monetary-range-input__wrapper">
                        <div className="monetary-input-signifier">
                            <div>$</div>
                        </div>
                        <input className="range-single-input higher" type="number" ref={maxRef} placeholder="Max" onChange={(e) => { maxStateSetter(e.target.value); maxRef.current.value = (e.target.value)}}/>
                    </div>
                </div>
                <RangeRepresentation minState={minState} maxState={maxState} maxVal={maxVal} />
            </div>
        )
    } else {
        return(
            <div className='range-filter__outer-wrapper'>
                <div className='range-filter-input__wrapper'>
                    <input className="range-single-input lower" type="number" ref={minRef} placeholder="Min" onChange={(e) => { minStateSetter(e.target.value); minRef.current.value = (e.target.value)}}/>
                    <input className="range-single-input higher" type="number" ref={maxRef} placeholder="Max" onChange={(e) => { maxStateSetter(e.target.value); maxRef.current.value = (e.target.value)}}/>
                </div>
                <RangeRepresentation minState={minState} maxState={maxState} maxVal={maxVal} />
            </div>
        )
    }
}

export default RangeFilter