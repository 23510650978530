import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faTimes } from '@fortawesome/free-solid-svg-icons'

/****************************************************************************************************
This component is responsible for any field that only sends contains data to looker which looks like
"%string%, %string%". This filter allows the user to simply click enter or press a button to add a
field. Fields are joined in the HomePage.js

inputRef:: Responsible for tracking the value currently entered into the input field
stateFilterList:: Contains the list that is shown to the user, the list that you add to with the input
stateFilterListSetter:: Updates the list above
refFilterList:: Responsible for holding the list that is actually sent to looker. UseState has
performance issues so useRef is used instead
******************************************************************************************************/
const ContainsFilter = ({ stateFilterList, stateFilterListSetter, refFilterList }) => {

    const [currentValue, setCurrentValue] = React.useState("")
    const refState = React.useRef('')

    /****************************************************************************************************
    Removes items from the list of selected items, this happens when you click the "X" on a selected item
    ******************************************************************************************************/
    const handleRemoveItem = (i) => {
        refFilterList.current = stateFilterList.filter(item => item !== i)
        stateFilterListSetter(stateFilterList.filter(item => (item !== i || item !== i)))
    };

    /****************************************************************************************************
    Adds an item to the list, also clears the input field.
    ******************************************************************************************************/
    const handleAdd = (val) => {
        stateFilterListSetter(stateFilterList.concat(val))
        refFilterList.current = refFilterList.current.concat(val)
        setCurrentValue("")
        refState.current.value = ("")
    }
    return(
        <>
            <div className='search-filter__outer-wrapper'>
                <div className='search-filter__inner-wrapper'>
                <input type="text" className="search-input" ref={refState} onKeyDown={(e) => {(e.key === "Enter" && e.target.value) ? handleAdd(e.target.value) : void(0)}} onChange={(e) => { refState.current.value = e.target.value}}/>
                <div className='contains-bar-end' onMouseDown={() => refState.current.value ? handleAdd(refState.current.value) : void(0)}>
                    <FontAwesomeIcon className="icon" icon={faCirclePlus} />
                </div>
                </div>
            </div>
            <div className={stateFilterList.length > 0 ? 'search-multi-select-selected__wrapper' : 'search-multi-select-selected__wrapper empty'}>
                {
                    stateFilterList.map((item) =>
                        <div key={item} className="search-multi-select-selected-item">
                            <div className='search-multi-select-selected-item_main'>{item}</div>
                            <FontAwesomeIcon className="icon white contains" icon={faTimes} onClick={() => handleRemoveItem(item)}/> 
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default ContainsFilter