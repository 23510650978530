const s4list = [
    "Abrasive Products",
    "Accident And Health Insurance",
    "Accounting, Auditing, And Bookkeeping",
    "Adhesives And Sealants",
    "Adjustment And Collection Services",
    "Administration of Educational Programs",
    "Administration of General Economic Programs",
    "Administration of Housing Programs",
    "Administration of Public Health Programs",
    "Administration of Social, Human Resource and Income Maintenance Programs",
    "Administration of Urban Planning and Community and Rural Development",
    "Administration of Veterans' Affairs, except Health and Insurance",
    "Advertising Agencies",
    "Advertising, Not Elsewhere Classified",
    "Agricultural Chemicals, Not Elsewhere Classified",
    "Air And Gas Compressors",
    "Air Courier Services",
    "Air Transportation, Nonscheduled",
    "Air Transportation, Scheduled",
    "Air and Water Resource and Solid Waste Management",
    "Aircraft",
    "Aircraft Engines And Engine Parts",
    "Aircraft Parts And Equipment, Not Elsewhere Classified",
    "Airports, Flying Fields, And Services",
    "Alkalies And Chlorine",
    "Aluminum Die-Castings",
    "Aluminum Extruded Products",
    "Aluminum Foundries",
    "Aluminum Rolling And Drawing, Not Elsewhere Classified",
    "Aluminum Sheet, Plate, And Foil",
    "Ammunition, Except For Small Arms, Not Elsewhere Classified",
    "Amusement And Recreation, Not Elsewhere Classified",
    "Amusement Parks",
    "Analytical Instruments",
    "Animal And Marine Fats And Oils",
    "Anthracite Mining",
    "Apartment Building Operators",
    "Apparel And Accessories, Not Elsewhere Classified",
    "Apparel Belts",
    "Architectural Metalwork",
    "Architectural Services",
    "Armature Rewinding Shops",
    "Asbestos Products",
    "Asphalt Felts And Coatings",
    "Asphalt Paving Mixtures And Blocks",
    "Auto And Home Supply Stores",
    "Auto Exhaust System Repair Shops",
    "Automatic Vending Machines",
    "Automobile Parking",
    "Automobiles And Other Motor Vehicles",
    "Automotive And Apparel Trimmings",
    "Automotive Dealers, Not Elsewhere Classified",
    "Automotive Glass Replacement Shops",
    "Automotive Repair Shops, Not Elsewhere Classified",
    "Automotive Services, Not Elsewhere Classified",
    "Automotive Stampings",
    "Automotive Transmission Repair Shops",
    "Bags: Plastic, Laminated, And Coated",
    "Bags: Uncoated Paper And Multiwall",
    "Ball And Roller Bearings",
    "Bank Holding Companies",
    "Barber Shops",
    "Beauty Shops",
    "Beer And Ale",
    "Beet Sugar",
    "Biological Products, Except Diagnostic",
    "Bituminous Coal And Lignite-Surface Mining",
    "Bituminous Coal-Underground Mining",
    "Blankbooks And Looseleaf Binders",
    "Blast Furnaces And Steel Mills",
    "Blowers And Fans",
    "Boat Dealers",
    "Boatbuilding And Repairing",
    "Bolts, Nuts, Rivets, And Washers",
    "Book Printing",
    "Book Publishing",
    "Book Stores",
    "Bookbinding And Related Work",
    "Books, Periodicals, And Newspapers",
    "Botanical And Zoological Gardens",
    "Bottled And Canned Soft Drinks",
    "Bowling Centers",
    "Bras, Girdles, And Allied Garments",
    "Bread, Cake, And Related Products",
    "Brick And Structural Clay Tile",
    "Brick, Stone, And Related Material",
    "Bridge, Tunnel, And Elevated Highway",
    "Broadwoven Fabric Mills, Cotton",
    "Broadwoven Fabric Mills, Manmade",
    "Broadwoven Fabric Mills, Wool",
    "Brooms And Brushes",
    "Building Maintenance Services, Not Elsewhere Classified",
    "Burial Caskets",
    "Bus Charter Service, Except Local",
    "Bus Terminal And Service Facilities",
    "Business And Secretarial Schools",
    "Business Associations",
    "Business Consulting, Not Elsewhere Classified",
    "Business Services, Not Elsewhere Classified",
    "Cable And Other Pay Television Services",
    "Calculating And Accounting Equipment",
    "Camera And Photographic Supply Stores",
    "Candy And Other Confectionery Products",
    "Candy, Nut, And Confectionery Stores",
    "Cane Sugar Refining",
    "Canned And Cured Fish And Seafoods",
    "Canned Fruits And Specialties",
    "Canned Specialties",
    "Canvas And Related Products",
    "Carbon And Graphite Products",
    "Carbon Black",
    "Carbon Paper And Inked Ribbons",
    "Carburetors, Pistons, Rings, Valves",
    "Carpentry Work",
    "Carpet And Upholstery Cleaning",
    "Carpets And Rugs",
    "Carwashes",
    "Catalog And Mail-Order Houses",
    "Cellulosic Manmade Fibers",
    "Cement, Hydraulic",
    "Cemetery Subdividers And Developers",
    "Central Reserve Depository, Not Elsewhere Classified",
    "Ceramic Wall And Floor Tile",
    "Cereal Breakfast Foods",
    "Cheese; Natural And Processed",
    "Chemical And Fertilizer Mining",
    "Chemical Preparations, Not Elsewhere Classified",
    "Chemicals And Allied Products, Not Elsewhere Classified",
    "Chewing And Smoking Tobacco",
    "Chewing Gum",
    "Child Day Care Services",
    "Children's and Infants' Wear Stores",
    "Chocolate And Cocoa Products",
    "Cigarettes",
    "Cigars",
    "Civic And Social Associations",
    "Clay And Related Minerals, Not Elsewhere Classified",
    "Clay Refractories",
    "Coal And Other Minerals And Ores",
    "Coal Mining Services",
    "Coated Fabrics, Not Rubberized",
    "Coin-Operated Amusement Devices",
    "Coin-Operated Laundries And Cleaning",
    "Cold Finishing of Steel Shapes",
    "Colleges And Universities",
    "Combination Utilities, Not Elsewhere Classified",
    "Commercial Art And Graphic Design",
    "Commercial Banks, Not Elsewhere Classified",
    "Commercial Equipment, Not Elsewhere Classified",
    "Commercial Laundry Equipment",
    "Commercial Lighting Fixtures",
    "Commercial Nonphysical Research",
    "Commercial Photography",
    "Commercial Physical Research",
    "Commercial Printing, Gravure",
    "Commercial Printing, Lithographic",
    "Commercial Printing, Not Elsewhere Classified",
    "Commodity Contracts Brokers, Dealers",
    "Communication Services, Not Elsewhere Classified",
    "Communications Equipment, Not Elsewhere Classified",
    "Computer And Software Stores",
    "Computer Facilities Management",
    "Computer Integrated Systems Design",
    "Computer Maintenance And Repair",
    "Computer Peripheral Equipment, Not Elsewhere Classified",
    "Computer Related Services, Not Elsewhere Classified",
    "Computer Rental And Leasing",
    "Computer Storage Devices",
    "Computer Terminals",
    "Computers, Peripherals, And Software",
    "Concrete Block And Brick",
    "Concrete Products, Not Elsewhere Classified",
    "Concrete Work",
    "Confectionery",
    "Construction And Mining Machinery",
    "Construction Machinery",
    "Construction Materials, Not Elsewhere Classified",
    "Construction Sand And Gravel",
    "Converted Paper Products, Not Elsewhere Classified",
    "Conveyors And Conveying Equipment",
    "Cookies And Crackers",
    "Copper Foundries",
    "Copper Ores",
    "Copper Rolling And Drawing",
    "Cordage And Twine",
    "Correctional Institutions",
    "Corrugated And Solid Fiber Boxes",
    "Costume Jewelry",
    "Cottonseed Oil Mills",
    "Courier Services, Except By Air",
    "Courts",
    "Creamery Butter",
    "Credit Reporting Services",
    "Crowns And Closures",
    "Crude Petroleum And Natural Gas",
    "Crude Petroleum Pipelines",
    "Crushed And Broken Granite",
    "Crushed And Broken Limestone",
    "Crushed And Broken Stone, Not Elsewhere Classified",
    "Current-Carrying Wiring Devices",
    "Curtains And Draperies",
    "Custom Compound Purchased Resins",
    "Custom Computer Programming Services",
    "Cut Stone And Stone Products",
    "Cutlery",
    "Cyclic Crudes And Intermediates",
    "Dairy Products Stores",
    "Dairy Products, Except Dried Or Canned",
    "Dance Studios, Schools, And Halls",
    "Data Processing And Preparation",
    "Data Processing Schools",
    "Deep Sea Domestic Transportation of Freight",
    "Deep Sea Foreign Transportation of Freight",
    "Deep Sea Passenger Transportation, Except Ferry",
    "Dehydrated Fruits, Vegetables, Soups",
    "Dental Equipment And Supplies",
    "Dental Laboratories",
    "Department Stores",
    "Detective And Armored Car Services",
    "Diagnostic Substances",
    "Die-Cut Paper And Board",
    "Dimension Stone",
    "Direct Mail Advertising Services",
    "Direct Selling Establishments",
    "Disinfecting And Pest Control Services",
    "Distilled And Blended Liquors",
    "Dog And Cat Food",
    "Dolls And Stuffed Toys",
    "Drapery And Upholstery Stores",
    "Drapery Hardware And Blinds And Shades",
    "Drilling Oil And Gas Wells",
    "Drinking Places",
    "Drive-In Motion Picture Theaters",
    "Drug Stores And Proprietary Stores",
    "Drugs, Proprietaries, And Sundries",
    "Dry, Condensed, Evaporated Products",
    "Drycleaning Plants, Except Rugs",
    "Durable Goods, Not Elsewhere Classified",
    "Dwelling Operators, Except Apartments",
    "Eating Places",
    "Edible Fats And Oils",
    "Electric And Other Services Combined",
    "Electric Housewares And Fans",
    "Electric Lamps",
    "Electric Services",
    "Electrical Apparatus And Equipment",
    "Electrical Appliances, Television And Radio",
    "Electrical Equipment And Supplies, Not Elsewhere Classified",
    "Electrical Industrial Apparatus",
    "Electrical Repair Shops",
    "Electrical Work",
    "Electromedical Equipment",
    "Electrometallurgical Products",
    "Electron Tubes",
    "Electronic Capacitors",
    "Electronic Coils And Transformers",
    "Electronic Components, Not Elsewhere Classified",
    "Electronic Computers",
    "Electronic Connectors",
    "Electronic Parts And Equipment, Not Elsewhere Classified",
    "Elementary And Secondary Schools",
    "Elevators And Moving Stairways",
    "Employment Agencies",
    "Engine Electrical Equipment",
    "Engineering Services",
    "Entertainers And Entertainment Groups",
    "Envelopes",
    "Environmental Controls",
    "Equipment Rental And Leasing, Not Elsewhere Classified",
    "Excavation Work",
    "Executive Offices",
    "Executive and Legislative Offices Combined",
    "Explosives",
    "Fabric Dress And Work Gloves",
    "Fabricated Metal Products, Not Elsewhere Classified",
    "Fabricated Pipe And Fittings",
    "Fabricated Plate Work (Boiler Shop)",
    "Fabricated Rubber Products, Not Elsewhere Classified",
    "Fabricated Structural Metal",
    "Fabricated Textile Products, Not Elsewhere Classified",
    "Facilities Support Services",
    "Family Clothing Stores",
    "Farm And Garden Machinery",
    "Farm Machinery And Equipment",
    "Farm Product Warehousing And Storage",
    "Farm Supplies",
    "Farm-Product Raw Materials, Not Elsewhere Classified",
    "Fasteners, Buttons, Needles, And Pins",
    "Federal And Federally Sponsored Credit",
    "Federal Credit Unions",
    "Federal Reserve Banks",
    "Federal Savings Institutions",
    "Ferries",
    "Ferroalloy Ores, Except Vanadium",
    "Fertilizers, Mixing Only",
    "Fiber Cans, Drums, And Similar Products",
    "Finishing Plants, Cotton",
    "Finishing Plants, Manmade",
    "Finishing Plants, Not Elsewhere Classified",
    "Fire Protection",
    "Fire, Marine, And Casualty Insurance",
    "Fish And Seafoods",
    "Flat Glass",
    "Flavoring Extracts And Syrups, Not Elsewhere Classified",
    "Floor Covering Stores",
    "Floor Laying And Floor Work, Not Elsewhere Classified",
    "Florists",
    "Flour And Other Grain Mill Products",
    "Flowers And Florists Supplies",
    "Fluid Meters And Counting Devices",
    "Fluid Milk",
    "Fluid Power Cylinders And Actuators",
    "Fluid Power Pumps And Motors",
    "Fluid Power Valves And Hose Fittings",
    "Folding Paperboard Boxes",
    "Food Preparations, Not Elsewhere Classified",
    "Food Products Machinery",
    "Footwear",
    "Footwear Cut Stock",
    "Footwear, Except Rubber, Not Elsewhere Classified",
    "Foreign Bank And Branches And Agencies",
    "Foreign Trade And International Banks",
    "Freight Transportation Arrangement",
    "Freight Transportation On The Great Lakes",
    "Fresh Fruits And Vegetables",
    "Fresh Or Frozen Packaged Fish",
    "Frozen Bakery Products, Except Bread",
    "Frozen Fruits And Vegetables",
    "Frozen Specialties, Not Elsewhere Classified",
    "Fruit and Vegetable Markets",
    "Fuel Dealers, Not Elsewhere Classified",
    "Fuel Oil Dealers",
    "Functions Related To Depository Banking",
    "Funeral Service And Crematories",
    "Fur Goods",
    "Furniture",
    "Furniture And Fixtures, Not Elsewhere Classified",
    "Furniture Stores",
    "Games, Toys, And Children's Vehicles",
    "Garment Pressing And Cleaners' Agents",
    "Gas And Other Services Combined",
    "Gas Production And/Or Distribution",
    "Gas Transmission And Distribution",
    "Gaskets; Packing And Sealing Devices",
    "Gasoline Service Stations",
    "General Automotive Repair Shops",
    "General Government, Not Elsewhere Classified",
    "General Industrial Machinery,",
    "General Medical And Surgical Hospitals",
    "General Warehousing And Storage",
    "Gift, Novelty, And Souvenir Shop",
    "Girl's And Children's Dresses, Blouses",
    "Girl's And Children's Outerwear, Not Elsewhere Classified",
    "Glass And Glazing Work",
    "Glass Containers",
    "Gold Ores",
    "Grain And Field Beans",
    "Gray And Ductile Iron Foundries",
    "Greeting Cards",
    "Groceries And Related Products, Not Elsewhere Classified",
    "Groceries, General Line",
    "Grocery Stores",
    "Guided Missiles And Space Vehicles",
    "Gum And Wood Chemicals",
    "Gypsum Products",
    "Hand And Edge Tools, Not Elsewhere Classified",
    "Hard Surface Floor Coverings, Not Elsewhere Classified",
    "Hardware",
    "Hardware Stores",
    "Hardware, Not Elsewhere Classified",
    "Hardwood Dimension And Flooring Mills",
    "Hardwood Veneer And Plywood",
    "Hats, Caps, And Millinery",
    "Health And Allied Services, Not Elsewhere Classified",
    "Heating Equipment, Except Electric",
    "Heavy Construction Equipment Rental",
    "Heavy Construction, Not Elsewhere Classified",
    "Help Supply Services",
    "Highway And Street Construction",
    "Hobby, Toy, And Game Shops",
    "Hoists, Cranes, And Monorails",
    "Holding Companies, Not Elsewhere Classified",
    "Home Health Care Services",
    "Homefurnishings",
    "Hosiery, Not Elsewhere Classified",
    "Hospital And Medical Service Plans",
    "Hotels And Motels",
    "House Slippers",
    "Household Appliance Stores",
    "Household Appliances, Not Elsewhere Classified",
    "Household Audio And Video Equipment",
    "Household Cooking Equipment",
    "Household Furnishings, Not Elsewhere Classified",
    "Household Furniture, Not Elsewhere Classified",
    "Household Refrigerators And Freezers",
    "Household Vacuum Cleaners",
    "Ice Cream And Frozen Deserts",
    "Individual and Family Social Services",
    "Industrial And Personal Service Paper",
    "Industrial Buildings And Warehouses",
    "Industrial Furnaces And Ovens",
    "Industrial Gases",
    "Industrial Inorganic Chemicals, Not Elsewhere Classified",
    "Industrial Launderers",
    "Industrial Machinery And Equipment",
    "Industrial Machinery, Not Elsewhere Classified",
    "Industrial Organic Chemicals, Not Elsewhere Classified",
    "Industrial Patterns",
    "Industrial Sand",
    "Industrial Supplies",
    "Industrial Trucks And Tractors",
    "Industrial Valves",
    "Information Retrieval Services",
    "Inorganic Pigments",
    "Inspection And Fixed Facilities",
    "Installing Building Equipment",
    "Instruments To Measure Electricity",
    "Insurance Agents, Brokers, And Service",
    "Insurance Carriers, Not Elsewhere Classified",
    "Intercity And Rural Bus Transportation",
    "Intermediate Care Facilities",
    "Internal Combustion Engines, Not Elsewhere Classified",
    "International Affairs",
    "Investment Advice",
    "Investment Offices, Not Elsewhere Classified",
    "Investors, Not Elsewhere Classified",
    "Iron And Steel Forgings",
    "Iron Ores",
    "Irrigation Systems",
    "Jewelers' Materials And Lapidary Work",
    "Jewelry And Precious Stones",
    "Jewelry Stores",
    "Jewelry, Precious Metal",
    "Job Training and Vocational Rehabilitation Services",
    "Junior Colleges",
    "Kidney Dialysis Centers",
    "Knit Outerwear Mills",
    "Knit Underwear Mills",
    "Knitting Mills, Not Elsewhere Classified",
    "Labor Organizations",
    "Laboratory Apparatus And Furniture",
    "Lace And Warp Knit Fabric Mills",
    "Laminated Plastics Plate And Sheet",
    "Land, Mineral, Wildlife, and Forest Conservation",
    "Laundry And Garment Services, Not Elsewhere Classified",
    "Lawn And Garden Equipment",
    "Lead And Zinc Ores",
    "Lead Pencils And Art Goods",
    "Leather And Sheep-Lined Clothing",
    "Leather Gloves And Mittens",
    "Leather Goods, Not Elsewhere Classified",
    "Leather Tanning And Finishing",
    "Legal Counsel And Prosecution",
    "Legal Services",
    "Legislative Bodies",
    "Libraries",
    "Life Insurance",
    "Lighting Equipment, Not Elsewhere Classified",
    "Lime",
    "Linen Supply",
    "Liquefied Petroleum Gas Dealers",
    "Liquor Stores",
    "Livestock",
    "Loan Brokers",
    "Local And Suburban Transit",
    "Local Bus Charter Service",
    "Local Passenger Transportation, Not Elsewhere Classified",
    "Local Trucking With Storage",
    "Local Trucking, Without Storage",
    "Logging",
    "Lubricating Oils And Greases",
    "Luggage",
    "Luggage And Leather Goods Stores",
    "Lumber And Other Building Materials",
    "Lumber, Plywood, And Millwork",
    "Macaroni And Spaghetti",
    "Machine Tool Accessories",
    "Machine Tools, Metal Cutting Type",
    "Machine Tools, Metal Forming Type",
    "Magnetic And Optical Recording Media",
    "Malleable Iron Foundries",
    "Malt",
    "Malt Beverages",
    "Management Consulting Services",
    "Management Investment, Open-Ended",
    "Management Services",
    "Manifold Business Forms",
    "Manufactured Ice",
    "Manufacturing Industries, Not Elsewhere Classified",
    "Marinas",
    "Marine Cargo Handling",
    "Marking Devices",
    "Masonry And Other Stonework",
    "Mattresses And Bedsprings",
    "Measuring And Controlling Devices, Not Elsewhere Classified",
    "Measuring And Dispensing Pumps",
    "Meat And Fish Markets",
    "Meat Packing Plants",
    "Meats And Meat Products",
    "Mechanical Rubber Goods",
    "Medical And Hospital Equipment",
    "Medical Equipment Rental",
    "Medical Laboratories",
    "Medicinals And Botanicals",
    "Membership Organizations, Not Elsewhere Classified",
    "Membership Sports And Recreation Clubs",
    "Membership-Basis Organization Hotels",
    "Men's And Boy's Clothing",
    "Men's And Boy's Clothing, Not Elsewhere Classified",
    "Men's And Boy's Furnishings",
    "Men's And Boy's Neckwear",
    "Men's And Boy's Suits And Coats",
    "Men's And Boy's Trousers And Slacks",
    "Men's And Boy's Underwear And Nightwear",
    "Men's And Boy's Work Clothing",
    "Men's And Boys' Clothing Stores",
    "Men's Footwear, Except Athletic",
    "Merchandising Machine Operators",
    "Metal Barrels, Drums, And Pails",
    "Metal Cans",
    "Metal Coating And Allied Services",
    "Metal Doors, Sash, And Trim",
    "Metal Foil And Leaf",
    "Metal Heat Treating",
    "Metal Household Furniture",
    "Metal Mining Services",
    "Metal Ores, Not Elsewhere Classified",
    "Metal Sanitary Ware",
    "Metal Stampings, Not Elsewhere Classified",
    "Metals Service Centers And Offices",
    "Metalworking Machinery, Not Elsewhere Classified",
    "Millwork",
    "Mineral Wool",
    "Minerals, Ground Or Treated",
    "Mining Machinery",
    "Miscellaneous Apparel and Accessory Stores",
    "Miscellaneous Business Credit",
    "Miscellaneous Fabricated Wire Products",
    "Miscellaneous Food Stores",
    "Miscellaneous General Merchandise",
    "Miscellaneous Homefurnishings",
    "Miscellaneous Metalwork",
    "Miscellaneous Nonmetallic Mining",
    "Miscellaneous Personal Services, Not Elsewhere Classified",
    "Miscellaneous Publishing",
    "Miscellaneous Retail Stores, Not Elsewhere Classified",
    "Mobile Home Dealers",
    "Mobile Home Site Operators",
    "Mobile Homes",
    "Mortgage Bankers And Correspondents",
    "Motion Picture And Tape Distribution",
    "Motion Picture And Video Production",
    "Motion Picture Distribution Services",
    "Motion Picture Theaters, Except Drive-In",
    "Motor Homes",
    "Motor Vehicle Parts And Accessories",
    "Motor Vehicle Parts, Used",
    "Motor Vehicle Supplies And New Parts",
    "Motor Vehicles And Car Bodies",
    "Motorcycle Dealers",
    "Motorcycles, Bicycles, And Parts",
    "Motors And Generators",
    "Museums And Art Galleries",
    "Musical Instrument Stores",
    "Musical Instruments",
    "Nailed Wood Boxes And Shook",
    "Narrow Fabric Mills",
    "National Commercial Banks",
    "National Security",
    "Natural Gas Distribution",
    "Natural Gas Liquids",
    "Natural Gas Transmission",
    "New And Used Car Dealers",
    "News Dealers And Newsstands",
    "News Syndicates",
    "Newspapers",
    "Nitrogenous Fertilizers",
    "Nonclay Refractories",
    "Noncommercial Research Organizations",
    "Noncurrent-Carrying Wiring Devices",
    "Nondeposit Trust Facilities",
    "Nondurable Goods, Not Elsewhere Classified",
    "Nonferrous Die-Castings Except Aluminum",
    "Nonferrous Forgings",
    "Nonferrous Foundries, Not Elsewhere Classified",
    "Nonferrous Rolling And Drawing, Not Elsewhere Classified",
    "Nonferrous Wiredrawing And Insulating",
    "Nonmetallic Mineral Products,",
    "Nonmetallic Minerals Services, except Fuels",
    "Nonresidential Building Operators",
    "Nonresidential Construction, Not Elsewhere Classified",
    "Nonwoven Fabrics",
    "Nursing And Personal Care, Not Elsewhere Classified",
    "Office Equipment",
    "Office Furniture, Except Wood",
    "Office Machines, Not Elsewhere Classified",
    "Offices And Clinics of Chiropractors",
    "Offices And Clinics of Dentists",
    "Offices And Clinics of Medical Doctors",
    "Offices And Clinics of Optometrists",
    "Offices And Clinics of Osteopathic Physicians",
    "Offices And Clinics of Podiatrists",
    "Offices of Health Practitioner",
    "Oil And Gas Exploration Services",
    "Oil And Gas Field Machinery",
    "Oil And Gas Field Services, Not Elsewhere Classified",
    "Oil Royalty Traders",
    "Operative Builders",
    "Ophthalmic Goods",
    "Optical Goods Stores",
    "Optical Instruments And Lenses",
    "Ordnance And Accessories, Not Elsewhere Classified",
    "Organic Fibers, Noncellulosic",
    "Outdoor Advertising Services",
    "Packaged Frozen Goods",
    "Packaging Machinery",
    "Packing And Crating",
    "Paint, Glass, And Wallpaper Stores",
    "Painting And Paper Hanging",
    "Paints And Allied Products",
    "Paints, Varnishes, And Supplies",
    "Paper Industries Machinery",
    "Paper Mills",
    "Paper; Coated And Laminated Packaging",
    "Paper; Coated And Laminated, Not Elsewhere Classified",
    "Paperboard Mills",
    "Partitions And Fixtures, Except Wood",
    "Passenger Car Leasing",
    "Passenger Car Rental",
    "Passenger Transportation Arrangement",
    "Patent Owners And Lessors",
    "Pens And Mechanical Pencils",
    "Pension, Health, And Welfare Funds",
    "Periodicals",
    "Personal Credit Institutions",
    "Personal Leather Goods, Not Elsewhere Classified",
    "Petroleum And Coal Products, Not Elsewhere Classified",
    "Petroleum Bulk Stations And Terminals",
    "Petroleum Products, Not Elsewhere Classified",
    "Petroleum Refining",
    "Pharmaceutical Preparations",
    "Phosphate Rock",
    "Phosphatic Fertilizers",
    "Photocopying And Duplicating Services",
    "Photofinish Laboratories",
    "Photographic Equipment And Supplies",
    "Photographic Studios, Portrait",
    "Physical Fitness Facilities",
    "Pickles, Sauces, And Salad Dressings",
    "Piece Goods And Notions",
    "Pipelines, Not Elsewhere Classified",
    "Plastering, Drywall, And Insulation",
    "Plastics Bottles",
    "Plastics Foam Products",
    "Plastics Materials And Basic Shapes",
    "Plastics Materials And Resins",
    "Plastics Pipe",
    "Plastics Plumbing Fixtures",
    "Plastics Products, Not Elsewhere Classified",
    "Platemaking Services",
    "Plating And Polishing",
    "Pleating And Stitching",
    "Plumbing And Hydronic Heating Supplies",
    "Plumbing Fixture Fittings And Trim",
    "Plumbing, Heating, Air-Conditioning",
    "Police Protection",
    "Polishes And Sanitation Goods",
    "Political Organizations",
    "Porcelain Electrical Supplies",
    "Potash, Soda, And Borate Minerals",
    "Potato Chips And Similar Snacks",
    "Pottery Products, Not Elsewhere Classified",
    "Poultry And Poultry Products",
    "Poultry Slaughtering And Processing",
    "Power Laundries, Family And Commercial",
    "Power Transmission Equipment, Not Elsewhere Classified",
    "Power, Distribution And Specialty Transformers",
    "Power-Driven Handtools",
    "Prefabricated Metal Buildings",
    "Prefabricated Wood Buildings",
    "Prepackaged Software",
    "Prepared Feeds, Not Elsewhere Classified",
    "Prepared Flour Mixes And Doughs",
    "Prerecorded Records And Tapes",
    "Pressed And Blown Glass, Not Elsewhere Classified",
    "Primary Aluminum",
    "Primary Batteries, Dry And Wet",
    "Primary Copper",
    "Primary Metal Products",
    "Primary Nonferrous Metals, Not Elsewhere Classified",
    "Printed Circuit Boards",
    "Printing And Writing Paper",
    "Printing Ink",
    "Printing Trades Machinery",
    "Private Households",
    "Process Control Instruments",
    "Products of Purchased Glass",
    "Professional Equipment, Not Elsewhere Classified",
    "Professional Organizations",
    "Psychiatric Hospitals",
    "Public Building And Related Furniture",
    "Public Finance, Taxation, and Monetary Policy",
    "Public Golf Courses",
    "Public Order And Safety, Not Elsewhere Classified",
    "Public Relations Services",
    "Pulp Mills",
    "Pumps And Pumping Equipment",
    "Racing, Including Track Operation",
    "Radio And T.V. Communications Equipment",
    "Radio And Television Repair",
    "Radio Broadcasting Stations",
    "Radio, Television, And Electronic Stores",
    "Radio, Television, Publisher Representatives",
    "Radiotelephone Communication",
    "Railroad Equipment",
    "Railroads, Line-Haul Operating",
    "Raw Cane Sugar",
    "Ready-Mixed Concrete",
    "Real Estate Agents And Managers",
    "Real Estate Investment Trusts",
    "Real Property Lessors, Not Elsewhere Classified",
    "Reconstituted Wood Products",
    "Record And Prerecorded Tape Stores",
    "Recreational Vehicle Dealers",
    "Refined Petroleum Pipelines",
    "Refrigerated Warehousing And Storage",
    "Refrigeration And Heating Equipment",
    "Refrigeration Equipment And Supplies",
    "Refrigeration Service And Repair",
    "Refuse Systems",
    "Regulation and Administration of Communications, Electric, Gas, and other Utilities",
    "Regulation and Administration of Transportation",
    "Regulation of Agricultural Marketing and Commodities",
    "Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors",
    "Relays And Industrial Controls",
    "Religious Organizations",
    "Rental of Railroad Cars",
    "Repair Services, Not Elsewhere Classified",
    "Residential Care",
    "Residential Construction, Not Elsewhere Classified",
    "Residential Lighting Fixtures",
    "Retail Bakeries",
    "Retail Nurseries And Garden Stores",
    "Reupholstery And Furniture Repair",
    "Rice Milling",
    "Roasted Coffee",
    "Robes And Dressing Gowns",
    "Rolling Mill Machinery",
    "Roofing, Siding, And Insulation",
    "Roofing, Siding, And Sheetmetal Work",
    "Rooming and Boarding Houses",
    "Rubber And Plastics Footwear",
    "Rubber And Plastics Hose And Beltings",
    "Salted And Roasted Nuts And Seeds",
    "Sanitary Food Containers",
    "Sanitary Paper Products",
    "Sanitary Services, Not Elsewhere Classified",
    "Sausages And Other Prepared Meats",
    "Savings Institutions, Except Federal",
    "Saw Blades And Handsaws",
    "Sawmills And Planing Mills, General",
    "Scales And Balances, Except Laboratory",
    "Schiffli Machine Embroideries",
    "School Buses",
    "Schools And Educational Services",
    "Scrap And Waste Materials",
    "Screw Machine Products",
    "Search And Navigation Equipment",
    "Secondary Nonferrous Metals",
    "Secretarial And Court Reporting",
    "Security And Commodity Exchanges",
    "Security And Commodity Service",
    "Security Brokers And Dealers",
    "Security Systems Services",
    "Semiconductors And Related Devices",
    "Semivitreous Table And Kitchenware",
    "Service Establishment Equipment",
    "Service Industry Machinery, Not Elsewhere Classified",
    "Services Allied To Motion Pictures",
    "Services, Not Elsewhere Classified",
    "Setup Paperboard Boxes",
    "Sewerage Systems",
    "Sewing, Needlework, And Piece Goods",
    "Sheet Metalwork",
    "Shipbuilding And Repairing",
    "Shoe Repair Shops and Shoeshine Parlors",
    "Shoe Stores",
    "Short-Term Business Credit",
    "Signs And Advertising Specialties",
    "Silver Ores",
    "Silverware And Plated Ware",
    "Single-Family Housing Construction",
    "Skilled Nursing Care Facilities",
    "Small Arms",
    "Small Arms Ammunition",
    "Soap And Other Detergents",
    "Social Services, Not Elsewhere Classified",
    "Softwood Veneer And Plywood",
    "Soybean Oil Mills",
    "Space Propulsion Units And Parts",
    "Space Research and Technology",
    "Space Vehicle Equipment, Not Elsewhere Classified",
    "Special Dies, Tools, Jigs, And Fixtures",
    "Special Industry Machinery, Not Elsewhere Classified",
    "Special Product Sawmills, Not Elsewhere Classified",
    "Special Trade Contractors, Not Elsewhere Classified",
    "Special Warehousing And Storage, Not Elsewhere Classified",
    "Specialty Hospitals, Except Psychiatric",
    "Specialty Outpatient Clinics, Not Elsewhere Classified",
    "Speed Changers, Drives, And Gears",
    "Sporting And Athletic Goods, Not Elsewhere Classified",
    "Sporting And Recreation Goods",
    "Sporting And Recreational Camps",
    "Sporting Goods And Bicycle Shops",
    "Sports Clubs, Managers, And Promoters",
    "State Commercial Banks",
    "State Credit Unions",
    "Stationery And Office Supplies",
    "Stationery Products",
    "Stationery Stores",
    "Steam And Air-Conditioning Supply",
    "Steel Foundries, Not Elsewhere Classified",
    "Steel Investment Foundries",
    "Steel Pipe And Tubes",
    "Steel Springs, Except Wire",
    "Steel Wire And Related Products",
    "Storage Batteries",
    "Structural Clay Products, Not Elsewhere Classified",
    "Structural Steel Erection",
    "Structural Wood Members, Not Elsewhere Classified",
    "Subdividers And Developers, Not Elsewhere Classified",
    "Surety Insurance",
    "Surface Active Agents",
    "Surgical And Medical Instruments",
    "Surgical Appliances And Supplies",
    "Surveying Services",
    "Switchgear And Switchboard Apparatus",
    "Switching And Terminal Services",
    "Synthetic Rubber",
    "Tanks And Tank Components",
    "Tax Return Preparation Services",
    "Taxicabs",
    "Telegraph And Other Communications",
    "Telephone And Telegraph Apparatus",
    "Telephone Communication, Except Radio",
    "Television Broadcasting Stations",
    "Terrazzo, Tile, Marble, Mosaic Work",
    "Testing Laboratories",
    "Textile Bags",
    "Textile Goods, Not Elsewhere Classified",
    "Textile Machinery",
    "Theatrical Producers And Services",
    "Thread Mills",
    "Throwing And Winding Mills",
    "Tire Cord And Fabrics",
    "Tire Retreading And Repair Shops",
    "Tires And Inner Tubes",
    "Tires And Tubes",
    "Title Abstract Offices",
    "Title Insurance",
    "Tobacco And Tobacco Products",
    "Tobacco Stemming And Redrying",
    "Tobacco Stores And Stands",
    "Toilet Preparations",
    "Top And Body Repair And Paint Shops",
    "Tour Operators",
    "Towing And Tugboat Service",
    "Toys And Hobby Goods And Supplies",
    "Trailer Parks And Campsites",
    "Transportation Equipment And Supplies",
    "Transportation Equipment, Not Elsewhere Classified",
    "Transportation Services, Not Elsewhere Classified",
    "Travel Agencies",
    "Travel Trailers And Campers",
    "Truck And Bus Bodies",
    "Truck Rental And Leasing, Without Drivers",
    "Truck Trailers",
    "Trucking Terminal Facilities",
    "Trucking, Except Local",
    "Trusts, Not Elsewhere Classified",
    "Trusts: Educational, Religious, Etc.",
    "Turbines And Turbine Generator Sets",
    "Typesetting",
    "U.S. Postal Service",
    "Unsupported Plastics Film And Sheet",
    "Unsupported Plastics Profile Shapes",
    "Upholstered Household Furniture",
    "Uranium-Radium-Vanadium Ores",
    "Used Car Dealers",
    "Used Merchandise Stores",
    "Utility Trailer Rental",
    "Valves And Pipe Fittings, Not Elsewhere Classified",
    "Variety Stores",
    "Vegetable Oil Mills, Not Elsewhere Classified",
    "Vehicular Lighting Equipment",
    "Video Tape Rental",
    "Vitreous China Table And Kitchenware",
    "Vitreous Plumbing Fixtures",
    "Vocational Schools, Not Elsewhere Classified",
    "Warm Air Heating And Air Conditioning",
    "Watch, Clock, and Jewelry Repair",
    "Watches, Clocks, Watchcases, And Parts",
    "Water Passenger Transportation",
    "Water Supply",
    "Water Transportation Services, Not Elsewhere Classified",
    "Water Transportation of Freight",
    "Water Well Drilling",
    "Water, Sewer, And Utility Lines",
    "Waterproof Outerwear",
    "Weft Knit Fabric Mills",
    "Welding Apparatus",
    "Welding Repair",
    "Wet Corn Milling",
    "Wine And Distilled Beverages",
    "Wines, Brandy, And Brandy Spirits",
    "Wire Springs",
    "Women's Accessory And Specialty Stores",
    "Women's And Children's Clothing",
    "Women's And Children's Underwear",
    "Women's And Misses' Blouses And Shirts",
    "Women's And Misses' Outerwear, Not Elsewhere Classified",
    "Women's And Misses' Suits And Coats",
    "Women's Clothing Stores",
    "Women's Footwear, Except Athletic",
    "Women's Handbags And Purses",
    "Women's Hosiery, Except Socks",
    "Women's, Junior's, And Misses' Dresses",
    "Wood Containers, Not Elsewhere Classified",
    "Wood Household Furniture",
    "Wood Kitchen Cabinets",
    "Wood Office Furniture",
    "Wood Pallets And Skids",
    "Wood Partitions And Fixtures",
    "Wood Preserving",
    "Wood Products, Not Elsewhere Classified",
    "Wood Television And Radio Cabinets",
    "Woodworking Machinery",
    "Wrecking And Demolition Work",
    "X-Ray Apparatus And Tubes",
    "Yarn Spinning Mills"
]

export default s4list