const departmentsList = [
    "C-Suite",
    "Finance",
    "Human Resources",
    "Sales",
    "Operations",
    "Information Technology",
    "Engineering & Technical",
    "Marketing",
    "Legal",
    "Medical & Health",
    "Other"
]

export default departmentsList