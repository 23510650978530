import { motion } from "framer-motion"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import React from "react"
import WorkflowsAPI from "./WorkflowsAPI"
import Lottie from 'react-lottie-player'
import MultiSelect from "./multiselectDropdown"
import managementLevelsList from "../data/mlevels"
import departmentsList from "../data/departments"
import jobFunctionsList from "../data/jfunctions"
import lottieJson from './anim/fetchLottie.json'

/****************************************************************************************************
All constant variables ending with "Variants" are used to control the framer motion (see above) 
animations for certain sections.

modalVariants:: Variants for the inner section of the contact request modal.
backgroundVariants:: Variants for the darkened background for the contact request modal.
loaderVariants:: Variants for the loading animation that displays within the contact request modal.
errorVariants:: Variants for the error message within the contact request modal.
******************************************************************************************************/
const modalVariants = {
    visible: {
        x: 0,
        transition: {
            delay: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        }
    },
    hidden: { x: '100vw' },
    exit: { x: '100vw' },
}

const backgroundVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
    exit: {
        opacity: 0,
        transition: {
            delay: 0.5
        }
    },
}

const loaderErrorVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
    exit: { opacity: 0 },
}

const workflowsAPI = new WorkflowsAPI() // Creates a connection with the API in the imports above. For reference of what each API function does refer to the documentation.

/****************************************************************************************************
This next section contains the modal component which includes the background and the contents of the
modal. It has two imports from HomePage.js they are:

setShowModal:: A React useState variable to tell the page whether to show or hide the modal
companyData:: Comes from a postMessage within looker. Returns the data for the selected companies
******************************************************************************************************/
const ContactsModal = ({ setShowModal, companyData }) => {

    const [hasAllManagementLevels, setAllManagementLevels] = React.useState(true) // Describes the state of the yes/no toggle regarding Management Levels
    const [hasAllDepartments, setAllDepartments] = React.useState(true) // Describes the state of the yes/no toggle regarding Departments
    const [hasAllJobFunctions, setAllJobFunctions] = React.useState(true) // Describes the state of the yes/no toggle regarding DJob Functions
    const [contactListName, setContactListName] = React.useState("") // The name of the contact list to generate. **DEV NOTE**: This currently is not implemented as the name for the CSV file

    /****************************************************************************************************
    Describes the page the modal sits on. The modal has three pages:

    Page 1:: This page has the form for what contacts to request and uses the above variables.
    Page 2:: This page lists number of contacts and allows you to confirm or go back.
    Page 3:: This page lets you generate the CSV spreadsheet for your requested contacts.
    ******************************************************************************************************/
    const [contactsRequestPage, setContactsRequestPage] = React.useState(1)

    const [JWT, setJWT] = React.useState("") // The state variable that holds the authorizer token used by the API. Returned from the Authroize API call.
    const [numberOfContacts, setNumberOfContacts] = React.useState("") // The number generated by the API call that generates prelim contacts
    const [prelimResults, setPrelimResults] = React.useState("") // The prelim contacts generated by the API call that generates prelim contacts
    const [finalResults, setFinalResults] = React.useState("") // The final results returned that are then put into CSV format

    /****************************************************************************************************
    The next three variables are responsible for keeping track of which management levels, departments
    and job functions are selected, only if the "Yes/No" toggle reads no -- indicating that the user
    intends to specify fields for that category.
    ******************************************************************************************************/
    const [selectedManagementLevels, setSelectedManagementLevels] = React.useState([])
    const [selectedDepartments, setSelectedDepartments] = React.useState([])
    const [selectedJobFunctions, setSelectedJobFunctions] = React.useState([])

    /****************************************************************************************************
    The next three variables are responsible for tracking whether there was an error, then whether or
    not the user has minimized the error message, then specifically what the error message is.

    **DEV NOTE**: This format could be reworked to be only one variable in the future.
    ******************************************************************************************************/
    const [hadError, setError] = React.useState(false)
    const [errorActive, setErrorActive] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState("")

    const [statusMessage, setStatusMessage] = React.useState("") // The status of the request posted to the user in a status box.

    const [requestProcessing, setRequestProcessing] = React.useState(false) // Keeps track of whether or not to show the loader

    const stopProcessing = React.useRef(false) // Tells function to die silently and minimize the loader. For when the user presses cancel.

    const [encodedUri, setEncodedUri] = React.useState("") // Keeps track of whether or not to show the loader

    /****************************************************************************************************
    When stopProcessing is true, the function below is called. This disconnects the running function
    from the API call so that the API does not return information to the front end. To avoid paying for
    nothing, the cancel functionality is only present on the first API call which is free.
    ******************************************************************************************************/
    const cancelProcessing = () => {
        console.log("Cancelled!")
        stopProcessing.current = false
        setRequestProcessing(false)
    }

    /****************************************************************************************************
    Most of this function should be commented individually, but in general this function is responsible
    for creating the request to send to the API then completing the necessary steps to get a measure of
    how many contacts would be returned by the enrich request.
    ******************************************************************************************************/
    const compileAndSubmit = async () => {
        removeError() // Clears all error indicators from the component.
        setRequestProcessing(true) // Enables loader.
        var darr, mlarr, jfarr; // initializes the variables that will hold the request parameters.
        if(hasAllManagementLevels) {
            mlarr = managementLevelsList.join(", ") // When all management levels is "Yes" this will put all the values into a string.
        } else if (selectedManagementLevels.length > 0) {
            mlarr = selectedManagementLevels.join(", ") // When all management levels is "No" this will put all the values currently selected into a string.
        }

        if(hasAllDepartments) {
            darr = departmentsList.join(", ") // When all departments is "Yes" this will put all the values into a string.
        } else if (selectedDepartments.length > 0) {
            darr = selectedDepartments.join(", ") // When all departments is "No" this will put all the values currently selected into a string.
        }

        if(hasAllJobFunctions) {
            jfarr = "all" // When all job functions is "Yes" this will simply tell the api not to use Job Functions. This is because there are too many. It is still in fact using all of them though.
        } else if (selectedJobFunctions.length > 0) {
            jfarr = selectedJobFunctions.join(", ") // When all job functions is "No" this will put all the values currently selected into a string.
        }
        
        /****************************************************************************************************
        Firstly, checks to make sure all fields are defined. DEV NOTE: Should throw error when not all
        fields are filled out.

        All functions are in try-catch to die silently and throw the error to the user not the console for
        best user experience.
        ******************************************************************************************************/
        if(contactListName && darr && mlarr && jfarr) {
            var prelimBatches, uuid, jwt // Initialized locals to hold variables in deeper scopes
            setStatusMessage("Creating Batches to Send for Preliminary Contact Count") // Change status message
            try {
                if(stopProcessing.current) {
                    cancelProcessing() // When cancel is hit the above statement evaluates to true and the function dies silently.
                    return null // Die Silently; Early return
                }

                /****************************************************************************************************
                __API CALL__: Takes the companies and seperates them into batches so that the API doesn't time out.
                Seperates the data into arrays of arrays. The size of the batch can be adjusted by changing the
                second parameter below in the createBatches method. The API Returns (then the front end saves) the
                seperated batches.
                ******************************************************************************************************/
                const createBatchesResponse = await workflowsAPI.createBatches(companyData.current, 5)
                prelimBatches = createBatchesResponse.data
            } catch (error) {
                proposeError("Error while batching request: " + String(error)) // Set Error Message
                setRequestProcessing(false) // Disable loader
                return null // Die Silently; Early return
            }

            setStatusMessage("Saving the contact request to the database") // Change status message
            try {
                if(stopProcessing.current) {
                    cancelProcessing() // When cancel is hit the above statement evaluates to true and the function dies silently.
                    return null // Die Silently; Early return
                }

                /****************************************************************************************************
                __API CALL__: Calls to save the request to the request table so it can be accessed later, the 
                front-end saves the UUID as the id for the request.
                ******************************************************************************************************/
                const saveRequestResponse = await workflowsAPI.saveRequest(companyData.current, darr, mlarr, jfarr, contactListName)
                uuid = saveRequestResponse.identifier
            } catch (error) {
                proposeError("Error while saving request: " + String(error)) // Set Error Message
                setRequestProcessing(false) // Disable loader
                return null // Die Silently; Early return
            }

            setStatusMessage("Authorizing request with third party") // Change status message
            try {
                if(stopProcessing.current) {
                    cancelProcessing() // When cancel is hit the above statement evaluates to true and the function dies silently.
                    return null // Die Silently; Early return
                }

                /****************************************************************************************************
                __API CALL__: Sends username and password of an administrator to the authorize path of the API which
                returns a JWT authorized token which will need to be used in the remaining direct requests to the
                zoomInfo API. So it's saved in a state variable for later and a regular js variable for the rest of
                this function.
                ******************************************************************************************************/
                const authorizeResponse = await workflowsAPI.authorize()
                jwt = authorizeResponse.jwtToken
                setJWT(jwt)
            } catch (error) {
                proposeError("Error while authorizing request: " + String(error)) // Set Error Message
                setRequestProcessing(false) // Disable loader
                return null // Die Silently; Early return
            }

            var initial_results = [] // Array for the function to add results to at the end of each batch

            for (let i = 0; i < prelimBatches.length; i++) {
                try {
                    if(stopProcessing.current) {
                        cancelProcessing() // When cancel is hit the above statement evaluates to true and the function dies silently.
                        return null // Die Silently; Early return
                    }

                    setStatusMessage(`Now Processing Batch ${i+1}/${prelimBatches.length}`) // Change status message to reflect current batch

                    /****************************************************************************************************
                    __API CALL__: One by one the for loop sends batches of company ID's to the API and it returns prelim
                    contacts, the only real purpose of which is to consolidate contact id's and to get a count for how
                    many contacts will need to be enriched if the request is continued. 

                    In the event too many contacts are returned (5000 or more in a single batch -- remember there are
                    alot of batches) the request will be rejected on the backend and will return ...tooMany as true.
                    ******************************************************************************************************/
                    const batchResponse = await workflowsAPI.processPreliminaryBatch(prelimBatches[i], uuid, jwt)
                    initial_results.push.apply(initial_results, batchResponse.batchResult)
                    if(batchResponse.tooMany) {
                        proposeError("Error while processing batches in search: Too many results returned, try filtering down farther and returning fewer contacts") // Set Error Message
                        setRequestProcessing(false) // Disable loader
                        return null // Die Silently; Early return
                    }
                } catch (error) {
                    proposeError("Error while processing batches in search: " + String(error)) // Set Error Message
                    setRequestProcessing(false) // Disable loader
                    return null // Die Silently; Early return
                }
            }

            setContactsRequestPage(2) // Shifts the modal to the next page
            setStatusMessage("") // Clears status message which is part of the loader and so is already off the page
            setNumberOfContacts(initial_results.length) // Sets number of contacts which displays on the page
            setPrelimResults(initial_results) // Saves the preliminary results to the state object
        } else {
            proposeError("Please fill Out all fields") // Set Error Message
        }

        setRequestProcessing(false) // Disable loader
    }


    /****************************************************************************************************
    Most of this function should be commented individually, but in general this function is responsible
    for taking the preliminary contact id's and enriching them and returning them to the front end where
    the function below this one will convert it into a CSV
    ******************************************************************************************************/
    const generateContactList = async () => {
        removeError() // Clears all error indicators from the component.
        setRequestProcessing(true) // Enables loader.
        var preEnrichedContactIdBatches; // Initializes the variable that will hold the batches to be sent.
        var res = []; // Initializes the array that will hold the final results.

        setStatusMessage("Creating Batches to Send for Complete Dataset") // Change status message

        try {

            /****************************************************************************************************
            __API CALL__: Takes the contact ID's and seperates them into batches so that the ZoomInfo API 
            will accept the request. Separates the data into arrays of arrays. The size of the batch can 
            be adjusted by changing the second parameter below in the createEnrichedBatches method. The API 
            Returns (then the front end saves) the seperated batches. 

            In this case the number of items allowed in a batch is greater because the constraint is what
            ZoomInfo will allow and not a possible timeout. This function should never timeout
            ******************************************************************************************************/
            const createEnrichedBatchesResponse = await workflowsAPI.createEnrichedBatches(prelimResults, 25)
            preEnrichedContactIdBatches = createEnrichedBatchesResponse.data
        } catch (error) {
            proposeError("Error while creating enrichable batches: " + String(error)) // Set Error Message
            setRequestProcessing(false) // Disable loader
            return null // Die Silently; Early return
        }

        for (let i = 0; i < preEnrichedContactIdBatches.length; i++) {
            try {
                setStatusMessage(`Now Processing Batch ${i+1}/${preEnrichedContactIdBatches.length}`) // Change status message

                /****************************************************************************************************
                __API CALL__: Sends the batched contact ID's and the authorizer we saved earlier to the API. The API
                calls to ZoomInfo and enriches the data and sends it back in the form of contacts, which are each
                pushed into an array.

                DEV NOTE: Eventually it'd be nice to flatten the JSON object so it makes a cleaner CSV
                ******************************************************************************************************/
                const enrichResponse = await workflowsAPI.enrichContacts(preEnrichedContactIdBatches[i], JWT)
                res.push.apply(res, enrichResponse.contactList)
                console.log(res)
            } catch (error) {
                proposeError("Error while enriching contacts: " + String(error)) // Set Error Message
                setRequestProcessing(false) // Disable loader
                return null // Die Silently; Early return
            }
        }

        setStatusMessage(`Creating CSV File: ${contactListName}`) // Change status message
        
        const items = res // Saves the final results to another variable [|| Can't remember why, remove if prudent to do so ||]
        const replacer = (key, value) => value === null ? '' : value // Replaces null values with ''
        const header = Object.keys(items[0]) // Gets header row

        /****************************************************************************************************
        Saves the final results to a CSV file which is opened with window.open() eventually this should use
        a different format where what's downloaded is a file that is <contactListName>.csv
        ******************************************************************************************************/
        const csv = [
        header.join(','), // header row first
        ...items.map(row => header.map(fieldName => {
            return JSON.stringify(row[fieldName], replacer).replaceAll(",", ";")
        }).join(','))].join('\r\n')

        let csvContent = "data:text/csv;charset=utf-8,"
        csvContent = csvContent + csv;

        console.log(csvContent)
        setEncodedUri(csvContent);

        setContactsRequestPage(3) // Shifts the modal to the next page
        //setFinalResults(finalResults) // Saves the final results to the state object
        setRequestProcessing(false) // Disable loader
    }

    const pullCSV = async() => {
        var hiddenElement = document.createElement('a');
        hiddenElement.target = '_blank';

        const blob = new Blob([encodedUri], {type: "text/csv"})
        const url = window.URL.createObjectURL(blob);

        hiddenElement.href = url;  
        hiddenElement.download = contactListName + ".csv";  
        hiddenElement.click();

        window.URL.revokeObjectURL(url);
    } 

    const createCSV = async() => {
        const items = finalResults // Saves the final results to another variable [|| Can't remember why, remove if prudent to do so ||]
        const replacer = (key, value) => value === null ? '' : value // Replaces null values with ''
        const header = Object.keys(items[0]) // Gets header row

        /****************************************************************************************************
        Saves the final results to a CSV file which is opened with window.open() eventually this should use
        a different format where what's downloaded is a file that is <contactListName>.csv
        ******************************************************************************************************/
        const csv = [
        header.join(','), // header row first
        ...items.map(row => header.map(fieldName => {

            return JSON.stringify(row[fieldName], replacer).replaceAll(",", ";")
        }).join(','))].join('\r\n')

        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += csv;
        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);

        setShowModal(false) // Hides the modal after it downloads the CSV to the local machine
    }

    /****************************************************************************************************
    Adds an error and invokes error signifiers asynchronously.
    ******************************************************************************************************/
    const proposeError = async(message) => {
        setErrorActive(true)
        setError(true)
        setErrorMessage(message)
        setStatusMessage("")
    }

    /****************************************************************************************************
    Removes errors and error signifiers asynchronously.
    ******************************************************************************************************/
    const removeError = async() => {
        setErrorActive(false)
        setError(false)
        setErrorMessage("")
    }

    return (
        <motion.div initial="hidden" animate="visible" exit="exit" variants={backgroundVariants} className="contacts-modal__bg modal__bg">
            <motion.div initial="hidden" animate="visible" exit="exit" variants={modalVariants} className="contacts-modal">
                {requestProcessing && 
                    <motion.div className="loader__overlay" initial="hidden" animate="visible" exit="exit" variants={loaderErrorVariants}>
                        <Lottie loop animationData={lottieJson} play style={{ width: 150, height: 150 }}/>
                        <div className="loader-status__wrapper">
                            <div className="loader-status">
                                <span className="modal__status-text">{statusMessage}</span>
                                { contactsRequestPage === 1 &&
                                    <span id="cancel-api" onClick={() => stopProcessing.current = true}>Cancel Request</span>
                                }
                            </div>
                        </div>
                    </motion.div>
                }
                {contactsRequestPage === 1 && 
                <div className="contact-request-form">
                    <div className="contact-request-form-title__wrapper">
                        <h5 className="contact-request-form-title">Contact Request Form</h5>
                        <FontAwesomeIcon icon={faTimes} size="lg" className="modal-closer" onClick={() => {setShowModal(false)}} />
                    </div>
                    <div className="contact-request-form__body no-pad">
                        <div className="modal-row-item large">
                            <input className="contact-request-form__input" type="text" placeholder="Enter contact list name..." value={contactListName} onChange={(e) => setContactListName(e.target.value)} />
                        </div>
                        <div className="modal-row-item small">
                            <div className="modal-row-item__top-level toggle-switch">
                                <span className="modal-row-item__top-level-heading">Request All Management Levels?</span>
                                <div className="custom-modal-toggle">
                                    <div className={hasAllManagementLevels ? 'custom-modal-toggle__option active' : 'custom-modal-toggle__option'} onClick={() => setAllManagementLevels(true)}>
                                        Yes
                                    </div>
                                    <div className={hasAllManagementLevels ? 'custom-modal-toggle__option' : 'custom-modal-toggle__option active'} onClick={() => setAllManagementLevels(false)}>
                                        No
                                    </div>
                                </div>
                            </div>
                            <div className={hasAllManagementLevels ? "modal-expanded-content collapsed" : "modal-expanded-content expanded"}>
                                <MultiSelect optionList={managementLevelsList} inputText={"Select Management Levels"} selectedList={selectedManagementLevels} setSelectedList={setSelectedManagementLevels}/>
                            </div>
                        </div>
                        <div className="modal-row-item small">
                            <div className="modal-row-item__top-level toggle-switch">
                                <span className="modal-row-item__top-level-heading">Request All Departments?</span>
                                <div className="custom-modal-toggle">
                                    <div className={hasAllDepartments ? 'custom-modal-toggle__option active' : 'custom-modal-toggle__option'} onClick={() => setAllDepartments(true)}>
                                        Yes
                                    </div>
                                    <div className={hasAllDepartments ? 'custom-modal-toggle__option' : 'custom-modal-toggle__option active'} onClick={() => setAllDepartments(false)}>
                                        No
                                    </div>
                                </div>
                            </div>
                            <div className={hasAllDepartments ? "modal-expanded-content collapsed" : "modal-expanded-content expanded"}>
                                <MultiSelect optionList={departmentsList} inputText={"Select Departments"} selectedList={selectedDepartments} setSelectedList={setSelectedDepartments}/>
                            </div>
                        </div>
                        <div className="modal-row-item small">
                            <div className="modal-row-item__top-level toggle-switch">
                                <span className="modal-row-item__top-level-heading">Request All Job Functions?</span>
                                <div className="custom-modal-toggle">
                                    <div className={hasAllJobFunctions ? 'custom-modal-toggle__option active' : 'custom-modal-toggle__option'} onClick={() => setAllJobFunctions(true)}>
                                        Yes
                                    </div>
                                    <div className={hasAllJobFunctions ? 'custom-modal-toggle__option' : 'custom-modal-toggle__option active'} onClick={() => setAllJobFunctions(false)}>
                                        No
                                    </div>
                                </div>
                            </div>
                            <div className={hasAllJobFunctions ? "modal-expanded-content collapsed" : "modal-expanded-content expanded"}>
                                <MultiSelect optionList={jobFunctionsList} inputText={"Select Job Functions"} selectedList={selectedJobFunctions} setSelectedList={setSelectedJobFunctions}/>
                            </div>
                        </div>
                    </div>
                    {hadError && errorActive &&
                        <motion.div className="modal-error__wrapper mx" initial="hidden" animate="visible" exit="exit" variants={loaderErrorVariants}>
                            <motion.div className="modal__error" initial="hidden" animate="visible" exit="exit" variants={loaderErrorVariants}>
                                <span className="modal__error-text">{errorMessage}</span>
                                <FontAwesomeIcon icon={faTimes} size="lg" className="error-closer" onClick={() => {setErrorActive(false)}} />
                            </motion.div>
                        </motion.div>
                    }
                    <div className='contact-request-form__footer'>
                        <button id="cancel" className="button" onClick={() => setShowModal(false)}>Cancel</button>
                        <button id="continue1" className="variant-1 button" onClick={() => compileAndSubmit()}>Continue</button>
                    </div>
                </div>}
                {contactsRequestPage === 2 && 
                <div className="contact-request-form">
                    <div className="contact-request-form-title__wrapper">
                        <h5 className="contact-request-form-title">Contact Request Form</h5>
                        <FontAwesomeIcon icon={faTimes} size="lg" className="modal-closer" onClick={() => {setShowModal(false)}} />
                    </div>
                    <div className="contact-request-form__body">
                        <h5 className="contact-request-form-title">Your request will result in <b>{numberOfContacts}</b> contacts.</h5>
                        <div className="contact-request-form-paragraph__wrapper">
                            <p className="contact-request-form-paragraph">Please review the following information. <br/>Press “Confirm & Generate” to generate your contact list.</p>
                        </div>
                        <div className="contact-request-form-table__wrapper">
                            <table className="cr-table">
                                <thead>
                                    <tr>
                                        <th>Contact List Name</th>
                                        <th>Departments</th>
                                        <th>Levels</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{contactListName}</td>
                                        <td>{hasAllDepartments ? "All" : selectedDepartments.join(", ")}</td>
                                        <td>{hasAllManagementLevels ? "All" : selectedManagementLevels.join(", ")}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {hadError && errorActive &&
                        <motion.div className="modal-error__wrapper mx" initial="hidden" animate="visible" exit="exit" variants={loaderErrorVariants}>
                            <motion.div className="modal__error" initial="hidden" animate="visible" exit="exit" variants={loaderErrorVariants}>
                                <span className="modal__error-text">{errorMessage}</span>
                                <FontAwesomeIcon icon={faTimes} size="lg" className="error-closer" onClick={() => {setErrorActive(false)}} />
                            </motion.div>
                        </motion.div>
                    }
                    <div className='contact-request-form__footer es'>
                        <button id="cancel" className="button es" onClick={() => {setContactsRequestPage(1); removeError()}}>Back</button>
                        <button id="continue1" className="variant-1 button es" onClick={() => generateContactList()}>Generate</button>
                    </div>
                </div>}
                {contactsRequestPage === 3 && 
                <div className="contact-request-form">
                    <div className="contact-request-form-title__wrapper">
                        <h5 className="contact-request-form-title">Contact Request Form</h5>
                        <FontAwesomeIcon icon={faTimes} size="lg" className="modal-closer" onClick={() => {setShowModal(false)}} />
                    </div>
                    <div className="contact-request-form__body center">
                        <h5 className="contact-request-form-title center-text">View your contacts</h5>
                        <div className="contact-request-form-paragraph__wrapper">
                            <p className="contact-request-form-paragraph center-text">Download your contact list as a .CSV file.</p>
                        </div>
                        <div className='contact-request-form-action__wrapper'>
                            <button id="csv" className="button variant-2 action-button auto-width" onClick={() => pullCSV()}>DOWNLOAD CSV</button>
                        </div>
                    </div>
                </div>}
            </motion.div>
        </motion.div>
    )
}

export default ContactsModal