const topicsList = [
    "1:1 Program",
    "25 Hour Jet Card {Jetscom}",
    "3 Way Matching {HypedUp}",
    "340B",
    "340B Consulting Service {Lighthouse Pharmacy Solutions}",
    "340B Medicaid Duplicate Discount",
    "340B Optimization Services {Lighthouse Pharmacy Solutions}",
    "360 Feedback Software {Qualtrics}",
    "3D Animation & Design",
    "3D Animation Software",
    "3D CAD",
    "3D Design Engineering",
    "3D Metal Printing {AddUp}",
    "3D Modeling",
    "3D Printer Maintenance",
    "3D Printing",
    "3D Scanner",
    "3D Topography",
    "3D Visualization {HTC America}",
    "3GTMS {CLX Logistics}",
    "401k Retirement Plan",
    "45Q {Foss and Company}",
    "4K Display",
    "4imprint",
    "5 Axis Router {CR Onsrud}",
    "5G",
    "5G as a Service {Betacom}",
    "60601 Testing {Eurofins MET Laboratories}",
    "6Sense",
    "8x8, Inc.",
    "A/B Testing Software",
    "A2P {OpenMarket}",
    "AAA Custom 1 {AAA}",
    "ABM Platforms",
    "ABN AMRO",
    "AC Service {Metro Group}",
    "ACO Performance {Olio Health}",
    "ACWI exUS Equity {MacKay Shields}",
    "ADA Inspection {Thermal Cam USA}",
    "ADA Surveys {Sevan Multi Site Solutions}",
    "ADP TotalSource",
    "ADR {National Arbitration and Mediation}",
    "ADX {DemystData}",
    "AECOM Technology",
    "AI Automation",
    "AI Engineering {Quantiphi}",
    "AI Foundry - 0736 {Deloitte}",
    "AI Insights & Engagement {Deloitte}",
    "AI Insights and Engagement - 0629 {Deloitte}",
    "AI Pathology {Paige}",
    "AI Reservoir Sim {Tachyus}",
    "AI-based Predictive Maintenance {SKF}",
    "AIG Cyber Insurance {Sophos}",
    "AMC Health Competitors {AMC Health}",
    "AML KYT {CipherTrace}",
    "AMP for Email {Dyspatch}",
    "AON (AON)",
    "AP Automation",
    "API Gateway",
    "API Management",
    "API Management Platform {Joynd}",
    "API Security",
    "API Storage Tank Emissions and Leakage {Cashco}",
    "APIs and Integration - 0802 {Deloitte}",
    "AR Recovery {Medcare MSO}",
    "ARM {Qualcomm Technologies}",
    "AS 9100 {DQS}",
    "ASC 606",
    "ASC 740",
    "ASC842 {Baker Tilly}",
    "ASO Provider {ARC Group Benefits}",
    "ASR Audio to Text {Verbit}",
    "AT&T",
    "AT&T Retailer",
    "AWS Elastic Beanstalk",
    "AWS IoT",
    "AWS Lambda",
    "Abandoned Retirement Plan",
    "Abbott Laboratories (ABT)",
    "Abbott Test Solutions {Thermo Fisher Scientific}",
    "Abernathy Macgregor {Rockway Asset Management}",
    "Above the Fold",
    "Absolute Return Strategies",
    "Accelerated Mobile Pages (AMP)",
    "Access Certification {Tuebora}",
    "Access Control",
    "Access Governance {Charter Global}",
    "Access Solutions {Flexible Lifeline Systems}",
    "Accessibility Elevators {Savaria}",
    "Accessible Fleet Sales {Savaria}",
    "Account Reconciliation",
    "Account Takeover",
    "Account-Based Marketing (ABM)",
    "Account-Based Sales Development (ABSD)",
    "Accounting",
    "Accounting Audits",
    "Accounting Automation",
    "Accounting Automation {UHY Consulting}",
    "Accounting Compliance",
    "Accounting Consolidation",
    "Accounting Disruption {UHY Consulting}",
    "Accounting Software",
    "Accounts Payable",
    "Accounts Receivable",
    "Accounts Receivable Management Services {CPSI}",
    "Accruent {Nuvolo Limited}",
    "Acoustic Emission Testing {Metal Finishing Technologies}",
    "Acquia",
    "Acquisition Analytics {LatentView}",
    "Acquisition Financing",
    "Acquisition Strategy",
    "Acronis",
    "Actionable Insights",
    "Active Directory",
    "Active Directory Management {Cayosoft}",
    "Active Directory Security {Alsid}",
    "Active Pure Competitors {ActivePure Technology}",
    "Active Shooter {Shooter Detection Systems}",
    "Active Shooter {ZeroEyes}",
    "ActiveBatch {SMA Solutions}",
    "Activity Based Working",
    "Activity-Based Costing (ABC)",
    "Actuarial Service",
    "Actuators",
    "Acumatica",
    "Acumatica {Brightpearl}",
    "Ad Blocking",
    "Ad Creative Management",
    "Ad Effectiveness {Phoenix Marketing Intl}",
    "Ad Exchange",
    "Ad Fraud",
    "Ad Hoc Analytics",
    "Ad Measurement {Phoenix Marketing Intl}",
    "Ad Networks",
    "Ad Optimization",
    "Ad Servers",
    "Ad Verification",
    "Ad Viewability",
    "AdSense",
    "Adaptive Learning",
    "Additive Manufactured Electronics {Nano Dimension}",
    "Additive Manufacturing {Fast Radius}",
    "Address Verification",
    "Addressable TV Advertising",
    "Adecco {Kelly Services}",
    "Adiabatic Process {HEL Group}",
    "Adiabatic/Evaporative Humidifiers {DriSteem}",
    "Adobe Creative Cloud {NPI}",
    "Adobe Experience Manager (AEM)",
    "Adobe Sign",
    "Adobe Summit",
    "Adolescent Substance Abuse {Niznik Behavioral Health}",
    "Adoption Benefits",
    "Adult Learning",
    "Advanced Analytics",
    "Advanced Persistent Threat (APT)",
    "Advanced Primary Care",
    "Advanced TV {Marketing Architects}",
    "Advanced Threat Protection (ATP)",
    "Advent International {Rockway Asset Management}",
    "Advertising",
    "Advertising Agencies",
    "Advertising Campaign",
    "Advertising Solutions",
    "Advisory Services",
    "Advocacy Marketing",
    "Aeration",
    "Aeration {TruGreen}",
    "Aerospace",
    "Aerospace & Defense {Metal Finishing Technologies}",
    "Aerospace 3D Printer {AON3D}",
    "Aetna",
    "Affiliate Marketing",
    "Affiliate Networks",
    "Affirmative Action",
    "Affordable Care Act (ACA)",
    "Aflac (AFL)",
    "Age Discrimination",
    "Agency Bonds {Multi Bank Securities}",
    "Agency Licensing {Agentsync}",
    "Agency Management System",
    "Agency Trading Desk",
    "Agglomeration {Spraying Systems}",
    "Agile Analytics",
    "Agile Business Intelligence",
    "Agile Coaching {Trissential}",
    "Agile Management",
    "Agile Software Development",
    "Agile Tools",
    "Agile Training",
    "Agile Transformation {Tasktop Technologies}",
    "Agile Transformation {Trissential}",
    "Agricultural Equipment",
    "Agriculture Pest Control {Rollins}",
    "Air Cargo",
    "Air Cargo Track and Trace {Hangar A}",
    "Air Duct Cleaning",
    "Air Filter {ActivePure Technology}",
    "Air Freight Forwarder {Hangar A}",
    "Air Freight {Hangar A}",
    "Air Pollution Control",
    "Air Purification",
    "Air Purifier {Aeroex Technologies}",
    "Air {Toll Holdings}",
    "Aircraft Charter {XOJET}",
    "Aircraft Engines {Metal Finishing Technologies}",
    "Aircraft Maintenance",
    "Aircraft Sellers {Sky Aviation}",
    "Airport Operations Management {Veoci}",
    "Airties {Plume Designs Inc}",
    "Akamai Technologies",
    "Alera Group {Paylocity}",
    "All Flash Array",
    "All-hands Products {BrandLive}",
    "Allegis Global Solutions {Kelly Services}",
    "Alliance Marketing",
    "Allocation Entries",
    "Allscripts",
    "Allstate (ALL)",
    "Ally Financial (ALLY)",
    "Alorica",
    "Alt A Loans {Deephaven Mortgage}",
    "Altec Inc {Terex}",
    "Alternative Energy {Bloom Energy}",
    "Alternative Investment",
    "Alteryx",
    "Altus Group {Modern Tax Group}",
    "Aluminum Framing & Extrusion {SPARXiQ}",
    "Aluminum Machining {CR Onsrud}",
    "Alumni Management Software",
    "Amazon",
    "Amazon Advertising",
    "Amazon Cloudfront {Gigamon}",
    "Amazon Connect",
    "Amazon DynamoDB",
    "Amazon Elastic Block Storage (EBS)",
    "Amazon Elastic Compute Cloud {Gigamon}",
    "Amazon Elastic MapReduce (EMR)",
    "Amazon Kinesis",
    "Amazon Pay",
    "Amazon Redshift",
    "Amazon Relational Database Service (RDS)",
    "Amazon S3",
    "Amazon Virtual Private Cloud {Gigamon}",
    "Amazon Web Services (AWS)",
    "American Academy of Physical Medicine and Rehabilitation {Claimocity}",
    "American Arbitration Association {National Arbitration and Mediation}",
    "American Express (AXP)",
    "American International Group (AIG)",
    "Amplitude",
    "Analyst Relations",
    "Analytics Maturity Assessment {International Institute For Analytics}",
    "Analytics Services {LatentView}",
    "Analytics Software",
    "Analytics Strategy {International Institute For Analytics}",
    "Analytics as a service (AaaS)",
    "Anaplan {Public Financial Management}",
    "Android",
    "Android API",
    "Android Push Notifications",
    "Angel Investors",
    "Angular",
    "Animal Research",
    "Animal Testing",
    "Animal Transport",
    "Annual Enrollment",
    "Annual General Meeting {Lumi}",
    "Anomaly Detection {Gigamon}",
    "Anonymization",
    "Ansible",
    "Anthem (ANTM)",
    "Anti Money Laundering {Trulioo}",
    "Anti-Corruption",
    "Anti-Money Laundering",
    "Anti-Racism",
    "Anti-spam",
    "Anti-spyware",
    "Antibody Production {Cell Culture Company}",
    "Antigen Test",
    "Antimicrobial {Nanotouch}",
    "Antitrust",
    "Antivirus",
    "Apache Cassandra",
    "Apache CouchDB",
    "Apache HBase",
    "Apache HTTP Server",
    "Apache Ignite",
    "Apache Kafka",
    "Apache Lucene",
    "Apache Pig",
    "Apache Solr",
    "Apache Spark",
    "App Analytics Companies",
    "App Development {Graphable}",
    "App Install Fraud Competitors {Machine Advertising}",
    "App Install Fraud {Machine Advertising}",
    "App Store Optimization",
    "AppDynamics",
    "AppFire",
    "AppLocker",
    "Apparel Software",
    "Apparel {Coats}",
    "Appeals and Grievances Software {Inovaare}",
    "Appeals and Grievances {Inovaare}",
    "Appian",
    "Apple Business Chat",
    "Apple Mobile Device Management {Addigy}",
    "Apple iPad",
    "Appliance Delivery and Installation {Allegro Home Delivery}",
    "Applicant Tracking Systems",
    "Application Acceleration",
    "Application Delivery",
    "Application Development",
    "Application Infrastructure",
    "Application Integration",
    "Application Layer",
    "Application Lifecycle Management",
    "Application Management",
    "Application Migration",
    "Application Modernization",
    "Application Optimization",
    "Application Performance",
    "Application Performance Management {ElasticSearch}",
    "Application Performance Monitoring (APM) Software",
    "Application Platform",
    "Application Platform as a Service (aPaaS)",
    "Application Release Automation",
    "Application Search {ElasticSearch}",
    "Application Security",
    "Application Security Testing (AST)",
    "Application Server",
    "Application Services",
    "Applications {Canon Solutions}",
    "Appointment Management",
    "Appointment Reminders",
    "Appointment Scheduling",
    "Appointment Setting",
    "Appraisal Management Company {Property Interlink}",
    "Apprentice Workers",
    "Apttus",
    "Aras",
    "Arbitration {National Arbitration and Mediation}",
    "Archibus {Nuvolo Limited}",
    "Architecture",
    "Archiving",
    "ArcticWolf {Sophos}",
    "Arena Solutions",
    "Ariba Consulting {CCP Global}",
    "Ariba Products {CCP Global}",
    "Ariba {CCP Global}",
    "Arista Networks (ANET)",
    "Armored Car",
    "Arrow Electronics (ARW)",
    "Artificial Intelligence",
    "Artificial Intelligence Platforms",
    "Artificial Intelligence for IT Operations",
    "Aruba Networks",
    "As a Service and New Business Incubation - 0636 {Deloitte}",
    "As-Built Drawings {Sevan Multi Site Solutions}",
    "As-a-Service and New Business Incubation {Deloitte}",
    "Asana",
    "Asbestos Removal",
    "Asphalt & Concrete Equipment",
    "Assembler",
    "Assemblies {Nelson Miller}",
    "Assessment Software",
    "Asset Management Software",
    "Asset Management {United FS}",
    "Asset Monetization {Veyron}",
    "Asset Performance Management",
    "Asset Performance Management {SKF}",
    "Asset Retirement Obligations {LeaseQuery}",
    "Asset Tracking",
    "Asset Tracking {Phillips Connect Technologies}",
    "Asset-Based Lending",
    "Asset/Equipment Identification",
    "Assets Under Management (AUM)",
    "Assisted Living Software",
    "Association Management Software",
    "Association Testing",
    "Assurance Collectives {ARC Group Benefits}",
    "Assured Partners {Paylocity}",
    "Athenahealth",
    "Athletic Fundraising {The Power Ad}",
    "Atlassian",
    "Atos",
    "Attack Surface Management",
    "Attendant Console {Imagicle}",
    "Attivio",
    "Attribution Models",
    "Attribution {Novus Partners}",
    "Auction Software",
    "Audience Based Targeting {The Nielsen Company}",
    "Audience Engagement",
    "Audience Management Platform",
    "Audience Response Software",
    "Audience Segmentation",
    "Audio Conferencing",
    "Audio Editing Software",
    "Audio Visual Repair and Support",
    "Audiovisual",
    "Audit Planning",
    "Audit Trail",
    "Audits",
    "AugMedix {DeepScribe}",
    "Augmented Reality",
    "Authentication",
    "Authoring System",
    "Auto Dialer Software",
    "Auto Insurance",
    "Auto Loans",
    "Auto Repair Software",
    "AutoCAD",
    "Autodesk (ADSK)",
    "Autodesk Fusion 360",
    "Autodesk {OpenSpace Labs}",
    "Autodiscovery Tools",
    "Automate Merchant Onboarding {Infinicept}",
    "Automated Contract Management",
    "Automated Guided Vehicles {Alliance Material Handling}",
    "Automated Infrastructure Management (AIM)",
    "Automated Lead Distribution / Lead Assignment {ZoomInfo}",
    "Automated Mailers {Sealed Air}",
    "Automated Marketing",
    "Automated Payroll",
    "Automated Print and Apply {Avery Dennison}",
    "Automated Quality Management (AQM)",
    "Automated Workflow",
    "Automated storage and retrieval systems (ASRS)",
    "Automatic Bill Payment",
    "Automatic Call Distribution",
    "Automatic Data Processing (ADP)",
    "Automatic Doors",
    "Automatic Identification & Capture (AIDC)",
    "Automatic Rollover IRA",
    "Automation Anywhere",
    "Automation Competitors {AutoGen Inc}",
    "Automation Integrator {Sealed Air}",
    "Automation Testing",
    "Automation {Newmark Knight Frank}",
    "Automobile Financing",
    "Automotive Manufacturing {Coats}",
    "Automotive Parts Packaging {Sealed Air}",
    "Automotive Software",
    "Autonomous Maintenance",
    "Autonomous Mobile Robot",
    "Autonomous Vehicle Solutions",
    "Autonomous {Cemtrex}",
    "Autoscaling",
    "Autostore AS {Kuecker Logistics}",
    "Avaya",
    "Aventri",
    "Aviation Financing",
    "Aviation Financing {Honour Capital}",
    "Aviation Insurance",
    "Avigilon",
    "Avnet (AVT)",
    "Awake Security {Gigamon}",
    "Awards",
    "Axis Communications {ZeroEyes}",
    "Azure Arc {Vast IT}",
    "Azure Data Lake",
    "B+N Industries {Principle Global}",
    "B2B Contact Database {ZoomInfo}",
    "B2B Data",
    "B2B E-Commerce",
    "B2B Marketing",
    "B2B Secure Collaboration",
    "B2B Technology",
    "B2C Technology",
    "B2C eCommerce",
    "BB&T Corporation",
    "BBVA Compass Bank",
    "BCP {Cato Networks}",
    "BI Tool Comparison {Graphable}",
    "BMC Software",
    "BMC Switzerland",
    "BMC {NPI}",
    "BNP Paribas",
    "BNSZA Competitors {BNSZA}",
    "BOS Innovations Inc.",
    "BPM {Sapiens}",
    "BT Global Services",
    "BX Software {Qualtrics}",
    "Backend as a Service (BaaS)",
    "Backflow Testing {Metro Group}",
    "Background Check Frustrations {PreEmploy}",
    "Background Check Solutions Superlatives {PreEmploy}",
    "Background Check Solutions {PreEmploy}",
    "Background Checks",
    "Background Investigations",
    "Background Removal {Pixelz}",
    "Backhaul",
    "Backup & Recovery Software",
    "Backup Cameras {Safety Vision}",
    "Bad Debt",
    "Badge Holders",
    "Bags and Totes {Capwell Funding}",
    "Bakery Software",
    "BambooHR {Paylocity}",
    "Bandwidth",
    "Bandwidth as a Service",
    "Bank Account Management {TIS GmbH}",
    "Bank Custody {CipherTrace}",
    "Bank Risk {CipherTrace}",
    "Bank Secrecy Act (BSA)",
    "Bank Statement Loans {Deephaven Mortgage}",
    "Bank of America (BAC)",
    "Banking Software",
    "Bankruptcy",
    "Bankruptcy Attorney {Property Interlink}",
    "Bankruptcy Sale",
    "Banks {Winthrop Resources}",
    "Barclays (BCS)",
    "Barcode Software",
    "Barcoding",
    "Barcoding and QR Coding {HypedUp}",
    "Bare-Metal Cloud",
    "Barrier Bags {Sealed Air}",
    "Basketball Scoreboards {The Power Ad}",
    "Bastian {Fortna}",
    "Batch Processing",
    "Batch Recording {Werum IT Solutions}",
    "Bathroom Hand Dryer {Dyson}",
    "Batteries",
    "Battery Chargers {NOCO Company}",
    "Battery Manufacturing {Alexander Battery Technologies}",
    "Battery Outsourcing {Alexander Battery Technologies}",
    "Battery Pack Assembly {Alexander Battery Technologies}",
    "Battery Testing {HEL Group}",
    "Baxter Planning {Baxter}",
    "Beazley Group",
    "Bed/Breakfast Software {Cloudbeds}",
    "Bedding and Mattresses {Coats}",
    "Beechcraft King Air {Textron Aviation}",
    "Beekeeper {Speakap BV}",
    "Behavioral Data",
    "Behavioral Health {Integrated Behavioral Health}",
    "Benefit Technology Resources {Paylocity}",
    "Benefits Administration",
    "Benefits Administration Software",
    "Berkshire Grey {Fortna}",
    "Berlin Gardens {Prestwick Group}",
    "Best Battery Laptop {Qualcomm Technologies}",
    "Best Financial Advisor Compensation {Moskal Gross Orchosky}",
    "Better Up {ExecOnline}",
    "Beyond Visual Line of Sight {Censys}",
    "Bid Protests",
    "Bidets {Brondell Inc}",
    "Big Data",
    "Big Data Analytics",
    "Big Data Software",
    "Big Data as a Service",
    "BigCommerce",
    "Bill of Materials (BOM)",
    "Bill.com",
    "Billing & Invoicing",
    "Billing and Invoicing - Dental",
    "BillingPlatform {BluLogix}",
    "Billtrust",
    "Bimodal IT",
    "Bin Scale",
    "Bing",
    "Biobank",
    "Biodegradable Lubricants/Oils/Greases",
    "Biogas",
    "Bioinformatics",
    "Bioinformatics {Sapient}",
    "Biological Recapitulation {Emulate}",
    "Biomarkers {Sapient}",
    "Biometrics",
    "Biometrics {Trulioo}",
    "Bioreactor {HEL Group}",
    "Biosensors {Nagase}",
    "Birdeye {Review Trackers}",
    "Birst",
    "Bishop Fox {Cyber Security Works}",
    "BitDefender {Sophos}",
    "BitGlass {Vast IT}",
    "Bitcoin",
    "Bizible",
    "Bizzabo",
    "BlackBerry",
    "Blade Servers",
    "Blasting {Metal Finishing Technologies}",
    "Blink {Speakap BV}",
    "Bliss Point Media {Marketing Architects}",
    "Blockchain",
    "Blockchain Analytic Competition {CipherTrace}",
    "Blockchain as a Service",
    "Blogging",
    "Blue Collar Staffing Needs {Wonolo}",
    "Blue Cross SMB {Oscar Insurance}",
    "Blue Cross {Oscar Insurance}",
    "Blue Jeans Network",
    "Blue Light Emergency Phone",
    "Blue Prism",
    "Bluebeam {OpenSpace Labs}",
    "Bluecherry",
    "Bluewrist Inc.",
    "Board Management Software",
    "Board Portal Software {BoardBookit}",
    "BoardEffect {BoardBookit}",
    "Boardroom Furniture {Wilkhahn}",
    "Body Cameras",
    "Boiler Maintenance and Repair {Metro Group}",
    "Bomb Calorimetry {HEL Group}",
    "Bomgar",
    "Bonds",
    "Bonus Payments",
    "Book Publishing",
    "Booking Engine {Cloudbeds}",
    "Botanical Extraction {Phasex}",
    "Botnet",
    "Bottle Filling",
    "Bottom Funnel {Paragon}",
    "Bottom Line",
    "Bowling {Bowlero}",
    "Box Build Manufacturing",
    "Box Build Outsourcing",
    "Box Build {Greater Machining}",
    "Box, Inc.",
    "Brain Chip {Emulate}",
    "Branch {Rain}",
    "Brand Ambassadors",
    "Brand Awareness",
    "Brand Building Strategy",
    "Brand Health Tracking {Phoenix Marketing Intl}",
    "Brand Intelligence",
    "Brand Licensing Software",
    "Brand Lift {The Nielsen Company}",
    "Brand Loyalty",
    "Brand Management",
    "Brand Name Development {Brand Institute}",
    "Brand Photography",
    "Brand Positioning",
    "Brand Protection",
    "Brand Resonance {The Nielsen Company}",
    "Brand Standards {Intellishop}",
    "Brand Strategies {LaunchMetrics}",
    "Brand Voice",
    "Brand and Advertising {Deloitte}",
    "Brand {Cato Networks}",
    "Brand {Eckoh}",
    "Branded Apparel {Capwell Funding}",
    "Branded Environment Solutions {Principle Global}",
    "Branded Merchandise",
    "Branded Merchandise {WebbMason}",
    "Branded Messaging {OpenMarket}",
    "Branded Retail Environments {Principle Global}",
    "Branding",
    "Branding Agency",
    "Branding {SalesSparx}",
    "Brandwatch",
    "Brandwatch {Talkwalker}",
    "BreakBulk Shipping {Voyager Portal}",
    "Breakroom Furniture",
    "Breakroom Solutions",
    "Breath Alcohol Testing {Fastest Labs}",
    "Brewery Software",
    "BrightLocal {Review Trackers}",
    "Brightedge",
    "Bring Your Own Device (BYOD)",
    "Brivo {Feenics}",
    "BroadCom {SMA Solutions}",
    "Broadband",
    "Broadridge Financial Solutions {Taylor Corporation}",
    "Broken Tile Repair {Roof Rx}",
    "Broker Dealer {Multi Bank Securities}",
    "Broker Licensing {Agentsync}",
    "Brokered Deposits {Multi Bank Securities}",
    "Brown & Brown Insurance",
    "Brunner {Wilkhahn}",
    "Brushless DC Motors {Lin Engineering}",
    "BryterCX (Clickfox)",
    "Bubble Fluid {Froggys Fog}",
    "Bubble Machine {Froggys Fog}",
    "Bucket Trucks {Terex}",
    "Budget Book {GTY Technologies}",
    "Budgeting",
    "Bug Bounty",
    "BugCrowd {Cybri}",
    "Build Automation",
    "Build Management",
    "Building Automation",
    "Building Brand Power {Principle Global}",
    "Building Condition Assessment {Black and McDonald Limited}",
    "Building Controls {Black and McDonald Limited}",
    "Building Envelope {Knauss Property Service}",
    "Building Envelope {Tremco}",
    "Building Information Modeling (BIM)",
    "Building Maintenance",
    "Building Management System (BMS)",
    "Building Operator {Black and McDonald Limited}",
    "Building Sanitization {Jani King}",
    "Building Security",
    "Building Washing {Knauss Property Service}",
    "Buildium",
    "Bulk Packaging",
    "Bulk Personal Care Products {TNG Worldwide}",
    "Bulk Shipping Software {Voyager}",
    "Bulk payments",
    "Burglar Alarms",
    "Burner Service {Metro Group}",
    "Busch Systems {Prestwick Group}",
    "Business Agility {Trissential}",
    "Business Analytics",
    "Business Broker",
    "Business Checking",
    "Business Coaching {CEO Coaching International}",
    "Business Collaboration",
    "Business Continuity",
    "Business Continuity Planning",
    "Business Credit Cards",
    "Business Debit Card",
    "Business Development Company (BDC)",
    "Business Education",
    "Business Email Compromise (BEC)",
    "Business Enablement",
    "Business Ethernet Network",
    "Business Funding",
    "Business Funding {Olympus Business Funding}",
    "Business Health Insurance Benefits {Sana Benefits}",
    "Business Innovation {SalesSparx}",
    "Business Insurance",
    "Business Integration",
    "Business Intelligence",
    "Business Intelligence Analytics",
    "Business Intelligence Software {Transportation Impact}",
    "Business Internet {Oberholtzer Media}",
    "Business Line of Credit",
    "Business Loans",
    "Business Logic and Rules Engine {Sapiens}",
    "Business Metrics",
    "Business Monitoring {HCL Technologies}",
    "Business Owners Policy (BOP)",
    "Business Payment Solutions",
    "Business Personal Property Tax",
    "Business Philanthropy",
    "Business Podcast Platforms {Casted}",
    "Business Process Automation (BPA)",
    "Business Process Management (BPM)",
    "Business Process Outsourcing",
    "Business Process Reengineering (BPR)",
    "Business Process as a Service (BPaaS)",
    "Business Rationalization",
    "Business Relationship Management (BRM)",
    "Business Rules {Sapiens}",
    "Business Savings Account",
    "Business Talent Group {Catalant Technologies}",
    "Business Text Messaging",
    "Business Text Messaging {Infobip}",
    "Business Transformation",
    "Business Travel Expenses",
    "Business Travel Safety",
    "Business Verification {Trulioo}",
    "Business Voicemail Solutions {Mutare Software}",
    "Butler Buildings {Cincinnati Commercial Contracting}",
    "Buy Now Pay Later",
    "Buy a Business {Transworld Business Brokers}",
    "C#",
    "C++",
    "C.H. Robinson",
    "CA Technologies",
    "CA {NOCO Company}",
    "CAD Software",
    "CAM Software",
    "CAPTCHA",
    "CARES Act",
    "CASB {Cato Networks}",
    "CB {NOCO Company}",
    "CBD Gummies {GVB Biopharma}",
    "CBD Supplier {GVB Biopharma}",
    "CBD {GVB Biopharma}",
    "CBG {GVB Biopharma}",
    "CBN {GVB Biopharma}",
    "CBRE (CB Richard Ellis Group)",
    "CBRS {Betacom}",
    "CBRS {Communication Technology Services}",
    "CBS (CBS)",
    "CCPA",
    "CD {Multi Bank Securities}",
    "CDMA",
    "CDW {Sturgeon}",
    "CEC Controls Company Inc",
    "CEIC Data",
    "CEO Coaching {CEO Coaching International}",
    "CI/MI Competitors {Wide Narrow}",
    "CI/MI Software {Wide Narrow}",
    "CIAM 2 {Strivacity}",
    "CIAM {Strivacity}",
    "CITI {Flatirons Digital Innovations}",
    "CJIS Compliance",
    "CMMS Software",
    "CMS Medicare Audit {Inovaare}",
    "CMS Program Audit {Inovaare}",
    "CNC Automation {Fusion Robojob}",
    "CNC Machine",
    "CNC Machining Centers {CR Onsrud}",
    "CNC Machining {Fast Radius}",
    "CNC Router {CR Onsrud}",
    "CO2 Extraction {Phasex}",
    "COBOL",
    "COBRA (Health Insurance Continuation)",
    "COVID Antibody Test",
    "COVID Antigen Test {Assure Global}",
    "COVID-19",
    "COVID-19 Reopening Strategy",
    "COVID-19 Reopening Strategy {Matrix Medical Network}",
    "COVID-19 Screening",
    "COVID-19 Testing",
    "CPG Digital Marketing {MikMak}",
    "CPG Labels {AstroNova}",
    "CPU Usage",
    "CRA",
    "CRE Interest and Inflation {Newmark Knight Frank}",
    "CRM Advice {FreeAgent CRM}",
    "CRM Automation",
    "CRM Best Practices",
    "CRM Enrichment",
    "CRM Management",
    "CRM Retargeting",
    "CRM Software",
    "CT {NOCO Company}",
    "CTV Advertising",
    "CX Competitor Pricing {Qualtrics}",
    "CX Competitor Product Features And Integrations {Qualtrics}",
    "CX Competitor Rating And Reviews {Qualtrics}",
    "CX Competitor {Qualtrics}",
    "CX General Interest {Qualtrics}",
    "CX Software {Qualtrics}",
    "Cable Assemblies",
    "Calabrio {Imagicle}",
    "Calero {Profit Enhancement Services}",
    "Call Abandonment {Fonolo}",
    "Call Analytics",
    "Call Back Software {Fonolo}",
    "Call Center Management",
    "Call Center SLA {Fonolo}",
    "Call Center Software",
    "Call Center {Lexhelper}",
    "Call Centre PCI Compliance {GCI}",
    "Call Recording Software",
    "Call Sentiment Analysis {Imagicle}",
    "Call Spread {J Wood Capital}",
    "Call Tracking",
    "Call Transcripts {Imagicle}",
    "Call Volume Spikes {Fonolo}",
    "Calorimeter {HEL Group}",
    "Camp Management",
    "Campaign Management",
    "Campaign Planning {BrandMaker}",
    "Campus Advertising",
    "Campus Energy Dashboard",
    "Cancer Genomics",
    "Cancer Gifts {Spoonful}",
    "Candidate Engagement {ViziRecruiter}",
    "Candidate Experience",
    "Candidate Sourcing",
    "Cannabis Banking Compliance Software {NatureTrak Inc}",
    "Cannabis Growers {Environmental Compliance Equipment}",
    "Cannabis Regulatory Authority {NatureTrak Inc}",
    "Cannabis regulations",
    "Canon {Canon Solutions}",
    "Canon {Revolution Office}",
    "Canvassing {SalesRabbit}",
    "Cap Tables",
    "Capacity Management",
    "Capacity Planning",
    "Capacity Tag Management",
    "Capacity Utilization",
    "Capital Equipment Financing",
    "Capital Injection",
    "Capital Management {Aurigo}",
    "Capital One Financial (COF)",
    "Capital Planning {Aurigo}",
    "Capped Call {J Wood Capital}",
    "Caps and Hats {Capwell Funding}",
    "Captions {Verbit}",
    "Captive Insurance {Gilroy Kernan and Gilroy}",
    "Captives {Winthrop Resources}",
    "Car Lease {Canford Finance}",
    "Car Rental Software",
    "Carahsoft",
    "Carbon Accounting {Tachyus}",
    "Carbon Black",
    "Carbon Capture Tax Credits {Foss and Company}",
    "Carbon Capture {FuelCell Energy}",
    "Carbon Capture, Utilization, and Storage {Cashco}",
    "Carbon Emission Estimation {Tachyus}",
    "Carbon Emissions {Energy Action}",
    "Carbon Footprint",
    "Carbon Management",
    "Carbon Neutrality {Veolia}",
    "Card Verification Value (CVV)",
    "CardConnect {Affinipay}",
    "Cardinal Logistics",
    "Care Coordination",
    "Care Management",
    "Care Navigation",
    "Career Development",
    "Career Management",
    "Cargo Security {ShadowTrack}",
    "Cargo Tracking {ShadowTrack}",
    "Carpet Cleaning",
    "Carrier Appointments and Terminations {Agentsync}",
    "Carrier Billing",
    "Carrier Payments {TriumphPay}",
    "Carrum Health {EmployeeDirect Healthcare}",
    "Carve-Out",
    "Case Management System",
    "Cash Application {Esker Inc}",
    "Cash Balance Plan",
    "Cash Counter {Cash Processing Solutions}",
    "Cash Flow Management",
    "Cash Management",
    "Cash Processing {Cash Processing Solutions}",
    "Cash Recycler {Cash Processing Solutions}",
    "Cash Recyclers",
    "Cash Sorter {Cash Processing Solutions}",
    "Cash flow forecasting",
    "Cashless Tipping Solutions {Rain}",
    "Cass Information Systems",
    "Cass Information Systems {TriumphPay}",
    "Cast Urethane {Fast Radius}",
    "Casualty Insurance",
    "Catalant {Catalant Technologies}",
    "Catalog Management Software",
    "Catalysis {HEL Group}",
    "Category Management",
    "Catering",
    "Catering Software",
    "Caulking Sealant {Knauss Property Service}",
    "Causal Artificial Intelligence/Causal Machine Learning {GNS Healthcare}",
    "Cell Banking {Cell Culture Company}",
    "Cell Booster",
    "Cell Culture {Cell Culture Company}",
    "Cell Culture {Emulate}",
    "Cell Therapy",
    "Cellular IOT",
    "Cellular/LTE Laptop {Qualcomm Technologies}",
    "Celona {Communication Technology Services}",
    "Celonis",
    "Cemetery Software",
    "Centerline Manufacturing",
    "Centers for Disease Control and Prevention(CDC)",
    "Centers for Medicare and Medicaid Services",
    "Centers of Excellence (CoEs)",
    "Centerview {Rockway Asset Management}",
    "Centric",
    "CenturyLink",
    "Ceridian",
    "Cerner",
    "Cerner Consulting Services {HCTec}",
    "Cerner HealtheLife Support {HCTec}",
    "Cerner Services {HCTec}",
    "Cerner Support {HCTec}",
    "Cerner Training Services {HCTec}",
    "Certificate Authority (CA)",
    "Certificate Programs",
    "Certificate Signing Request (CSR)",
    "Certificate of Deposit",
    "Certification testing",
    "Certifications {Metal Finishing Technologies}",
    "Certified Information Systems Security Professional (CISSP)",
    "Certified Public Accountant {Paylocity}",
    "Cessna Citation {Textron Aviation}",
    "Chain of Compliance {Werum IT Solutions}",
    "Challenger Sales {Challenger}",
    "Change Management",
    "Change Management {Sapiens}",
    "Channel Incentives {ACB}",
    "Channel Management",
    "Channel Management Software",
    "Channel Management Software {Cloudbeds}",
    "Channel Marketing",
    "Channel Partner Management",
    "Channel Program",
    "Chaos Engineering",
    "Chapter 11 Bankruptcy (Reorganization)",
    "Chapter 7 Bankruptcy (Liquidation)",
    "Charge Capture {Claimocity}",
    "ChargePoint",
    "Chargeback",
    "Charitable Giving",
    "Chart Recorder {Astro Nova}",
    "Charter Flights {Sky Aviation}",
    "Charter Management Software {Voyager Portal}",
    "Charter Transportation",
    "Chase Bank",
    "ChatOps",
    "Chatbot & Live Chat Software",
    "Chatbot Enabled Payments",
    "Chatbot {AtlasRTX}",
    "Chatbots {Rasa Technologies}",
    "Check Fraud",
    "Check Point Software Technologies",
    "Checking Account",
    "Checking Account Acquisition {CS3 Marketing}",
    "Checkout Optimization",
    "ChemTrec {Arrowdale I}",
    "Chemical Analysis {Thermo Fisher Scientific}",
    "Chemical Logistics {CLX Logistics}",
    "Chemical Software",
    "Chemical Synthesis {HEL Group}",
    "Chemical Water Treatment {Metro Group}",
    "Cherwell Software",
    "Child Care",
    "Child Care Software",
    "Child Labor",
    "Chili Piper",
    "Chinese Translation {Capital Linguistics}",
    "Chiropractic Billing",
    "Chiropractic Software",
    "Choice Architecture",
    "Chorus.ai",
    "Chrome River",
    "Chromebook",
    "Chromebook Insurance {Technology Resource Advisors}",
    "Chromebook Parts {Technology Resource Advisors}",
    "Chromebook Repair {Technology Resource Advisors}",
    "Chronic Care Management {Matrix Medical Network}",
    "Chronic Care Software {ADURO}",
    "Chronic Disease Prevention {Weight Watchers}",
    "Chubb",
    "Church Management Software",
    "Church Streaming {WSN Live}",
    "Churn Management {Vindicia}",
    "Cigna SMB {Oscar Insurance}",
    "Cigna {Oscar Insurance}",
    "Ciox {ScanSTAT Technologies}",
    "Circuit Design",
    "Cisco ACI",
    "Cisco AMP {Sophos}",
    "Cisco Certification",
    "Cisco Collaboration",
    "Cisco Meraki",
    "Cisco Meraki {Sophos}",
    "Cisco Systems",
    "Cisco Unified Computing System",
    "Cisco WebEx",
    "Cisco {NPI}",
    "Citibank",
    "Citigroup",
    "Citizen Developer {Sapiens}",
    "Citizen Development",
    "Citizens Bank",
    "Citric Acid {Froggys Fog}",
    "Citrix Systems",
    "City Budgeting {GTY Technologies}",
    "City Procurement",
    "Civil 3D {Applied Technology Group}",
    "Civil Engineering",
    "Civil Rights",
    "Claim Denial",
    "Claims Auditing / Analysis",
    "Claims Management",
    "Claims Management Solutions/Software/Companies {Sapiens}",
    "Claims Processing",
    "Clarabridge {Qualtrics}",
    "Clarabridge {Talkwalker}",
    "Claroty",
    "Classroom Furniture",
    "Classroom Management Software",
    "Clayton Dubilier and Rice {Rockway Asset Management}",
    "Clean River and Magnuson Group {Prestwick Group}",
    "Cleaning Labor {Jani King}",
    "Cleaning {Metal Finishing Technologies}",
    "Clear Span Tent Structure {Creative Tents}",
    "ClearDB",
    "ClearSlide",
    "Clearbit",
    "Clia Certification {Thermo Fisher Scientific}",
    "Click Fraud Software",
    "Click and Collect",
    "Click-Through Rate (CTR)",
    "Click-to-Call",
    "Clickstream Analytics",
    "Clicktale",
    "Client Accounting Services (CAS)",
    "Client Entertainment",
    "Client Entertainment Event {Bowlero}",
    "Client Gifts {Spoonful}",
    "Client-Side Security",
    "Clienteling {Endear}",
    "Climate Controlled Storage",
    "Climate-Smart Agriculture",
    "Climb",
    "Clinical Data Management",
    "Clinical Decision Support {Benefit Resource}",
    "Clinical Forecasting {4G Clinical}",
    "Clinical Helpdesk {HCTec}",
    "Clinical Research",
    "Clinical Testing {Thermo Fisher Scientific}",
    "Clinical Trial",
    "Clinical Trial Management System (CTMS)",
    "Clinical Trial Supplies {Clinigen Group}",
    "Clinical Trial {Metabolon}",
    "Clinical Trials {Sapient}",
    "Clinical Workflows",
    "Clinicogenomic Data {GNS Healthcare}",
    "Clipping Path {Pixelz}",
    "Closed Captioning",
    "Cloud Access Security Broker",
    "Cloud Accounting",
    "Cloud Adoption {DMI}",
    "Cloud Applications",
    "Cloud Architect",
    "Cloud Automation",
    "Cloud Backup & Recovery",
    "Cloud Based Trucking Software",
    "Cloud Call Center {Skit.ai}",
    "Cloud Communications",
    "Cloud Compliance",
    "Cloud Computing",
    "Cloud Contact Center",
    "Cloud Cost Optimization",
    "Cloud Data",
    "Cloud Database",
    "Cloud Digital Asset Management",
    "Cloud ERP",
    "Cloud Fax",
    "Cloud Harmonics",
    "Cloud IDE",
    "Cloud Infrastructure",
    "Cloud Infrastructure and Engineering - 0511 {Deloitte}",
    "Cloud Infrastructure and Engineering {Deloitte}",
    "Cloud Integration",
    "Cloud Logistics",
    "Cloud Managed Services - 0697 {Deloitte}",
    "Cloud Managed Services {Deloitte}",
    "Cloud Management",
    "Cloud Marketing",
    "Cloud Migration",
    "Cloud Migration {Deloitte}",
    "Cloud Monitoring {ElasticSearch}",
    "Cloud Native Development and Integration {Deloitte}",
    "Cloud Native and Application Development - 0510 {Deloitte}",
    "Cloud OS",
    "Cloud Operations {DMI}",
    "Cloud Optimization {Profit Enhancement Services}",
    "Cloud Orchestration",
    "Cloud Printing {Superior Office Products}",
    "Cloud Providers",
    "Cloud Provisioning",
    "Cloud Security",
    "Cloud Services",
    "Cloud Services Brokerage",
    "Cloud Storage",
    "Cloud Strategy",
    "Cloud Strategy Arch and Transformation - 0513 {Deloitte}",
    "Cloud Strategy {Deloitte}",
    "Cloud Telephony {GCI}",
    "Cloud VC",
    "Cloud VoIP",
    "Cloud and Edge Infrastructure",
    "Cloud as a Service",
    "Cloud-Based Analytics",
    "Cloud-Based Digital Signage",
    "Cloud-Connected Audio",
    "CloudAbility {CloudCheckr}",
    "CloudHealth {CloudCheckr}",
    "CloudHealth {Vast IT}",
    "Cloudera",
    "Cloudingo",
    "Clover",
    "Cluster Analysis",
    "Clustering",
    "Co Op {ACB}",
    "Co-Op Advertising",
    "Co-working",
    "Coaching & Mentoring",
    "Coaxial Cables",
    "Cobalt Competitors {Jungle Scout}",
    "Cobalt {Cybri}",
    "Cobalt {Jungle Scout}",
    "CobbleStone",
    "Code Coverage {Codecov}",
    "Code Quality {Codecov}",
    "Code42",
    "Coders for Hire {Turing}",
    "Coding {AQuity}",
    "Coffee services",
    "Cogeneration {Veolia}",
    "Cognex",
    "Cognism",
    "Cognitive Computing",
    "Cognitive IoT",
    "Cognitive Search {Advertas}",
    "Cognizant",
    "Cohesity {Vast IT}",
    "CollabNet VersionOne",
    "Collaboration Portal",
    "Collaboration Software",
    "Collaborative Commerce",
    "Collaborative Innovation",
    "Collaborative Robots",
    "Collaborative Work Management",
    "Collection Agency {NCSPlus}",
    "Collection Competitors {AutoGen Inc}",
    "Collections Management {Esker Inc}",
    "Collective Bargaining",
    "College and University Event Platforms {eCareerFairs}",
    "College/Campus Recruiting",
    "Colocation",
    "Color Formulation",
    "Color Measurement",
    "Color Quality",
    "Com Carrier Board {WinSystems}",
    "Com Express Modules {WinSystems}",
    "Combilift {Alliance Material Handling}",
    "Combined Competitors {XOJET}",
    "Combined Cooling, Heat and Power (CCHP)",
    "Comcast",
    "Command Line Interface",
    "Comment Systems",
    "CommerceHub",
    "Commercebuild {DMI}",
    "Commercetools",
    "Commercial & Real Estate Financing",
    "Commercial Auto Insurance",
    "Commercial Bridge Loans {Verus Commercial Real Estate Finance}",
    "Commercial Building",
    "Commercial Cleaning",
    "Commercial Cleaning Supplies",
    "Commercial Door",
    "Commercial Door Seal {Reed Rubber Products}",
    "Commercial Fleet Sales",
    "Commercial Floor Care",
    "Commercial Flooring",
    "Commercial Hand Dryers {Dyson}",
    "Commercial Insurance",
    "Commercial Internet of Things",
    "Commercial Landscaping",
    "Commercial Lawn Care",
    "Commercial Lending",
    "Commercial Mortgages",
    "Commercial Off-The-Shelf (COTS)",
    "Commercial Painting",
    "Commercial Paper",
    "Commercial Paving",
    "Commercial Perimeter Protection",
    "Commercial Pest Control",
    "Commercial Printing",
    "Commercial Printing {Taylor Corporation}",
    "Commercial Production",
    "Commercial Real Estate Agent {Transworld Business Brokers}",
    "Commercial Real Estate Appraisal {Property Interlink}",
    "Commercial Real Estate Brokerage",
    "Commercial Real Estate Investing",
    "Commercial Real Estate {Cincinnati Commercial Contracting}",
    "Commercial Real Estate {Wilson Office Interiors}",
    "Commercial Refrigeration",
    "Commercial Restroom Services",
    "Commercial Roofing",
    "Commercial Security",
    "Commercial Trade",
    "Commission Junction",
    "Commissioning Services {35 North}",
    "Common Access Card (CAC)",
    "Common Criteria (CC)",
    "Commscope (COMM)",
    "Communication Hardware",
    "Communication Server",
    "Communications Platform as a Service (CPaaS)",
    "Communications Satellite",
    "Communications Systems",
    "Community Management",
    "Community Reinvestment Act (CRA)",
    "Community Selling {Firework}",
    "Community Solar {Insight Sourcing Group}",
    "Community Wifi {Smart Rent}",
    "Commuting",
    "Commvault",
    "Company Car {Canford Finance}",
    "Company Dissolution",
    "Company Sports Event",
    "Company Store",
    "Company Store {WebbMason}",
    "Comparably",
    "Comparator Sourcing {Clinigen Group}",
    "Compensation",
    "Compensation Management",
    "Compensation Planning {Compaas}",
    "Competencies",
    "Competition {RobinAI}",
    "Competition {Sapiens}",
    "Competition {Winthrop Resources}",
    "Competitive / SWOT Analysis",
    "Competitive Bid",
    "Competitive Flatbed {Canon Solutions}",
    "Competitive Intelligence {Cipher Systems}",
    "Competitive Intelligence {QL2 Software}",
    "Competitive Matrix {Cipher Systems}",
    "Competitive Rolltoroll {Canon Solutions}",
    "Competitive Strategy {Cipher Systems}",
    "Competitor Analysis {Cipher Systems}",
    "Competitor List {Reputation Sensei}",
    "Competitor Research {Cipher Systems}",
    "Competitor {Fonolo}",
    "Competitor {LaunchMetrics}",
    "Competitors 1 Allocators {Novus Partners}",
    "Competitors 2 Managers {Novus Partners}",
    "Competitors AND {AQuity}",
    "Competitors Automation {Pixelz}",
    "Competitors Traditional {Pixelz}",
    "Competitors of CURATE {Ikon Science}",
    "Competitors of ROKDOC {Ikon Science}",
    "Competitors {AQuity}",
    "Competitors {Agentsync}",
    "Competitors {Black and McDonald Limited}",
    "Competitors {Cato Networks}",
    "Competitors {Censys}",
    "Competitors {Communication Technology Services}",
    "Competitors {Derivative Path}",
    "Competitors {Dyspatch}",
    "Competitors {Eckoh}",
    "Competitors {Gridless Power}",
    "Competitors {HealthDirect Institutional Pharmacy Services}",
    "Competitors {Jani King}",
    "Competitors {Joynd}",
    "Competitors {Lexhelper}",
    "Competitors {LinearB}",
    "Competitors {Lynx Systems}",
    "Competitors {McKesson Ontada}",
    "Competitors {MediData}",
    "Competitors {Mimeo}",
    "Competitors {OpenSymmetry}",
    "Competitors {Paragon}",
    "Competitors {Payveris}",
    "Competitors {PreEmploy}",
    "Competitors {Reed Rubber Products}",
    "Competitors {ReferralPoint}",
    "Competitors {Rollins}",
    "Competitors {SalesRabbit}",
    "Competitors {Skynamo}",
    "Competitors {UHY Consulting}",
    "Competitors {Venio}",
    "Competitors {Verbit}",
    "Competitors {WSN Live}",
    "Competitors {White Label Communications}",
    "Complaint Management Software",
    "Complete Blood Count",
    "Complete Response Letter {Rockway Asset Management}",
    "Complex Event Processing (CEP)",
    "Compliance",
    "Compliance Audit",
    "Compliance Management",
    "Compliance Markets {Redshaw Advisors Limited}",
    "Compliance Tools",
    "Compliance Tools and Compliance Training {Unemployment Services Trust}",
    "Compliance Training",
    "Compliance {Eckoh}",
    "Composability {Clear Dynamics}",
    "Composable Enterprise {Clear Dynamics}",
    "Composite Mats {Mavrik Sales}",
    "Composites {Coats}",
    "Compound Semiconductor {Nagase}",
    "Compressors",
    "Computacenter",
    "Computer Hacking",
    "Computer Hardware",
    "Computer Hijacking",
    "Computer On Module {WinSystems}",
    "Computer Recycling, E-waste, Electronics Recycling",
    "Computer Science",
    "Computer Software Assurance {Trissential}",
    "Computer Telephony Integration (CTI)",
    "Computer Virus",
    "Computer Vision",
    "Computer-Assisted Coding (CAC)",
    "Computer-Based Training",
    "Concentrix",
    "Concierge Medical Services",
    "Concierge Medicine {MDVIP}",
    "Concrete Cleaning",
    "Concur Travel",
    "Condition Based Maintenance",
    "Condition Monitoring Companies {SKF}",
    "Condition Monitoring {SKF}",
    "Conductor",
    "Conference Room Furniture {Wilkhahn}",
    "Conference Room technology",
    "Confidential Information",
    "Configuration Audit",
    "Configuration Files",
    "Configuration Management",
    "Configuration Options",
    "Configure Price Quote (CPQ)",
    "Conflict Resolution",
    "Confluence",
    "Confluent",
    "Confocal",
    "Conga",
    "Congress {LFA Holdings}",
    "Connected Cars",
    "Connected Enterprise",
    "Connected Experience",
    "Connected TV advertising {Marketing Architects}",
    "Connected World",
    "Consent Management",
    "Constant Bitrate (CBR)",
    "Constant Contact",
    "Constant Voltage Charger {Rendell Sales Co}",
    "Construction Accounting Services",
    "Construction Accounting Software",
    "Construction Cameras",
    "Construction Cost Estimating {35 North}",
    "Construction Costs {Newmark Knight Frank}",
    "Construction Documentation/Timelapse {OpenSpace Labs}",
    "Construction Equipment",
    "Construction Estimating Software",
    "Construction Loan Software {Rabbet}",
    "Construction Loans",
    "Construction Management Software",
    "Construction Management {Cincinnati Commercial Contracting}",
    "Construction Photography {OpenSpace Labs}",
    "Construction Project Management {35 North}",
    "Construction Scheduling Software {Assignar Inc}",
    "Construction Standards",
    "Construction Technology",
    "Construction Technology {OpenSpace Labs}",
    "Construction Time Tracking {Assignar Inc}",
    "Construction/Renovations",
    "Construction/Renovations {Cincinnati Commercial Contracting}",
    "Consultative Selling {Challenger}",
    "Consulting {LFA Holdings}",
    "Consumer Intelligence {Talkwalker}",
    "Consumer Marketing",
    "Consumer Rebates {ACB}",
    "Consumer Spending",
    "Consumption {Winthrop Resources}",
    "Contact Center Automation {GCI}",
    "Contact Center Automation {Skit.ai}",
    "Contact Center Outsourcing",
    "Contact Center Solutions",
    "Contact Center Technical Support Agents {Quantanite}",
    "Contact Center as a Service (CCaaS)",
    "Contact Center {Eckoh}",
    "Contact Centre/Customer Care Software {Qualtrics}",
    "Contact Management Software",
    "Contact Tracing",
    "Contact Tracing Software {Central Research}",
    "Contactless Payment",
    "Container Management Software",
    "Containerization",
    "Content Delivery Networks",
    "Content Filtering",
    "Content Governance",
    "Content Ingestion",
    "Content Integration",
    "Content Management",
    "Content Management System",
    "Content Management System Tools",
    "Content Marketing",
    "Content Marketing Platform {Casted}",
    "Content Marketing Strategy {Casted}",
    "Content Moderation",
    "Content Monitoring",
    "Content Personalization",
    "Content Recommendation",
    "Content Sharing",
    "Content Strategy",
    "Content Syndication",
    "Contentful",
    "Contentsquare",
    "Contextual Analytics",
    "Contingent Staffing",
    "Continuing Education",
    "Continuing Legal Education (CLE)",
    "Continuous Attack Surface Testing {SynnerComm}",
    "Continuous Availability",
    "Continuous Controls Monitoring",
    "Continuous Deployment",
    "Continuous Integration",
    "Continuous Process",
    "Contract Automation {RobinAI}",
    "Contract Lifecycle Management",
    "Contract Management Software",
    "Contract Manufacturer",
    "Contract Manufacturing Organization {Cell Culture Company}",
    "Contract Negotiations",
    "Contract Packaging {Astro Nova}",
    "Contract Packaging {Sealed Air}",
    "Contract Recruiter {Cornerstone RPO}",
    "Contract Renewal",
    "Contract Works",
    "Contract research organization (CRO)",
    "Contractor Compliance",
    "Contractor Management",
    "Control Risks",
    "Controlled Unclassified Information (CUI) {RegDOX}",
    "ConvergeHealth - 0724 {Deloitte}",
    "ConvergeHealth Safety - 0779 {Deloitte}",
    "Converged Infrastructure",
    "Conversation Intelligence",
    "Conversational AI Customer Experience {Quantiphi}",
    "Conversational AI Platforms {Rasa Technologies}",
    "Conversational AI {Advertas LLC}",
    "Conversational AI {Rasa Technologies}",
    "Conversational AI {Skit.ai}",
    "Conversational Commerce",
    "Conversational IVR {Advertas}",
    "Conversational Marketing",
    "Conversion Marketing",
    "Conversion Rate Optimization (CRO)",
    "Convertible Bond",
    "Convertible Bonds {Matthews South}",
    "Convertible Debt {J Wood Capital}",
    "Convertible Laundry Carts {EBCO Industry}",
    "Conveyor & Sortation",
    "Conveyors {Raymond Handling Solutions}",
    "Convoy {JB Hunt}",
    "Cooler Bag {Redibag USA}",
    "Cooler Seal Manufacturing {Reed Rubber Products}",
    "Cooling Fans",
    "Cooling Systems",
    "Cooling Tower Maintenance and Repair {Metro Group}",
    "Cooling Towers {Veolia}",
    "Coordinate Measuring Machine (CMM)",
    "Copier Services {Superior Office Products}",
    "Copiers",
    "Copy Paper {Garveys Office Products}",
    "Core Analysis",
    "Core Bond Fund {MacKay Shields}",
    "Core Plus Bond Fund {MacKay Shields}",
    "CoreHR",
    "Cornerstone OnDemand (CSOD)",
    "Corporate Apparel",
    "Corporate Bonds {Multi Bank Securities}",
    "Corporate Communications",
    "Corporate Concierge",
    "Corporate Culture",
    "Corporate Documents",
    "Corporate Events {Bowlero}",
    "Corporate Financing",
    "Corporate Fitness Facility {Peloton Interactive}",
    "Corporate Fixed Income {MacKay Shields}",
    "Corporate Gifting",
    "Corporate Holidays {Spoonful}",
    "Corporate Housing",
    "Corporate Marketing Events",
    "Corporate Naming {Brand Institute}",
    "Corporate Payments {TIS GmbH}",
    "Corporate Performance Management",
    "Corporate Portals",
    "Corporate Relocation",
    "Corporate Retreat",
    "Corporate Social Responsibility (CSR)",
    "Corporate Social Responsibility (CSR) Software",
    "Corporate Tax Software",
    "Corporate Universities",
    "Corporate Video",
    "Corporate Website",
    "Corporate Wellness Software",
    "Corporate Wellness {Weight Watchers}",
    "Corrective Action Preventive Action (CAPA)",
    "Corrugated Packaging",
    "Cosmetic Manufacturing {Univar Inc}",
    "Cost Control",
    "Cost Efficiency",
    "Cost Savings",
    "CouchBase",
    "Counseling",
    "Coupa (COUP)",
    "Courier Mobile Apps {Taylor Data Systems}",
    "Courier Software",
    "Course Authoring Software",
    "Court Management Software",
    "Covered California {De La Torre}",
    "Covid Cleaning Services {Wilson Office Interiors}",
    "Covid Mandates",
    "Covid Research",
    "Coyote Logistics",
    "Crane Mats {Mavrik Sales}",
    "Crates {UFP Industries}",
    "Creaform",
    "Creative Agency",
    "Creative Optimization",
    "Creative Services",
    "Credential Stuffing",
    "Credential management",
    "Credit & Collections",
    "Credit Card Fraud",
    "Credit Card Processing",
    "Credit Cards",
    "Credit Fixed Income {MacKay Shields}",
    "Credit Insurance {Trade Credit Insurance Agency}",
    "Credit Management",
    "Credit Reporting Agency",
    "Credit Reporting {Property Interlink}",
    "Credit Union Marketing {CS3 Marketing}",
    "Credit Unions",
    "Cresa {Cresa Global}",
    "Cresa {West Lane Schlager}",
    "Crestron",
    "Crisis Communication",
    "Critical Path Method (CPM)",
    "Cross Border Supply Chain {Kloeckner Metals}",
    "Cross-border payments",
    "Cross-selling",
    "Cross-site Scripting",
    "Crowd Management",
    "CrowdStrike",
    "Crowdfunding",
    "Crowdsourcing",
    "Crowdstrike {Sophos}",
    "Crowdtesting",
    "Crown {Alliance Material Handling}",
    "Crushers {EBCO Industry}",
    "Cryo Milling {Omni International}",
    "Cryovac {Sealed Air}",
    "Cryptocurrency",
    "Cryptojacking",
    "Crystallization {HEL Group}",
    "CtrlS",
    "Culture Amp",
    "CultureAmp {Engagement Multiplier}",
    "Currency Risk",
    "Current Expected Credit Loss Standard {Valuant}",
    "Cushman & Wakefield",
    "Custody and Escrow Services {Argent Financial Group}",
    "Custom App/Software Development {Profusion Systems}",
    "Custom Battery Charger {Rendell Sales Co}",
    "Custom Battery Packs {Alexander Battery Technologies}",
    "Custom Damper Seal {Reed Rubber Products}",
    "Custom Ducting",
    "Custom Lithium-Ion Polymer Battery Pack {Rendell Sales Co}",
    "Custom Manufacturing {Fast Radius}",
    "Custom Network Transformation {Gigamon}",
    "Custom Packaging",
    "Custom Packaging {Taylor Corporation}",
    "Custom Presentation Boxes {Weldon Williams & Lick}",
    "Custom Promotional Items {WebbMason}",
    "Custom Software Development",
    "Custom Stickers {AstroNova}",
    "Custom Thermal Stock {Weldon Williams & Lick}",
    "Custom Workstations {SPARXiQ}",
    "Customer Acquisition",
    "Customer Acquisition Cost (CAC)",
    "Customer Advocacy Software",
    "Customer Analytics {LatentView}",
    "Customer Attrition",
    "Customer Behavior",
    "Customer Churn Analytics {LatentView}",
    "Customer Communication Management System {Metroland Business Machines}",
    "Customer Communications",
    "Customer Data Integration",
    "Customer Data Management",
    "Customer Data Platform (CDP) Software",
    "Customer Due Diligence {Trulioo}",
    "Customer Engagement",
    "Customer Engagement {Eckoh}",
    "Customer Experience Analytics",
    "Customer Experience Management",
    "Customer Experience Software",
    "Customer Feedback Management",
    "Customer Financing",
    "Customer Insight",
    "Customer Intelligence",
    "Customer Interaction Management",
    "Customer Journey",
    "Customer Journey Management",
    "Customer Journey Map",
    "Customer Journey Software",
    "Customer Lifecycle",
    "Customer Lifetime Value",
    "Customer Loyalty",
    "Customer Marketing {Crowdvocate LTD}",
    "Customer Onboarding {Rocketlane Corp}",
    "Customer Onboarding {Trulioo}",
    "Customer Reference {Crowdvocate LTD}",
    "Customer Relationship Management",
    "Customer Retention",
    "Customer Review Best Practices {Reputation Sensei}",
    "Customer Satisfaction",
    "Customer Self Service",
    "Customer Service AI {Skit.ai}",
    "Customer Service Automation",
    "Customer Service Best Practices",
    "Customer Service Outsourcing",
    "Customer Service {Quantanite}",
    "Customer Strategy and Experience - 0521 {Deloitte}",
    "Customer Strategy and Experience {Deloitte}",
    "Customer Success Management",
    "Customer Success Software",
    "Customer Support Analytics",
    "Customer Support Operations {MessageBird}",
    "Customer Training and Onboarding",
    "Customer Video Feedback {Veedback}",
    "Customer Wait Experience",
    "Customs Brokerage",
    "Customs {Toll Holdings}",
    "Cutting {Flow International}",
    "Cvent",
    "Cyber Awareness {Cybermaniacs}",
    "Cyber Deception",
    "Cyber Intelligence",
    "Cyber Liability Insurance",
    "Cyber Range",
    "Cyber Risk Management",
    "Cyber Security Compliance {Sophos}",
    "Cyber Security Framework",
    "Cyber Threat Hunting",
    "Cyber Threats",
    "CyberArk {Thycotic}",
    "Cybercrime",
    "Cybersecurity",
    "Cybersecurity Awareness Training {Cybermaniacs}",
    "Cybersecurity Maturity Model Certification (CMMC)",
    "Cybersource",
    "Cyberwarfare",
    "Cybrary {TryHackMe}",
    "Cylance {Sophos}",
    "D&H Distributing",
    "DAT (Delivery at Terminal)",
    "DB {NOCO Company}",
    "DCL Logistics",
    "DDoS & Bot Detection",
    "DELL EMC (EMC)",
    "DHL Express",
    "DLT",
    "DMS {Agaram Technologies}",
    "DNA Extraction {AutoGen Inc}",
    "DNA Sequencing",
    "DNN Software",
    "DORA Metrics {LinearB}",
    "DOT Drug Testing {Fastest Labs}",
    "DOT Hazmat Violations {Arrowdale I}",
    "DQ {NOCO Company}",
    "DU {NOCO Company}",
    "DXC Technology",
    "Daily Active Users (DAU)",
    "Daily Deals",
    "DailyPay {Rain}",
    "Dairy Packaging {Sealed Air}",
    "Dangerous Goods",
    "Dark Fiber",
    "Dark Web",
    "Dark Web Monitoring",
    "DarkTrace {Sophos}",
    "Darktrace {Gigamon}",
    "Dash Camera",
    "Dassault Systemes",
    "Data Acquisition Systems {AstroNova}",
    "Data Aggregation",
    "Data Analytics",
    "Data Analytics & Modernization {Deloitte}",
    "Data Append",
    "Data Breach",
    "Data Catalogue",
    "Data Center",
    "Data Center Automation",
    "Data Center Consolidation",
    "Data Center Decommission",
    "Data Center Design",
    "Data Center Equipment",
    "Data Center Infrastructure Management (DCIM)",
    "Data Center Interconnect",
    "Data Center Maintenance",
    "Data Center Management",
    "Data Center Migration",
    "Data Center Power",
    "Data Center Relocation {Allegro Home Delivery}",
    "Data Center Security",
    "Data Center Sustainability",
    "Data Center Transformation {DMI}",
    "Data Centricity {Trissential}",
    "Data Cleansing",
    "Data Convergence",
    "Data Dashboard",
    "Data Deduplication",
    "Data Discovery",
    "Data Distribution",
    "Data Encryption",
    "Data Enrichment",
    "Data Exchanges",
    "Data Execution Prevention (DEP)",
    "Data Exfiltration",
    "Data Extraction Software",
    "Data Forensics {Gulfstream Legal Group}",
    "Data Governance & Quality Software",
    "Data Hub",
    "Data Integration",
    "Data Labeling",
    "Data Lake",
    "Data Loss",
    "Data Loss Prevention",
    "Data Management",
    "Data Management Platform",
    "Data Management System",
    "Data Mining",
    "Data Modernization and Analytics - 0627 {Deloitte}",
    "Data Monetization",
    "Data Networks",
    "Data Orchestration",
    "Data Pipeline and Supply Chain {Stonebranch}",
    "Data Preparation",
    "Data Privacy & Protection",
    "Data Privacy Software",
    "Data Protection Officer (DPO)",
    "Data Quality",
    "Data Records {AstroNova}",
    "Data Reduction",
    "Data Replication & Mirroring",
    "Data Retention",
    "Data Science",
    "Data Security",
    "Data Sharing",
    "Data Storage Management",
    "Data Streams",
    "Data Structures",
    "Data Theft",
    "Data Transformation",
    "Data Transmission",
    "Data Validation",
    "Data Vaulting",
    "Data Virtualization",
    "Data Visualization Software",
    "Data Warehouse",
    "Data Warehousing Software",
    "Data and Analytics Models {International Institute For Analytics}",
    "Data as a Service",
    "Data-Driven Culture {International Institute For Analytics}",
    "Data-Driven Marketing",
    "Data/Document Extraction {HypedUp}",
    "DataRobot",
    "DataStax",
    "Database Administration",
    "Database Audit",
    "Database Development",
    "Database Management",
    "Database Migration & Conversion",
    "Database Security",
    "Database Servers",
    "Database Solutions",
    "Database as a Service (DBaaS) Provider",
    "Databases",
    "Databricks",
    "Datadog",
    "Datanyze",
    "Datasite",
    "Dave Oakes {ICR}",
    "De-identification",
    "Dealership Management System",
    "Death Audit {Central Research}",
    "Debt Broker {DebtTrader}",
    "Debt Buyer",
    "Debt Collection Software",
    "Debt Restructuring",
    "Debt Sellers {DebtTrader}",
    "Debt-Service Coverage Ratio (DSCR)",
    "Debugging",
    "Debugging tools",
    "Decarbonization {Vivid Edge}",
    "Decentralized Clinical Trials {Data Cubed}",
    "Decentralized Clinical Trials {MediData}",
    "Decentralized Clinical Trials {THREAD}",
    "Decibel Insight",
    "Decision Competitors {Sapiens}",
    "Decision Manager",
    "Decision Support Systems",
    "Decision Support {Sapiens}",
    "Decline Curve Analysis Software {Tachyus}",
    "Decorative Waste and Recycling {Prestwick Group}",
    "Dedicated Servers {Corporate Colocation}",
    "Deep Learning Software",
    "Deep Well Injection {Veolia}",
    "Defect Tracking",
    "Defense Federal Acquisition Regulation Supplement (DFARS) {RegDOX}",
    "Defense in Depth",
    "Defibrillator/AED Devices",
    "Defined Benefit Plans",
    "Defined Contribution Plans",
    "Delivery",
    "Delivery Management Software",
    "Dell EMC Storage {Questivity Incorporated}",
    "Dell Laptops {Stallard Technologies}",
    "Dell Servers",
    "Dell Servers {Stallard Technologies}",
    "Dell Storage {Stallard Technologies}",
    "Dell Workstations {Stallard Technologies}",
    "Deloitte {Catalant Technologies}",
    "Delon {Delon}",
    "Demand Forecasting {Transportation Impact}",
    "Demand Generation",
    "Demand Generation Consulting",
    "Demand Generation Software",
    "Demand Planning",
    "Demand Response",
    "Demand Side Platforms",
    "Demand-Side Management",
    "Demandbase",
    "Dematic {Fortna}",
    "Demister {Aeroex Technologies}",
    "Demotion",
    "Demountable Walls {Wilson Office Interiors}",
    "Denial Management {CPSI}",
    "Denim {Coats}",
    "Dental Imaging Software",
    "Dental Insurance",
    "Dependency Mapping",
    "Deployment Options",
    "Deposit Accounts",
    "Deposit Growth {CS3 Marketing}",
    "Depreciable Assets",
    "Derivatives Advisory {Derivative Path}",
    "Derivatives Trading Platform {Derivative Path}",
    "Descartes Systems",
    "Descriptive Analytics",
    "Design Build Construction {35 North}",
    "Design Pickle",
    "Design Research",
    "Design Software",
    "Design Subscription Services",
    "Design Systems",
    "Design for Manufacturability",
    "Desktop Computer",
    "Desktop Management",
    "Desktop Sharing",
    "Desktop as a Service",
    "DevSecOps",
    "Development Operations (DevOps)",
    "Device Security",
    "Device Trade-In",
    "Device as a Service",
    "Devo",
    "Devops Transformation {Tasktop Technologies}",
    "Diabetes",
    "Dialogflow {Rasa Technologies}",
    "Dialogtech",
    "Die Cutting {The Strouse Corporation}",
    "Digger Derrick {Terex}",
    "Digimind {Talkwalker}",
    "Digital Acquisition {CS3 Marketing}",
    "Digital Adoption Platform {Lemon Learning}",
    "Digital Advocacy {Phone2Action}",
    "Digital Agency",
    "Digital Architecture",
    "Digital Art",
    "Digital Art Production",
    "Digital Assessment Tools",
    "Digital Asset Management (DAM) Software",
    "Digital Asset Technology",
    "Digital Audience Measurement {The Nielsen Company}",
    "Digital Banking",
    "Digital Banking Solutions - 0514 {Deloitte}",
    "Digital CX Software {Qualtrics}",
    "Digital Campaign Measurement {The Nielsen Company}",
    "Digital Care - 0516 {Deloitte}",
    "Digital Commerce - 0534 {Deloitte}",
    "Digital Commerce {Deloitte}",
    "Digital Consulting",
    "Digital Core Suite {Sapiens}",
    "Digital Customer Experience {Quantanite}",
    "Digital Customer Service",
    "Digital Display Advertising",
    "Digital Disruption",
    "Digital Employee",
    "Digital Engagement",
    "Digital Engagement {CS3 Marketing}",
    "Digital Experience",
    "Digital Factory",
    "Digital Front Door",
    "Digital Human {Soul Machine}",
    "Digital Identity Verification {Trulioo}",
    "Digital Insurance Solutions/Suite/Platform {Sapiens}",
    "Digital Integration Hub (DIH)",
    "Digital Locker",
    "Digital Mail",
    "Digital Marketing",
    "Digital Media",
    "Digital Onboarding {CS3 Marketing}",
    "Digital Pathology Systems {Epredia}",
    "Digital Pathology {Epredia}",
    "Digital Pathology {Paige}",
    "Digital Patient Intake {The SSI Group}",
    "Digital Place-Based Advertising",
    "Digital Print Solutions",
    "Digital Print {Superior Office Products}",
    "Digital Printing {Taylor Corporation}",
    "Digital Process",
    "Digital Process Automation",
    "Digital Process Technologies",
    "Digital Referral {CS3 Marketing}",
    "Digital Signage",
    "Digital Signage Experts Group",
    "Digital Signage Software",
    "Digital Strategy",
    "Digital Subscriber Line",
    "Digital Supply Networks - 0552 {Deloitte}",
    "Digital Thread",
    "Digital Transaction Management (DTM)",
    "Digital Transformation",
    "Digital Transformational Planning {DMI}",
    "Digital Twin",
    "Digital Video Advertising",
    "Digital Workplace",
    "Digital Workspace App",
    "Digitization",
    "Dilligent Board and Leadership Collaboration {BoardBookit}",
    "Dimensional Marketing",
    "Dining Service {American Food and Vending}",
    "Direct Attached Storage (DAS)",
    "Direct Deposit",
    "Direct Hire {US Tech}",
    "Direct Mail",
    "Direct Mail Marketing {Taylor Corporation}",
    "Direct Mail Services {Taylor Corporation}",
    "Direct Mail Tracking",
    "Direct Marketing",
    "Direct Primary Care",
    "Direct Response",
    "Direct Routing",
    "Direct Sourcing",
    "Direct Store Delivery",
    "Direct to Carton {AstroNova}",
    "Direct to Consumer Sales",
    "Direct to Product {AstroNova}",
    "Direct-to-Consumer Advertising (DTC advertising)",
    "Directory Services",
    "Dirty Data {ZoomInfo}",
    "Disability",
    "Disability Citations & Compliance {Savaria}",
    "Disaster Monitoring {Swan Island}",
    "Disaster Planning",
    "Disaster Recovery",
    "Disaster Recovery as a Service",
    "Discipline",
    "Discount Strategy",
    "Discovery {Benefit Resource}",
    "Discrimination",
    "Disease Control {TruGreen}",
    "Disinfect",
    "Disinfectant Fogger {Froggys Fog}",
    "Disinfecting Spray",
    "Disinfecting Wipes",
    "Disk-Based Backup & Storage",
    "Disparate Systems",
    "Display Advertising Metrics",
    "Display Advertising Software",
    "Display Screen Equipment (DSE)",
    "Disposable Gloves {Neobex Medical}",
    "Disposable Thermometers",
    "Distance Learning",
    "Distributed Antenna Systems (DAS)",
    "Distributed Computing",
    "Distributed Database",
    "Distributed Denial-of-Service (DDoS)",
    "Distributed Energy Resources (DERs)",
    "Distributed Energy {Veolia}",
    "Distributed Generation {Veolia}",
    "Distributed Marketing",
    "Distributed Marketing {Taylor Corporation}",
    "Distribution & Storage",
    "Distribution Center",
    "Distribution Center Design",
    "Distribution Centers {Myron Bowling}",
    "Distribution Engineering {Fortna}",
    "Distribution Software",
    "Diversity",
    "Diversity Recruiting",
    "Diversity Services",
    "Diversity Spend {Delta Financial Group}",
    "Diversity Training {ExecOnline}",
    "Diversity Vendor {Delta Financial Group}",
    "Divestment",
    "Dividends",
    "Django",
    "Docebo",
    "Dock Door Scheduling",
    "Docker",
    "Docketing Software",
    "DocuSign",
    "Document Control",
    "Document Converter",
    "Document Data Migration {Data Strategies International}",
    "Document Generation Software",
    "Document Imaging",
    "Document Management Software",
    "Document Management {Central Research}",
    "Document Management {Taylor Corporation}",
    "Document Review {Gulfstream Legal Group}",
    "Document Scanning {GRM Company}",
    "Document Security",
    "Document Sharing",
    "Document Shredding",
    "Document Storage {GRM Company}",
    "Document Translation",
    "Document Verification {Trulioo}",
    "Documentary Collections",
    "Domain Expertise",
    "Domain Keys Identified Mail (DKIM)",
    "Domain Name Services",
    "Domain Parking",
    "Domain-based Message Authentication, Reporting and Conformance (DMARC)",
    "Domestic Carriers {Hangar A}",
    "Domestic Shipping",
    "Domo {Graphable}",
    "Donaldson Filters {Aeroex}",
    "Door to Door {SalesRabbit}",
    "Doosan {Bloom Energy}",
    "Dormitories",
    "Double-Entry Bookkeeping",
    "DoubleClick Bid Manager",
    "Download Manager (DLM)",
    "Downstream Competitors {Jungle Scout}",
    "Downstream {Jungle Scout}",
    "Downstream {Veolia}",
    "Downtime",
    "Downtime Monitoring",
    "Drata {Cyber Security Works}",
    "Draw Management Software {Rabbet}",
    "Drayage Software {Envase Technologies}",
    "Dreamforce",
    "Drift",
    "Drilling & Mining Equipment",
    "Drinking Water",
    "Drinkware {Capwell Funding}",
    "Drip Marketing",
    "Drips {Greenwatt}",
    "Driveaway Services",
    "Driver Risk Monitoring {eDriving}",
    "Driver Safety Training {eDriving}",
    "Driver Telematics {eDriving}",
    "Driver Training",
    "Driving Record Checks {PreEmploy}",
    "Driving School Software",
    "Drone Delivery {Drone Delivery Canada}",
    "Drone Inspections {Skyline Drones}",
    "Drone Mapping {Skyline Drones}",
    "Drone Services",
    "Drone Specific Terms {Censys}",
    "Drone Survey {Skyline Drones}",
    "Drone Training {Skyline Drones}",
    "Drones/Unmanned Aerial Vehicles",
    "Dropbox",
    "Dropship Software {Inventory Source}",
    "Dropshipping",
    "Drug & Alcohol Testing",
    "Drug & Alcohol Treatment {Niznik Behavioral Health}",
    "Drug Discovery {Automata}",
    "Drug Naming {Brand Institute}",
    "Drug Rebates {Xevant}",
    "Drupal",
    "Due Diligence",
    "Dun & Bradstreet",
    "Duo Security",
    "Dura Base {Mavrik Sales}",
    "Dust Containment",
    "Duty of Care",
    "Dynamic Application Security Testing (DAST)",
    "Dynamic Asset Allocation",
    "Dynamic Authorization",
    "Dynamic Discounting",
    "Dynamic Pricing",
    "Dynamic Routing",
    "Dynamics NAV",
    "Dynatrace",
    "Dyson Hand Dryer {Dyson}",
    "E Builder {Avicado Construction Technology}",
    "E-Business Strategy",
    "E-Prescribing Software",
    "E-Signature Software",
    "E-Verify",
    "E-commerce Payment",
    "E-regulatory systems",
    "E2open",
    "EAFE Equity {MacKay Shields}",
    "EAP {Clinigen Group}",
    "EB {NOCO Company}",
    "ECG Management",
    "EDC {THREAD}",
    "EDRM {Venio}",
    "EHR Integration {GRM Company}",
    "EIFS {Knauss Property Service}",
    "ELK Stack",
    "EMV (Payment System)",
    "EOS {AddUp}",
    "ERP Alternatives {Profusion Systems}",
    "ERP Migration {Envision Resource Group}",
    "ERP Migration {Profusion Systems}",
    "ERP Security",
    "ERP Support {Profusion Systems}",
    "ESG Investment {Foss and Company}",
    "ESG and Diversity {Delta Financial Group}",
    "ESPs {Dyspatch}",
    "EVS Environmental Services {Jani King}",
    "EX Competitor {Qualtrics}",
    "EX General Interest {Qualtrics}",
    "EX Software {Qualtrics}",
    "Early Case Management {Venio Systems}",
    "Eating Disorder {Niznik Behavioral Health}",
    "Echo Logistics",
    "Eclipse IDE",
    "Eco Bins {Method}",
    "Eco-Friendly Printers",
    "Ecommerce Fulfillment",
    "Ecommerce Photography",
    "Eddy Current Testing {Metal Finishing Technologies}",
    "Eden Competition {Eden Health}",
    "Edge computing",
    "Education Software",
    "Education Verification {PreEmploy}",
    "Eero {Plume Design Inc}",
    "Effective Communication",
    "Effective Practices",
    "Effective Selling",
    "Efficient Federal Operations",
    "Egencia",
    "Elasticsearch",
    "Elavon",
    "Elder Care Software",
    "Elecronic Lab Notebook {Agaram Technologies}",
    "Electric Hand Dryer {Dyson}",
    "Electric Pallet Jacks {Alliance Material Handling}",
    "Electric Pumps",
    "Electric Rates {Solar Energy Partners}",
    "Electric Vehicle Charging Station",
    "Electric and Hybrid Fleets",
    "Electric vehicle",
    "Electrical Engineering",
    "Electrical Maintenance {Action Services Group}",
    "Electrical Software {Authority Brands}",
    "Electricity Supply Procurement {Freedom Energy Logistics}",
    "Electrocardiography",
    "Electrolysis {FuelCell Energy}",
    "Electronic Article Surveillance",
    "Electronic Billing",
    "Electronic Clinical Outcome Assessment {4G Clinical}",
    "Electronic Contract Manufacturing",
    "Electronic Data Capture (EDC)",
    "Electronic Data Interchange",
    "Electronic Data Transfer (EDT)",
    "Electronic Design Automation",
    "Electronic Device Cosmetic Repair {Encore Repair Services}",
    "Electronic Document",
    "Electronic Documents and Statements {InfoIMAGE}",
    "Electronic Enclosures and Housings {Resolve Business Development}",
    "Electronic Enclosures {Greater Machining}",
    "Electronic Funds Transfer (EFT)",
    "Electronic Health Records",
    "Electronic High Level Assembly Services",
    "Electronic Identity Verification (eIDV)",
    "Electronic Logging Device (ELD)",
    "Electronic Manufacturer",
    "Electronic Manufacturing Services (EMS)",
    "Electronic Refurbish and Repair {Encore Repair Services}",
    "Electronic Test Equipment {Astro Nova}",
    "Electronically Stored Information (ESI)",
    "Electronics Outsourcing",
    "Electronics {Houseware Distributors}",
    "Electrostatic Sprayer {Froggys Fog}",
    "Elliot Management {Rockway Asset Management}",
    "Ellkay {Flatirons Digital Innovations}",
    "Eloqua",
    "Email Appending",
    "Email Archiving",
    "Email Builder {Dyspatch}",
    "Email Deliverability",
    "Email Hosting",
    "Email Hygiene",
    "Email List Management",
    "Email Management",
    "Email Management Software",
    "Email Marketing",
    "Email Marketing Automation",
    "Email Marketing Services",
    "Email Marketing Software",
    "Email Marketing Tools",
    "Email Security",
    "Email Servers",
    "Email Service Providers",
    "Email Signature Software",
    "Email Tracking",
    "Email Verification",
    "Embedded Analytics",
    "Embedded Barcode Reader or Scanner {Diamond Technologies}",
    "Embedded Business Intelligence",
    "Embedded Computers {WinSystems}",
    "Embedded Database {Raima}",
    "Embedded Payments {Infinicept}",
    "Embedded Reporting",
    "Embedded Software",
    "Embedded Systems {WinSystems}",
    "Embodied Labs",
    "Embroidered Shirt {WebbMason}",
    "Embroidery {Capwell Funding}",
    "Emergency Fleet Repair",
    "Emergency Management",
    "Emergency Management Software {Veoci}",
    "Emergency Management {Shooter Detection Systems}",
    "Emergency Medicine",
    "Emergency Notification Software",
    "Emergency Response {Veolia}",
    "Emergency Tent Structure {Creative Tents}",
    "Emergency Triage Application {Aneu Strategies Group}",
    "Emerging Market Debt Fund {MacKay Shields}",
    "Emerging Technologies",
    "Emeritus {ExecOnline}",
    "Emission Reductions",
    "Emission Standards",
    "Emissions",
    "Emotional Intelligence",
    "Employee Advocacy",
    "Employee App {Speakap BV}",
    "Employee Appreciation Gifts {Spoonful}",
    "Employee Assistance",
    "Employee Assistance Program {Integrated Behavioral Health}",
    "Employee Attendance & Hours",
    "Employee Benefit Consultant {Insurance Office of America}",
    "Employee Benefits Broker",
    "Employee Biometric Screening {ADURO}",
    "Employee Burnout",
    "Employee Case Management",
    "Employee Communications",
    "Employee Communications App {Speakap BV}",
    "Employee Development",
    "Employee Engagement",
    "Employee Engagement Software",
    "Employee Engagement Software {Qualtrics}",
    "Employee Experience",
    "Employee File Management",
    "Employee Financial Wellness {Rain}",
    "Employee Generated Content (EGC)",
    "Employee Handbooks",
    "Employee Health Screening {ADURO}",
    "Employee Health and Wellness {Matrix Medical Network}",
    "Employee Healthcare {Weight Watchers}",
    "Employee Incentives",
    "Employee Involvement",
    "Employee Leasing {Paylocity}",
    "Employee Life Cycle",
    "Employee Monitoring Software",
    "Employee Performance",
    "Employee Performance Management",
    "Employee Perks Vendors {Xexec}",
    "Employee Perks and Discounts",
    "Employee Portal",
    "Employee Recognition",
    "Employee Recognition Software",
    "Employee Recognition Vendors {Xexec}",
    "Employee Referrals",
    "Employee Relationship Management",
    "Employee Resource Groups (ERGs)",
    "Employee Retention",
    "Employee Retention Credit {Baker Tilly}",
    "Employee Retention Tax Credit",
    "Employee Rewards",
    "Employee Safety",
    "Employee Sales Development {Learning Outsource Group}",
    "Employee Scheduling",
    "Employee Scheduling Software",
    "Employee Screening",
    "Employee Stock Ownership Plan",
    "Employee Stock Purchase Plan (ESPP)",
    "Employee Surveys",
    "Employee Temperature Checks {Action Services Group}",
    "Employee Turnover",
    "Employee Uniform Solutions",
    "Employee central",
    "Employer Branding {Removify}",
    "Employer Branding {ViziRecruiter}",
    "Employer of Record (EOR)",
    "Employment Agencies",
    "Employment Applications",
    "Employment Background",
    "Employment Contracts",
    "Employment Eligibility Verification (I-9)",
    "Employment Services",
    "Employment Verification",
    "Employment testing",
    "Encoders",
    "Encrypted Backup {Secure Data}",
    "Encrypted Flash Drive {Secure Data}",
    "Encrypted Storage {Secure Data}",
    "Encrypted USB {Secure Data}",
    "Encryption",
    "Encryption Key Management",
    "End User Computing",
    "End User Security",
    "End User Services {DMI}",
    "End of Service Life Maintenance",
    "End-To-End Automation",
    "End-of-Life (EOL)",
    "End-to-End Ecommerce",
    "End-to-End Encryption",
    "Endpoint Detection and Response (EDR)",
    "Endpoint Management",
    "Endpoint Protection",
    "Energy Audit",
    "Energy Buying {Energy Action}",
    "Energy Consulting",
    "Energy Consumption",
    "Energy Cost Savings {Freedom Energy Logistics}",
    "Energy Efficiency",
    "Energy Financing",
    "Energy Management",
    "Energy Management Software",
    "Energy Master Planning {Veolia}",
    "Energy Monitoring Audits Services Sustainability {Black and McDonald Limited}",
    "Energy Procurement",
    "Energy Procurement {Veolia}",
    "Energy Storage",
    "Energy Supplier {Freedom Energy Logistics}",
    "Energy Trading and Risk Management {Opportune}",
    "Energy Transition",
    "Engage VR {HTC America}",
    "Engagement Platform",
    "Engineer to Order / Make to Order",
    "Engineering Metrics and Analytics {LinearB}",
    "Enhanced 9-1-1",
    "Enhanced Content",
    "Ensighten",
    "Entelo",
    "Enterprise Application Integration",
    "Enterprise Application Security",
    "Enterprise Architecture",
    "Enterprise Asset Management",
    "Enterprise Cloud",
    "Enterprise Collaboration",
    "Enterprise Computing",
    "Enterprise Contact Center",
    "Enterprise Content Management",
    "Enterprise Data Governance",
    "Enterprise Data Maintenance",
    "Enterprise Data Management",
    "Enterprise Database",
    "Enterprise Development {DMI}",
    "Enterprise Document Management",
    "Enterprise Feedback Management (EFM)",
    "Enterprise Firewall",
    "Enterprise Information Integration / Metadata Management",
    "Enterprise Infrastructure",
    "Enterprise Legal Management (ELM)",
    "Enterprise Mobility Management",
    "Enterprise Mobility Suite",
    "Enterprise Performance Management",
    "Enterprise Printing",
    "Enterprise Resource Planning",
    "Enterprise Search Software",
    "Enterprise Service Bus",
    "Enterprise Software",
    "Enterprise Storage Solutions",
    "Enterprise Tag Management",
    "Enterprise Value",
    "Enterprise Voice",
    "Enterprise Wearables",
    "Enterprise Workforce Planning {Catalant Technologies}",
    "Enterprise eCommerce",
    "Entitlement Management",
    "Entity Data Management",
    "Entrepreneur {Transworld Business Brokers}",
    "Entry-Level Servers",
    "Environment Health & Safety (EHS)",
    "Environmental Controls",
    "Environmental Health & Safety Management (EHS) Software",
    "Environmental Management",
    "Environmental Monitoring",
    "Environmental Packaging {Sealed Air}",
    "Environmental, Social, Governance Criteria (ESG)",
    "Epic Beaker Implementation {HCTec}",
    "Epic Community Connect Services {HCTec}",
    "Epic Go Live Support {HCTec}",
    "Epic Helpdesk {HCTec}",
    "Epic Hyperdrive Support {HCTec}",
    "Epic Implementation Services {HCTec}",
    "Epic Infrastructure Services {HCTec}",
    "Epic My Chart Support {HCTec}",
    "Epic Support {HCTec}",
    "Epic Systems",
    "Epic Training Services {HCTec}",
    "Epic Upgrade Services {HCTec}",
    "Epic Willow Support {HCTec}",
    "Epicor Software",
    "Epidemiology Consulting {Optum Inc}",
    "Epigenetics",
    "Episerver",
    "Epson Label Printer {AstroNova}",
    "Equal Employment Opportunity",
    "Equal Pay",
    "Equifax Workforce Solutions {Unemployment Services Trust}",
    "Equinix",
    "Equipment Financing {Delta Financial Group}",
    "Equipment Leasing {Delta Financial Group}",
    "Equipment Liquidators {Myron Bowling}",
    "Equipment Loans",
    "Equity",
    "Equity Management Software",
    "Ergonomics",
    "Error & Exception Monitoring",
    "Error Handling",
    "Escheat",
    "Ethereum",
    "Ethernet",
    "Ethics",
    "Ethnicity / National Origin",
    "Ethylene, Hydrogen, and LNG Production {Cashco}",
    "Evaporative Cooling",
    "Even {Rain}",
    "Event Apps",
    "Event Automation",
    "Event Management",
    "Event Managment Software",
    "Event Planning",
    "Event Registration",
    "Event Tents and Temporary Structures",
    "Event and Trade Show Displays {Speedpro Imaging}",
    "Event-Driven Architecture (EDA)",
    "EventMobi",
    "Events Products {BrandLive}",
    "Evernote",
    "Eversound",
    "Everstring",
    "EviSort",
    "Exabeam",
    "Exam Gloves {Ahpmus}",
    "Exasol",
    "Excel Modeling {Public Financial Management}",
    "Excess Inventory",
    "Exchange Rate",
    "Exchange Server",
    "Excise Tax",
    "Exclusive Networks",
    "Executive Coaching {CEO Coaching International}",
    "Executive Compensation",
    "Executive Education {ExecOnline}",
    "Executive Information Systems",
    "Executive Protection",
    "Executive Search",
    "Executive Search {UHY Consulting}",
    "Executive Seating {Wilkhahn}",
    "Exit Interviews",
    "Exit Planning",
    "Expanded Access Provider {Clinigen Group}",
    "Expansion Global Payroll {Emerald Technology}",
    "Expel {Cyber Security Works}",
    "Expense Management",
    "Expense Reduction",
    "Expense Reporting",
    "Experian (EXPN)",
    "Experience Management - Hux {Deloitte}",
    "Experience Management Hux - 0533 {Deloitte}",
    "Experiential Marketing",
    "Expert Network",
    "Expert Witnesses",
    "Export {Toll Holdings}",
    "Exports",
    "ExteNet {Communication Technology Services}",
    "Extended Detection and Response",
    "Extended Reality",
    "Extended stay rates",
    "Exterior Building Maintenance and Repair {Knauss Property Service}",
    "Exterminators {Rollins}",
    "External Authorization",
    "External Control Arm {McKesson Ontada}",
    "External Hard Drive",
    "Extract, Transform, & Load (ETL)",
    "Extranets",
    "Extreme Networks",
    "Extrusion Processors {CR Onsrud}",
    "Eye Protection",
    "F5 Networks",
    "FIB SEM {Thermo Fisher Scientific}",
    "FIDO (Fast Identity Online)",
    "FIFO (First In, First Out)",
    "FM Global",
    "FM Systems {Nuvolo Limited}",
    "FOIA Professional Services {Central Research}",
    "FTZ Storage",
    "FX SaaS Solutions {Derivative Path}",
    "Fabricated Packaging {Sealed Air}",
    "Face Masks",
    "Face Protection",
    "Facebook Mobile Ads",
    "Facial Recognition",
    "Facilities Management",
    "Facility Closure",
    "Facility Energy",
    "Facility Maintenance {Black and McDonald Limited}",
    "Facility Management Software",
    "Facility Standards",
    "Facility Upgrades {The Power Ad}",
    "Factoring",
    "Factory Automation",
    "Factory Supply Chain Solutions",
    "Failure Analysis",
    "Fair Labor Standards Act (FLSA)",
    "Fair Lending",
    "FairWarning",
    "Fall Arrest Restraints {Flexible Lifeline Systems}",
    "Fall Protection",
    "Fall Protection Systems {Flexible Lifeline Systems}",
    "Fall Protection {Flexible Lifeline Systems}",
    "False Claims Act {Baker Tilly}",
    "Family & Medical Leave",
    "Family Medicine",
    "Family Office Services {Argent Financial Group}",
    "Far-UV",
    "Farm Management Software",
    "Faro",
    "Fastenal",
    "Faster Development Cycles",
    "Fault Tolerance",
    "Fax Management",
    "Federal Risk and Authorization Management Program (FedRAMP)",
    "Federated Wireless {Communication Technology Services}",
    "Fedex",
    "Feedback Solutions {Qualtrics}",
    "Fenergo",
    "Fertility Benefits",
    "Fertilization {TruGreen}",
    "Fiber Management {Vetro}",
    "Fiber Mapping {Vetro}",
    "Fiber Network Inventory {Vetro}",
    "Fiber Optics",
    "Fiber Planning {Vetro}",
    "Fibre Channel",
    "Fibre Internet {CommsChoice}",
    "Fidelity",
    "Fiduciary",
    "Field Labeling {Eurofins MET Laboratories}",
    "Field Marketing",
    "Field Sales",
    "Field Service Automation",
    "Field Service Inventory Planning {Baxter}",
    "Field Service Management",
    "File Servers",
    "File Sharing",
    "File Transfer Management",
    "File Transfer Protocol (FTP)",
    "FileZilla",
    "Film/Movie Production",
    "Filtration",
    "FinTech",
    "Finance and Accounting Outsourcing",
    "Financial Advisor Compensation {Moskal Gross Orchosky}",
    "Financial Advisors",
    "Financial Assurance",
    "Financial Close",
    "Financial Crimes Enforcement Network (FinCEN)",
    "Financial Education/Seminars",
    "Financial Exchange Technology",
    "Financial Industry Regulatory Authority (FINRA)",
    "Financial Infrastructure {ITRS Group}",
    "Financial Investigations {CipherTrace}",
    "Financial Management",
    "Financial Management Services",
    "Financial Markets Data",
    "Financial Modeling Software",
    "Financial Planning & Analysis Software",
    "Financial Reporting",
    "Financial Risk",
    "Financial Services Software",
    "Financial Systems",
    "Financial Wellness",
    "Fire Ant Control {TruGreen}",
    "Fire Damage",
    "Fire Department Software",
    "Fire Detection",
    "Fire Monitoring",
    "Fire protection",
    "FireEye",
    "Firewall",
    "Firewall Software",
    "Firewood {Performance}",
    "Firmographics",
    "First Call Resolution (FCR)",
    "First-Party Data",
    "Fitness & Gym Management",
    "Fitness Delivery and Installation {Allegro Home Delivery}",
    "Fitness Equipment {Peloton Interactive}",
    "Fitness for Duty Exam {Caliburn}",
    "Five9",
    "Fixed Income",
    "Fixed Mobile Convergence",
    "Flame Arrestors {Cashco}",
    "Flash Array SSD {Kioxia}",
    "Flash Drive",
    "Flash Storage",
    "Fleet Cameras {Safety Vision}",
    "Fleet Fuel Cards",
    "Fleet Maintenance",
    "Fleet Procurement",
    "FlexJet {XOJET}",
    "Flexera",
    "Flexible Benefit Plans",
    "Flexible Offices {Officio}",
    "Flexible Packaging",
    "Flexible Packaging {Printpack}",
    "Flexible Packaging {Signature Flexible Packaging}",
    "Flexible Packaging {Taylor Corporation}",
    "Flexible Spending Accounts",
    "Flexible Tables {Wilkhahn}",
    "Flexible Workforce {Catalant Technologies}",
    "Flexible Working",
    "Flexport",
    "Float Sensors",
    "Flocked Swabs {Copan Diagnostics}",
    "Flood Certificates {Property Interlink}",
    "Florence Healthcare {Advarra}",
    "Florist Software",
    "Flow Cytometry {Curiox Biosystems}",
    "Flow Distribution {Tasktop Technologies}",
    "Flow Metrics {Tasktop Technologies}",
    "Flow Products {Flow International}",
    "Flu Testing {Thermo Fisher Scientific}",
    "Fluorescent Penetrant Inspection {Metal Finishing Technologies}",
    "Foam Packaging {Sealed Air}",
    "Fog Fluid {Froggys Fog}",
    "Fog Machine {Froggys Fog}",
    "Folding Cartons {Taylor Corporation}",
    "Food Cost Management",
    "Food Grade Lubricants/Oils/Greases",
    "Food Ordering & Delivery",
    "Food Packaging",
    "Food Packaging {Sealed Air}",
    "Food Packaging {Signature Flexible Packaging}",
    "Food Processing",
    "Food Safety",
    "Food Traceability Software",
    "Food/Cafeteria Plans",
    "Foodservice Distribution",
    "Foot Protection",
    "Football Scoreboard {The Power Ad}",
    "Forbearance {Greenwatt}",
    "Force Placed Insurance {Loan Protector}",
    "Foreign Currency Transactions",
    "Foreign Exchange Hedging",
    "Foreign Exchange Options",
    "Foreign Exchange Spot Transaction",
    "Forescout",
    "Forestry Software",
    "Forklift Service {Alliance Material Handling}",
    "Forklift Telematics {Alliance Material Handling}",
    "Forklifts {Alliance Material Handling}",
    "Form 1099-MISC",
    "Form 483 {Rockway Asset Management}",
    "Form Optimization {ZoomInfo}",
    "Forrester",
    "Fortegra Financial",
    "Fortinet",
    "Fortran",
    "Foss and Company {Foss and Company}",
    "Foundation and Philanthropic Services {Argent Financial Group}",
    "Fourth EU Anti-Money Laundering Directive",
    "Fractional Jet Ownership {Sky Aviation}",
    "Fractional Ownership {XOJET}",
    "Frameworks & Libraries",
    "Franchise",
    "Franchise Opportunity {Jani King}",
    "Franchise Ownership {Authority Brands}",
    "Franchise Royalty Assurance {Intellishop}",
    "FranklinCovey {ExecOnline}",
    "Fraud Analysis {FRISS}",
    "Fraud Detection",
    "Fraud Detection Software {Lextegrity}",
    "Fraud Prevention {Trulioo}",
    "Fraud Protection",
    "Freelance Developers {Turing}",
    "Freelance Engineers {Turing}",
    "Freelancer",
    "Freeze Dryer / Lyophilizer {Millrock Technology}",
    "Freight Audit and Payment {TriumphPay}",
    "Freight Audit {TriumphPay}",
    "Freight Broker",
    "Freight Broker Software {Envase Technologies}",
    "Freight Logistics {Transportation Impact}",
    "Freight Management Software",
    "Freight Management System",
    "Freight Management {Transportation Impact}",
    "Freight Payment {A3 Freight Payment}",
    "Freight Quota",
    "Freight Rates",
    "Freight Shipping",
    "Freight Solutions {Transportation Impact}",
    "Freight {Toll Holdings}",
    "French CX Software {Qualtrics}",
    "French EX Software {Qualtrics}",
    "Freshdesk",
    "Freshworks",
    "Frozen Tissue Section {Epredia}",
    "Fuel Blending {Veolia}",
    "Fuel Cell Power Generation {FuelCell Energy}",
    "Fuel Cell {Bloom Energy}",
    "Fuel Cell {FuelCell Energy}",
    "Fuel Delivery",
    "Fuel Delivery {Kinect Consulting}",
    "Fuel Management {Kinect Consulting}",
    "Fuel Storage",
    "Fulfillment Center",
    "Fulfillment Services",
    "Fulfillment by Amazon",
    "Full Circle Insights",
    "Full Disk Encryption",
    "Full truckload (FTL) Shipping",
    "Fume Extractor {Aeroex}",
    "Functional Printing",
    "Functional Testing",
    "Fundraising & Donation Management",
    "Fundraising {SalesSparx}",
    "Furniture Delivery and Assembly {Allegro Home Delivery}",
    "Furniture Rental {Brook Furniture Rental}",
    "Fusion 360",
    "Future of Work",
    "G Suite",
    "GCC High {Planet Technologies}",
    "GEODIS",
    "GEP Worldwide",
    "GIB {IFM}",
    "GIS Fiber Mapping {Vetro}",
    "GNSS Timing Module",
    "GNU General Public License",
    "GPM {ARC Group Benefits}",
    "GPS",
    "GPS Cloud Migration - 0512 {Deloitte}",
    "GPS Tracking {ShadowTrack}",
    "GRESB {EPT Global}",
    "GSM",
    "Gainsight",
    "Galen Healthcare {Flatirons Digital Innovations}",
    "Gallup {Engagement Multiplier}",
    "Game Development",
    "Game Development Software",
    "Gamification Software",
    "Gantt Chart Software {Smartsheet}",
    "Gartner",
    "Gas Credit Cards",
    "Geekplus Technology {Kuecker Logistics}",
    "Gemalto",
    "Gender Equality",
    "Gender Gap",
    "Gene Expression",
    "Gene Therapy",
    "General Apple MDM Competitors {Addigy}",
    "General Contracting",
    "General Contracting {Cincinnati Commercial Contracting}",
    "General Data Protection Regulation (GDPR)",
    "General Liability Insurance",
    "General Print {Mimeo}",
    "General Rate Increase {Transportation Impact}",
    "General {Enosix}",
    "Generally Accepted Accounting Principles (GAAP)",
    "Generator/Equipment Brands {GenServe}",
    "Generic Terms {GenServe}",
    "Genesys",
    "Genetec",
    "Genetic Analysis",
    "Genetic Engineering",
    "Genomics",
    "Geofencing",
    "Geographic Information Systems",
    "Geolocation",
    "Geomechanics {Ikon Science}",
    "Georgia Industrial Real Estate {Sturgeon}",
    "Geoscience Software {Ikon Science}",
    "Geospatial Analytics",
    "Geosteering Software {Ikon Science}",
    "Geotechnical Engineering",
    "Get Well Gifts {Spoonful}",
    "Ghostwriter",
    "Gift Valuation",
    "Gigabit Networking",
    "Gigabit Services",
    "Gigamon",
    "Git Analytics and Dashboards {LinearB}",
    "GitHub",
    "GitOps",
    "Glances {Skynamo}",
    "Glass Boards {Inkflo}",
    "Glass Office Fronts {Wilson Office Interiors}",
    "Glassbox",
    "Glint {Qualtrics}",
    "Glint {WorkStep}",
    "Global ACH payments",
    "Global Business Travel",
    "Global Data Synchronization Network (GDSN)",
    "Global Distribution System",
    "Global Employment Law",
    "Global Marketing",
    "Global Mobility",
    "Global Payments",
    "Global Payroll {Emerald Technology}",
    "Global Procurement",
    "Global Recruitment",
    "Global Relocation Services {Global Mobility Solutions}",
    "Global Social Media Marketing",
    "Global Supply Chain",
    "Global Vehicle Systems Inc.",
    "Global Website Performance",
    "Global Wide Area Network",
    "Globalization",
    "Gluten Free Gifts {Spoonful}",
    "Glycerin {Froggys Fog}",
    "Glycol {Metro Group}",
    "Go to Market Strategy",
    "GoTransverse {BluLogix}",
    "Going Independent {Moskal Gross Orchosky}",
    "Golden Ticket {Alsid}",
    "Golf Financing {Honour Capital}",
    "Golf Shirts {Capwell Funding}",
    "Gong.io",
    "Goods & Service Tax",
    "Google Ads",
    "Google BigQuery",
    "Google Calendar",
    "Google Cloud",
    "Google Cloud AutoML",
    "Google Drive",
    "Google Hangouts",
    "Google Nexus",
    "Google Pay",
    "Google Search",
    "Google Wallet",
    "Google {NPI}",
    "Governance, Risk, & Compliance Software",
    "Government Affairs Software {Phone2Action}",
    "Government Bonds {Multi Bank Securities}",
    "Government Budget Software {GTY Technologies}",
    "Government Contract Disputes",
    "Government Credit Bond {MacKay Shields}",
    "Government Procurement",
    "Government Procurement {DMI}",
    "Government Sponsored Enterprises {Multi Bank Securities}",
    "Government {LFA Holdings}",
    "Government {Verbit}",
    "Grant Management Software",
    "Grantek Systems Integration Inc.",
    "Graph Database {Graphable}",
    "Graphic Design Software",
    "Graphic Printing {Speedpro Imaging}",
    "Graphic Printing {Superior Office Products}",
    "Graphical User Interface (GUI)",
    "Green Computing",
    "Greenlight Guru",
    "Grid Computing",
    "GridGain",
    "Grievances",
    "Ground Transportation",
    "Group Captive Insurance",
    "Group Health Insurance {Sana Benefits}",
    "Group Insurance Broker {ARC Group Benefits}",
    "Group Leaders",
    "Growth Equity {GP Equity Advisors}",
    "Growth Hacking {LatentView Analytics}",
    "Grub Control {TruGreen}",
    "Guaranteed Energy Savings Contract",
    "Guardrail {Flexible Lifeline Systems}",
    "Guerrilla Marketing",
    "Guest WiFi",
    "Guided Selling",
    "Guidewire (GWRE)",
    "Gun Shot Detection {ZeroEyes}",
    "Gun Violence {ZeroEyes}",
    "Gunshot Detection {Shooter Detection Systems}",
    "Gusto {Paylocity}",
    "H2O.ai",
    "HC Operate - 0610 {Deloitte}",
    "HCL Technologies",
    "HCM Evaluation and Selection {KSI Services}",
    "HCM RFP {KSI Services}",
    "HCM Technology Assessment {KSI Services}",
    "HG Insights",
    "HH Global",
    "HIPAA Compliance",
    "HIPAA Compliant Messaging",
    "HLS Streaming {WSN Live}",
    "HP 3D Printing {HP}",
    "HP Cloud Services",
    "HP Exstream {Metroland Business Machine}",
    "HP Inc (HPQ)",
    "HP Jet Intelligence",
    "HP JetAdvantage",
    "HP LaserJet",
    "HP OfficeJet",
    "HP PageWide",
    "HP ePrint",
    "HPE 3PAR StoreServ",
    "HPE Greenlake",
    "HPE Vertica",
    "HR Analytics Software",
    "HR Automation",
    "HR Metrics",
    "HR Outsourcing",
    "HR Professional Development",
    "HR Professionals",
    "HR Service Delivery Software",
    "HR Software Vendor Integrations {Joynd}",
    "HR Strategy",
    "HR Strategy and Solutions - 0581 {Deloitte}",
    "HRIS (Human Resource Information Systems)",
    "HRSA Audit",
    "HSBC Bank",
    "HTC",
    "HTML 5",
    "HUB International {Paylocity}",
    "HVAC (Heating, Ventilation, & Air Conditioning)",
    "HVAC Software",
    "Hackathon",
    "HackerOne {Cybri}",
    "Hadoop",
    "Hadoop Distributed File System",
    "Hand Protection",
    "Hand Sanitizer",
    "Hand Soap {Froggys Fog}",
    "Hand Washing Stations {United Site Service}",
    "Hand Washing {Meritech Hygeine}",
    "Handheld Scanner",
    "Handwriting {Friendly}",
    "Harassment",
    "Hard Drive Shredding / Hard Drive Destruction",
    "Hard Drive Wiping",
    "Hard Drives",
    "Hardware Encrypted Drives {Secure Data}",
    "Hardware Failures",
    "Hardware Infrastructure",
    "Hardware Resources",
    "Harmony Healthcare IT {Flatirons Digital Innovations}",
    "Harvard Manage Mentor {ExecOnline}",
    "Haver Analytics",
    "Hawthorne {Marketing Architects}",
    "Hazardous Materials",
    "Hazardous Materials Disposal",
    "Hazardous Waste Transportation {Veolia}",
    "Hazelcast",
    "Hazmat Shipping Emergency Response {Arrowdale I}",
    "Hazmat Transport {Arrowdale I}",
    "Head Protection",
    "Headless Commerce {DMI}",
    "Headset / Headphones",
    "Health & Safety",
    "Health & Safety Consulting",
    "Health Care Costs",
    "Health Economics and Outcomes Research {Optum Inc}",
    "Health IT Consulting",
    "Health Information Exchange",
    "Health Information Management {GRM Company}",
    "Health Information Privacy",
    "Health Insurance",
    "Health Navigation",
    "Health Reimbursement Account",
    "Health Savings Account (HSA)",
    "Health Triage",
    "HealthEquity {Benefit Resource}",
    "Healthcare",
    "Healthcare & Practice Management",
    "Healthcare Analytics",
    "Healthcare Big Data",
    "Healthcare CRM {FreeAgent CRM}",
    "Healthcare Compliance",
    "Healthcare Consulting {Engage Health Solutions}",
    "Healthcare Environment of Care Planning Services {Patient Ready By Design}",
    "Healthcare Financial Benchmarking",
    "Healthcare IT Outsourcing {HCTec}",
    "Healthcare IT Solution Providers {HCTec}",
    "Healthcare Interoperability",
    "Healthcare Law",
    "Healthcare M&A {Healthcare IT Leaders}",
    "Healthcare Productivity",
    "Healthcare Providers",
    "Healthcare Research {KJT Group}",
    "Healthcare Revenue Cycle",
    "Healthcare Security {Healthcare IT Leaders}",
    "Healthcare Software",
    "Healthcare Strategic Planning",
    "Healthcare Technology Companies {Healthcare IT Leaders}",
    "Hearing Protection",
    "Heat Maps",
    "Heat Sinks {Greater Machining}",
    "Heavy Equipment",
    "Hedge Accounting {Derivative Path}",
    "Hedge Funds",
    "Hedging",
    "HelloSign",
    "Help Desk / Service Desk",
    "Hematoxylin and Eosin Staining {Epredia}",
    "Hemp Regulatory Authority {NatureTrak Inc}",
    "Hewlett Packard Enterprise (HPE)",
    "Hexacomb Packaging {Emballages LM}",
    "Hexagon Metrology",
    "High Availability",
    "High Density Storage {Raymond Handling Solutions}",
    "High Dynamic Range (HDR)",
    "High Hazard Chemicals {Veolia}",
    "High Level Assembly Services",
    "High Potential Development {ExecOnline}",
    "High Pressure Testing {HEL Group}",
    "High Risk Compliance Solutions {NatureTrak Inc}",
    "High Speed Recorder {Astro Nova}",
    "High Throughput Screening {Automata}",
    "High Yield Bond Fund {MacKay Shields}",
    "High Yield Bonds",
    "High Yield Savings",
    "High-Density Polyethylene (HDPE)",
    "High-performance Computing",
    "High-speed Networks",
    "High-volume Printing",
    "HighJump",
    "HighRadius",
    "Higher Education Budget Software {GTY Technologies}",
    "Higher Education Budgeting {GTY Technologies}",
    "Highspot",
    "Hire App Developers {Turing}",
    "Hire Developers {Turing}",
    "Hire Engineers {Turing}",
    "Hire Programmers {Turing}",
    "Hire Software Developers {Turing}",
    "Hire Software Engineers {Turing}",
    "HireVue",
    "Hiring",
    "Hiring {RobinAI}",
    "Hiscox Business Insurance",
    "Histology Processing {Epredia}",
    "Histology {Epredia}",
    "Historic Tax Credits {Foss and Company}",
    "Hitachi EM {Thermo Fisher Scientific}",
    "Hitachi Vantara",
    "Hitrust",
    "Hive.hr {Engagement Multiplier}",
    "Holiday Gifts {Spoonful}",
    "Holiday Party {Bowlero}",
    "Holiday Season",
    "Home Care",
    "Home Elevators {Savaria}",
    "Home Healthcare Software",
    "Home Inspection Software",
    "Home Mortgage Disclosure Act (HMDA)",
    "Honeycomb Packaging {Emballages LM}",
    "Hootsuite",
    "Horizontal Carousel {Raymond Handling Solutions}",
    "Hortonworks",
    "Hospital Budgeting",
    "Hospital Curtains {Kleenedge}",
    "Hospital Diversion {Central Research}",
    "Hospital Evacuation {Central Research}",
    "Hospital Medicine",
    "Hospital Price Transparency {CPSI}",
    "Hospital Staffing {CPSI}",
    "Hospitality Industry Trends {Cloudbeds}",
    "Hospitality Software",
    "Hosted Application",
    "Hosted Contact Center",
    "Hosted Exchange",
    "Hosted PBX {CommsChoice}",
    "Hosted VoIP",
    "Hosting Providers & IT Management",
    "Hot Desking",
    "Hotel Management",
    "Hotel Management Software",
    "Hotel Management Software {Cloudbeds}",
    "Hotel TV {Direct Tech Sales}",
    "Hotel accommodations",
    "Hotel meeting space",
    "Hotel rates",
    "Hotjar",
    "Hours of Service",
    "Hours of Work",
    "Housekeeping {Jani King}",
    "Huawei",
    "Hubspot",
    "Human Capital Management",
    "Human Capital Management (HCM) Software",
    "Human Capital Risk Management {Insurance Office of America}",
    "Human Centered Leadership {Mentora Institute}",
    "Human Cohorts {Metabolon}",
    "Human Emulation System {Emulate}",
    "Human Machine Interface Solutions {Nelson Miller}",
    "Human Resource Consulting {Unemployment Services Trust}",
    "Human Resource Management",
    "Human Resource Technology",
    "Human Services Software",
    "Human in the Loop AI",
    "Human-Centered Design",
    "Human-Machine Interface (HMI) Design",
    "Humana",
    "Humana SMB {Oscar Insurance}",
    "Humidifiers {DriSteem}",
    "Hybrid Applications",
    "Hybrid Cloud",
    "Hybrid Events",
    "Hybrid IT",
    "Hybrid IT Environments",
    "Hybrid Meetings",
    "Hybrid Stepper Motors {Lin Engineering}",
    "Hybrid Wide Area Networking (WAN)",
    "Hybrid Working / Hybrid Workplace",
    "Hydro Valve {EBCO Industry}",
    "Hydrogen Fuel Cell",
    "Hydrogen Fuel Cell {FuelCell Energy}",
    "Hydropower Valve {EBCO Industry}",
    "Hyland Software",
    "Hyper-Converged Infrastructure",
    "HyperScale Data Center {Chayora}",
    "Hyperautomation",
    "Hyperconnectivity",
    "Hyster {Alliance Material Handling}",
    "IATF 16949 {DQS}",
    "IBM",
    "IBM Blockchain",
    "IBM Cloud",
    "IBM Cloud Services",
    "IBM DataStage",
    "IBM Maximo {Nuvolo Limited}",
    "IBM RPG",
    "IBM SPSS",
    "IBM Tririga {Nuvolo Limited}",
    "IBM Watson",
    "IBM WebSphere eXtreme Scale",
    "IBM i API {Eradani}",
    "IFS",
    "IFS Solutions {Astra Canyon Group}",
    "IO Link {IFM}",
    "IOT Communications {OpenMarket}",
    "IOT Security",
    "IP Faxing",
    "IP Networks",
    "IP Storage",
    "IP Telephony",
    "IPO Advisory {ICR}",
    "IPSec",
    "IRB {Advarra}",
    "IRS 280E Tax Code {Crowe Horwath}",
    "ISMS Family of Standards",
    "ISNetworld",
    "ISO 13485 {DQS}",
    "ISO 14001 {DQS}",
    "ISO 14224",
    "ISO 20022",
    "ISO 27001 {DQS}",
    "ISO 27001 {Drata}",
    "ISO 27001 {Vanta}",
    "ISO 9001",
    "IT Asset Disposition / IT Asset Retirement / ITAD",
    "IT Asset Management",
    "IT Asset Management Software",
    "IT Asset Management {Profit Enhancement Services}",
    "IT Careers",
    "IT Consulting",
    "IT Cost Management",
    "IT Efficiency",
    "IT Expense Management {Profit Enhancement Services}",
    "IT Financing {Delta Financial Group}",
    "IT Governance, Risk & Compliance",
    "IT Hardware Maintenance",
    "IT Industry",
    "IT Infrastructure",
    "IT Infrastructure Monitoring",
    "IT Leadership",
    "IT Leasing {Delta Financial Group}",
    "IT Management",
    "IT Modernization {Tasktop Technologies}",
    "IT Operations",
    "IT Optimization",
    "IT Outsourcing",
    "IT Portfolio Management",
    "IT Provider {Millennia Technologies}",
    "IT Service Management",
    "IT Spending",
    "IT Staff Augmentation {SibEDGE}",
    "IT Strategy",
    "IT Transformation",
    "IT as a Service (ITaaS)",
    "IT process automation (ITPA)",
    "IT/OT Convergence",
    "ITIL",
    "IVR Payment {GCI}",
    "IWMS Software",
    "Idea Management Software",
    "Identity & Access Management",
    "Identity Assurance {ValidSoft}",
    "Identity Governance {Tuebora}",
    "Identity Management",
    "Identity Providers (IdP)",
    "Identity Theft Protection",
    "Identity Verification {Trulioo}",
    "Identity as a Service (IDaaS)",
    "Illumina",
    "Image Analysis Software {Epredia}",
    "Imaging Center {Medcare MSO}",
    "Immersion Heater OR Electric Heater",
    "Immersive Labs {TryHackMe}",
    "Immersive Technology",
    "Immigration",
    "Immunofluorescence and Immunohistochemistry {Epredia}",
    "Immunohistochemistry {Epredia}",
    "Immunotherapy {McKesson Ontada}",
    "Imperva",
    "Implementation {Principle Global}",
    "Import {Toll Holdings}",
    "Imports",
    "In Home Delivery and Installation {Allegro Home Delivery}",
    "In Silico Patients/In Silico Clinical Trials {GNS Healthcare}",
    "In Vitro Models {Emulate}",
    "In house Pharmacy {Tango 340B}",
    "In-App Advertising",
    "In-App Messaging",
    "In-Building Connectivity",
    "In-Database Analytics",
    "In-Image Advertising",
    "In-Memory Data Grid (IMDG)",
    "In-Memory Database {Raima}",
    "In-Memory Technology",
    "In-Store Music",
    "InRiver",
    "Inbound Marketing",
    "Inbound Supply",
    "Incentive Compensation",
    "Incentive Program",
    "Incentive Stock Options",
    "Incentive Travel",
    "Incentives {Kloeckner Metals}",
    "Incident & Problem Management",
    "Incident Management Software",
    "Incident Response {Sophos}",
    "Income Share Agreement",
    "Independent Contractors",
    "Index License Management",
    "Index Servers",
    "Indiana State Tax Credits {Foss and Company}",
    "Indicators of Compromise (IOC)",
    "Indicon Corporation",
    "Indirect Sales",
    "Indirect Tax",
    "Individual Coverage Health Reimbursement Arrangements (ICHRA) {Nexben}",
    "Individual Coverage Health Reimbursement Arrangements (IHCRA) {Nexben}",
    "Indoor Air Quality",
    "Indoor Air Quality {Black and McDonald Limited}",
    "Indoor Cell Site",
    "Indoor Recycling Bins {Method}",
    "Industrial Adhesives",
    "Industrial Air Purification {Aeroex Technologies}",
    "Industrial Air Purifiers",
    "Industrial Automation",
    "Industrial Automation LLC",
    "Industrial Baler",
    "Industrial Control Systems (ICS)",
    "Industrial Conveyors {SPARXiQ}",
    "Industrial Cooling",
    "Industrial Design {Resolve Business Development}",
    "Industrial Equipment Maintenance {Black and McDonald Limited}",
    "Industrial Gas Regulation and Transmission {Cashco}",
    "Industrial HEPA Filter {Aeroex Technologies}",
    "Industrial High-Performance Computing",
    "Industrial Hygiene",
    "Industrial Internet of Things",
    "Industrial Lubricants/Oils/Greases",
    "Industrial Painting (Coating)",
    "Industrial Refrigeration Maintenance {Black and McDonald Limited}",
    "Industrial Robots",
    "Industrial Space",
    "Industrial Vending",
    "Industrial Water & Wastewater Treatment {Veolia}",
    "Industrial and Flex Space {Cresa Global}",
    "Industrial {HP}",
    "Industry 4.0",
    "Influencer Marketing",
    "Influencer Marketing Software",
    "Infobip",
    "Infor",
    "Infor - 0579 {Deloitte}",
    "Informatica",
    "Information Assurance",
    "Information Lifecycle Management",
    "Information Security",
    "Information and Communication Technology (ICT)",
    "Infrastructure",
    "Infrastructure Management",
    "Infrastructure Monitoring",
    "Infrastructure Provisioning",
    "Infrastructure Security",
    "Infrastructure as Code",
    "Infrastructure as a Service (IaaS)",
    "Infraworks",
    "IngeniousMed {Claimocity}",
    "Ingram Micro",
    "Inhouse Color Labeling {AstroNova}",
    "Initial Public Offering (IPO)",
    "Injection Molding",
    "Injection Molding {Fast Radius}",
    "Ink Cartridge",
    "Inline Deduplication",
    "InnerWorkings",
    "Innovation",
    "Insect Control {TruGreen}",
    "Insect Extermination {Rollins}",
    "InsideView",
    "Insider Threat",
    "Insidesales.com",
    "Inspection Services {Thermal Cam USA}",
    "Inspection {United FS}",
    "Insperity",
    "Instagram Ads",
    "Installation Services {Allegro Home Delivery}",
    "Installed Base Planning {Baxter}",
    "Instant Financial {Rain}",
    "Instant Messaging",
    "Instant Payment Notification",
    "Institutional Investing",
    "Institutional Pharmacy Services {HealthDirect Institutional Pharmacy Services}",
    "Instructure",
    "Instrument Interface Middleware {Agaram Technologies}",
    "Insulation",
    "Insurance Analytics/Insurance Big Data {Sapiens}",
    "Insurance Automation {Sapiens}",
    "Insurance Billing Solutions/Software/Companies {Sapiens}",
    "Insurance Broker",
    "Insurance Captives {Horton Group}",
    "Insurance Claims Estimating",
    "Insurance Claims Management Software {Ventiv Technology}",
    "Insurance Compliance Management {Agentsync}",
    "Insurance Distribution {Agentsync}",
    "Insurance Follow Up {CPSI}",
    "Insurance IT/Insurance Solutions/Insurance Systems/Insurance Software {Sapiens}",
    "Insurance Management Solutions/Systems/Software {Sapiens}",
    "Insurance Predictive Modeling/Analytics {Sapiens}",
    "Insurance Producer Management {Agentsync}",
    "Insurance Products",
    "Insurance Risk Survey Management Software",
    "Insurance Software",
    "Insurance Technology/Insuretech {Sapiens}",
    "Insurance Tracking Services {Loan Protector}",
    "Insurance Vendor {Loan Protector}",
    "Integrated Cloud Services",
    "Integrated Development Environments",
    "Integrated Marketing",
    "Integrated Messaging",
    "Integrated Risk Management Software",
    "Integrated Risk Management Software {Ventiv Technology}",
    "Integrated Services Digital Network (ISDN)",
    "Integrated Social Marketing",
    "Integrated Talent Management",
    "Integrated Web Services {Eradani}",
    "Integrated workplace management system",
    "Integration Partners {Joynd}",
    "Integration Platform as a Service (iPaaS)",
    "Integration Testing {Codecov}",
    "Integrity RTOS {Raima}",
    "Intel",
    "Intel Joule",
    "Intel Xeon",
    "Intellectual Property Management Software",
    "Intelligence Augmentation",
    "Intelligence Services {Swan Island}",
    "Intelligent Automation",
    "Intelligent Automation - 0628 {Deloitte}",
    "Intelligent Document Processing {Quantiphi}",
    "Intelligent Virtual Assistants (IVA)",
    "Intellipack {Sealed Air}",
    "Intent Based Networking",
    "Intent Data",
    "Intent Marketing",
    "InterCall",
    "Interactive 360 {Sevan Multi Site Solutions}",
    "Interactive Flat Panels {Newline Interactive}",
    "Interactive Marketing",
    "Interactive Response Technology (IRT)",
    "Interactive Visualization",
    "Interactive Voice Response",
    "Interactive Whiteboards",
    "Intercom Systems",
    "Intercompany Accounting",
    "Interest Rate Hedging",
    "Interim Executive",
    "Interim Executive {UHY Consulting}",
    "Interior Design",
    "Intermodal Marketing Company Software {Envase Technologies}",
    "Intermodal Shipping",
    "Intermodal Software {Envase Technologies}",
    "Internal Communications Software",
    "Internal Medicine",
    "Internal Talent Marketplace {Catalant Technologies}",
    "International Assignment Management {Global Mobility Solutions}",
    "International Business Insurance {Insurance Office of America}",
    "International Carriers {Hangar A}",
    "International Data Corporation",
    "International Equity Fund {MacKay Shields}",
    "International Expansion {Emerald Technology}",
    "International Expansion {Emerald Tech}",
    "International Marketing",
    "International Shipping",
    "International Traffic in Arms Regulations (ITAR) {RegDOX}",
    "Internationalization (i18n)",
    "Internet Information Services (IIS)",
    "Internet Marketing",
    "Internet Protocol Private Branch Exchange (IP PBX)",
    "Internet Protocol Television (IPTV)",
    "Internet Protocol Version 4 (IPv4)",
    "Internet Protocol Version 6 (IPv6)",
    "Internet Relay Chat (IRC)",
    "Internet Search Engines",
    "Internet Security",
    "Internet Service Provider",
    "Internet Transit",
    "Internet infrastructure",
    "Internet of Everything (IoE)",
    "Internet of Medical Things (IoMT)",
    "Internet of Things (IoT)",
    "Interoperability",
    "Interpreting Services {Capital Linguistics}",
    "Intralinks",
    "Intranets",
    "Intrinsically Safe Monitor",
    "Intrusion Detection",
    "Intrusion Prevention",
    "Intrusion Prevention System {Cato Networks}",
    "Intuit (INTU)",
    "Inventory Control",
    "Inventory Management",
    "Inventory Management Software",
    "Inventory Optimization {Bricz}",
    "Inventory Planning {Transportation Impact}",
    "Invest or Purchase Business {UFP Industries}",
    "Investigation Management Software",
    "Investigations",
    "Investment Banking",
    "Investment Software",
    "Investment Strategy and Planning",
    "Investment Tax Credits {Foss and Company}",
    "Investment {Novus Partners}",
    "Investor Relations",
    "Investor Relations Consultant",
    "Invigilation",
    "Invoca",
    "Invoice Management",
    "Invoice Matching {Esker Inc}",
    "Ionizer {ActivePure Technology}",
    "Ironclad",
    "Islanders Season Tickets {Elevate Sports Venture}",
    "Isothermal/Steam Humidifiers {DriSteem}",
    "Istio {Tetrate}",
    "Iterative Prototyping",
    "Iterative Software Development",
    "Ivalua",
    "Ivanti",
    "J Wood Capital {ICR}",
    "J.B. Hunt (JBHT)",
    "JD Edwards",
    "JDA Software",
    "JIRA",
    "JLT Group",
    "JPMorgan Chase (JPM)",
    "JR Automation",
    "Jail Management Software",
    "Jamf 2 {Addigy}",
    "Jamf {Addigy}",
    "Jana Partners {Rockway Asset Management}",
    "Janitorial Services",
    "Java",
    "Java Message Service (JMS)",
    "JavaScript",
    "JavaScript Security",
    "Jboss",
    "Jenkins",
    "Jet Card {Sky Aviation}",
    "Jet Cards Charter Flights {Sky Aviation}",
    "Jet Edge {XOJET}",
    "Jet Linx {XOJET}",
    "JetCard {XOJET}",
    "Jewelry Store Management Software",
    "Jira Metrics {LinearB}",
    "Jitbit HelpDesk",
    "Job Analysis",
    "Job Board Software",
    "Job Costing",
    "Job Descriptions",
    "Job Dissatisfaction {Zoominfo}",
    "Job Evaluation",
    "Job Posting",
    "Job Satisfaction",
    "Job Scheduling / Workload Automation",
    "Jobsite Security {Gridless Power}",
    "Joele Frank {Rockway Asset Management}",
    "Join.me",
    "Jones Lang LaSalle (JLL)",
    "Joomla",
    "Journey Analytics {Usermind}",
    "Journey Orchestration {Usermind}",
    "Joyent",
    "Jumpstarter {NOCO Company}",
    "Juniper Mist {Questivity Incorporated}",
    "Juniper Networks",
    "KKR {Rockway Asset Management}",
    "KOL Engagement {Anju Software}",
    "KOL Identification {Anju Software}",
    "KOL Management {Anju Software}",
    "KOL Marketing {LaunchMetrics}",
    "KPMG",
    "Kaiser Permanente SMB {Oscar Insurance}",
    "Kaiser Permanente {Oscar Insurance}",
    "Kandji 2 {Addigy}",
    "Kandji {Addigy}",
    "Kantar Health {KJT Group}",
    "Kaseya 2 {Addigy}",
    "Kaseya {Addigy}",
    "Kaspersky {Sophos}",
    "Kekst CNC {Rockway Asset Management}",
    "Kelly Services OCG {Kelly Services}",
    "Kenna Security",
    "Kenshoo",
    "Kentico",
    "Kerberos",
    "Kernel Updates {Cloud Linux}",
    "Keto {All Clean Natural}",
    "Key-Value Stores",
    "KeyBank",
    "Keyence",
    "Keynote Speakers",
    "Keystroke Logging",
    "Keyword research",
    "Khoros",
    "Kickstarter",
    "Kill Claim {All Clean Natural}",
    "Kiosks",
    "Kira Systems",
    "Kitchen Household Products {Houseware Distributors}",
    "Kitewheel",
    "Kitting",
    "Kitting {Encore Repair Services}",
    "Know Your Customer (KYC)",
    "Knowledge Graph {Graphable Inc}",
    "Knowledge Management",
    "Knowledge-Based Authentication (KBA)",
    "Kofax",
    "Konica Minolta {Revolution Office}",
    "Kotter",
    "Kronos",
    "Kubernetes",
    "Kuehne+Nagel Group",
    "Kyriba",
    "L3 Technologies",
    "LAN Switches",
    "LCD Digital Signage",
    "LCD Repairs {Encore Repair Services}",
    "LCFS {Foss and Company}",
    "LE {NOCO Company}",
    "LED Billboards",
    "LED Digital Signage",
    "LED Displays",
    "LED Driver",
    "LED Lighting",
    "LED Message Display",
    "LED Retrofit",
    "LEED certification",
    "LG Mobile",
    "Lab Consulting {Medcare MSO}",
    "Lab Informatics {Agaram Technologies}",
    "Lab Reactor {HEL Group}",
    "Lab Services {Matrix Medical Network}",
    "Lab Space {Cresa Global}",
    "Label Printer {AstroNova}",
    "Labor Availability {Newmark Knight Frank}",
    "Labor Leasing {Emerald Technology}",
    "Labor Management",
    "Labor Management Software",
    "Labor Relations",
    "Labor Standards",
    "Laboratory Automation",
    "Laboratory Automation {Automata}",
    "Laboratory Informatics",
    "Laboratory Information Management Systems",
    "Laboratory Information Management Systems {Agaram Technologies}",
    "Laboratory Robotics",
    "Labour Leasing International {Emerald Technology}",
    "Lachman Competitors {Lachman Consultants Services}",
    "Ladder Safety {Flexible Lifeline Systems}",
    "Land Management {Aurigo}",
    "Landing Page Builders",
    "Landscaping {United FS}",
    "Language Interpretation",
    "Language Service Provider (LSP)",
    "Laptop",
    "Laptop Security {Qualcomm Technologies}",
    "Large Competitors {Data Cubed}",
    "Large Format Printing {Taylor Corporation}",
    "Large Group Health Plan",
    "Large Part and Complex Machining {TraceAMatic}",
    "Laser Fume Extractor {Aeroex}",
    "Laser Printers",
    "Laser Scanning {Applied Technology Group}",
    "Last Mile Delivery",
    "Lattice Engines",
    "Law Firm Funding {Counsel Financial}",
    "Law Practice Management Software",
    "Law Practice Optimization/Legal Practice Optimization (LPO)",
    "Lawn Plan {TruGreen}",
    "Lawn Seeding {SavATree}",
    "Laws & Regulations",
    "Layoffs",
    "Lead Capture Software",
    "Lead Capture {Lexhelper}",
    "Lead Enrichment Tools {ZoomInfo}",
    "Lead Generation",
    "Lead Generation Consulting",
    "Lead Generation Services",
    "Lead Management",
    "Lead Nurturing",
    "Lead Quality",
    "Lead Scoring",
    "Lead Tracking",
    "Lead routing",
    "Lead-to-account Matching",
    "Lead411",
    "LeadIQ",
    "Leadership Development {ExecOnline}",
    "Leadership Development {Leadr}",
    "Leadership Training",
    "Leading Competitors {Unemployment Services Trust}",
    "Leading with Love {Mentora Institute}",
    "Leadspace",
    "Lean Manufacturing",
    "LeanData",
    "Learning Management Systems",
    "Learning as a Service",
    "Lease Accounting Software",
    "Lease Accounting {LeaseQuery}",
    "Lease Administration {Leasecake}",
    "Lease Extension and Lease Negotiation {Cresa Global}",
    "Lease Management Software {Leasecake}",
    "Lease Renewal {Cresa Global}",
    "Leaseback",
    "Leasing Accounting",
    "Leasing Vendor {Delta Financial Group}",
    "Leasing {Winthrop Resources}",
    "Leave Management",
    "Leave of Absence",
    "Legacy AV {Sophos}",
    "Legacy Data Access {Flatirons Digital Innovations}",
    "Legacy Data Migration {Data Strategies International}",
    "Legacy Software Integration {HypedUp}",
    "Legal Assistant {Lexhelper}",
    "Legal Billing Software",
    "Legal Cannabis Growing",
    "Legal Case Management Software",
    "Legal Docs {RobinAI}",
    "Legal Hold",
    "Legal Matter Management",
    "Legal Operations",
    "Legal Process Outsourcing",
    "Legal Project Management",
    "Legal Review {Venio}",
    "Legal Solutions",
    "Legal Technology {Venio}",
    "LegalTech Show",
    "LegalTech {RobinAI}",
    "Legionella {Metro Group}",
    "Lenel {Feenics}",
    "Lenovo (HKG)",
    "Less-than-truckload shipping (LTL)",
    "Letter of Credit",
    "Level Funded Health Plans {Sana Benefits}",
    "Level Gauge",
    "Leveraged Buyout",
    "LiDAR",
    "LiFEPO4 Battery {Rendell Sales Co}",
    "License Plate Reader {OMNIQ}",
    "License Plate Recognition",
    "Licensing",
    "Licensing Management Software {Octane5 International}",
    "Licensing Royalties Software {Octane5 International}",
    "Licensing Vendors {Agentsync}",
    "Life Insurance",
    "Life Science {Newmark Knight Frank}",
    "Life and Annuities Solutions/Systems/Software {Sapiens}",
    "Lifeboat",
    "Lifeline Systems {Flexible Lifeline Systems}",
    "Liferay",
    "Lifesize",
    "Lifestyle Photography",
    "Lift Trucks",
    "Lift Trucks {Alliance Material Handling}",
    "Lifting & Material Handling Equipment",
    "Lighting Maintenance",
    "Lighting Retrofit {Action Services Group}",
    "Lighting as a service",
    "Lightspeed {CitiXys Limited}",
    "Lime Application {TruGreen}",
    "Limitless Designs",
    "Lin Engineering Competitors {Lin Engineering}",
    "Lincoln National (LNC)",
    "Line of Credit",
    "Linear Actuators {Lin Engineering}",
    "Linear TV {Marketing Architects}",
    "Link Building",
    "LinkSquares",
    "LinkedIn Learning {ExecOnline}",
    "Linkedin",
    "Linkedin Recruiter",
    "Linkedin Sales Navigator",
    "Linux",
    "Linux Kernel {Cloud Linux}",
    "Liquid Biopsy {Fulgent Genetics}",
    "Liquid Handling {Automata}",
    "Liquid Level Sensor",
    "LiquidPlanner",
    "Liquidity Management",
    "Litecoin",
    "Lithium Batteries",
    "Lithium Ion {Alexander Battery Technologies}",
    "Lithium Rechargeable {Alexander Battery Technologies}",
    "Litigation",
    "Litigation Funding",
    "Live Chat Software {AtlasRTX}",
    "Live Patching and Kernel Patching {Cloud Linux}",
    "Live Selling",
    "Live Streaming",
    "LiveAgent",
    "LivePerson",
    "LiveVox",
    "Livestream Commerce {Firework}",
    "Load Balancing",
    "Load Balancing Software",
    "Load Shedding",
    "Load Testing",
    "Loading Dock",
    "Loan Acquisition {CS3 Marketing}",
    "Loan Growth {CS3 Marketing}",
    "Loan Management Technology Platform {Derivative Path}",
    "Loan Origination Software",
    "Loan Processing {GRM Company}",
    "Loan Servicing Software",
    "Loan Signing Agent",
    "Lobby Management {Coconut Calendar}",
    "Lobbying {LFA Holdings}",
    "Local Area Networking",
    "Local Courier",
    "Local Marketing",
    "Local Payments/Alternative Payments {NCR}",
    "Local Sports Streaming {WSN Live}",
    "Localization",
    "Location Analytics",
    "Location Clustering {Bricz}",
    "Location Intelligence Software",
    "Location-Based Marketing",
    "Lockton",
    "Locum Tenens",
    "Locus {Fortna}",
    "Lodging",
    "Log Analysis Software",
    "Log Files",
    "Log Management",
    "LogMeIn",
    "Logistics & Procurement",
    "Logistics Shipping Company {Transportation Impact}",
    "Logistics/Supply Chain {PalletOne}",
    "Logo Creation",
    "Logo Design {Brand Institute}",
    "Logrhythm",
    "Long Hold Times {Fonolo}",
    "Long Term Care Pharmacy {HealthDirect Institutional Pharmacy Services}",
    "Long Term Evolution",
    "Long-Range Planning",
    "Looker Data Sciences",
    "Loss Control Software Solutions",
    "Loss Draft {Loan Protector}",
    "Loss Prevention",
    "Loss Prevention Companies {Intellishop}",
    "Loss Prevention Programs {Intellishop}",
    "Loss control 360",
    "Low-Code",
    "Low-Density Polyethylene Bags",
    "Low-Touch Customer Service",
    "Low-Volume Production/Manufacturing {Fast Radius}",
    "Lower Credit Card Fees {NCR}",
    "Loyalty Program",
    "Loyalty Software",
    "Lubricant Delivery {Kinect Consulting}",
    "Lucas Systems",
    "Lucid BuildingOS",
    "LucidChart {SmartDraw}",
    "Luminance",
    "Lusha",
    "Lyophilized Beads {Millrock Technology}",
    "Lyra {Integrated Behavioral Health}",
    "M&A Deal Sourcing {Transworld Business Brokers}",
    "M&A Lawyer",
    "M&T Bank (MTB)",
    "M-Files",
    "MB {NOCO Company}",
    "MCIF",
    "MDM Generic Terms {Addigy}",
    "MEMS {Nagase}",
    "MEP Engineering",
    "MGA Licensing {Agentsync}",
    "MIL-STD Testing {Eurofins MET Laboratories}",
    "MPLS {Cato Networks}",
    "MRI Imaging System",
    "MSL Identification {Anju Software}",
    "Mac OS X",
    "Machine Data",
    "Machine Learning",
    "Machine Safety Guards {SPARXiQ}",
    "Machine Translation (Automated Translation)",
    "Machine-to-Machine (M2M)",
    "Machining {Greater Machining}",
    "Macintosh Computer",
    "Macroeconomics",
    "Magecart",
    "Magento",
    "Magnetic Particle Inspection {Metal Finishing Technologies}",
    "Mail Order",
    "MailChimp",
    "Mailroom Operations {Metroland Business Machine}",
    "Main Street Lending",
    "Mainframes",
    "Maintenance Manager {Black and McDonald Limited}",
    "Maintenance Outsourcing {Quant}",
    "Maintenance, Repair and Overhaul (MRO)",
    "Malicious Domains",
    "Malware",
    "Malware Analysis",
    "Malware Attacks",
    "Malware Detection",
    "Managed Analytics - 0630 {Deloitte}",
    "Managed Analytics {Deloitte}",
    "Managed Cloud Operations {DMI}",
    "Managed Database",
    "Managed Detection & Response (MDR)",
    "Managed Hosting Services",
    "Managed IP Telephony",
    "Managed Mobility Services",
    "Managed Portfolios",
    "Managed Print Services",
    "Managed Security Services",
    "Managed Service Program {Synkriom}",
    "Managed Service Provider",
    "Managed Services",
    "Management & Executive Development",
    "Management Buyout (MBO)",
    "Management Consulting",
    "Management Fundamentals",
    "Management Leadership/Skill Development {Learning Outsource Group}",
    "Management Training {ExecOnline}",
    "Manager Development {ExecOnline}",
    "Mandatory Convertible {J Wood Capital}",
    "Mandiant",
    "Mandiant {Sophos}",
    "MangoApps",
    "Manhattan Associates",
    "Manicure Supplies {TNG Worldwide}",
    "ManpowerGroup {Kelly Services}",
    "Manufacturers {Alliance Material Handling}",
    "Manufacturing Cost Reduction",
    "Manufacturing Data",
    "Manufacturing Data Analytics {Transportation Impact}",
    "Manufacturing ERP {XcelPros}",
    "Manufacturing Execution System",
    "Manufacturing Outsourcing",
    "Manufacturing Process Control",
    "Manufacturing Processes {Metal Finishing Technologies}",
    "Manufacturing Software",
    "Manufacturing Software {Profusion Systems}",
    "Manufacturing operations management",
    "MapR",
    "MariaDB",
    "Marijuana Workplace Policy {Fastest Labs}",
    "Marin Software",
    "Marine Software",
    "Marine Supply Chain {Voyager Portal}",
    "MarkLogic",
    "Market Data Research {ITRS Group}",
    "Market Development Funds",
    "Market Entry Strategy",
    "Market Expansion",
    "Market Research",
    "Market Research Automation",
    "Market Research Software / DesignXM / CoreXM {Qualtrics}",
    "Market Sizing",
    "MarketFinance",
    "Marketing Agency",
    "Marketing Analytics",
    "Marketing Analytics Software",
    "Marketing Architects {Marketing Architects}",
    "Marketing Attribution",
    "Marketing Automation",
    "Marketing Automation Consultants {Object 9}",
    "Marketing Automation Tools",
    "Marketing Calendar {Smartsheet}",
    "Marketing Campaign",
    "Marketing Challenges",
    "Marketing Communications",
    "Marketing Dashboards",
    "Marketing Email",
    "Marketing Funnel",
    "Marketing Information Systems",
    "Marketing Initiatives",
    "Marketing KPI",
    "Marketing Leads",
    "Marketing Management Software",
    "Marketing Messaging",
    "Marketing Operations {BrandMaker}",
    "Marketing Optimization",
    "Marketing Performance Measurement",
    "Marketing Programs",
    "Marketing ROI",
    "Marketing Reporting",
    "Marketing Resource Management",
    "Marketing Search {Mimeo}",
    "Marketing Strategy",
    "Marketing Technology",
    "Marketing Technology and Insights - 0529 {Deloitte}",
    "Marketing Technology {Deloitte}",
    "Marketing Tools",
    "Marketing Workflow",
    "Marketo",
    "Marketplace Software",
    "Marketplace payments",
    "Marsh & McLennan Companies (MMC)",
    "Martial Arts Software",
    "Masonry",
    "Mass Arbitration Filings {National Arbitration and Mediation}",
    "Mass Notification Solutions",
    "Mass Payouts",
    "Mass Shooting {ZeroEyes}",
    "Mass payments",
    "Massage Supplies {TNG Worldwide}",
    "Master Data Management (MDM)",
    "Master Patient Index",
    "MasterCard (MA)",
    "MasterControl",
    "Material Handling",
    "Material Handling Systems {Intellitrak}",
    "Material Planning",
    "Material Requirements Planning (MRP)",
    "Materials Characterization {Thermo Fisher Scientific}",
    "Materials Inspection {Thermo Fisher Scientific}",
    "Materials Management",
    "Matthews South {ICR}",
    "McAfee {Sophos}",
    "Meat Packaging {Sealed Air}",
    "Mechanical Engineering",
    "Mechanical Turk",
    "Mechanics Lien {CRF Solutions}",
    "Medallia",
    "Medallia {Qualtrics}",
    "Medallia {Talkwalker}",
    "Media Asset Management",
    "Media Attribution",
    "Media Management Systems",
    "Media Monitoring {LaunchMetrics}",
    "Media Planning",
    "Mediation {National Arbitration and Mediation}",
    "Medicaid",
    "Medical Bedding and Linens",
    "Medical Billing",
    "Medical Billing Application {Claimocity}",
    "Medical Billing Clearinghouse {The SSI Group}",
    "Medical Billing Software",
    "Medical Claim Audit {Engage Health Solutions}",
    "Medical Coding",
    "Medical Coding Audit Software {Health Information Associates}",
    "Medical Coding Audit {Health Information Associates}",
    "Medical Coding Resources {CPSI}",
    "Medical Consulting Services {Matrix Medical Network}",
    "Medical Courier {Taylor Data Systems}",
    "Medical Device Cleaning {Phasex}",
    "Medical Device Warranty Credit {SpendMend}",
    "Medical Device {Comar}",
    "Medical Devices",
    "Medical Document Storage {GRM Company}",
    "Medical Equipment",
    "Medical Financing {Honour Capital}",
    "Medical Furniture {Wilson Office Interiors}",
    "Medical Image Sharing",
    "Medical Imaging",
    "Medical Imaging AI {Quantiphi}",
    "Medical Information {Anju Software}",
    "Medical Interpreting",
    "Medical Marijuana Certification",
    "Medical Nutrition",
    "Medical Office Real Estate",
    "Medical Power Supply",
    "Medical Privacy",
    "Medical Record Management {ScanSTAT Technologies}",
    "Medical Record Retrieval {ScanSTAT Technologies}",
    "Medical Scribing - General {AQuity}",
    "Medical Scribing {AQuity}",
    "Medical Scribing {PredictionHealth}",
    "Medical Space {Cresa Global}",
    "Medical Technology",
    "Medical Tourism",
    "Medical Transcription Software",
    "Medical Waste",
    "Medication Adherence",
    "Medication Errors {MedAware}",
    "Medication Safety {MedAware}",
    "Medication Therapy Management",
    "Mediquant {Flatirons Digital Innovations}",
    "Meditech",
    "Meeting Management Software",
    "Membrane Switches",
    "Memory Card",
    "MemoryWell",
    "Mendix",
    "Mental Health App {Integrated Behavioral Health}",
    "Mental Health Benefits",
    "Mental Health Billing",
    "Mental Health Software",
    "Mentoring Software",
    "Merchandise Allocation {Bricz}",
    "Merchandise Planning",
    "Merchandising",
    "Merchant Cash Advance (MCA)",
    "Merchant Services",
    "Merchant Underwriting {Infinicept}",
    "Merchant of Record {With Reach UK Ltd}",
    "Mercury Waste {Veolia}",
    "MercuryGate",
    "Mergers & Acquisitions (M&A)",
    "Mergers & Acquisitions Due Diligence",
    "Mergers and Acquisition Branding {Principle Global}",
    "Mesenchymal Stem Cell {RoosterBio}",
    "Mesh Networking",
    "Mess Hall Tent {Creative Tents}",
    "Messagebird {MessageBird}",
    "Messaging Solutions",
    "MetLife (MET)",
    "Metabolism {Metabolon}",
    "Metabolomics {Metabolon}",
    "Metabolomics {Sapient}",
    "Metabolon {Metabolon}",
    "Metal Additive Manufacturing {AddUp}",
    "Metal Direct Energy Deposition {AddUp}",
    "Metal Finishing {Metal Finishing Technologies}",
    "Metal Foundry",
    "Metal Parts Manufacturing {Fast Radius}",
    "Metal Polishing",
    "Metal Powder Bed Fusion {AddUp}",
    "Metalworking",
    "Metaverse",
    "Metering Automation",
    "Metering Software",
    "Metrology",
    "Mezzanine Finance",
    "Micro Data Center",
    "Micro Focus",
    "Micro Focus ArcSight",
    "Micro Markets {American Food and Vending}",
    "MicroLED",
    "MicroStrategy (MSTR)",
    "Microbiology",
    "Microbiome {Metabolon}",
    "Microfulfillment",
    "Microgrid {Bloom Energy}",
    "Microgrids",
    "Micron Technology (MU)",
    "Micropayments",
    "Microphysiological Systems {Emulate}",
    "Microscope",
    "Microservices",
    "Microsoft 365 Purchase {Directions on Microsoft}",
    "Microsoft Azure",
    "Microsoft Azure Sentinel",
    "Microsoft Business Central {Sparkrock}",
    "Microsoft Certification",
    "Microsoft Corporation",
    "Microsoft Dynamics",
    "Microsoft Dynamics AX {XcelPros}",
    "Microsoft Dynamics CRM",
    "Microsoft Dynamics ERP",
    "Microsoft Dynamics Implementation {XcelPros}",
    "Microsoft Dynamics Partner {XcelPros}",
    "Microsoft Dynamics Support {XcelPros}",
    "Microsoft EA Negotiation Support {Directions on Microsoft}",
    "Microsoft Endpoint Protection {Sophos}",
    "Microsoft Enterprise Agreement {Directions on Microsoft}",
    "Microsoft Enterprise Agreement {NPI}",
    "Microsoft Excel",
    "Microsoft Exchange",
    "Microsoft Forefront Identity Manager",
    "Microsoft Forefront Unified Access Gateway",
    "Microsoft Great Plains {Sparkrock}",
    "Microsoft Hyper-V",
    "Microsoft Licensing Training {Directions on Microsoft}",
    "Microsoft Project",
    "Microsoft SONiC",
    "Microsoft SQL Server",
    "Microsoft SharePoint",
    "Microsoft Support",
    "Microsoft Surface",
    "Microsoft Teams",
    "Microsoft Teams Phone System {GCI}",
    "Microsoft Teams Security {GCI}",
    "Microsoft Teams Video Conference {GCI}",
    "Microsoft Virtual Machine Manager",
    "Microsoft Visio {SmartDraw}",
    "Microsoft Visual Studio",
    "Mid-Market Finance",
    "Middleware",
    "Migration",
    "Mileage Allowance",
    "Military Service (USERRA)",
    "Miller Zelle {Principle Global}",
    "Mimecast {Sophos}",
    "Mimikatz {Alsid}",
    "Minimum Viable Product (MVP)",
    "Minimum Wage",
    "Mining Software",
    "Mirakl {DMI}",
    "Miro",
    "Mist Collector {Aeroex Technologies}",
    "Mist Systems",
    "Mitel Networks Corporation",
    "Mitratech",
    "Mixpanel",
    "Mobile / Wireless",
    "Mobile Advertising",
    "Mobile App Advertising Performance",
    "Mobile App Analytics",
    "Mobile App Automation",
    "Mobile App Conversion",
    "Mobile App Engagement",
    "Mobile App Retargeting",
    "Mobile App User Experience",
    "Mobile App User Interface",
    "Mobile Application Development Software",
    "Mobile Application Management",
    "Mobile Application Performance",
    "Mobile Apps",
    "Mobile Audience Targeting",
    "Mobile Banking",
    "Mobile CRM",
    "Mobile Collaboration",
    "Mobile Command Center {Censys}",
    "Mobile Computing",
    "Mobile Cranes",
    "Mobile Credentials {Feenics}",
    "Mobile Data Security Software",
    "Mobile Development Tools",
    "Mobile Device Management",
    "Mobile Device Sanitizer",
    "Mobile Device/Tablet Accessories",
    "Mobile Event App",
    "Mobile Forensics",
    "Mobile Health (mHealth)",
    "Mobile Integration",
    "Mobile Interactions",
    "Mobile Learning",
    "Mobile Marketing",
    "Mobile Marketing Software",
    "Mobile Operating System (Mobile OS)",
    "Mobile Optimization",
    "Mobile Payments",
    "Mobile Phone Recycling",
    "Mobile Point of Sale (mPOS)",
    "Mobile Predictive Analytics",
    "Mobile Printing",
    "Mobile Printing {Superior Office Products}",
    "Mobile Push",
    "Mobile Robots",
    "Mobile Security",
    "Mobile Security Management",
    "Mobile Security {Gridless Power}",
    "Mobile Statements {InfoIMAGE}",
    "Mobile Technology",
    "Mobile Video Surveillance {Safety Vision}",
    "Mobile Wallet",
    "Mobile Workers",
    "Mobile Workforce",
    "Mobile Workforce Automation",
    "Mobile Workstation",
    "Mobility Management",
    "Mobility and Accessibility {Savaria}",
    "Modeling Intelligence (MI)",
    "Modeling {Sapiens}",
    "Modern Marketing",
    "Modular Data Center",
    "Modular Plan {ARC Group Benefits}",
    "Modular Tent {Creative Tents}",
    "Modus Direct {Marketing Architects}",
    "Mold Inhibitor {Froggys Fog}",
    "Mold Removal",
    "Molecular Diagnostics",
    "Molecular Markers",
    "Molex",
    "Monarch Capital {Foss and Company}",
    "Monetize Payments {Infinicept}",
    "Money Manager {Multi Bank Securities}",
    "Money Market Funds",
    "MongoDB",
    "Monitor Mounts",
    "Monitors",
    "Morgan Stanley",
    "Mortgage Credit Report {Property Interlink}",
    "Mortgage Fraud {Property Interlink}",
    "Mortgage Impairment {Loan Protector}",
    "Mortgage Lending",
    "Mortgage {Sapiens}",
    "Mortgaged Backed Securities {Multi Bank Securities}",
    "Mosquito Control {TruGreen}",
    "Mosyle 2 {Addigy}",
    "Mosyle {Addigy}",
    "Motion Control",
    "Motion Control {Lin Engineering}",
    "Motivation",
    "Motorola Solutions (MSI)",
    "MouseFlow",
    "Mouthguards",
    "Movie Development",
    "Movie/Video Financing",
    "Mulch {Performance}",
    "MuleSoft",
    "Multi Asset Credit {MacKay Shields}",
    "Multi Currency Accounting",
    "Multi Vendor Enrichment {ZoomInfo}",
    "Multi Vendor Marketplace {Omnyfy}",
    "Multi-Cloud",
    "Multi-Function Printing",
    "Multi-Tenant Data Centers (MTDCs)",
    "Multi-Touch Attribution",
    "Multichannel Distribution",
    "Multichannel Marketing",
    "Multicultural Marketing",
    "Multifactor Authentication",
    "Multifamily Residential",
    "Multilingual Desktop Publishing (DTP)",
    "Multilingual SEO Services",
    "Multilingual Website",
    "Multiprotocol Label Switching",
    "Multivariate Testing",
    "Municipal Bond Fund {MacKay Shields}",
    "Municipal Bonds {Multi Bank Securities}",
    "Municipal Budgeting {GTY Technologies}",
    "Mural",
    "Musculoskeletal",
    "Museum Software",
    "MySQL",
    "MyndVR",
    "Mystery Shoppers {HS Brands Global}",
    "NABERS {EPT Global}",
    "NCR Corporation",
    "NERC CIP Compliance",
    "NFP {Paylocity}",
    "NICE inContact",
    "NIST 800-171",
    "NTT Communications",
    "NVM Express",
    "NVMe SSD {Kioxia}",
    "Nagios",
    "Nanotechnology",
    "Narrow Networks {ReferralPoint}",
    "Narrowband",
    "Nationwide Delivery {Allegro Home Delivery}",
    "Nationwide Mutual Insurance Company",
    "Native Advertising",
    "Native Cloud Application (NCA)",
    "Natural Gas Supply Procurement {Freedom Energy Logistics}",
    "Natural Language Processing",
    "Natural Products Extraction {Phasex}",
    "Natural and Organic Lawn Care {TruGreen}",
    "NatureTrak Competition 1 {NatureTrak Inc}",
    "NatureTrak Competition 2 and 3 {NatureTrak Inc}",
    "NatureTrak Competition 2 {NatureTrak Inc}",
    "NatureTrak Competition 3 {NatureTrak Inc}",
    "NatureTrak Competition {NatureTrak Inc}",
    "NatureTrak {NatureTrak Inc}",
    "Navia {Benefit Resource}",
    "Nearshore Software Development",
    "Needs Assessment",
    "Negotiations Training {Challenger}",
    "Neo4j {Graphable Inc}",
    "Net Jets {XOJET}",
    "Net Operating Losses",
    "Net Profit Margin",
    "Net Promoter",
    "Net Terms",
    "Net Zero",
    "Net Zero {Energy Action}",
    "NetApp (NTAP)",
    "NetIQ",
    "NetSuite",
    "NetSuite Consulting {Profusion Systems}",
    "NetSuite ERP Software {Profusion Systems}",
    "NetSuite ERP {Profusion Systems}",
    "NetSuite Migration {Profusion Systems}",
    "NetSuite Support {Profusion Systems}",
    "Netbase {Talkwalker}",
    "Netscout {Gigamon}",
    "Netsuite {Brightpearl}",
    "Network Access Control (NAC)",
    "Network Address Translation (NAT)",
    "Network Analytics",
    "Network Architecture",
    "Network Attached Storage",
    "Network Automation",
    "Network Best Practices",
    "Network Congestion",
    "Network Convergence",
    "Network Detection",
    "Network Detection and Response {cynamics.ai}",
    "Network Encryption",
    "Network Forensics",
    "Network Functions Virtualization (NFV)",
    "Network Hardware",
    "Network Infrastructure",
    "Network Integrity {ReferralPoint}",
    "Network Latency",
    "Network Load Balancing (NLB)",
    "Network Management",
    "Network Monitoring Software",
    "Network Neutrality",
    "Network Operations Center (NOC)",
    "Network Optimization",
    "Network Packet Brokers (NPBs)",
    "Network Performance",
    "Network Printing",
    "Network Protocols",
    "Network Redundancy",
    "Network Security",
    "Network Security Appliance",
    "Network Strategy {Fortna}",
    "Network Telemetry",
    "Network Time Protocol",
    "Network Topology",
    "Network Traffic Analysis",
    "Network Virtualization",
    "Network as a Service (NaaS)",
    "Networked Digital Signage",
    "Networking / Telecommunications",
    "Networking Solutions",
    "Netwrix {Lepide Software}",
    "Neuroleadership Institute {ExecOnline}",
    "New Baby Gifts {Spoonful}",
    "New Chief {Delta Financial Group}",
    "New Customer Onboarding {CS3 Marketing}",
    "New Healthcare Technology {Healthcare IT Leaders}",
    "New Hire Orientation",
    "New Relic {Gigamon}",
    "New Senior {Delta Financial Group}",
    "New York Life Insurance",
    "New York State Tax Credits {Foss and Company}",
    "Newmark Group",
    "Next Best Action",
    "Next Generation Firewall {Cato Networks}",
    "Next-Generation Firewall (NGFW)",
    "Nextgen Healthcare",
    "Nginx",
    "Niche MDR {Sophos}",
    "Night Guards",
    "Nimble Storage",
    "Nitrile Gloves {Ahpmus}",
    "Nitrile Gloves {Assure Global}",
    "Nitrile Gloves {Froggys Fog}",
    "No-Code Software",
    "NoSQL",
    "Node.js",
    "Non Agency Loans {Deephaven Mortgage}",
    "Non Destructive Testing {Metal Finishing Technologies}",
    "Non Qualified Mortgage {Deephaven Mortgage}",
    "Non Recourse Funding {Counsel Financial}",
    "Non-Chemical Water Treatment",
    "Nonprofit Corporation",
    "Norton LifeLock",
    "Notary Services",
    "Nozomi Networks",
    "Nuance DAX {DeepScribe}",
    "Nuclear Power {Kloeckner Metals}",
    "Nucleic Acid Extraction Services {AutoGen}",
    "Nuisance Pest Control {TruGreen}",
    "Nurse Call Systems",
    "Nurse Triage",
    "Nutanix",
    "Nutritional Labeling {Microbac Laboratories}",
    "Nvidia",
    "Nvidia GPU {Questivity Incorporated}",
    "Nvidia Tesla {Questivity Incorporated}",
    "O&P {HP}",
    "OCR Software",
    "OLAP",
    "ON24",
    "OSHA Compliance Training",
    "OSHA Compliant Fall Arrest Equipment {Flexible Lifeline Systems}",
    "OTT advertising {Marketing Architects}",
    "OWASP Training {HackEDU}",
    "Object-Oriented Programming (OOP)",
    "Objectives and Key Results (OKR)",
    "ObserveIT",
    "Obvius AcquiSuite",
    "Ocado Engineering {Fortna}",
    "Occupational Safety & Health Administration (OSHA)",
    "Ocean Supply Chain {Voyager Portal}",
    "Ocean {Toll Holdings}",
    "Odor Removal {ActivePure Technology}",
    "Odyssey Logistics {CLX Logistics}",
    "Off-Grid Security {Gridless Power}",
    "Offensive Security {TryHackMe}",
    "Office 365",
    "Office Chairs {Wilkhahn}",
    "Office Chairs {Wilson Office Interiors}",
    "Office Cubicles {Wilson Office Interiors}",
    "Office Design {Wilson Office Interiors}",
    "Office Desks {Wilson Office Interiors}",
    "Office Expansion",
    "Office Flooring {Wilson Office Interiors}",
    "Office Furniture",
    "Office Furniture {Wilson Office Interiors}",
    "Office Lease",
    "Office Lighting",
    "Office Move {West Lane Schlager}",
    "Office Pest Control {Rollins}",
    "Office Printers",
    "Office Relocation",
    "Office Space",
    "Office Space Design {Wilkhahn}",
    "Office Space {Cresa Global}",
    "Office Suites Software",
    "Office Vibe {Engagement Multiplier}",
    "Office of Foreign Assets Control (OFAC)",
    "Office of The Comptroller of the Currency (OCC)",
    "Offset Marketplace {Redshaw Advisors}",
    "Offshore Software Development",
    "Oil & Gas Software",
    "Oil Field Services",
    "Oil Level Sensor",
    "Oil Mist Filter {Aeroex Technologies}",
    "Oil Production Allocation Using AI {Tachyus}",
    "Oil and Gas EOR Enhancement Using AI {Tachyus}",
    "Oil and Gas Mineral Management {Argent Financial Group}",
    "Okta, Inc.",
    "Olink {Fulgent Genetics}",
    "Olympus Corporation of the Americas",
    "Omnichannel",
    "Omnichannel Journey",
    "Omnichannel Platform",
    "On Call Compensation",
    "On Time Delivery",
    "On-Premise Contact Center",
    "On-Premise Software",
    "On-Shelf Visibility",
    "On-Site Medical Care",
    "OnBoard Board Management Software {BoardBookit}",
    "Onboard Scale",
    "Onboarding",
    "Onboarding Application",
    "Oncology Trial",
    "One Two Zero {Redshaw Advisors}",
    "One-To-One Marketing",
    "OneDigital {Paylocity}",
    "OneDrive",
    "OneNeck IT Solutions",
    "Onica {Cyber Security Works}",
    "Online / Web Trading",
    "Online Age Verification",
    "Online Banking",
    "Online Community Management Software",
    "Online Lead Generation",
    "Online Merchandising",
    "Online Notary Services",
    "Online Patient Registration {The SSI Group}",
    "Online Payments / Web Payments {NCR}",
    "Online Proofing Software",
    "Online Recruiting",
    "Online Reputation Management Services {Citation Labs}",
    "Online Reputation Management Software",
    "Online Research Panel",
    "Online Sales",
    "Online Sample Provider",
    "Online Transaction Processing (OLTP)",
    "Online Video",
    "Online Video Marketing",
    "Online Video Platforms",
    "Online invigilation",
    "Online payment platforms",
    "Onsite CO2 {FuelCell Energy}",
    "Onsite Clinics {Matrix Medical Network}",
    "Onsite Fleet Fueling {Kinect Consulting}",
    "Onsite Fueling {Mavrik Sales}",
    "Onsite Maintenance {HypedUp}",
    "Ontra {RobinAI}",
    "Onward Automation Inc.",
    "Onyx Engineering Ltd.",
    "Open Compute Networking",
    "Open Source",
    "Open Source Audit",
    "Open Source Digital Signage Software",
    "Open Source Security",
    "OpenPath {Feenics}",
    "OpenShift Container Platform",
    "OpenSpace Competitors {OpenSpace Labs}",
    "OpenStack",
    "OpenText (OTEX)",
    "OpenText {Metroland Business Machine}",
    "Opengov {GTY Technologies}",
    "Openprise",
    "Operating Cost",
    "Operating Margin",
    "Operating Model Transformation - 0502 {Deloitte}",
    "Operating Model Transformation {Deloitte}",
    "Operating Systems",
    "Operational Assessment",
    "Operational BI",
    "Operational Data Store",
    "Operational Efficiency",
    "Operational Excellence",
    "Operational Intelligence (OI)",
    "Operational Resilience",
    "Operational Technology Cyber Security {Nuvolo Limited}",
    "Operational Technology Security",
    "Operations Assessment {Fortna}",
    "Operations Design {Fortna}",
    "Operations Management",
    "Operations Optimization",
    "Operator Connect",
    "Operator Console {Imagicle}",
    "Operator Qualifications {Energy WorldNet Inc}",
    "Ophthalmic Equipment",
    "Opioid Risk {MedAware}",
    "Opportunity Planning",
    "Opportunity Zone Real Estate",
    "Optical Networking",
    "Optical Scanner",
    "Optical Storage Media",
    "Optimising Delivery Efficiency {Doddle}",
    "Optimized Packaging {Sealed Air}",
    "Optimizely",
    "Optoelectronic {Nagase}",
    "Optometry Software",
    "Oracle",
    "Oracle CPQ",
    "Oracle Cloud Infrastructure",
    "Oracle Cloud Testing",
    "Oracle Cloud Training",
    "Oracle Commerce Platform",
    "Oracle Database",
    "Oracle E-Business Suite",
    "Oracle ERP Cloud",
    "Oracle Enabled Transformation - 0583 {Deloitte}",
    "Oracle Enterprise Transformation - 0786 {Deloitte}",
    "Oracle Exadata",
    "Oracle Finance Transformation - 0571 {Deloitte}",
    "Oracle Financials Cloud",
    "Oracle Forms",
    "Oracle Guided Learning",
    "Oracle HCM Cloud",
    "Oracle Hyperion",
    "Oracle Marketing Cloud",
    "Oracle Multitenant",
    "Oracle PeopleSoft",
    "Oracle Real Application Clusters",
    "Oracle Supply Chain Management (SCM) Cloud",
    "Oracle Supply Chain Transformation - 0573 {Deloitte}",
    "Oracle TimesTen In-Memory Database",
    "Oracle WebCenter",
    "Order Fulfillment",
    "Order Management",
    "Order Management Software",
    "Order To Cash",
    "Organ Chip {Emulate}",
    "Organic Growth {Challenger}",
    "Organizational Assessment",
    "Organizational Change Management",
    "Organizational Development",
    "Organizational Learning",
    "Organizational Structure",
    "Organoids {Emulate}",
    "Ornamental Weed Control {TruGreen}",
    "Out of Home",
    "Out-of-Band Authentication",
    "OutReach.io",
    "Outbound Fulfillment",
    "Outbound Marketing",
    "Outdoor Digital Advertising",
    "Outdoor Display",
    "Outdoor Furniture",
    "Outdoor Goods {Coats}",
    "Outdoor Security {Gridless Power}",
    "Outlook.com",
    "Outplacement",
    "Outrigger Pads {Mavrik Sales}",
    "Outside Storage",
    "Outsourced Accounting",
    "Outsourced CFO Services",
    "Outsourced Coding - General {AQuity}",
    "Outsourced Coding Competitors {AQuity}",
    "Outsourced Contract Packaging",
    "Outsourced Debt Collection",
    "Outsourced Internal Audit {Baker Tilly}",
    "Outsourced Marketing",
    "Outsourced Medical Billing",
    "Outsourced Payroll",
    "Outsourced Recruiting {Orion International}",
    "Outsourcing",
    "Outsourcing Software Services {Profusion Systems}",
    "Outsourcing {Mimeo}",
    "Outsourcing {Quantanite}",
    "Outstanding Check",
    "Outsystems",
    "Over-the-top media TV & Service (OTT)",
    "Overall Equipment Effectiveness",
    "Overhead Conveyor Systems {Intellitrak}",
    "Overhead Cranes",
    "Overtime",
    "Ozburn Hessey Logistics (OHL)",
    "P2P Lending (Social Lending)",
    "PAC Management {Phone2Action}",
    "PACS/Radiology/Enterprise Image {Laitek}",
    "PAPR {RPB Safety}",
    "PB {NOCO Company}",
    "PBX Manufacturers {White Label Communications}",
    "PCB Repairs {Encore Repair Services}",
    "PCI Compliance",
    "PCI Developer Training {HackEDU}",
    "PCI Industrial Computer Manufacturers Group {WinSystems}",
    "PCI Security Standards Council",
    "PCR Testing {Thermo Fisher Scientific}",
    "PDF Accessibility",
    "PEEK 3D Printer {AON3D}",
    "PGP (Pretty Good Privacy)",
    "PGi",
    "PHP",
    "PM {NOCO Company}",
    "PMP",
    "PNC Financial Services Group (PNC)",
    "POS Marketing",
    "POTS Replacement",
    "PPO",
    "PTC (PTC)",
    "PTC ThingWorx",
    "PTC Windchill",
    "Package Management",
    "Package Testing {Sealed Air}",
    "Packaging & Labeling",
    "Packaging Automation",
    "Packaging Innovations {Sealed Air}",
    "Packaging Operations {Sealed Air}",
    "Packaging and Labelling {Clinigen Group}",
    "Packing Supplies",
    "Pager Duty",
    "Paid Time Off",
    "Paint Powder Coat {Greater Machining}",
    "Pallet Procurement/Sourcing {PalletOne}",
    "Pallet Racking",
    "Pallets",
    "Palo Alto Cortex {Sophos}",
    "Palo Alto Networks",
    "PandaDoc",
    "Panel Provider",
    "Panel Survey",
    "Panic Devices",
    "Pantry Service {American Food and Vending}",
    "Paper Bags {Redibag USA}",
    "Paper Packaging {Sealed Air}",
    "Paralegal",
    "Paralegal {Lexhelper}",
    "Parallel Chemistry {HEL Group}",
    "Parcel Lockers {Doddle}",
    "Parcel Shipping",
    "Parental Leave",
    "Park Place Technologies",
    "Parking Barrier Gate",
    "Parking Lot Maintenance",
    "Parking Lot Security",
    "Parking Management Software",
    "Parking Meter",
    "Parking Permits {Weldon Williams & Lick}",
    "Parking Solutions",
    "Parking Systems",
    "Parks & Recreation Software",
    "Part-Time Employees",
    "Partner Ecosystem",
    "Partner Enablement",
    "Partner Management Software {Magentrix}",
    "Partner Marketing",
    "Partner Relationship Management {Magentrix}",
    "Partner Relationships",
    "Partner Stalker {Oberholtzer}",
    "Partner Training and Onboarding",
    "Partners {OpenSymmetry}",
    "Partners {Payveris}",
    "Partners {Skynamo}",
    "Parts Storage {Raymond Handling Solutions}",
    "Passivation {Metal Finishing Technologies}",
    "Passive Optical Network (PON)",
    "Password Management",
    "Password Protection",
    "Passwordless Authentication",
    "Passwordless Authentication {TruU}",
    "Patch Management",
    "Patent Database",
    "Pathology Billing",
    "Pathology Cancer Diagnostics {Epredia}",
    "Pathology Scanner {Paige}",
    "Pathology Software {Paige}",
    "Pathology Testing",
    "Pathology Viewer {Paige}",
    "Patient Care",
    "Patient Case Management Software",
    "Patient Centricity {Data Cubed}",
    "Patient Data",
    "Patient Education and Self-Management {PatientBetter}",
    "Patient Engagement",
    "Patient Engagement {Data Cubed}",
    "Patient Experience",
    "Patient Financial Services",
    "Patient Flow {Infinite Leap}",
    "Patient Liability Estimates {CPSI}",
    "Patient Monitoring",
    "Patient Recruitment",
    "Patient Relationship Management",
    "Patient Retention and Engagement {Data Cubed}",
    "Patient Retention {Data Cubed}",
    "Patient Sample Collection {Copan Diagnostics}",
    "Patient Tracking {Central Research}",
    "Pay Equity {Compaas}",
    "Pay On-Demand {Rain}",
    "Pay Per Click (PPC)",
    "Pay Per Click Agency {Object 9}",
    "Pay for Performance",
    "Pay freelancers",
    "PayActiv {Rain}",
    "PayFac {Infinicept}",
    "PayPal",
    "Paycheck Protection Program (PPP)",
    "Paychecks",
    "Paychex",
    "Paycom",
    "Paycor",
    "Paylocity",
    "Payment API",
    "Payment Bond Claim {CRF Solutions}",
    "Payment Gateway",
    "Payment Gateway Software",
    "Payment Hub",
    "Payment Integration",
    "Payment Integrity {Engage Health Solutions}",
    "Payment Processing",
    "Payment Security",
    "Payment Security {Eckoh}",
    "Payment Service Provider {NCR}",
    "Payment Services (PSD 2)",
    "Payment Terminal",
    "Payment plan",
    "Payments Revenue {Infinicept}",
    "Paymentus {Payveris}",
    "Payroll",
    "Payroll Cards {Rain}",
    "Payroll Funding",
    "Payroll Management",
    "Payroll Management Software",
    "Payroll Services",
    "Payroll System",
    "Peakon {Engagement Multiplier}",
    "Pedicure Supplies {TNG Worldwide}",
    "Peer-to-Peer Text Campaign",
    "Pegasystems",
    "Pelitas {The SSI Group}",
    "Pendo",
    "Penetration Testing",
    "Pens {Capwell Funding}",
    "Pension Obligation Bonds",
    "Pension Risk Transfer",
    "Pensions / Retirement Benefits",
    "Penske {JB Hunt}",
    "People Counter",
    "People Development {Leadr}",
    "People Management {Leadr}",
    "People.ai",
    "Perfect Store Execution",
    "Performance Appraisal",
    "Performance Contracting",
    "Performance Indicator",
    "Performance Monitoring",
    "Performance Testing",
    "Perfusion Bioreactors {Cell Culture Company}",
    "PerkinElmer {Agilent}",
    "Perl",
    "Permanent Life Insurance",
    "Permission Email Marketing",
    "Personal Care Hygiene Products {Houseware Distributors}",
    "Personal Cyber Security",
    "Personal Emergency Response Systems (PERS)",
    "Personal Finance Management",
    "Personal Leadership {Mentora Institute}",
    "Personal Protective Equipment (PPE)",
    "Personal Trust and Estate Planning {Argent Financial Group}",
    "Personalization",
    "Personalization Software",
    "Personally Identifiable Information (PII)",
    "Personnel Administration",
    "Pest Control General {Rollins}",
    "Pest Control Software",
    "Pet Insurance",
    "Petrochemical {Veolia}",
    "Pharma 4.0 {Werum IT Solutions}",
    "Pharmaceutical ERP {XcelPros}",
    "Pharmaceutical Grant",
    "Pharmaceutical Manufacturing Regulations {Lachman Consultants Services}",
    "Pharmaceutical Manufacturing Violations {Lachman Consultants Services}",
    "Pharmaceutical Recalls {Lachman Consultants Services}",
    "Pharmaceutical Research",
    "Pharmaceutical Software",
    "Pharmaceutical distribution",
    "Pharmaceuticals",
    "Pharmacodynamics {Sapient}",
    "Pharmacokinetics",
    "Pharmacovigilance",
    "Pharmacy Benefit Management (PBM)",
    "Pharmacy Benefit Manager",
    "Pharmacy Benefits",
    "Pharmacy Claims Data {Xevant}",
    "Pharmacy Delivery {HealthDirect Institutional Pharmacy Services}",
    "Pharmacy Management Systems",
    "Pharmacy Services {HealthDirect Institutional Pharmacy Services}",
    "Pharmacy Software",
    "Phishing",
    "Phone Call Processing",
    "Phone System",
    "Photo Editing Mobile Apps {Pixelz}",
    "Photo Editing Software {Pixelz}",
    "Photo Editing {Pixelz}",
    "Photoshelter",
    "Photovoltaics {Nagase}",
    "Physical Security",
    "Physical Security Software",
    "Physical Signage",
    "Physical Therapy Software",
    "Physician Billing {Medcare MSO}",
    "Physician Compensation",
    "Physician Rounding Software {Claimocity}",
    "Pick and Pack",
    "Pile Drivers {Mavrik Sales}",
    "Ping Identity",
    "Pipe Labeling",
    "Pipe Maintenance",
    "Pipeline Management {FreeAgent CRM}",
    "Pipeline Marketing",
    "Pipeline Safety",
    "Pivotal Greenplum",
    "Pixels",
    "Pixelz {Pixelz}",
    "Placemaking",
    "PlanGrid {Applied Technology Group}",
    "PlanOn {Nuvolo Limited}",
    "Plane Manufacturers {XOJET}",
    "Planful",
    "Planned Maintenance",
    "Planning & Design",
    "Planning & Forecasting",
    "Plant Maintenance",
    "Plasma Cutting",
    "Plastic Enclosures and Housings {Resolve Business Development}",
    "Plastic Injection Molding {Nelson Miller}",
    "Plastic Parts Manufacturing {Fast Radius}",
    "Plastics Fabricators {CR Onsrud}",
    "Platform as a Service (PaaS)",
    "Plating {Metal Finishing Technologies}",
    "Plex",
    "Plugging {TruGreen}",
    "Plumbing Software {Authority Brands}",
    "Podcast API {PodChaser}",
    "Podcast Contacts {PodChaser}",
    "Podcast Montoring {PodChaser}",
    "Podcasting",
    "Podium {Review Trackers}",
    "Point of Presence (POP)",
    "Point of Sale (POS) System",
    "Point-In-Time Recovery",
    "Point-to-Point Encryption (P2PE)",
    "Pointillist",
    "Policies & Practices",
    "Policy Administration",
    "Policy Decision Engine",
    "Policy Management Software",
    "Policy Tracking {Phone2Action}",
    "Policy and Regulatory {LFA Holdings}",
    "Policy as Code",
    "Political Campaign Software",
    "Politically Exposed Person (PEP)",
    "Polo Shirts {Capwell Funding}",
    "Polycom (PLCM)",
    "Polyfluoroalkyl Substances {Microbac Laboratories}",
    "Polymer Materials",
    "Polywood {Prestwick Group}",
    "Population Health Management",
    "Pore Pressure Analysis {Ikon Science}",
    "Portable Document Format (PDF)",
    "Portable Restrooms {United Site Services}",
    "Portal Software",
    "Portfolio {Novus Partners}",
    "Position Sensors",
    "Positive Pay",
    "Possession Arrows {The Power Ad}",
    "Post Quantum Encryption {Qrypt Inc}",
    "Post Settlement Funding {Counsel Financial}",
    "Post-Merger Integration",
    "Postgres",
    "Potassium Hydroxide (KOH) Recovery {Veolia}",
    "Powder Processing {Spraying Systems}",
    "Power & Cooling",
    "Power BI",
    "Power Distribution",
    "Power Grid {Kloeckner Metals}",
    "Power Monitoring {AstroNova}",
    "Power Platform Strategy {Directions on Microsoft}",
    "Power Platform {Planet Technologies}",
    "Power Supply",
    "Power Usage Effectiveness (PUE)",
    "Power over Ethernet (PoE)",
    "PowerShell",
    "Praetorian {Cybri}",
    "Pre Placement Exams {Caliburn International}",
    "Pre-Employment Assessment",
    "Pre-Lien Notice {CRF Solutions}",
    "Pre-Roll Video",
    "PreEmergent Weed Control {TruGreen}",
    "PreEmploy {PreEmploy}",
    "Precision Machined Parts",
    "Precision Marketing",
    "Precision Medicine {GNS Healthcare}",
    "Precision Medicine {McKesson Ontada}",
    "Predictive Analytics",
    "Predictive Apps",
    "Predictive Dialing",
    "Predictive Lead Scoring",
    "Predictive Maintenance",
    "Predictive Maintenance {SKF}",
    "Preferred Stock",
    "Pregis {Sealed Air}",
    "Preliminary Notice {CRF Solutions}",
    "Prepackaged Bankruptcy",
    "Prescriptive Analytics",
    "Presentation Sharing",
    "Presentation Software",
    "Presentations",
    "Press Kits",
    "Press Releases",
    "Pressure Sensor",
    "Preventive Maintenance Services",
    "Price Benchmark Service {NPI}",
    "Price Optimization",
    "Price Sensitivity",
    "PriceSpider {MikMak}",
    "Pricing Analytics",
    "Pricing Best Practices",
    "Pricing Software",
    "Pricing Strategy",
    "Prime Therapeutics",
    "Print Collateral",
    "Print Finishing Equipment",
    "Print Management {Taylor Corporation}",
    "Print Marketing {Taylor Corporation}",
    "Print On Demand {Taylor Corporation}",
    "Print Shop {Superior Office Products}",
    "Print on Gift Bags {AstroNova}",
    "Print on Paper Bags {AstroNova}",
    "Print on Shopping Bags {AstroNova}",
    "Printed Circuit Board Manufacturing",
    "Printed Electronics {Nagase}",
    "Printed T-Shirt {WebbMason}",
    "Printer Cost Per Page",
    "Printer Fleet Management",
    "Printer Security",
    "Printing Machinery",
    "Printing Solutions",
    "Printing Supplies",
    "Prior Authorization",
    "Privacy Curtains {Kleenedge}",
    "Private Branch Exchange",
    "Private Cellular Network",
    "Private Cloud",
    "Private Equity",
    "Private Flights {XOJET}",
    "Private Jet",
    "Private Jet Card {Jetscom}",
    "Private Jet Membership Program {Jetscom}",
    "Private LTE {Communication Technology Services}",
    "Private Label CBD {GVB Biopharma}",
    "Private Label Manufacturer/Supplier",
    "Private Label VOIP {White Label Communications}",
    "Private Label {Astro Nova}",
    "Private Networks {Betacom}",
    "Private Security",
    "Private Wireless {Betacom}",
    "Privilege Escalation {Gigamon}",
    "Privileged Access Management (PAM)",
    "Pro Secure {HTC America}",
    "ProCore",
    "ProMedica Smart Scribe {DeepScribe}",
    "Proactive Management",
    "Process Control",
    "Process Development {HEL Group}",
    "Process Engineering",
    "Process Equipment Maintenance {Black and McDonald Limited}",
    "Process Improvement",
    "Process Mining",
    "Process Observability {HCL Technologies}",
    "Process Safety",
    "Process Simulation",
    "Process Stability {HCL Technologies}",
    "Proctoring",
    "Procure to Pay",
    "Procurement & Sourcing Solutions {Insight Sourcing Group}",
    "Procurement Consulting {Insight Sourcing Group}",
    "Procurement Outsourcing {Insight Sourcing Group}",
    "Procurement Software",
    "Procurement Transformation",
    "Product Assortment {QL2 Software}",
    "Product Catalog {Delon}",
    "Product Coaching",
    "Product Configurators",
    "Product Consulting",
    "Product Content Syndication",
    "Product Cost Management",
    "Product Data Management",
    "Product Demonstrations",
    "Product Development & Design",
    "Product Experience Management (PXM)",
    "Product Information Management (PIM)",
    "Product Innovation",
    "Product Labels and Stickers {Taylor Corporation}",
    "Product Launch",
    "Product Liability",
    "Product Lifecycle Management",
    "Product Management",
    "Product Marketing",
    "Product Photography",
    "Product Return",
    "Product Reviews Software",
    "Product Sampling",
    "Product/Market Fit",
    "Product/SKU matching {QL2 Software}",
    "Production Digital Presses",
    "Production Equipment Maintenance {Black and McDonald Limited}",
    "Production Print {Superior Office Products}",
    "Production Zonal Allocation {Tachyus}",
    "Productivity",
    "Productivity Improvement",
    "Professional Development",
    "Professional Employer Organization",
    "Professional Employment Organization {Emerald Technology}",
    "Professional Liability Insurance",
    "Professional License and Credential Verification {PreEmploy}",
    "Professional Services",
    "Professional Services Automation",
    "Professional Services Automation Software",
    "Professional Translation",
    "Profilometer",
    "Profitability Management",
    "Profusion and Competitors {Profusion Systems}",
    "Program Integrity {Engage Health Solutions}",
    "Program Management",
    "Program Management Office (PMO)",
    "Program Management Office {Principle Global}",
    "Programmatic",
    "Programmatic Buying",
    "Programming Languages",
    "Progressive (PGR)",
    "Project Collaboration",
    "Project Management",
    "Project Management Software",
    "Project Planning {Smartsheet}",
    "Project Portfolio Management {Smartsheet}",
    "Project Scheduling",
    "Project to Product {Tasktop Technologies}",
    "Projection Mapping",
    "Promark",
    "Promotion Marketing",
    "Promotional Effectiveness",
    "Promotional Items",
    "Promotional Items and Swag {Capwell Funding}",
    "Promotional Printing",
    "Promotional Printing/Signage {All Points Media}",
    "Promotional Products and Swag {Taylor Corporation}",
    "Promotional Signage",
    "Proofpoint {Sophos}",
    "Property Insurance",
    "Property Management",
    "Property Management Automation {Lynx Systems}",
    "Property Management Software",
    "Property Management {United FS}",
    "Property Preservation {United FS}",
    "Property Tax",
    "Property Tax Appeal {Modern Tax Group}",
    "Property Tax Compliance {Modern Tax Group}",
    "Property Tax Consultant {Modern Tax Group}",
    "Property Tax Protest {Modern Tax Group}",
    "Property Tax Savings {Modern Tax Group}",
    "Property Tax Services",
    "Prophix {Public Financial Management}",
    "Proposal Software",
    "Propylene Glycol {Froggys Fog}",
    "Protective Barrier",
    "Protective Clothing",
    "Protective Packaging {Sealed Air}",
    "Protective Packaging {UFP Industries}",
    "Protein Manufacturing {Cell Culture Company}",
    "Protein Methods",
    "Prototype Systems Build",
    "Prototyping Software",
    "Provisioning",
    "Pseudonymization",
    "Public Cloud",
    "Public Private Partnership",
    "Public Private Partnership {Veyron}",
    "Public Relations",
    "Public Relations SEO {Citation Labs}",
    "Public Safety Officer",
    "Public Speaking",
    "Public Switched Telephone Network (PSTN)",
    "Public Transportation Software",
    "Public Works Software",
    "Public-Key Infrastructure (PKI)",
    "Public-Private Partnerships {Veolia}",
    "Publication Planning {Anju Software}",
    "Puppet Labs",
    "Purchasing Software",
    "Pure Storage",
    "Push Notification Software",
    "Push to Talk over Cellular {Hytera America}",
    "Python",
    "QBE Insurance",
    "QC {RobinAI}",
    "QNX {Raima}",
    "QRNG {Qrypt Inc}",
    "Qatalyst {Rockway Asset Management}",
    "Qlik",
    "Quadax {The SSI Group}",
    "Qualified",
    "Qualified Intermediary Services {Argent Financial Group}",
    "Qualified Security Assessor {Crowe Horwath}",
    "Quality Assurance",
    "Quality Maintenance",
    "Quality Management",
    "Quality Management System (QMS)",
    "Quality Of Service",
    "Qualtrics",
    "Qualtrics {Qualtrics}",
    "Qualtrics {Talkwalker}",
    "Quantum Computing",
    "Quantum Computing {Qrypt Inc}",
    "Quantum Health",
    "Query language",
    "Queue Management {Coconut Calendar}",
    "Queuing System",
    "QuickBooks",
    "QuickBooks to Other ERP Migration {Profusion Systems}",
    "Quickbooks Commerce {Brightpearl}",
    "Quill {Garveys Office Products}",
    "Quote Software",
    "Quote-to-Cash",
    "R&D Tax Credits",
    "RAIN agency {Marketing Architects}",
    "RB {NOCO Company}",
    "RESTful API Modeling Language (RAML)",
    "RFID",
    "RFID Access Control {Weldon Williams & Lick}",
    "RFID {Avery Dennison}",
    "RMM Competitors {Addigy}",
    "RNA Extraction {AutoGen Inc}",
    "RNA Sequencing",
    "ROI Analysis",
    "RPG SOAP {Eradani}",
    "RPO Companies {Orion International}",
    "RPO {US Tech}",
    "RSA",
    "RSA Insurance",
    "RSV Testing {Thermo Fisher Scientific}",
    "RStudio",
    "RTLS- Real Time Location Systems",
    "RTM {IFM}",
    "RabbitMQ",
    "Rack Servers",
    "Rackspace",
    "Raddon",
    "Radio",
    "Radio Advertising",
    "Radio Repeater",
    "Radiological Information System (RIS)",
    "Radiology Billing",
    "Radiology Software",
    "Radon Mitigation and Testing",
    "Railing Systems",
    "Rakuten",
    "Random Access Memory (RAM)",
    "Randomization and Trial Supply Management (RTSM)",
    "Randstad {Kelly Services}",
    "Ranpak {Sealed Air}",
    "Ransomware",
    "Rapid Application Development (RAD)",
    "Rapid Card {Rain}",
    "Rapid Prototyping",
    "Rapid7",
    "Rational Unified Process (RUP)",
    "Raymond {Alliance Material Handling}",
    "Readmission",
    "Ready to Use Sterile Vials {Millrock Technology}",
    "Real Estate Appraisal",
    "Real Estate Consulting",
    "Real Estate Development Software {Rabbet}",
    "Real Estate Investments",
    "Real Estate Software",
    "Real Property",
    "Real Time Decisioning",
    "Real Time Geospatial Data {HypedUp}",
    "Real Time Interaction Management",
    "Real Time Operating Systems (RTOS)",
    "Real Time Personalization",
    "Real World Evidence and Data {KJT Group}",
    "Real World Evidence {McKesson Ontada}",
    "Real World Evidence {Optum Inc}",
    "Real-Time Analytics",
    "Real-Time Bidding (RTB)",
    "Real-Time Collaboration",
    "Real-Time Communications (RTC)",
    "Real-Time Data Processing",
    "Real-Time Polymerase Chain Reaction (Real-Time PCR)",
    "Real-Time Reporting",
    "Reality Capture",
    "Realtime {Verbit}",
    "Rear Vision Cameras {Safety Vision}",
    "Rebranding",
    "Recapitalization",
    "Reconnaissance & Surveillance",
    "Records & Information Management (RIM)",
    "Records Retrieval {Gulfstream Legal Group}",
    "Recovery Audit {SpendMend}",
    "Recovery Auditor {Engage Health Solutions}",
    "Recovery Time Objective (RTO)",
    "Recruiting",
    "Recruiting Software / Services",
    "Recruiting as a service",
    "Recruitment Advertising",
    "Recruitment Automation",
    "Recruitment Marketing {ViziRecruiter}",
    "Recruitment Outsourcing",
    "Recruitment Process Outsourcing {Orion International}",
    "Recurring Billing",
    "Recycle Bin Signs {Method}",
    "Recycled Plastics",
    "Recycling Bins {Method}",
    "Red Hat Software",
    "Red Team",
    "Redfast {Vindicia}",
    "Redis Labs",
    "Reduction, Replacement, Refinement {Emulate}",
    "Refer A Friend {CS3 Marketing}",
    "Reference Architecture",
    "Reference Based Pricing",
    "Reference Check",
    "Referral Capture {Tango 340B}",
    "Referral Management {ReferralPoint}",
    "Refinancing",
    "Refinitiv Datastream",
    "Refrigerated Carrier",
    "Regions Financial (RF)",
    "Registered Investment Advisor {Moskal Gross Orchosky}",
    "Regulatory Compliance",
    "Regulatory Decision Making {McKesson Ontada}",
    "Regulatory Electronic Submission Publishing",
    "Regulatory Information Management",
    "Reinsurance Solutions/Systems/Software {Sapiens}",
    "Reko Tool & Mold",
    "Related Brands {PreEmploy}",
    "Relationship Intelligence Software {ZoomInfo}",
    "Relationship Marketing",
    "Relays",
    "Release Management",
    "Release Management Software",
    "Release of Information {ScanSTAT Technologies}",
    "Reliant Technology Maintenance",
    "Religion, Belief and Spirituality",
    "Relocation",
    "Relocation Management Software",
    "Relocation Services",
    "Remarketing",
    "Remediation {Clean Earth}",
    "Remittance Management {CPSI}",
    "Remote Access",
    "Remote Administration",
    "Remote Assistance",
    "Remote Automation",
    "Remote Benefits / Remote Health Insurance",
    "Remote Condition Monitoring",
    "Remote Desktop Protocol (RDP)",
    "Remote Desktop Software",
    "Remote Diagnostics",
    "Remote Monitoring",
    "Remote Network Management",
    "Remote Networking",
    "Remote Online Notarization {Signix}",
    "Remote Patient Monitoring",
    "Remote Patient Monitoring {Optimize Health}",
    "Remote Procedure Call (RPC)",
    "Remote Radio Units (RRUs)",
    "Remote Support Software",
    "Remote Video Monitoring",
    "Remote Workforce",
    "Remote Working",
    "Remote proctoring",
    "Remote work security",
    "Renewable Energy",
    "Renewable Energy Tax Credits {Foss and Company}",
    "Renewable Energy {Redshaw Advisors Limited}",
    "Renewable Fuel",
    "Renovation {United FS}",
    "Renters Insurance",
    "Replace Quest ActiveRoles {Cayosoft}",
    "Replatforming {DMI}",
    "Reporting & Disclosure",
    "Reporting Software",
    "Representational state transfer (REST)",
    "Reputation Management",
    "Reputation Management {Reputation Sensei}",
    "Reputation Marketing {Reputation Sensei}",
    "Reputation Monitoring",
    "Reputation.com",
    "Reputation.com {Review Trackers}",
    "Request For Proposal (RFP)",
    "Request Intake Process {Smartsheet}",
    "Requirements Management",
    "Requirements Management Software",
    "Research & Development",
    "Reservation System",
    "Reservoir Characterization {Ikon Science}",
    "Reshoring Manufacturing {Newmark Knight Frank}",
    "Residential Furniture {Brook Furniture Rental}",
    "Residential Property Maintenance Software {Property Meld}",
    "Residential Window Seal Manufacturing {Reed Rubber Products}",
    "Residual Benefit Lease {Veyron}",
    "Residual Ownership Lease {Veyron}",
    "Resource Management Software {Smartsheet}",
    "Respirator Masks",
    "Restaurant Analytics",
    "Restaurant Cleaning {Jani King}",
    "Restaurant Management Software",
    "Restaurant Pest Control {Rollins}",
    "Restaurant Security {Intellishop}",
    "Restful API",
    "Restoration Services",
    "Resume Parsing",
    "Retail Audit {Intellishop}",
    "Retail Digital Signage",
    "Retail Environments {Principle Global}",
    "Retail Execution {Aforza}",
    "Retail Execution {Skynamo}",
    "Retail Finance",
    "Retail Home Improvement {UFP Industries}",
    "Retail Loss Prevention {Intellishop}",
    "Retail Marketing",
    "Retail Marketing Solutions {Principle Global}",
    "Retail Merchandising Unit",
    "Retail Pro {CitiXsys Limited}",
    "Retargeting Software",
    "Retention Analytics {LatentView}",
    "Retention Software {WorkStep}",
    "Retina Network Security Scanner",
    "Retirement Plan Administration",
    "Retirement Plan Advisor",
    "Retirement Planning",
    "Retouching {Pixelz}",
    "Retro-comissioning {Veolia}",
    "Retrospectives",
    "Return Center",
    "Return on Marketing Investment",
    "Return to Work",
    "Returns Management",
    "Reusable Bags {Redibag USA}",
    "RevOps Automation {ZoomInfo}",
    "Revenue Cycle Analytics",
    "Revenue Cycle Consulting {CPSI}",
    "Revenue Cycle Management (RCM)",
    "Revenue Intelligence",
    "Revenue Management",
    "Revenue Management Software",
    "Revenue Management {Cloudbeds}",
    "Revenue Operations {OpenSymmetry}",
    "Revenue Recognition",
    "Revenue Reporting",
    "Reverse Logistics",
    "Reverse Osmosis",
    "Reverse Sales Tax Audit",
    "Review Generation {Removify}",
    "Review Removal {Removify}",
    "Review by Exception {Werum IT Solutions}",
    "Revit Fabrication",
    "Rhino Labs {Cybri}",
    "Rich Communication Services {OpenMarket}",
    "Rich Media",
    "Rich Push Notifications",
    "Richardson {Challenger}",
    "Ricoh {Revolution Office}",
    "Right of Way {Aurigo}",
    "RightAngle {Opportune}",
    "Rights Management Software",
    "Rigid Rail Overhead Fall Protection {Flexible Lifeline Systems}",
    "Rimini Street",
    "RingCentral",
    "RingLead",
    "RingLead Competitors {ZoomInfo}",
    "Rippling {Paylocity}",
    "Risk Assessment {FRISS}",
    "Risk Control Software Solutions",
    "Risk Management",
    "Risk Management Analytics Software {Ventiv Technology}",
    "Risk Management Information Software {Ventiv Technology}",
    "Risk Management Services",
    "Risk Modeling",
    "Risk {Novus Partners}",
    "RiskIQ {Cyber Security Works}",
    "Riverbed {Gigamon}",
    "Roadblocking",
    "Roadnet",
    "Robert Half {Kelly Services}",
    "Robot Software",
    "Robotic & Intelligent Automation {Deloitte}",
    "Robotic Process Automation",
    "Robotic Sensing",
    "Robots",
    "Rock Physics {Ikon Science}",
    "Rodent/Small Animal Extermination {Rollins}",
    "Role-Based Access Control (RBAC)",
    "Roll Offs {United Site Service}",
    "Roof Inspection {Roof Rx}",
    "Roof Maintenance {Roof Rx}",
    "Roof Operations {Roof Rx}",
    "Roof Repair {Roof Rx}",
    "Roof Warranty {Roof Rx}",
    "Roofing Contractor {Roof Rx}",
    "Rooftop Safety Equipment {Flexible Lifeline Systems}",
    "Room Scheduling Software",
    "Root Cause Analysis",
    "Route Accounting System",
    "Route Optimization",
    "Route Planning Software",
    "Router Competitors {CR Onsrud}",
    "Routers",
    "Routing & Switching",
    "Routing Protocols",
    "Routing {SalesRabbit}",
    "Row-Based Cooling",
    "Royalty Debt {J Wood Capital}",
    "Royalty Reporting {Octane5 International}",
    "Rubbermaid Bins {Method}",
    "Rubrik",
    "Ruby",
    "Ruby on Rails",
    "Ruckus Networks",
    "Rugged Product Design {Resolve Business Development}",
    "Rx Savings Solutions {Xevant}",
    "Ryder {JB Hunt}",
    "S/4HANA {TIS GmbH}",
    "S4 HANA {TIS GmbH}",
    "S4 Hana Migration {Enosix}",
    "S4/HANA {TIS GmbH}",
    "SAP",
    "SAP Advanced Planning Optimization APO {SCMO2}",
    "SAP Application Managed Services (AMS)",
    "SAP Ariba",
    "SAP BRIM {BluLogix}",
    "SAP BusinessObjects",
    "SAP Commerce Cloud {DMI}",
    "SAP Consulting {CCP Global}",
    "SAP Data Services",
    "SAP ERP Central Component (SAP ECC)",
    "SAP Enterprise Transformation - 0565 {Deloitte}",
    "SAP Extended Warehouse Management {SCMO2}",
    "SAP Finance Transformation - 0566 {Deloitte}",
    "SAP HANA",
    "SAP Integrated Business Planning IBP {SCMO2}",
    "SAP Products {CCP Global}",
    "SAP SF Enabled Transformation - 0584 {Deloitte}",
    "SAP TMS {CLX Logistics}",
    "SAP Vulnerabilities",
    "SAS (Statistical Analysis System)",
    "SAS Institute",
    "SASB {Newmark Knight Frank}",
    "SASE {Cato Networks}",
    "SD-VOSB {Delta Financial Group}",
    "SE {NOCO Company}",
    "SEI Maintenance",
    "SEM EDS {Thermo Fisher Scientific}",
    "SEO Link Building Services {Citation Labs}",
    "SEO/SEM Retargeting",
    "SEP IRA",
    "SMB Health Insurance {Oscar Insurance}",
    "SMETA {DQS}",
    "SMS (Short Message Service)",
    "SMS Marketing Software",
    "SOC {Cysiv}",
    "SOC1",
    "SOW {US Tech}",
    "SPM Automation (Canada) Inc.",
    "SSE {Cato Networks}",
    "SSL Certificates",
    "SSL VPN",
    "SYN Flood",
    "SaaS Operations Management",
    "Saba Software",
    "Sachem Head Capital Management {Rockway Asset Management}",
    "Safety Management Software",
    "Safety Netting and Fall Protection",
    "Safety Supplies",
    "Safety Training",
    "Safety Wear {Capwell Funding}",
    "Sage Group",
    "Sage Intacct",
    "Sakon",
    "Salaried Employee",
    "Salary Sacrifice {Canford Finance}",
    "Sale Leaseback {Veyron}",
    "Saleboat {Skynamo}",
    "Sales Acceleration",
    "Sales Activity Tracking",
    "Sales Analytics",
    "Sales And Operations Planning",
    "Sales Automation",
    "Sales Channels",
    "Sales Coaching",
    "Sales Coaching Software",
    "Sales Commission Software {SalesVista}",
    "Sales Compensation Management",
    "Sales Dashboards",
    "Sales Effectiveness",
    "Sales Enablement",
    "Sales Enablement Software",
    "Sales Engagement Competitors 1 {Zoominfo}",
    "Sales Engagement Competitors 2 {Zoominfo}",
    "Sales Engagement {ZoomInfo}",
    "Sales Force Automation (SFA)",
    "Sales Force Effectiveness",
    "Sales Forecasting",
    "Sales Funnel",
    "Sales Gamification",
    "Sales Incentives",
    "Sales Incentives and Compensation {OpenSymmetry}",
    "Sales Intelligence",
    "Sales Intelligence Competitors",
    "Sales Leads",
    "Sales Management",
    "Sales Management Training {Learning Outsource Group}",
    "Sales Mapping {SalesRabbit}",
    "Sales Outsourcing",
    "Sales Performance Management",
    "Sales Performance Management {OpenSymmetry}",
    "Sales Pipeline",
    "Sales Productivity",
    "Sales Proposals & Quotes",
    "Sales Recruiting",
    "Sales Software",
    "Sales Strategy",
    "Sales Tax",
    "Sales Training",
    "Sales Training {Learning Outsource Group}",
    "Sales Transformation",
    "SalesIntel.io",
    "SalesLoft",
    "Salesforce",
    "Salesforce CPQ",
    "Salesforce Chatter",
    "Salesforce Commerce Cloud",
    "Salesforce Consulting",
    "Salesforce Developer",
    "Salesforce Distribution Engine",
    "Salesforce Einstein",
    "Salesforce High Velocity",
    "Salesforce Marketing Cloud",
    "Salesforce Partners",
    "Salesforce Sales Cloud",
    "Salesforce {Dyspatch}",
    "Saliva Collection Devices {AutoGen Inc}",
    "Same Day Shipping",
    "Sampling",
    "Samsung Galaxy",
    "Samsung Galaxy Tab",
    "SanDisk",
    "Sandboxing",
    "Sanitization",
    "Sanitizing Wipes",
    "Santander Bank",
    "Sarbanes Oxley Compliance (SOX)",
    "Sard Verbinnen {Rockway Asset Management}",
    "Satellite Broadband",
    "Satellite Television",
    "Savant Systems",
    "Savills",
    "Scalability",
    "Scale-Out Storage",
    "Scaled agile framework",
    "Scanners",
    "Scanning Electron Microscope {Thermo Fisher Scientific}",
    "Scenario Modeling {Public Financial Management}",
    "Scenario Planning {Public Financial Management}",
    "Scheduling Systems",
    "School Supplies {Houseware Distributors}",
    "Schrems II {Anonos}",
    "Scientific Data Management System {Agaram Technologies}",
    "Scoreboard Companies {The Power Ad}",
    "Scoreboard {The Power Ad}",
    "Scoring Table Companies {The Power Ad}",
    "Scoring Table {The Power Ad}",
    "Scott Klement {Eradani}",
    "Screen & Video Capture Software",
    "Screen Printing {Capwell Funding}",
    "Scribe Services {DeepScribe}",
    "ScribeAmerica {DeepScribe}",
    "Scribing {AQuity}",
    "Script Development",
    "Scripting",
    "Scrum Training",
    "Seagate Technology",
    "Seal Software",
    "Sealcoat {Stripe It Up}",
    "Sealed Lead Acid Batteries {Rendell Sales Co}",
    "Seamless Integration",
    "Seamless.ai",
    "Search & Retrieval",
    "Search Engine Marketing (SEM)",
    "Search Engine Optimization (SEO)",
    "Search Retargeting",
    "Search Servers",
    "Seaside Casual Furniture {Prestwick Group}",
    "SecOps",
    "Second Rate Systems {Oberholtzer}",
    "Second-Party Data",
    "Secondary Bank Loan {Deephaven Mortgage}",
    "Secondary Containment",
    "Secret Shopping {Intellishop}",
    "Secure Access Service Edge (SASE)",
    "Secure Coding Training {HackEDU}",
    "Secure Instant Messaging",
    "Secure Payments {NCR}",
    "Secure Remote Access",
    "Secure Sockets Layer",
    "Secure Supervisory Control & Data Acquisition",
    "Secure Voice",
    "Secure Web Gateway (SWG)",
    "SecureWorks",
    "Secureworks {Sophos}",
    "Security Analytics",
    "Security Architecture",
    "Security Assertion Markup Language (SAML)",
    "Security Automation",
    "Security Benchmarking",
    "Security Breaches",
    "Security Cameras {ZeroEyes}",
    "Security Compliance",
    "Security Configuration",
    "Security Consulting",
    "Security Event",
    "Security Fencing {Cochrane USA}",
    "Security Forensics",
    "Security Hygiene",
    "Security Information & Event Management (SIEM)",
    "Security Intelligence",
    "Security Management",
    "Security Monitoring",
    "Security Officer",
    "Security Operations",
    "Security Operations Center (SOC)",
    "Security Orchestration",
    "Security Orchestration Automation and Response (SOAR)",
    "Security Patches",
    "Security Policies",
    "Security Smoke {Froggys Fog}",
    "Security Solutions",
    "Security Storage",
    "Security Threats",
    "Security Tools",
    "Security Trailer {Gridless Power}",
    "Security Training",
    "Security Vulnerabilities",
    "Security as a Service",
    "Securonix",
    "Seed-to-sale Software",
    "Seeding {TruGreen}",
    "Seismic Software",
    "Self Funded Health Plans",
    "Self Guided Tours {Smart Rent}",
    "Self Service Portal",
    "Self-Service Analytics",
    "Self-Service Technology",
    "Sell My Business {Transworld Business Brokers}",
    "Sell Scrap Metal {Schrott24}",
    "Semi-Outdoor Display",
    "Semiconductors",
    "Seminars & Educational Programs",
    "Sender Policy Framework (SPF)",
    "Sensors",
    "Sentiment Analysis",
    "SentinelOne {Gigamon}",
    "SentinelOne {Sophos}",
    "Serenova",
    "Serialization",
    "Server Automation",
    "Server Environments",
    "Server Failure",
    "Server Farms",
    "Server Hardware",
    "Server Hosting",
    "Server Infrastructure",
    "Server Management",
    "Server Migration",
    "Server Performance",
    "Server Power",
    "Server Provisioning",
    "Server Resources",
    "Server Security",
    "Server Software",
    "Server Virtualization",
    "Server Virtualization Software",
    "Servers",
    "Service Catalog",
    "Service Delivery",
    "Service Desk Software",
    "Service Dispatch",
    "Service Excellence - 0537 {Deloitte}",
    "Service Excellence {Deloitte}",
    "Service Oriented Architecture",
    "Service Parts Management {Baxter}",
    "Service Titan {Authority Brands}",
    "ServiceChannel {Nuvolo Limited}",
    "ServiceNow",
    "ServiceNow HRT - 0722 {Deloitte}",
    "ServiceNow {Deloitte}",
    "Serviced Offices {Officio}",
    "Services Procurement",
    "Servigistics {Baxter}",
    "Session Replay",
    "SessionCam",
    "Severance Pay",
    "Sex Discrimination",
    "Sexual Orientation",
    "Shadow IT",
    "Sharable Content Object Reference Model",
    "SharePoint Migration",
    "Shared Charter Flight {XOJET}",
    "Sheet Metal Fabrication {Greater Machining}",
    "Sheet Metal Manufacturing",
    "Shelley Automation",
    "Shift Technology {FRISS}",
    "Shipping Damage {Sealed Air}",
    "Shipping Software",
    "Shipwire",
    "Shop Floor Control",
    "ShopKeep",
    "Shopify",
    "Shoppable Commerce {Firework}",
    "Shopper Marketing",
    "Shoppertainment {Firework}",
    "Shopping Cart Software",
    "Shopping Carts",
    "Short Term Apartments",
    "Short Term Warehousing",
    "Showpad",
    "Showroom Products {BrandLive}",
    "Shrink Wrap",
    "Sick Building {ActivePure Technology}",
    "Sick Leave",
    "Siebel Systems",
    "Siemens Teamcenter",
    "Sign Language",
    "Sign Maintenance and Installation {Action Services Group}",
    "SignNow",
    "SignalFX",
    "SignalPath {Advarra}",
    "Silhouette Photography",
    "Silk Screening {Capwell Funding}",
    "Silver Peak Systems",
    "Simple Mail Transfer Protocol (SMTP)",
    "Simple Object Access Protocol (SOAP)",
    "SimpliVity",
    "Simulate Disease Progression/Drug Response {GNS Healthcare}",
    "Simulation Software",
    "Simulmedia {Marketing Architects}",
    "Simultaneous Interpreting {Capital Linguistics}",
    "SingFit",
    "Single Board Computers {WinSystems}",
    "Single Customer View",
    "Single Family Rentals {Newmark Knight Frank}",
    "Single Family Residential Portfolios {Verus Commercial Real Estate Finance}",
    "Single Sign On (SSO) Software",
    "Single-Cell Analysis",
    "Sip Trunking",
    "SiriusDecisions",
    "SiriusDecisions Summit",
    "Site & Cart Abandonment",
    "Site Conditions Assessment {Sevan Multi Site Solutions}",
    "Site Management",
    "Site Personalization",
    "Site Reliability Engineering",
    "Site Search",
    "Site-Side Optimization",
    "Sitecore",
    "Situation Awareness {DMI}",
    "Situational Awareness Software {Central Research}",
    "Skylight Hatch Safety {Flexible Lifeline Systems}",
    "Skype for Business",
    "Slack",
    "Slide Printer {Epredia}",
    "SlideMate {Epredia}",
    "Slow-Moving Assets",
    "Small Business Accounting",
    "Small Business Administration (SBA) lending",
    "Small Business Insurance",
    "Small Business Insurance Benefits {Sana Benefits}",
    "Small Business Loans",
    "Small Business Printing",
    "Small Group Health Insurance Plans {Sana Benefits}",
    "Small Medium Competitors {Data Cubed}",
    "Small and Medium Enterprise (SMB)",
    "Smarketing",
    "Smart Buildings",
    "Smart Cache",
    "Smart Cities",
    "Smart Connected Products",
    "Smart Contracts",
    "Smart Devices",
    "Smart Factory",
    "Smart Grid",
    "Smart Home",
    "Smart Manufacturing",
    "Smart Manufacturing - 0558 {Deloitte}",
    "Smart Metering",
    "Smart Operations",
    "Smart Parking",
    "Smart Parking {Smart Rent}",
    "Smart Recycling Bin",
    "Smart Retail",
    "Smart Safe",
    "Smart Safe {Cash Processing Solutions}",
    "Smart Sensor",
    "Smart Storage",
    "Smarter Computing",
    "Smartsheet",
    "Smoke Damage",
    "Smoke Fluid {Froggys Fog}",
    "Smoke Generator {Froggys Fog}",
    "Smoke Removal {ActivePure Technology}",
    "Smoking Cessation",
    "Snow/Ice Management",
    "Snowflake Inc.",
    "Social Analytics",
    "Social Business",
    "Social Commerce",
    "Social Commerce Platforms {MikMak}",
    "Social Commerce {Firework}",
    "Social Content & Apps",
    "Social Content Management",
    "Social Listening",
    "Social Listening Vendors {LatentView}",
    "Social Login",
    "Social Media Advertising Software",
    "Social Media Analytics & Monitoring",
    "Social Media Engagement",
    "Social Media Intelligence",
    "Social Media Management Software",
    "Social Media Marketing",
    "Social Media Monitoring",
    "Social Media Optimization (SMO)",
    "Social Networks",
    "Social Relationship Management",
    "Social Retargeting",
    "Social Security Verification {Property Interlink}",
    "Social Sharing",
    "Social, Mobile, Analytics, Cloud (SMAC)",
    "Soft Phone",
    "Software AG (SOW)",
    "Software Asset Management",
    "Software Compliance",
    "Software Components",
    "Software Composition Analysis {SOOS}",
    "Software Defined Perimeter (SDP)",
    "Software Delivery",
    "Software Deployment",
    "Software Design",
    "Software Developers",
    "Software Development",
    "Software Development Kit",
    "Software Development Lifecycle",
    "Software Development Outsourcing",
    "Software Encryption",
    "Software Implementation {Lemon Learning}",
    "Software Licensing",
    "Software Migration",
    "Software Monetization",
    "Software Piracy",
    "Software Quality",
    "Software Requirements",
    "Software Supply Chain Security",
    "Software Testing",
    "Software Testing {Codecov}",
    "Software Translation",
    "Software Vendor",
    "Software Version Management",
    "Software as a Service (SaaS)",
    "Software as a Service (SaaS) Analytics",
    "Software as a Service (SaaS) Based Accounting",
    "Software-Defined Data Center",
    "Software-Defined Infrastructure",
    "Software-Defined Networking (SDN)",
    "Software-Defined Wide Area Network (SD-WAN)",
    "Soil Disposal {Clean Earth}",
    "Solar Energy Systems {Solar Gain}",
    "Solar Lighting",
    "Solar Metering",
    "Solar Panels",
    "Solar Power {Kloeckner Metals}",
    "Solar Security {Gridless Power}",
    "Solar System Maintenance/Repair {Solar Gain}",
    "Solar Tax Credits {Foss and Company}",
    "Solar-Plus-Storage",
    "Solid Oxide {FuelCell Energy}",
    "Solid State Drive (SSD)",
    "Solid Surface {CR Onsrud}",
    "SolidFire",
    "Solidworks",
    "Solidworks Product Design {Resolve Business Development}",
    "Solidworks {ValleyCAD}",
    "Solvent Recycling",
    "Sonicwall {Sophos}",
    "Sophos InterceptX {Sophos}",
    "Sound Masking",
    "Source Code Analysis",
    "Source Code Management",
    "Source Separation {Method}",
    "Source-to-Pay (S2P)",
    "Sourcing and Procurement - 0557 {Deloitte}",
    "Sourcing/Procurement {PalletOne}",
    "South Carolina State Tax Credits {Foss and Company}",
    "Spa & Salon Management",
    "Spa Laser Therapy Technology",
    "Space Available {Cincinnati Commercial Contracting}",
    "Space Management Software {Nuvolo Limited}",
    "Space Reservations {Nuvolo Limited}",
    "Spam Filtering",
    "Spam Filtering {Mutare Software}",
    "Spanish Translation {Capital Linguistics}",
    "Spare Parts Planning {Baxter}",
    "Spark Streaming",
    "Spatial Computing",
    "Spear Phishing",
    "Special Education",
    "Special Needs Trust {Argent Financial Group}",
    "Special Purpose Acquisition Company",
    "Special Purpose Acquisition Company {Embark}",
    "Speciality Pharmacy {Tango 340B}",
    "Specimen Tracking Technologies {Taylor Data Systems}",
    "Spectrum Cable",
    "Speech Recognition",
    "SpeedCommerce",
    "Spend Analytics {Insight Sourcing Group}",
    "Spend Management",
    "Spirit Wear {Capwell Funding}",
    "Split Billing Healthcare (Split Billing)",
    "Splunk",
    "Splunk {NPI}",
    "Sponsorship",
    "Sports Management",
    "Sports Management Software",
    "Sports Marketing",
    "Sports Sponsorships",
    "Sports Ticketing {Kraft Sports}",
    "Spot Freight",
    "Spray Drying {Spraying Systems}",
    "Sprim Competitors {Sprim}",
    "Sprinklr",
    "Sprinklr {Talkwalker}",
    "Sprint Corporation",
    "Spyware",
    "Square",
    "Squarespace",
    "Staff Management",
    "Staffing Management {CPSI}",
    "Staining Microscope Slides {Epredia}",
    "Stakeholder Management {Phone2Action}",
    "Stallard Competitors {Stallard Technologies}",
    "Stand Up Pouches {Signature Flexible Packaging}",
    "Standardized testing",
    "Standing Desks {Cemtrex}",
    "Staples {Garveys Office Products}",
    "Starboard Value {Rockway Asset Management}",
    "State Specific {Agentsync}",
    "State Tax Credits {Foss and Company}",
    "Statement Printing and Mailing {Taylor Corporation}",
    "Static Application Security Testing (SAST)",
    "Static Code Analysis {Codecov}",
    "Stealthbits {Lepide Software}",
    "Steelcase {Cemtrex}",
    "Steelhouse {Marketing Architects}",
    "Sterile/Aseptic Manufacturing",
    "Still Photography",
    "Stock Images Providers",
    "Stone Restoration",
    "Stop Notice {CRF Solutions}",
    "Storage Area Networks (SAN)",
    "Storage Capacity Management",
    "Storage Consolidation",
    "Storage Devices",
    "Storage Hardware",
    "Storage Management",
    "Storage Management Software",
    "Storage Performance",
    "Storage Resources",
    "Storage Security",
    "Storage Software",
    "Storage Solutions",
    "Storage Tank Fall Protection {Flexible Lifeline Systems}",
    "Storage Virtualization",
    "Storage as a Service",
    "Store Locator Software",
    "Storm Spout",
    "Stratasys 3D Printer {AON3D}",
    "Strategic Account Planning",
    "Strategic Meetings Management",
    "Strategic Planning",
    "Strategic Planning Software",
    "Strategic Portfolio Management {Catalant Technologies}",
    "Strategic Sourcing",
    "Strategy Execution {Catalant Technologies}",
    "Streaming Media",
    "Streaming Software",
    "Strike Management {Corporate Investigation Services}",
    "Stripe",
    "Stripe Connect {Infinicept}",
    "Striping {Stripe It Up}",
    "Stripping {Metal Finishing Technologies}",
    "Strong Encryption",
    "Structured Cabling",
    "Stucco {Knauss Property Service}",
    "Student Information Systems (SIS)",
    "Studios Brand and Advertising - 0530 {Deloitte}",
    "Styled Photography",
    "Stylesage {Edited}",
    "Sub-Metering",
    "Subrogation {National Subrogation Services}",
    "Subscriber Identity Management {Vindicia}",
    "Subscriber Mail",
    "Subscription Management Software",
    "Subscription Management {Vindicia}",
    "Subscription Payments {NCR}",
    "Substance Abuse Evaluation {Niznik Behavioral Health}",
    "Substation Automation",
    "Subsurface Data Management {Ikon Science}",
    "Subsurface Knowledge Management {Ikon Science}",
    "Subtitling",
    "SuccessFactors",
    "Succession Planning",
    "SugarCRM",
    "Sulfur Products {Veolia}",
    "SunTrust Banks (STI)",
    "Supercritical Fluid Extraction {Phasex}",
    "Supplement Manufacturer/Supplier",
    "Supplied Air Respirator {RPB Safety}",
    "Supplier Management",
    "Supplier Mapping",
    "Supplier Network",
    "Supplier Performance Management (SPM)",
    "Supplier Relationship Management (SRM)",
    "Supplier Risk Management",
    "Supply Chain",
    "Supply Chain Analytics {Transportation Impact}",
    "Supply Chain Assessment and Roadmap {SCMO2}",
    "Supply Chain Consulting {CLX Logistics}",
    "Supply Chain Consulting {Fortna}",
    "Supply Chain Control Tower",
    "Supply Chain Demand Planning",
    "Supply Chain Engineering {Fortna}",
    "Supply Chain Execution",
    "Supply Chain Finance {TriumphPay}",
    "Supply Chain Financing",
    "Supply Chain Integration",
    "Supply Chain Logistics",
    "Supply Chain Management",
    "Supply Chain Network Optimization {Bricz}",
    "Supply Chain Ops - 0776 {Deloitte}",
    "Supply Chain Optimization",
    "Supply Chain Procurement",
    "Supply Chain Risk Management",
    "Supply Chain Software {Transportation Impact}",
    "Supply Chain Transformation",
    "Supply Chain Transportation",
    "Supply Chain Visibility",
    "Supply Side Platforms",
    "Support Operations {MessageBird}",
    "Support Search {ElasticSearch}",
    "Surface Analysis {Thermo Fisher Scientific}",
    "Surface Finish",
    "Surface Hub",
    "Surface Roughness",
    "Surgical Concierge {EmployeeDirect Healthcare}",
    "Surgical Decision Support {EmployeeDirect Healthcare}",
    "Surgical Efficiency",
    "Surgical Gloves {Ahpmus}",
    "Surplus Lines Licensing {Agentsync}",
    "Survey & Form Building Software",
    "Survey Tools {Qualtrics}",
    "Surveymonkey",
    "Sustainability",
    "Sustainability Communications {3BL Media}",
    "Sustainability Consulting {Insight Sourcing Group}",
    "Sustainability Reporting",
    "Sustainability {Kloeckner Metals}",
    "Sustainable Banking",
    "Sustainable Business Growth",
    "Sustainable IT",
    "Sustainable Manufacturing {Coats}",
    "Sustainable Packaging {Be Green Packaging}",
    "Sustainable Packaging {Printpack}",
    "Sustainable Packaging {Signature Flexible Packaging}",
    "Swift (Apple)",
    "Swift CSP {Axletree}",
    "Swift GPI",
    "Swift Service Bureau",
    "Symantec/Broadcom {Sophos}",
    "Sympathy Gifts {Spoonful}",
    "Synack {Cybri}",
    "Syncari",
    "Syncron {Baxter}",
    "Syndicated Loans",
    "Synnex",
    "Synthesio {Talkwalker}",
    "Synthetic Control Arms {GNS Healthcare}",
    "Synthetic Lease {Veyron}",
    "Synthetic Monitoring {ElasticSearch}",
    "System Architecture",
    "System Center Configuration Manager",
    "System Management Software",
    "System On a Chip (SoC)",
    "System-on-Module (SoM)",
    "Systems Build Manufacturing Services",
    "Systems Build Outsourcing",
    "Systems Development Life Cycle (SDLC)",
    "Systems Electronic Contract Manufacturing",
    "Systems Integration",
    "Systems Integrators",
    "Systems Management",
    "T-Mobile",
    "TA Systems",
    "TASC {Benefit Resource}",
    "TB {NOCO Company}",
    "TCP/IP Protocol",
    "TCPA Compliance",
    "TD Bank (TD)",
    "TMS Integration {Transportation Impact}",
    "TOPO",
    "TPG Capital {Rockway Asset Management}",
    "TQL (Total Quality Logistics)",
    "TRACED Act",
    "TTEC (TTEC)",
    "TV Measurement {The Nielsen Company}",
    "Tableau",
    "Tableau Testing",
    "Tablets",
    "Tactical Planning",
    "Tag Management",
    "Talend",
    "Talent Acquisition Solutions {Orion International}",
    "Talent Acquisition {Cornerstone RPO}",
    "Talent Development",
    "Talent Management",
    "Talent Management Software",
    "Talent Mobility",
    "Taleo",
    "TalkDesk",
    "Talkwalker {Talkwalker}",
    "Tangible Personal Property {Modern Tax Group}",
    "Tango Analytics {Nuvolo Limited}",
    "Tangoe",
    "Tank Level Sensor",
    "Tank Monitoring",
    "Tape Drives & Libraries",
    "Target Redemption Forward (TARF)",
    "Target Retirement",
    "Task Management {Smartsheet}",
    "TaskUs",
    "Tatari {Marketing Architects}",
    "Tax Advisors Group {Modern Tax Group}",
    "Tax Compliance",
    "Tax Credit",
    "Tax Efficient Investing",
    "Tax Equity {Foss and Company}",
    "Tax Practice Management Software",
    "Tax Provisions",
    "Tax Transcript {Property Interlink}",
    "Tax Withholdings",
    "Taylor Corporation {Taylor Corporation}",
    "Tealeaf",
    "Team Building",
    "Team Collaboration",
    "Team Drives",
    "Team Events",
    "Team Uniforms",
    "Team Workspace",
    "Teams Meetings {GCI}",
    "Tech Data",
    "Tech Recruiting",
    "TechTarget",
    "Technical Support",
    "Technical Textile {Coats}",
    "Technology Design & Architecture",
    "Technology Expense Management {Profit Enhancement Services}",
    "Technology Investments",
    "Technology Research",
    "Technology Security",
    "Technology Stack",
    "Technology {Newmark Knight Frank}",
    "Telecare",
    "Telecom",
    "Telecom Expense Management {Profit Enhancement Services}",
    "Telecom Regulatory {White Label Communications}",
    "Telecommunications Equipment",
    "Telecommunications Expense Management",
    "Telecommunications and Fibre Optic {Coats}",
    "Teleconferencing",
    "Telehealth",
    "Telemarketing",
    "Telematics",
    "Telemedicine",
    "Telemedicine Software",
    "Telemetry Recorder {Astro Nova}",
    "Teleperformance",
    "Telephone & Data Systems",
    "Telephone interpreting",
    "Telepresence Robots {OhmniLabs Inc}",
    "Telepsychiatry",
    "Telepsychiatry/Psychiatry {Niznik Behavioral Health}",
    "Telestroke",
    "Televerde",
    "Temperature Control Valves {ThermOmegaTech}",
    "Temperature Controlled Packaging {Sealed Air}",
    "Temperature Controlled Shipping",
    "Temperature Monitoring",
    "Temperature Screening System",
    "Temperature Sensing Kiosk",
    "Temporary Fencing {United Site Service}",
    "Temporary Power {United Site Service}",
    "Temporary Trailers {United Site Service}",
    "Tenable",
    "Tenant Background Screening",
    "Tenant Experience Software",
    "Tenant Representation {Cresa Global}",
    "Tenant Representation {Newmark Knight Frank}",
    "Tenant Representation {West Lane Schlager}",
    "Teradata (TDC)",
    "Terex {Terex}",
    "Term Life Insurance",
    "Term Loans",
    "Terminal / Network Computers",
    "Terminal Emulators",
    "Terminal Operating System",
    "Termination",
    "Terminus",
    "Territory Planning {OpenSymmetry}",
    "Test Case Design {Trissential}",
    "Test Driven Development {Codecov}",
    "Text Analytics",
    "Text-to-Apply",
    "Textron Aviation {Textron Aviation}",
    "Thales",
    "Thank You Gifts {Spoonful}",
    "The American Rescue Plan Act",
    "The Hartford",
    "Thermal Actuators {ThermOmegaTech}",
    "Thermal Balancing {ThermOmegaTech}",
    "Thermal Bypass {ThermOmegaTech}",
    "Thermal Management {ThermOmegaTech}",
    "Thermal Oxidizers {Cashco}",
    "Thermal Temperature Cameras",
    "Thermal Temperature Scanner",
    "Thermo Fisher Scientific",
    "Third Party Administrators",
    "Third Party Risk Compliance {Lextegrity}",
    "Third-Party Data",
    "Third-Party Logistics Provider (3PL)",
    "Third-Party Maintenance",
    "Third-Party Support",
    "Thoma Bravo {Rockway Asset Management}",
    "Thomson Reuters (TRI)",
    "Thought Leadership",
    "Threat Alerting {Swan Island}",
    "Threat Detection",
    "Threat Intelligence",
    "Threat Intelligence {Swan Island}",
    "Threat Monitoring {Swan Island}",
    "Threat Prevention",
    "Through-Channel Marketing Automation",
    "Thunderhead",
    "Tick Control {TruGreen}",
    "Ticket Kiosk {Weldon Williams & Lick}",
    "Ticketing System",
    "Tidal {SMA Solutions}",
    "Tieback Anchor {Flexible Lifeline Systems}",
    "Tiered Storage",
    "TigerConnect",
    "TikTok Mobile Ads",
    "Tile and Grout Cleaning",
    "Time Management",
    "Time Management Software",
    "Time To Market (TTM)",
    "Time Tracking",
    "Time Tracking Software",
    "Time-Series Database {Raima}",
    "Timekeeping",
    "TinCan xAPI",
    "Tissue Disruption {Omni International}",
    "Tissue Grinding {Omni International}",
    "Tissue Microscope Slides {Epredia}",
    "Title Closing",
    "Title IV",
    "Toll Management",
    "Toner Cartridge",
    "Top Down Modeling {Tachyus}",
    "Top Registered Investment Advisor {Moskal Gross Orchosky}",
    "Topic 5 {Delon}",
    "Topic 7 {Delon}",
    "Tor",
    "Total Cost of Ownership",
    "Total Rewards",
    "Total Station",
    "Totango",
    "Touch Panel PC {WinSystems}",
    "Touch Screen",
    "Touch Screen Digital Signage",
    "Touchless Technology",
    "Tour Operator Software",
    "Tourism marketing",
    "Tower Servers",
    "Towing Software",
    "Toxin Free Cleaning {Nanotouch}",
    "Toyota {Alliance Material Handling}",
    "Toys and Gifts {Houseware Distributors}",
    "Trade Compliance",
    "Trade Credit Insurance",
    "Trade Finance",
    "Trade Promotion Management Software",
    "Trade Services",
    "Trade Settlement",
    "Trade Show Exhibit",
    "Trade Shows / Events",
    "Trade and Commerce {LFA Holdings}",
    "Trademark Infringement",
    "Trademark Registration",
    "Traditional Marketing",
    "Traffic Control System",
    "Traffic Management",
    "Traffic/Moving Violation Processing",
    "Trailer Wraps {Speedpro Imaging}",
    "Training & Development",
    "Training HR Sales Sea {Mimeo}",
    "Training Management Software",
    "Training Materials & Methods",
    "Training Outsourcing",
    "Training Video",
    "Transaction Banking",
    "Transaction Monitoring",
    "Transactional Email Software",
    "Transcreation",
    "Transcription",
    "Transcription {Verbit}",
    "TransferWise",
    "Transfix",
    "Transformers",
    "Transformers {Kloeckner Metals}",
    "Translation Companies {Capital Linguistics}",
    "Translation Management Software",
    "Translation Memory",
    "Translation Services",
    "Translational Science {Sapient}",
    "Transplace {CLX Logistics}",
    "Transport & Freight Trucks",
    "Transport Layer Security (TLS)",
    "Transportation & Fleet Management",
    "Transportation Capacity Planning {Bricz}",
    "Transportation Financing {Honour Capital}",
    "Transportation Management Solution (TMS)",
    "Transportation Spend Management {A3 Freight Payment}",
    "Transportation Visibility {Bricz}",
    "Transportation Worker Identification Credential (TWIC)",
    "Transunion SmartMove",
    "Travel Agency",
    "Travel Agency Software",
    "Travel Insurance",
    "Travel Management",
    "Travel Management Software",
    "Travel Risk Management (TRM)",
    "Travel Rule {CipherTrace}",
    "Travel Savings",
    "Travel Technology",
    "Travel Time",
    "Traveling Nursing {All Medical Personnel}",
    "Treasury Aggregator {TIS GmbH}",
    "Treasury Bills",
    "Treasury Management",
    "Tree Inventory {SavATree}",
    "Tree Mapping {SavATree}",
    "Tree Removal {SavATree}",
    "Tree and Shrub Fertilization {TruGreen}",
    "Tree and Shrub Insect Control {TruGreen}",
    "Trend Micro {Sophos}",
    "TriNet",
    "Triblio",
    "Trillium Machine and Tool, Inc.",
    "TripActions",
    "TriumphPay {TriumphPay}",
    "Trophies",
    "TruBridge {CPSI}",
    "Truck Driver Recruiting & Retention",
    "Truck Tarps",
    "Truck Transport",
    "Trucking Software",
    "True Influence",
    "Truist",
    "Trusted Platform Module (TPM)",
    "Tube Bender",
    "Tuition Assistance",
    "Tuition Assistance Tax Credits",
    "Tumor Profiling {Fulgent Genetics}",
    "Tunneling",
    "Turbine Parts Repair {EBCO Industry}",
    "Tutoring Software",
    "Twilio",
    "Two-Factor Authentication",
    "Two-way radio",
    "Type 2 Diabetes {ADURO}",
    "UBS AG",
    "UBS Arena {Elevate Sports Venture}",
    "UL-Listed Nurse Call",
    "UPS Systems",
    "URL Shorteners",
    "US Bank",
    "US Competitor List {Brightpearl}",
    "US Tool Group",
    "USA PATRIOT Act",
    "USI {Paylocity}",
    "UV Light Sanitizer",
    "UV-C Lighting",
    "UVC Disinfection Robot {Action Services Group}",
    "Uber",
    "Uber Freight {JB Hunt}",
    "Uberflip",
    "UiPath",
    "Ultimate Software",
    "Unclaimed Property",
    "Unconstrained Bond Fund {MacKay Shields}",
    "Underwriting",
    "Underwriting Solutions/Systems/Software {Sapiens}",
    "Unemployment Claims Fraud",
    "Unemployment Claims Management",
    "Unfunded Pension Liabilities",
    "Unified Communications",
    "Unified Communications as a Service (UCaaS)",
    "Unified Modeling Language (UML)",
    "Unified Threat Management",
    "Unified endpoint management (UEM)",
    "Uniforms {Capwell Funding}",
    "Union Bank",
    "Unions",
    "Unique Custom Promotional Item {WebbMason}",
    "Unique Selling Proposition (USP)",
    "Unit Testing {Codecov}",
    "United Parcel Service (UPS)",
    "UnitedHealth Group (UNH)",
    "Universal Flash Storage {Kioxia}",
    "Universal Robots",
    "Unix",
    "Unstructured Data",
    "Up Front Processor Microbiology {Copan Diagnostics}",
    "Upholstery Cleaning",
    "Upholstery and Furniture {Coats}",
    "Uplift Modeling",
    "Uplift {Cemtrex}",
    "Upserve",
    "Upwork",
    "Usability",
    "Usage Policies",
    "Used Dell Hardware {Stallard Technologies}",
    "Used Hardware",
    "User Access Review {Charter Global}",
    "User Activity Monitoring (UAM)",
    "User Behavior Analytics (UBA)",
    "User Experience (UX)",
    "User Experience (UX) Research Repository",
    "User Interface {DMI}",
    "User Management",
    "Usermind",
    "Utility Computing",
    "Utilization Management",
    "Utilization Review {Engage Health Solutions}",
    "VEBA HRA {Benefit Resource}",
    "VEBA {Benefit Resource}",
    "VIP Route Accounting",
    "VMware",
    "VMware NSX",
    "VOE and VOI Automation {Vault Verify}",
    "VOE and VOI {Vault Verify}",
    "VSPEX",
    "VTEX {DMI}",
    "VXLAN",
    "Vacation Rental Software",
    "Vaccine Management Software {Qualtrics}",
    "Vaccine Registration Software {Curogram}",
    "Vacuum Systems",
    "Validity",
    "Valuation",
    "Value Added Distribution",
    "Value Added Reseller",
    "Value Added Warehousing",
    "Value Based Contracting {Optum Inc}",
    "Value Based Selling {ValueSelling Associates}",
    "Value Proposition",
    "Value Stream Management {Tasktop Technologies}",
    "Value-Added Tax",
    "Valueact {Rockway Asset Management}",
    "Vanta {Drata}",
    "Vari {Cemtrex}",
    "Variable Data Printing",
    "Varonis {Lepide Software}",
    "Vault Management {Cash Processing Solutions}",
    "Vector Graphics Software",
    "Vectra {Gigamon}",
    "Veeam Software",
    "Veeva {Advarra}",
    "Vegetation Control {TruGreen}",
    "Vehicle Reimbursement {CarDATA}",
    "Vehicle Tracking",
    "Vehicle Wraps",
    "Velo3D {AddUp}",
    "Velos {Advarra}",
    "Vena Solutions",
    "Vendavo",
    "Vending Machines",
    "Vendor Consolidation",
    "Vendor Managed Inventory (VMI)",
    "Vendor Management System",
    "Vendor Portal {Esker Inc}",
    "Vendor Risk Management (VRM)",
    "Venture Capital (VC)",
    "Venue Cleaning {Jani King}",
    "Venue Management Software",
    "VeriStor",
    "Veritas Technologies",
    "Verizon Communications",
    "Verkada {ZeroEyes}",
    "Verse.io and Drips.com {Greenwatt}",
    "Verse.io {Greenwatt}",
    "Version Control",
    "VersionOne",
    "Vertical Industry Software",
    "Vertical Lift Module {Raymond Handling Solutions}",
    "Vertical Storage {Raymond Handling Solutions}",
    "Veteran Hiring Strategy {Orion International}",
    "Veterinary Software",
    "ViPR",
    "Viacom (VIA)",
    "Vibration Monitoring {BK Vibro}",
    "Video API",
    "Video Advertising Networks",
    "Video Analytics",
    "Video Banking / Video Teller",
    "Video Board {The Power Ad}",
    "Video Commerce {Firework}",
    "Video Conferencing",
    "Video Digital Signage",
    "Video Editing Software",
    "Video Files",
    "Video Generated Content {Veedback}",
    "Video Hosting",
    "Video Interviewing Software",
    "Video Localization",
    "Video Monetization",
    "Video Monitoring Systems {ZeroEyes}",
    "Video Production Services",
    "Video Remote Interpreting (VRI)",
    "Video Surveillance",
    "Video Translation",
    "Video Wall",
    "Video advertising {Marketing Architects}",
    "Vindicia Competitors {Vindicia}",
    "Vindicia Partners {Vindicia}",
    "Vinyl Gloves {Ahpmus}",
    "Vinyl Printing",
    "Vinyl and Rubber Gloves {TNG Worldwide}",
    "Violin Systems",
    "Viptela",
    "Viral Transport Media {Copan Diagnostics}",
    "Virtual Cards",
    "Virtual Care {Visuwell}",
    "Virtual Career Fair Platforms {eCareerFairs}",
    "Virtual Classroom {HTC America}",
    "Virtual Clinical Trial {Sprim}",
    "Virtual Clinical Trials {THREAD}",
    "Virtual Collaboration",
    "Virtual Data Center",
    "Virtual Data Room Software",
    "Virtual Desktop Infrastructure (VDI) Software",
    "Virtual Desktops",
    "Virtual Escape Room {Raid The Room}",
    "Virtual Event Platform {Raid The Room}",
    "Virtual Events",
    "Virtual Firewall",
    "Virtual Fitness {Peloton}",
    "Virtual Hold {Fonolo}",
    "Virtual Hospitals",
    "Virtual Influencer {Soul Machine}",
    "Virtual Infrastructure",
    "Virtual Intake Management {The SSI Group}",
    "Virtual Machine Monitor",
    "Virtual Machines",
    "Virtual Net Metering",
    "Virtual Payment Solutions",
    "Virtual Private Cloud",
    "Virtual Private Networks (VPN)",
    "Virtual Production",
    "Virtual Prototyping",
    "Virtual Reality (VR)",
    "Virtual Receptionist {Lexhelper}",
    "Virtual Sales Training {ValueSelling Associates}",
    "Virtual Scribe Services {DeepScribe}",
    "Virtual Servers",
    "Virtual Systems Administrator (VSA)",
    "Virtual Team Building {Raid The Room}",
    "Virtual Tour Software",
    "Virtualization",
    "Virtualization Management",
    "Visa (V)",
    "Visas",
    "Vision Care",
    "Vision Statements",
    "Visitor Management",
    "Visitor Management Software",
    "Vista Equity {Rockway Asset Management}",
    "Vista Solutions",
    "Visual Analytics",
    "Visual Basic",
    "Visual CPQ",
    "Visual Communication",
    "Visual Identity {Brand Institute}",
    "Visual Marketing",
    "Visual Merchandising",
    "Visual Solutions",
    "VoIP Providers",
    "Vocollect",
    "Voice AI {Skit.ai}",
    "Voice Analytics {Imagicle}",
    "Voice Assistant {Soul Machine}",
    "Voice Biometrics {ValidSoft}",
    "Voice Bot {Skit.ai}",
    "Voice Fraud {ValidSoft}",
    "Voice Outsourcing",
    "Voice Over IP",
    "Voice Picking",
    "Voice Recognition",
    "Voice of the Customer",
    "Voice-Directed Warehousing (VDW)",
    "Voicemail Transcription {Mutare Software}",
    "Voiteq",
    "Voluntary Benefits",
    "Volunteer Management Software",
    "Volusion",
    "Vonage {MessageBird}",
    "Voyage Operations Software {Voyager}",
    "Vulcan.io",
    "Vulnerability Assessment",
    "Vulnerability Management",
    "Vulnerability Scanning",
    "VxWorks {Raima}",
    "WAN {Cato Networks}",
    "WBENC {Delta Financial Group}",
    "WCG {Advarra}",
    "WEX Health {Benefit Resource}",
    "WFH Security {GCI}",
    "Wage & Salary Administration",
    "Wage Works {Benefit Resource}",
    "Wagestream {Rain}",
    "Walkie Talkie",
    "Wall of Fame {The Power Ad}",
    "Warburg Pincus {Rockway Asset Management}",
    "Warehouse Automation",
    "Warehouse Automation {Honour Capital}",
    "Warehouse Direct {Garveys Office Products}",
    "Warehouse Liquidation",
    "Warehouse Management",
    "Warehouse Management Solutions",
    "Warehouse Picking",
    "Warehouse Racking {Racking CA}",
    "Warehouse Robots {Fortna}",
    "Warehouse Space",
    "Warehousing and Distribution",
    "WarnerMedia",
    "Warranty Management Software",
    "Warranty Solutions",
    "Washdown Stations {ThermOmegaTech}",
    "Waste Auditing",
    "Waste Data {Method}",
    "Waste Equipment",
    "Waste Management",
    "Waste Management Software",
    "Waste Meter",
    "Waste Reporting {Method}",
    "Waste Sensor",
    "Waste Water",
    "Waste to Energy {Veolia}",
    "Wastewater Treatment {Veolia}",
    "Water Coolers",
    "Water Cycle Management {Veolia}",
    "Water Damage",
    "Water Distribution {Veolia}",
    "Water Heater",
    "Water Jet Cutting",
    "Water Management",
    "Water Meters",
    "Water Pollution Control",
    "Water Purification",
    "Water Quality",
    "Water and Sanitation",
    "Water services",
    "Waterfall methodology",
    "Waterproofing {Knauss Property Service}",
    "Waters Corporation {Agilent Technologies}",
    "Waters {Agilent}",
    "Wavelength Division Multiplexing (WDM)",
    "Wayfinding",
    "Waystar {The SSI Group}",
    "WeWork",
    "Wealth Management",
    "Weapons Detection {Shooter Detection Systems}",
    "Weapons Detection {ZeroEyes}",
    "Wear Parts",
    "Wearable Technology",
    "Weatherproofing {Knauss Property Service}",
    "Web Accelerators",
    "Web Accessibility",
    "Web Analytics",
    "Web Application Firewall (WAF)",
    "Web Application Security",
    "Web Applications",
    "Web Banner",
    "Web Beacons",
    "Web Content",
    "Web Design",
    "Web Development",
    "Web Filter",
    "Web Fonts",
    "Web Frameworks Software",
    "Web Marketing",
    "Web Servers",
    "Web Service Security",
    "Web Services {Eradani}",
    "Web-Scale IT",
    "WebRTC",
    "Webbmason Branding {Webbmason}",
    "Webcasting",
    "Webex Calling {Imagicle}",
    "Webinar Software",
    "Webinars",
    "Webroot",
    "Webroot {Sophos}",
    "Website Analytics",
    "Website Builders",
    "Website Localization Services",
    "Website Monitoring Software",
    "Website Performance",
    "Website Pop-Up",
    "Website Redesign",
    "Website Redesign {Object 9}",
    "Website Translation",
    "Website Visitor Tracking {ZoomInfo}",
    "Website and Collateral Development {SalesSparx}",
    "Weed Control {TruGreen}",
    "Weighing and Dispensing {Werum IT Solutions}",
    "Weight Loss/Management {Weight Watchers}",
    "Welding Respirator {RPB Safety}",
    "Welding Software",
    "Wellbeing {Weight Watchers}",
    "Wellness Benefits",
    "Wellness Gifts {Spoonful}",
    "Wells Fargo (WFC)",
    "Westcon Comstor",
    "Western Union Business Solutions",
    "What is My Business Worth {Transworld Business Brokers}",
    "WhatsApp",
    "WhatsApp Business API {MessageBird}",
    "WhatsApp Business {Infobip}",
    "Wheelchair Lifts {Savaria}",
    "Wheels Up {XOJET}",
    "Whistleblowing Hotline",
    "White Box Testing",
    "White Glove Delivery and Installation {Allegro Home Delivery}",
    "White Glove Delivery {USPack}",
    "White Label Learning Platform {KMI Learning}",
    "White Label {AstroNova}",
    "Whitelisting",
    "Whole Slide Scanning {Paige}",
    "Wholesale Voice",
    "WiFi",
    "WiMAX (Worldwide Interoperability for Microwave Access)",
    "Wide Area Network (WAN) Refresh",
    "Wide Area Networking (WAN)",
    "Widgets, Web Tools, & Plugins",
    "Wilkhahn {Wilkhahn}",
    "Willis Towers Watson (WLTW)",
    "Wind Farms {Thermal Cam USA}",
    "Wind Towers {Kloeckner Metals}",
    "Window Film and Window Graphics {Speedpro Imaging}",
    "Window Glazing Seals {Reed Rubber Products}",
    "Windows 10",
    "Windows 11",
    "Windows 8",
    "Windows Deployment Services",
    "Windows Hello",
    "Windows Intune",
    "Windows Management Instrumentation (WMI)",
    "Windows Migration",
    "Windows Mobile",
    "Windows Operating System",
    "Windows Phone",
    "Windows Server",
    "Windows Vista",
    "Windows XP",
    "Windstream",
    "Winery Software",
    "Wire Harnessing",
    "Wireless Application Protocol (WAP)",
    "Wireless Charging",
    "Wireless Communications",
    "Wireless Hardware",
    "Wireless Infrastructure",
    "Wireless LAN (WLAN)",
    "Wireless Networking",
    "Wireless Security",
    "Wireless Service Providers",
    "Wireshark",
    "Women Empowerment",
    "Women's Wealth and Financial Planning {Argent Financial Group}",
    "WooCommerce",
    "Wood Routing {CR Onsrud}",
    "WordPress",
    "WordPress VIP",
    "Work Anniversary",
    "Work Instructions {VKS}",
    "Work Management",
    "Work Opportunity Tax Credit",
    "Work Order Management",
    "Work Platforms {Flexible Lifeline Systems}",
    "Work Stoppages",
    "WorkStep {WorkStep}",
    "Workday Adaptive Planning",
    "Workday Inc.",
    "Workers Compensation Management Software {Ventiv Technology}",
    "Workers' Compensation",
    "Workflow Automation",
    "Workflow Software",
    "Workforce Analytics",
    "Workforce Management",
    "Workforce Management Software",
    "Workforce Optimization",
    "Workforce Planning",
    "Workforce Software",
    "Workforce Staffing {CPSI}",
    "Workforce Strategy - 0600 {Deloitte}",
    "Workfront",
    "Working Capital",
    "Workiva",
    "Workload Automation Software",
    "Workplace Analytics {GCI}",
    "Workplace Design and Workplace Intelligence {Cresa Global}",
    "Workplace Drug Testing {Fastest Labs}",
    "Workplace Injury",
    "Workplace Safety",
    "Workplace Strategy",
    "Workplace Technology",
    "Workplace Vaccination Policies",
    "Workplace Violence Prevention",
    "Workplace by Facebook",
    "Workspace Shields",
    "Workstyle Transformation",
    "Worldpay",
    "Wound Care",
    "Wrike",
    "XDR {Cato Networks}",
    "XML",
    "XPO Logistics",
    "XebiaLabs",
    "Xero {Xero}",
    "Xerox {Revolution Office}",
    "Xerox {Superior Office Products}",
    "XtremIO",
    "YB {NOCO Company}",
    "Yale {Alliance Material Handling}",
    "Yammer",
    "Yard Management Software",
    "Yardi Consultants {Lynx Systems}",
    "Yardi {Lynx Systems}",
    "YayPay",
    "Yellowfin",
    "Yext {Review Trackers}",
    "YouTube advertising {Marketing Architects}",
    "ZTNA {Cato Networks}",
    "Zayzoon {Rain}",
    "Zebra Printer {Taylor Data Systems}",
    "Zeiss EM {Thermo Fisher Scientific}",
    "Zeiss Metrology",
    "ZeissImages",
    "Zendesk",
    "Zero North {RiskSense}",
    "Zero Standing Privileges",
    "Zero Trust",
    "Zero Waste {Method}",
    "Zero-Day Threat",
    "Zerologon {Alsid}",
    "Zoho Desk",
    "ZoneAlarm",
    "Zoom Phone {Skyecast}",
    "Zoom Video Conferencing",
    "Zoom {Verbit}",
    "ZoomInfo Recruiter Direct Buying Signals {Zoominfo}",
    "ZoomInfo Recruiter Related Buying Signals {Zoominfo}",
    "Zoominfo",
    "Zoominfo Competitors {ZoomInfo}",
    "Zoominfo Engage",
    "Zoominfo Intent {ZoomInfo}",
    "Zscaler",
    "Zuora",
    "Zygo",
    "e-Forms",
    "eBay (EBAY)",
    "eBooking Competitors Set {Hangar A}",
    "eCOA {Data Cubed}",
    "eCOA {THREAD}",
    "eCareerFairs Competitors {eCareerFairs}",
    "eCheck Payment {Affinipay}",
    "eClinical Works",
    "eCommerce",
    "eCommerce Analytics {MikMak}",
    "eCommerce Image Services {Pixelz}",
    "eCommerce Marketing {MikMak}",
    "eCommerce Platform",
    "eCommerce Site Search",
    "eCommerce Strategy {MikMak}",
    "eCommerce {Enosix}",
    "eConsent {Advarra}",
    "eConsult {ReferralPoint}",
    "eDiscovery Hosting {Gulfstream Legal Group}",
    "eDiscovery Services {Gulfstream Legal Group}",
    "eDiscovery Software",
    "eFulfillment Service",
    "eLearning",
    "ePRO {Data Cubed}",
    "ePayment",
    "ePro eCoa {Data Cubed}",
    "eProcurement",
    "eSource {THREAD}",
    "ezbob Competitors {ezbob}",
    "iAPPS",
    "iCIMS",
    "iCloud",
    "iHeartMedia",
    "iManage",
    "iN2L",
    "iOS",
    "iOS API",
    "iOS App Marketing",
    "iOffice {Nuvolo Limited}",
    "iPhone",
    "iSCSI",
    "iSpot {Phoenix Marketing Intl}",
    "immixGroup",
    "monday.com",
    "nCloud {Cyber Security Works}",
    "nThrive {The SSI Group}",
    "pMD Charge Capture {Claimocity}",
    "uShip"
]

export default topicsList