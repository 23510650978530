const s2list = [
    "Administration of Economic Programs",
    "Administration of Environmental Quality and Housing Programs",
    "Administration of Human Resource Programs",
    "Amusement & Recreation Services",
    "Apparel & Other Textile Products",
    "Apparel And Accessory Stores",
    "Auto Repair, Services, And Parking",
    "Automotive Dealers & Service Stations",
    "Building Materials & Garden Supplies",
    "Business Services",
    "Chemicals And Allied Products",
    "Coal Mining",
    "Communications",
    "Depository Institutions",
    "Eating And Drinking Places",
    "Educational Services",
    "Electric, Gas & Sanitary Services",
    "Electronic & Other Electricequipment",
    "Engineering & Management Services",
    "Executive, Legislative, and General Government, except Finance",
    "Fabricated Metal Products",
    "Food And Kindred Products",
    "Food Stores",
    "Furniture And Fixtures",
    "Furniture And Homefurnishings Stores",
    "General Building Contractors",
    "General Merchandise Stores",
    "Health Services",
    "Heavy Construction, Ex. Building",
    "Holding & Other Investment Offices",
    "Hotels And Other Lodging Places",
    "Industrial Machinery & Equipment",
    "Instruments & Related Products",
    "Insurance Agents, Brokers & Service",
    "Insurance Carriers",
    "Justice, Public Order, and Safety",
    "Leather And Leather Products",
    "Legal Services",
    "Local & Interurban Passenger Transit",
    "Lumber And Wood Products",
    "Membership Organizations",
    "Metal Mining",
    "Misc. Manufacturing Industries",
    "Miscellaneous Repair Services",
    "Miscellaneous Retail",
    "Motion Pictures",
    "Museums, Botanical, Zoologicalgardens",
    "National Security and International Affairs",
    "Nondepository Institutions",
    "Nonmetallic Minerals, Except Fuels",
    "Oil And Gas Extraction",
    "Paper And Allied Products",
    "Personal Services",
    "Petroleum And Coal Products",
    "Pipelines, Except Natural Gas",
    "Primary Metal Industries",
    "Printing And Publishing",
    "Private Households",
    "Public Finance, Taxation, and Monetary Policy",
    "Railroad Transportation",
    "Real Estate",
    "Rubber & Misc. Plastics Products",
    "Security And Commodity Brokers",
    "Services, Not Elsewhere Classified",
    "Social Services",
    "Special Trade Contractors",
    "Stone, Clay, And Glass Products",
    "Textile Mill Products",
    "Tobacco Products",
    "Transportation By Air",
    "Transportation Equipment",
    "Transportation Services",
    "Trucking And Warehousing",
    "United States Postal Service",
    "Water Transportation",
    "Wholesale Trade-Durable Goods",
    "Wholesale Trade-Nondurable Goods"
]

export default s2list