import { Amplify,  API } from 'aws-amplify';
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig); // Uses the awsconfig file imported above to configure Amplify's backend
API.configure(awsconfig); // Uses the awsconfig file imported above to configure our REST API

/****************************************************************************************************
This is an API component that uses best practice. To avoid commenting the code below line by line,
the basic functionality is that the whole component is the API. Each function is representative
of a path on the API. The add another function in seperate variables you should save the API Name,
the path in the form of "/<name>/" and then if necessary, data which contains your schema of the
data you'd like to send to the API path. Try-Catch is used so that in the case of a failure it dies
silently.
******************************************************************************************************/
function WorkflowsAPI() {

    async function testAPI(companyData, departments, managementLevels) {
        try {
            const apiName = "WorkflowsAPI";
            const path = "/test/";

            const data = { 
                body: {
                    "data": companyData,
                    "options": {
                        "departments": departments,
                        "managementLevels": managementLevels
                    }
                } 
            };

            const res = await API.post(apiName, path, data);

            return res
        } catch(error) {
            console.log("Error calling API: ", error);
            return null;
        }
    }

    async function createBatches(companyData, recordsPerBatch) {
        try {
            const apiName = "WorkflowsAPI";
            const path = "/createBatches/";

            const data = { 
                body: {
                    "data": companyData,
                    "recordsPerBatch": recordsPerBatch
                } 
            };

            const res = await API.post(apiName, path, data);

            return res
        } catch(error) {
            console.log("Error calling API: ", error);
            return null;
        }
    }

    async function createEnrichedBatches(contactData, recordsPerBatch) {
        try {
            const apiName = "WorkflowsAPI";
            const path = "/createEnrichedBatches/";

            const data = { 
                body: {
                    "data": contactData,
                    "recordsPerBatch": recordsPerBatch
                } 
            };

            const res = await API.post(apiName, path, data);

            return res
        } catch(error) {
            console.log("Error calling API: ", error);
            return null;
        }
    }

    async function saveRequest(companyData, departments, managementLevels, jobFunctions, contactListName) {
        try {
            const apiName = "WorkflowsAPI";
            const path = "/saveRequest/";

            const data = { 
                body: {
                    "data": companyData,
                    "options": {
                        "departments": departments,
                        "managementLevels": managementLevels,
                        "jobFunctions": jobFunctions,
                        "contactListName": contactListName
                    }
                }
            };

            const res = await API.post(apiName, path, data);

            return res
        } catch(error) {
            console.log("Error calling API: ", error);
            return null;
        }
    }

    async function authorize() {
        try {
            const apiName = "WorkflowsAPI";
            const path = "/authorize/";

            const res = await API.post(apiName, path);
            return res
        } catch(error) {
            console.log("Error calling API: ", error);
            return null;
        }
    }

    async function processPreliminaryBatch(batch, uuid, jwt) {
        try {
            const apiName = "WorkflowsAPI";
            const path = "/processPreliminaryBatch/";

            const data = { 
                body: {
                    "data": batch,
                    "uuid": uuid,
                    "jwt": jwt,
                }
            };

            const res = await API.post(apiName, path, data);

            return res
        } catch(error) {
            console.log("Error calling API: ", error);
            return null;
        }
    }

    async function enrichContacts(batch, jwt) {
        try {
            const apiName = "WorkflowsAPI";
            const path = "/enrichContacts/";

            const data = { 
                body: {
                    "data": batch,
                    "jwt": jwt,
                } 
            };

            const res = await API.post(apiName, path, data);

            return res
        } catch(error) {
            console.log("Error calling API: ", error);
            return null;
        }
    }

    async function saveFilters(filters) {
        try {
            const apiName = "WorkflowsAPI";
            const path = "/saveFilters/";

            const data = { 
                body: {
                    "filters": filters,
                } 
            };

            const res = await API.post(apiName, path, data);

            return res
        } catch(error) {
            console.log("Error calling API: ", error);
            return null;
        }
    }

    async function getFilters(id) {
        try {
            const apiName = "WorkflowsAPI";
            const path = "/getFilters/";

            const data = { 
                body: {
                    "queryId": id,
                } 
            };

            const res = await API.post(apiName, path, data);

            return res
        } catch(error) {
            console.log("Error calling API: ", error);
            return null;
        }
    }

    /****************************************************************************************************
    Down here we make each path available as a method of the API. To use the API import it in a component
    as `import WorkflowsAPI from "./WorkflowsAPI"` then initialize the api with the new keyword like
    `const workflowsAPI = new WorkflowsAPI()` To then use it you can call things like 
    `await workflowsAPI.createBatches(x, y)`
    ******************************************************************************************************/
    return {
        testAPI: testAPI,
        enrichContacts: enrichContacts,
        createBatches: createBatches,
        saveRequest: saveRequest,
        authorize: authorize,
        processPreliminaryBatch: processPreliminaryBatch,
        createEnrichedBatches: createEnrichedBatches,
        saveFilters: saveFilters,
        getFilters: getFilters
    }
}

export default WorkflowsAPI;