import { LookerEmbedSDK } from '@looker/embed-sdk'
import React from 'react';
import Sidebar from './components/sidebar';
import ContactsModal from './components/contactsModal';
import ShareModal from './components/shareModal';
import { motion, AnimatePresence } from "framer-motion"
import WorkflowsAPI from './components/WorkflowsAPI';
import { useLocation, useNavigate } from "react-router-dom";
import Lottie from 'react-lottie-player';
import lottieJson from './components/anim/fetchLottie.json';
import LoadingBar from 'react-top-loading-bar'

const backgroundVariants = {
    visible: { 
        opacity: 1,
        transition: {
            duration: 0
        }
    },
    hidden: { 
        opacity: 0,
        transition: {
            duration: 0
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.5
        }
    },
}


const workflowsAPI = new WorkflowsAPI() // Creates a connection with the API in the imports above. For reference of what each API function does refer to the documentation.

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

/****************************************************************************************************
The HomePage component is currently responsible for all filtering and functions having to do with
filtering. It is incredibly code-rich and contains vital functions and variables.
******************************************************************************************************/
const HomePage = () => {

    const COMPANIESDASHBOARDID = "373";
    const INTENTDASHBOARDID = "411";
    const LOADTIMER = 5000 // in milliseconds

    let query = useQuery();
    let navigate = useNavigate();

    function deepCompare() {
        var i, l, leftChain, rightChain;

        function compare2Objects(x, y) {
            var p;

            // remember that NaN === NaN returns false
            // and isNaN(undefined) returns true
            if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
                return true;
            }

            // Compare primitives and functions.     
            // Check if both arguments link to the same object.
            // Especially useful on the step where we compare prototypes
            if (x === y) {
                return true;
            }

            // Works in case when functions are created in constructor.
            // Comparing dates is a common scenario. Another built-ins?
            // We can even handle functions passed across iframes
            if ((typeof x === 'function' && typeof y === 'function') ||
                (x instanceof Date && y instanceof Date) ||
                (x instanceof RegExp && y instanceof RegExp) ||
                (x instanceof String && y instanceof String) ||
                (x instanceof Number && y instanceof Number)) {
                return x.toString() === y.toString();
            }

            // At last checking prototypes as good as we can
            if (!(x instanceof Object && y instanceof Object)) {
                return false;
            }

            if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
                return false;
            }

            if (x.constructor !== y.constructor) {
                return false;
            }

            if (x.prototype !== y.prototype) {
                return false;
            }

            // Check for infinitive linking loops
            if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
                return false;
            }

            // Quick checking of one object being a subset of another.
            // todo: cache the structure of arguments[0] for performance
            for (p in y) {
                if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                    return false;
                }
                else if (typeof y[p] !== typeof x[p]) {
                    return false;
                }
            }

            for (p in x) {
                if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                    return false;
                }
                else if (typeof y[p] !== typeof x[p]) {
                    return false;
                }

                switch (typeof (x[p])) {
                    case 'object':
                    case 'function':

                        leftChain.push(x);
                        rightChain.push(y);

                        if (!compare2Objects(x[p], y[p])) {
                            return false;
                        }

                        leftChain.pop();
                        rightChain.pop();
                        break;

                    default:
                        if (x[p] !== y[p]) {
                            return false;
                        }
                        break;
                }
            }

            return true;
        }

        if (arguments.length < 1) {
            return true; //Die silently? Don't know how to handle such case, please help...
            // throw "Need two or more arguments to compare";
        }

        for (i = 1, l = arguments.length; i < l; i++) {

            leftChain = []; //Todo: this can be cached
            rightChain = [];

            if (!compare2Objects(arguments[0], arguments[i])) {
                return false;
            }
        }

        return true;
    }

    const type = localStorage.getItem('lastDashboardType');
    const id = type === "Intent" ? INTENTDASHBOARDID : COMPANIESDASHBOARDID
    const [currentDashboardState, setCurrentDashboardState] = React.useState(id) // All Companies Dashboard ID
    const currentDashboardRef = React.useRef(id) // All Companies Dashboard ID


    const companyData = React.useRef([]) // Placeholder that will eventually contain the data sent from the postMessage before the API request

    const appliedFilters = React.useRef(currentDashboardRef.current === COMPANIESDASHBOARDID ? {
        "Company Employee Count": ">0",
        "Company Name": "",
        "Company Revenue (K)": ">0",
        "Website": "",
        "HQ State": "",
        "Industry (Everstring)": "",
        "Is B2B (Yes / No)": "",
        "Is B2C (Yes / No)": "",
        "NAICS2 Description": "",
        "NAICS4 Description": "",
        "Requested KPI:": "Companies",
        "SIC2 Description": "",
        "SIC4 Description": "",
        "Top Keywords": "",
        "Topic": "",
        "Topic Filter Type": "Any Selected Topic",
        "HQ CBSA Name": "",
        "HQ Zipcode": "",
        "Location (Long/Lat)": ""
    } :
    {
        "Any or All Topics Filter (Yes / No)": "Yes",
        "Company Employee Count": ">0",
        "Company Name": "",
        "Company Revenue (K)": ">0",
        "Website": "",
        "HQ State": "",
        "Has Intent (Yes / No)": "Yes",
        "Industry (Everstring)": "",
        "Is B2B (Yes / No)": "",
        "Is B2C (Yes / No)": "",
        "NAICS2 Description": "",
        "NAICS4 Description": "",
        "Requested KPI:": "Companies",
        "SIC2 Description": "",
        "SIC4 Description": "",
        "Top Keywords": "",
        "Topic": "",
        "Topic Filter Type": "Any Selected Topic",
        "HQ CBSA Name": "",
        "HQ Zipcode": "",
        "Location (Long/Lat)": ""
    })

    const [needsUpdate, setNeedsUpdate] = React.useState(false) // The state variable that actually contains the boolean for whether the applied and unapplied filters differ

    /* useRef keeps points to where a current value is stored in the memory
      useState controls rerenders on dynamic elements */

    const dashboardRef = React.useRef(null) // Allows Filtering outside of setup

    // Employee Sidebar Trackers
    const [elv, setelv] = React.useState("") // Employee Lower Value State
    const [ehv, setehv] = React.useState("") // Employee Higher Value State
    const employeeLowerValue = React.useRef('') // Employee Lower Value Ref
    const employeeHigherValue = React.useRef('') // Employee Higher Value Ref

    // Revenue Sidebar Trackers
    const [rlv, setrlv] = React.useState("") // Revenue Lower Value State
    const [rhv, setrhv] = React.useState("") // Revenue Higher Value State
    const revenueLowerValue = React.useRef('') // Revenue Lower Value Ref
    const revenueHigherValue = React.useRef('') // Revenue Higher Value Ref

    // US State Sidebar Trackers
    const [sselected, setsselected] = React.useState([]) // List of currently selected options for filter (state: tracks value for rerender)
    const statesSelected = React.useRef([]) // List of currently selected options for filter (ref: tracks value for filter)

    // Topic Sidebar Trackers
    const [tselected, settselected] = React.useState([]) // List of currently selected options for filter (state: tracks value for rerender)
    const topicsSelected = React.useRef([]) // List of currently selected options for filter (ref: tracks value for filter)

    // Topic Any/All Dropdown Trackers in sidebar
    const anyAll = React.useRef("Any") // Tracks the value at any given time for DropDown
    const [aa, setaa] = React.useState('Any') // Tracks the state at any given time for DropDown (For rerender/reformatting)

    // 'Requested KPI:' Dropdown Trackers in sidebar
    const reqKPI = React.useRef("Companies") // Tracks the value at any given time for DropDown
    const [rkpi, setrkpi] = React.useState("Companies") // Tracks the state at any given time for DropDown (For rerender/reformatting)

    // Industry Sidebar Trackers
    const [iselected, setiselected] = React.useState([]) // List of currently selected options for filter (state: tracks value for rerender)
    const industriesSelected = React.useRef([]) // List of currently selected options for filter (ref: tracks value for filter)

    const [tkselected, settkselected] = React.useState([]) // List of currently selected options for filter (state: tracks value for rerender)
    const topKeywordsSelected = React.useRef([]) // List of currently selected options for filter (ref: tracks value for filter)

    const [urlsselected, seturlsslected] = React.useState([]) // List of currently selected options for filter (state: tracks value for rerender)
    const companyURLSSelected = React.useRef([]) // List of currently selected options for filter (ref: tracks value for filter)

    const [namesselected, setnamesselected] = React.useState([]) // List of currently selected options for filter (state: tracks value for rerender)
    const companyNamesSelected = React.useRef([]) // List of currently selected options for filter (ref: tracks value for filter)

    // SIC2 Sidebar Trackers
    const [s2selected, sets2selected] = React.useState([]) // List of currently selected options for filter (state: tracks value for rerender)
    const sic2Selected = React.useRef([]) // List of currently selected options for filter (ref: tracks value for filter)

    // SIC4 Sidebar Trackers
    const [s4selected, sets4selected] = React.useState([]) // List of currently selected options for filter (state: tracks value for rerender)
    const sic4Selected = React.useRef([]) // List of currently selected options for filter (ref: tracks value for filter)

    // NAICS2 Sidebar Trackers
    const [n2selected, setn2selected] = React.useState([]) // List of currently selected options for filter (state: tracks value for rerender)
    const naics2Selected = React.useRef([]) // List of currently selected options for filter (ref: tracks value for filter)

    // NAICS4 Sidebar Trackers
    const [n4selected, setn4selected] = React.useState([]) // List of currently selected options for filter (state: tracks value for rerender)
    const naics4Selected = React.useRef([]) // List of currently selected options for filter (ref: tracks value for filter)

    const longLat = React.useRef("")
    const CBSAName = React.useRef("")
    const zipCode = React.useRef("")

    // B2B/B2C Sidebar Trackers
    const [b2bb2c, setb2bb2c] = React.useState("") // B2B or B2C Value State
    const b2b = React.useRef(b2bb2c) // B2B Value pointer

    const filtersNeedUpdate = React.useRef(false) // Tracks whether filters should be updated on next pass

    const [showContactsModal, setShowContactsModal] = React.useState(false) // Modal to generate companies
    const [showShareModal, setShowShareModal] = React.useState(false) // Modal to copy share link

    const [showLoadingScreen, setShowLoadingScreen] = React.useState(true)

    const [currentShareURL, setCurrentShareURL] = React.useState("")

    const dashboardLoaded = React.useRef(false)

    const loaderRef = React.useRef(null)

    const [progress, setProgress] = React.useState(0)

    // Page Loading State DEV NOTE: Will need framer motion
    //const [isPageLoading, setPageLoading] = React.useState(false);

    function replaceState(f) {
        setelv(f.employeeLowerValue)
        setehv(f.employeeHigherValue)
        employeeLowerValue.current.value = f.employeeLowerValue
        employeeHigherValue.current.value = f.employeeHigherValue

        setrlv(f.revenueLowerValue)
        setrhv(f.revenueHigherValue)
        revenueLowerValue.current.value = f.revenueLowerValue
        revenueHigherValue.current.value = f.revenueHigherValue

        setsselected(f.selectedStates)
        statesSelected.current = f.selectedStates

        settselected(f.selectedTopics)
        topicsSelected.current = f.selectedTopics

        setiselected(f.selectedIndustries)
        industriesSelected.current = f.selectedIndustries

        settkselected(f.selectedTopKeywords)
        topKeywordsSelected.current = f.selectedTopKeywords

        sets2selected(f.selectedSIC2Descriptions)
        sic2Selected.current = f.selectedSIC2Descriptions

        sets4selected(f.selectedSIC4Descriptions)
        sic4Selected.current = f.selectedSIC4Descriptions

        setn2selected(f.selectedNAICS2Descriptions)
        naics2Selected.current = f.selectedNAICS2Descriptions

        setn4selected(f.selectedNAICS4Descriptions)
        naics4Selected.current = f.selectedNAICS4Descriptions

        setb2bb2c(f.B2BorB2C)
        b2b.current = f.B2BorB2C

        setaa(f.anyOrAllTopics)
        anyAll.current = f.anyOrAllTopics

        setrkpi(f.requestedKPI)
        reqKPI.current = f.requestedKPI

        seturlsslected(f.selectedCompanyURLS)
        companyURLSSelected.current = f.selectedCompanyURLS

        setnamesselected(f.selectedCompanyNames)
        companyNamesSelected.current = f.selectedCompanyNames
    }

    async function sendSharedFiltersToDB() {
        const sharedFilters = {
            employeeLowerValue: employeeLowerValue.current.value,
            employeeHigherValue: employeeHigherValue.current.value,
            revenueHigherValue: revenueHigherValue.current.value,
            revenueLowerValue: revenueLowerValue.current.value,
            selectedStates: statesSelected.current,
            selectedTopics: topicsSelected.current,
            selectedIndustries: industriesSelected.current,
            selectedTopKeywords: topKeywordsSelected.current,
            selectedSIC2Descriptions: sic2Selected.current,
            selectedSIC4Descriptions: sic4Selected.current,
            selectedNAICS2Descriptions: naics2Selected.current,
            selectedNAICS4Descriptions: naics4Selected.current,
            anyOrAllTopics: anyAll.current,
            requestedKPI: reqKPI.current,
            B2BorB2C: b2b.current,
            CBSA: CBSAName.current,
            zipCode: zipCode.current,
            longitudeLatitude: longLat.current,
            selectedCompanyNames: companyNamesSelected.current,
            selectedCompanyURLS: companyURLSSelected.current
        }

        const createSaveFiltersResponse = await workflowsAPI.saveFilters(sharedFilters)

        if (typeof window !== 'undefined') {
            var path = window.location.protocol + '//' + window.location.host + '?action=share&query_id=' + createSaveFiltersResponse.identifier;
            setCurrentShareURL(path)

            setShowShareModal(true)
        }
    }

    function getBasicFilters() {
        const filters = {
            "Requested KPI:": reqKPI.current,
            "Is B2B (Yes / No)": b2b.current === "b2b" || b2b.current === "b2bb2c" ? "Yes" : "",
            "Is B2C (Yes / No)": b2b.current === "b2c" || b2b.current === "b2bb2c" ? "Yes" : "",
            "Company Employee Count": getComputedRangeFilter(parseFloat(employeeLowerValue.current.value), parseFloat(employeeHigherValue.current.value)),
            "Company Revenue (K)": getComputedRangeFilter(parseFloat(revenueLowerValue.current.value), parseFloat(revenueHigherValue.current.value)),
            "Industry (Everstring)": getSearchTerms(industriesSelected.current),
            "HQ State": getSearchTerms(statesSelected.current),
            "Topic Filter Type": (anyAll.current === "All") ? "All Selected Topics" : "Any Selected Topic",
            "Topic": getSearchTerms(topicsSelected.current),
            "NAICS4 Description": getSearchTerms(naics4Selected.current),
            "SIC2 Description": getSearchTerms(sic2Selected.current),
            "NAICS2 Description": getSearchTerms(naics2Selected.current),
            "SIC4 Description": getSearchTerms(sic4Selected.current),
            "Intent Any or All Topics Filter (Yes / No)": currentDashboardState === COMPANIESDASHBOARDID ? "No" : "Yes",
            "Top Keywords": getContainsTerms(topKeywordsSelected.current),
            "HQ CBSA Name": CBSAName.current,
            "HQ Zipcode": zipCode.current,
            "Location (Long/Lat)": longLat.current,
            "Company Name": getContainsTerms(companyNamesSelected.current),
            "Website": getContainsTerms(companyURLSSelected.current)
        }

        return filters
    }

    // Creates the value to submit as filter on ranges.
    function getComputedRangeFilter(l, h) {
        if (l > 0 && (h >= l && (h !== '' || !isNaN(h) || !Number.isInteger(h)))) {
            return `[${l},${h}]`;
        } else if ((l === "" || isNaN(l)) && h > 0) {
            return `<=${h}`
        } else if ((h === "" || isNaN(h)) && l > 0) {
            return `>=${l}`
        } else {
            return ">0"
        }
    }

    /****************************************************************************************************
    Swaps to dashboard <i> and updates filters. Does not Reverse-Sync
    ******************************************************************************************************/
    const swapDashboards = (i) => {
        if (i < 1) {
            return null // Sentinel return
        }
        setCurrentDashboardState(i)
        currentDashboardRef.current = i
        removeAllDashboards()
        filtersNeedUpdate.current = true
        setupEverything(i)
        localStorage.setItem("lastDashboardType", i === INTENTDASHBOARDID ? "Intent" : "Companies")
    }

    /****************************************************************************************************
    Syncs HQ State on Run Complete since cross filtering does not trigger a filters-changed event
    ******************************************************************************************************/
    const syncOnRunComplete = (filters) => {
        if(filtersNeedUpdate.current) {
            return null
        } else {
            if (filters["HQ State"] !== "") {
                setsselected(filters["HQ State"].split(","))
                statesSelected.current = filters["HQ State"].split(",")
            } else {
                setsselected([])
                statesSelected.current = []
            }

            CBSAName.current = filters["HQ CBSA Name"]
            zipCode.current = filters["HQ Zipcode"]
            longLat.current = filters["Location (Long/Lat)"]

            appliedFilters.current = filters
        }
    }

    /****************************************************************************************************
    Removes all dashboard but one. This keeps primarily from multiple dashboards appearing when the
    useEffect is hit.
    ******************************************************************************************************/
    const removeExtraDashboards = async () => {
        var db = document.getElementById("dashboard")
        let numb = db.childElementCount

        while (numb > 1) {
            db.removeChild(db.lastChild);
            numb = db.childElementCount
        }
    }

    /****************************************************************************************************
    Removes all dashboards in the event that a reset happens.
    ******************************************************************************************************/
    const removeAllDashboards = async () => {
        var db = document.getElementById("dashboard")
        let numb = db.childElementCount

        while (numb > 0) {
            db.removeChild(db.lastChild);
            numb = db.childElementCount
        }
    }

    /****************************************************************************************************
    Converts array of search filters into a comma seperated list inside a string
    ******************************************************************************************************/
    const getSearchTerms = (list) => {
        try {
            for (let i = 0; i < list.length; i++) {
                if (list[i].includes(",") && !list[i].includes("\"")) {
                    list[i] = `"${list[i]}"`
                }
            }

            return list.join(",")
        } catch {
            return ""
        }
    }

    /****************************************************************************************************
    Converts array of contains filters into a comma seperated list inside a string, except with %
    ******************************************************************************************************/
    const getContainsTerms = (list) => {
        var l = list
        try {
            for (let i = 0; i < l.length; i++) {
                if (l[i].includes(",") && !l[i].includes("\"")) {
                    l[i] = `"${l[i]}"`
                }
                if(!l[i].includes('%')) {
                    l[i] = `%${l[i]}%`
                }
            }

            return l.join(",")
        } catch {
            return ""
        }
    }

    /****************************************************************************************************
    Tracks Sum of all filters, stored on the sidebar in both collapsed and expanded versions

    DEV NOTE: Selected topics will still be counted and filtered against even if the dashboard is the
    companies dashboard.
    ******************************************************************************************************/
    const getFilterSum = () => {
        const sum = (
            sselected.length +
            tselected.length +
            iselected.length +
            s2selected.length +
            s4selected.length +
            n2selected.length +
            n4selected.length +
            tkselected.length +
            (elv || ehv ? 1 : 0) +
            (rlv || rhv ? 1 : 0)
        )
        return (sum > 0 ? `(${sum})` : "")
    }

    /****************************************************************************************************
    Self Explantory, sends filters to dashboard. Applied with dashboard.run()
    ******************************************************************************************************/
    const applyFilters = async (dashboard) => {
        setNeedsUpdate(false)
        const filt = getBasicFilters()
        dashboard.updateFilters(filt)

        dashboard.run()
    }

    /****************************************************************************************************
    This function determines whether or not "NeedsUpdate" should be true and the apply button should
    be made active. It compares current unapplied filters to applied filters.
    ******************************************************************************************************/
    function checkForUpdates() {
        var current = {
            "Any or All Topics Filter (Yes / No)": "Yes",
            "Company Name": getContainsTerms(companyNamesSelected.current),
            "Website": getContainsTerms(companyURLSSelected.current),
            "Company Employee Count": getComputedRangeFilter(parseFloat(employeeLowerValue.current.value), parseFloat(employeeHigherValue.current.value)),
            "Company Revenue (K)": getComputedRangeFilter(parseFloat(revenueLowerValue.current.value), parseFloat(revenueHigherValue.current.value)),
            "HQ State": getSearchTerms(statesSelected.current),
            "Has Intent (Yes / No)": "Yes",
            "Industry (Everstring)": getSearchTerms(industriesSelected.current),
            "Is B2B (Yes / No)": b2b.current === "b2b" || b2b.current === "b2bb2c" ? "Yes" : "",
            "Is B2C (Yes / No)": b2b.current === "b2c" || b2b.current === "b2bb2c" ? "Yes" : "",
            "NAICS2 Description": getSearchTerms(naics2Selected.current),
            "NAICS4 Description": getSearchTerms(naics4Selected.current),
            "Requested KPI:": reqKPI.current,
            "SIC2 Description": getSearchTerms(sic2Selected.current),
            "SIC4 Description": getSearchTerms(sic4Selected.current),
            "Topic": getSearchTerms(topicsSelected.current),
            "Topic Filter Type": (anyAll.current === "All") ? "All Selected Topics" : "Any Selected Topic",
            "Top Keywords": getContainsTerms(topKeywordsSelected.current),
            "HQ CBSA Name": CBSAName.current,
            "HQ Zipcode": zipCode.current,
            "Location (Long/Lat)": longLat.current
        }

        if (currentDashboardRef.current === COMPANIESDASHBOARDID) {
            current = {
                "Company Employee Count": getComputedRangeFilter(parseFloat(employeeLowerValue.current.value), parseFloat(employeeHigherValue.current.value)),
                "Company Revenue (K)": getComputedRangeFilter(parseFloat(revenueLowerValue.current.value), parseFloat(revenueHigherValue.current.value)),
                "HQ State": getSearchTerms(statesSelected.current),
                "Company Name": getContainsTerms(companyNamesSelected.current),
                "Website": getContainsTerms(companyURLSSelected.current),
                "Industry (Everstring)": getSearchTerms(industriesSelected.current),
                "Is B2B (Yes / No)": b2b.current === "b2b" || b2b.current === "b2bb2c" ? "Yes" : "",
                "Is B2C (Yes / No)": b2b.current === "b2c" || b2b.current === "b2bb2c" ? "Yes" : "",
                "NAICS2 Description": getSearchTerms(naics2Selected.current),
                "NAICS4 Description": getSearchTerms(naics4Selected.current),
                "Requested KPI:": reqKPI.current,
                "SIC2 Description": getSearchTerms(sic2Selected.current),
                "SIC4 Description": getSearchTerms(sic4Selected.current),
                "Topic": getSearchTerms(topicsSelected.current),
                "Topic Filter Type": (anyAll.current === "All") ? "All Selected Topics" : "Any Selected Topic",
                "Top Keywords": getContainsTerms(topKeywordsSelected.current),
                "HQ CBSA Name": CBSAName.current,
                "HQ Zipcode": zipCode.current,
                "Location (Long/Lat)": longLat.current
            }

            if (!deepCompare(current, appliedFilters.current) && !needsUpdate) {
                setNeedsUpdate(true)
                console.log("FILTER DEBUG")
                console.log(current)
                console.log(appliedFilters.current)
                return
            } else if (needsUpdate && deepCompare(current, appliedFilters.current)) {
                setNeedsUpdate(false)
                return
            }
        }

        if (!deepCompare(current, appliedFilters.current) && !needsUpdate) {
            setNeedsUpdate(true)
            console.log("FILTER DEBUG")
            console.log(current)
            console.log(appliedFilters.current)
            return
        } else if (needsUpdate && deepCompare(current, appliedFilters.current)) {
            setNeedsUpdate(false)
            return
        }
    }
    
    const checkAuthenticated = async() => {
        if(dashboardLoaded.current) {
            setShowLoadingScreen(false)
        } else {
            window.location.replace('https://thrivecap.com')
        }
    }

    // Records dashboard, removes all dashboard but one, sets looker id and adds event handlers
    const setupDashboard = async (dashboard) => {
        dashboardRef.current = dashboard

        removeExtraDashboards() // Removes all dashboard but one

        /****************************************************************************************************
        Necessary to send and receive postMessages from the dashboard.
        ******************************************************************************************************/
        const iframe = document.getElementById("dashboard").firstChild
        iframe.setAttribute('id', 'looker')

        let action = query.get('action')
        let queryId = query.get('query_id')

        var shFilters;

        if(action==="share" && queryId) { 
            shFilters = await getSharedFilters(queryId)
            if(shFilters) {
                replaceState(shFilters)
                setNeedsUpdate(false)
                dashboard.updateFilters({
                    "Requested KPI:": shFilters.requestedKPI,
                    "Is B2B (Yes / No)": shFilters.B2BorB2C === "b2b" || shFilters.B2BorB2C === "b2bb2c" ? "Yes" : "",
                    "Is B2C (Yes / No)": shFilters.B2BorB2C === "b2c" || shFilters.B2BorB2C === "b2bb2c" ? "Yes" : "",
                    "Company Name": getContainsTerms(companyNamesSelected.current),
                    "Website": getContainsTerms(companyURLSSelected.current),
                    "Company Employee Count": getComputedRangeFilter(parseFloat(shFilters.employeeLowerValue), parseFloat(shFilters.employeeHigherValue)),
                    "Company Revenue (K)": getComputedRangeFilter(parseFloat(shFilters.revenueLowerValue), parseFloat(shFilters.revenueHigherValue)),
                    "Industry (Everstring)": getSearchTerms(shFilters.selectedIndustries),
                    "HQ State": getSearchTerms(shFilters.selectedStates),
                    "Topic Filter Type": (shFilters.anyOrAllTopics === "All") ? "All Selected Topics" : "Any Selected Topic",
                    "Topic": getSearchTerms(shFilters.selectedTopics),
                    "NAICS4 Description": getSearchTerms(shFilters.selectedNAICS4Descriptions),
                    "SIC2 Description": getSearchTerms(shFilters.selectedSIC2Descriptions),
                    "NAICS2 Description": getSearchTerms(shFilters.selectedNAICS2Descriptions),
                    "SIC4 Description": getSearchTerms(shFilters.selectedSIC4Descriptions),
                    "Intent Any or All Topics Filter (Yes / No)": currentDashboardState === COMPANIESDASHBOARDID ? "No" : "Yes",
                    "Top Keywords": getContainsTerms(shFilters.selectedTopKeywords),
                    "HQ CBSA Name": shFilters.CBSA,
                    "HQ Zipcode": shFilters.zipCode,
                    "Location (Long/Lat)": shFilters.longitudeLatitude
                })

                dashboard.run()
            }
        }

        // Event handler to apply
        document.querySelector('#apply').addEventListener('click', () => {
            applyFilters(dashboard)
        })

        window.addEventListener("message", (event) => {
            if (event.data.message === "crossFilterLocale") {
                statesSelected.current = event.data.value;
                setsselected(event.data.value)
                dashboard.updateFilters({
                    "HQ State": getSearchTerms(event.data.value)
                })

                dashboard.run()
            }
        });
    }

    const updateState = (state, e) => {

        /*#############################
        ###############################

        UNCOMMENT BELOW FOR DEBUG

        ###############################
        ##############################*/
        console.log(`%cEvent: %c${String(e.type)}`, 'background: transparent; color: #1395ff;', 'background: transparent; color: #fff;')
        console.log(e)
    }

    /****************************************************************************************************
    Initializes, Appends, Compiles, Builds and then Connects the looker dashboard. May require you to
    have a looker.ini file present. Review documentation to create a looker.ini file.
    ******************************************************************************************************/
    async function setupEverything(i) {
        LookerEmbedSDK.init('https://thrivecap.cloud.looker.com') // Initialize Looker with the provided URL

        /****************************************************************************************************
        Sets up the dashboard, items individually or group commented. <i> is the id fo the dashboard to setup
        ******************************************************************************************************/
        await LookerEmbedSDK.createDashboardWithId(i)
            .appendTo('#dashboard') // Apends the dashboard to the element with the id "dashboard"

            /****************************************************************************************************
            Events to fire when a certain postMessage is received.
            ******************************************************************************************************/
            .on('dashboard:run:start',
                (event) => {
                    updateState('Running', event)
                    appliedFilters.current = event.dashboard.dashboard_filters
                    checkForUpdates()
                }
            )
            .on('dashboard:run:complete',
                (event) => {
                    updateState('Done', event)
                    console.log(event.dashboard.dashboard_filters)
                    syncOnRunComplete(event.dashboard.dashboard_filters)
                    if (filtersNeedUpdate.current) {
                        const filt = getBasicFilters()
                        dashboardRef.current.updateFilters(filt)
                        dashboardRef.current.run()
                    }
                    filtersNeedUpdate.current = false
                    checkForUpdates()
                }
            )
            .on('dashboard:filters:changed',
                (event) => {
                    updateState('Filters Changed', event)
                    appliedFilters.current = event.dashboard.dashboard_filters
                    checkForUpdates()
                }
            )
            .on('dashboard:loaded',
                (event) => {
                    updateState('Dashboard Loaded', event)
                    dashboardLoaded.current = true
                }
            )
            .build()
            .connect()
            .then(setupDashboard)
            .catch((error) => {
                console.error('An unexpected error occurred', error)
            })

        console.log("Dashboard Setup Finished")
    }

    function startContactsAPIEngine() {
        setShowContactsModal(true)
    }

    async function getSharedFilters(queryId) {
        const q = await workflowsAPI.getFilters(queryId)
        return q.filters
    }

    React.useEffect(() => {
        document.querySelector('#reset').addEventListener('click', () => {
            navigate('/')
            window.location.reload();
        })
        console.log("Setting up backend dashboard ready for import")
        setupEverything(currentDashboardRef.current)
        console.log("Dashboard Finalized")

        /****************************************************************************************************
        Listens for a postMessage sending company data to the front end.
        ******************************************************************************************************/
        window.addEventListener("message", (event) => {
            if (event.data.message === "sendCompanyData") {
                companyData.current = event.data.value
                startContactsAPIEngine()
            }
        });

        // Code Smell, oops
        setTimeout(checkAuthenticated, LOADTIMER)
        loaderRef.current.complete()
        // eslint-disable-next-line
    }, []);

    try {
        checkForUpdates() // Checks for filterUpdates everytime the page reloads
    } catch (error) {
        console.error(error);
    }

    return (
        <div className="App">
            <AnimatePresence initial={true} exitBeforeEnter={true} onExitComplete={() => null}>
                {showLoadingScreen && 
                    <motion.div initial="hidden" animate="visible" exit="exit" variants={backgroundVariants} className='loading-screen'>
                        <LoadingBar color='#b3121f' ref={loaderRef} loaderSpeed={LOADTIMER + 500} height={6} shadow={false} waitingTime={LOADTIMER} progress={progress}/>
                        <div className='loading-screen-main'>
                            <Lottie loop animationData={lottieJson} play style={{ width: 200, height: 200 }}/>
                        </div>
                    </motion.div>
                }
            </AnimatePresence>
            <header className="dashboard-header">
                <div className="site-logo__wrapper height-100percent">
                    <div className="site-logo__inside-wrapper">
                        <img src="thrive_logo.svg" className="site-logo" alt="Thrive Logo" />
                        <h1 className="site-title">GTM Engine</h1>
                    </div>
                </div>
                <button id="continue1" className="variant-1 button margin-right" onClick={() => sendSharedFiltersToDB()}>Share Dashboard</button>
            </header>
            <AnimatePresence initial={false} exitBeforeEnter={true} onExitComplete={() => null}>
                {showContactsModal && <ContactsModal setShowModal={setShowContactsModal} companyData={companyData} />}
            </AnimatePresence>
            <AnimatePresence initial={false} exitBeforeEnter={true} onExitComplete={() => null}>
                {showShareModal && <ShareModal setShowModal={setShowShareModal} URL={currentShareURL} />}
            </AnimatePresence>
            <div className="dashboard-sidebar__wrapper">
                <Sidebar
                    getFilterSum={getFilterSum}

                    rkpi={rkpi}
                    setrkpi={setrkpi}
                    reqKPI={reqKPI}

                    b2bb2c={b2bb2c}
                    setb2bb2c={setb2bb2c}
                    b2b={b2b}

                    aa={aa}
                    setaa={setaa}
                    anyAll={anyAll}

                    tselected={tselected}
                    settselected={settselected}
                    topicsSelected={topicsSelected}

                    iselected={iselected}
                    setiselected={setiselected}
                    industriesSelected={industriesSelected}

                    sselected={sselected}
                    setsselected={setsselected}
                    statesSelected={statesSelected}

                    employeeLowerValue={employeeLowerValue}
                    employeeHigherValue={employeeHigherValue}
                    elv={elv}
                    ehv={ehv}
                    setelv={setelv}
                    setehv={setehv}

                    revenueLowerValue={revenueLowerValue}
                    revenueHigherValue={revenueHigherValue}
                    rlv={rlv}
                    rhv={rhv}
                    setrlv={setrlv}
                    setrhv={setrhv}

                    s2selected={s2selected}
                    sets2selected={sets2selected}
                    sic2Selected={sic2Selected}

                    s4selected={s4selected}
                    sets4selected={sets4selected}
                    sic4Selected={sic4Selected}

                    n2selected={n2selected}
                    setn2selected={setn2selected}
                    naics2Selected={naics2Selected}

                    n4selected={n4selected}
                    setn4selected={setn4selected}
                    naics4Selected={naics4Selected}

                    dashboardId={currentDashboardState}
                    setDashboardID={setCurrentDashboardState}

                    swapDashboards={swapDashboards}

                    needsUpdate={needsUpdate}

                    tkselected={tkselected}
                    settkselected={settkselected}
                    topKeywordsSelected={topKeywordsSelected}

                    urlsselected={urlsselected}
                    seturlsslected={seturlsslected}
                    companyURLSSelected={companyURLSSelected}

                    namesselected={namesselected}
                    setnamesselected={setnamesselected}
                    companyNamesSelected={companyNamesSelected}

                    COMPANIESDASHBOARDID = {COMPANIESDASHBOARDID}
                    INTENTDASHBOARDID = {INTENTDASHBOARDID}
                />

                <div className="dashboard__wrapper">
                    <main id="dashboard" className="dashboard">
                        {/* Dashboard gets embedded here leave blank*/}
                    </main>
                </div>
            </div>
        </div>
    );

}

export default HomePage;