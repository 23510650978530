import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesLeft, faSliders, faCaretDown, faArrowsRotate, faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import RangeFilter from './../components/rangeFilter';
import SearchFilter from './../components/searchFilter';
import ContainsFilter from './containsFilter';
import statesList from './../data/states';
import industriesList from './../data/industries';
import topicsList from './../data/topics';
import s2list from './../data/sic2';
import s4list from './../data/sic4';
import n2list from './../data/naics2';
import n4list from './../data/naics4';
import { motion, AnimatePresence } from "framer-motion"
import ExpandableRow from './expandableRow';

/****************************************************************************************************
All constant variables ending with "Variants" are used to control the framer motion (see above) 
animations for certain sections.

sidebarEVariants:: Variants for expanded version of the sidebar.
sidebarCVariants:: Variants for collapsed version of the sidebar.
******************************************************************************************************/
const sidebarEVariants = {
    visible: {
        x: 0,
    },
    hidden: { x: '-50vw' },
    exit: { x: '-50vw' },
}

const sidebarCVariants = {
    visible: {
        x: 0,
    },
    hidden: { x: '-50vw' },
    exit: { x: '-50vw' },
}

/****************************************************************************************************
For reference of any props please refer to HomePage.js where all of them are defined. The sidebar is
the most vital component to this project except the API. This is where we filter down on the dashboard
and basically all of the props here are for controlling the functionality for that.

DEV NOTE: To clean up our code maybe instead we could initialize all these props here and instead bring
the apply function and things as props
******************************************************************************************************/
const Sidebar = ({
    getFilterSum,
    rkpi,
    setrkpi,
    reqKPI,
    b2bb2c,
    setb2bb2c,
    b2b,
    aa,
    setaa,
    anyAll,
    tselected,
    settselected,
    topicsSelected,
    iselected,
    setiselected,
    industriesSelected,
    sselected,
    setsselected,
    statesSelected,
    employeeLowerValue,
    employeeHigherValue,
    elv,
    ehv,
    setelv,
    setehv,
    revenueLowerValue,
    revenueHigherValue,
    rlv,
    rhv,
    setrlv,
    setrhv,
    s2selected,
    sets2selected,
    sic2Selected,
    s4selected,
    sets4selected,
    sic4Selected,
    n2selected,
    setn2selected,
    naics2Selected,
    n4selected,
    setn4selected,
    naics4Selected,
    dashboardId,
    swapDashboards,
    needsUpdate,
    tkselected,
    settkselected,
    topKeywordsSelected,
    urlsselected,
    seturlsslected,
    companyURLSSelected,
    namesselected,
    setnamesselected,
    companyNamesSelected,
    COMPANIESDASHBOARDID,
    INTENTDASHBOARDID}) => {
    
    const [sidebarCollapsed, setSidebarCollapsed] = React.useState(false) // Tells whether the sidebar should be in the expanded or collapsed state.

    const [isKPIDropdownActive, setKPIDropdownActive] = React.useState(false) // Keeps track of whether the KPI dropdown is open
    const [isAnyAllDropdownActive, setAnyAllDropdownActive] = React.useState(false) // Keeps track of whether the Any/All Topics dropdown is open

    /****************************************************************************************************
    Formats numbers to their nearest 1e3 position i.e. like 1000 to 1K or 4,000,000,000 to 4B
    ******************************************************************************************************/
    function nFormatter(num, digits) {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "K" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "B" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "Qd" },
            { value: 1e18, symbol: "Qn" },
            { value: 1e21, symbol: "Sx" },
            { value: 1e24, symbol: "Sp" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function (item) {
            return num >= item.value;
        });
        return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
    }

    /****************************************************************************************************
    Formats filter text for the UI, where it displays on range filter. To see functions where 1000 must
    be multiplied in first refer to getComputedRangeTextK()
    ******************************************************************************************************/
    function getComputedRangeText(l, h) {
        if (l === "" && h === "") {
            return "";
        } else if (l === "" && h > 0) {
            return `<= ${nFormatter(h, 1)}`
        } else if (l >= 0 && h === "") {
            return `>= ${nFormatter(l, 1)}`
        } else {
            return `${nFormatter(l, 1)} - ${nFormatter(h, 1)}`
        }
    }

    /****************************************************************************************************
    Formats filter text for the UI where the value must first be multipled by 1000, where it displays on range filter. 
    To see functions where 1000 must not be multiplied in first refer to getComputedRangeText()
    ******************************************************************************************************/
    function getComputedRangeTextK(l, h) {
        if (l === "" && h === "") {
            return "";
        }
        l = (l === "" ? "" : l * 1000)
        h = (h === "" ? "" : h * 1000)
        if (l === "" && h > 0) {
            return `<= ${nFormatter(h, 1)}`
        } else if (l >= 0 && h === "") {
            return `>= ${nFormatter(l, 1)}`
        } else {
            return `${nFormatter(l, 1)} - ${nFormatter(h, 1)}`
        }
    }

    return (
        <>
        <AnimatePresence initial={false} exitBeforeEnter={true} onExitComplete={() => null}>
            {sidebarCollapsed &&
                <motion.section className="collapsed-sidebar" initial="hidden" animate="visible" exit="exit" variants={sidebarCVariants}>
                    <FontAwesomeIcon className="sidebar-open icon expander no-ml" icon={faAnglesRight} onClick={(e) => setSidebarCollapsed(false)} />
                    <FontAwesomeIcon className="sidebar-open__param icon expander no-ml" icon={faSliders} onClick={(e) => setSidebarCollapsed(false)} />
                    <span className="sidebar-filter-count all">{getFilterSum()}</span>
                </motion.section>
            }
        </AnimatePresence>
        <AnimatePresence initial={false} exitBeforeEnter={true} onExitComplete={() => null}>
            {!sidebarCollapsed &&
                <motion.section className="collapsible-sidebar" initial="hidden" animate="visible" exit="exit" variants={sidebarEVariants}>
                    <div className='sidebar__header sidebar-row-item'>
                        <div className="sidebar__header-title">
                            <h4>Parameters</h4>
                            <span className="sidebar-filter-count all">{getFilterSum()}</span>
                        </div>
                        <div className="sidebar__header-settings">
                            <FontAwesomeIcon className="icon expander" icon={faAnglesLeft} onClick={() => setSidebarCollapsed(true)} />
                        </div>
                    </div>
                    <div className='sidebar-row-item vert'>
                        <div className="toggle-button-group fill-width">
                            <button className={b2bb2c === "" ? 'toggle-button active' : 'toggle-button'} onClick={() => { 
                                setb2bb2c(""); 
                                b2b.current = ""; 
                            }}>All</button>
                            <button className={b2bb2c === "b2bb2c" ? 'toggle-button active' : 'toggle-button'} onClick={() => { 
                                setb2bb2c("b2bb2c"); 
                                b2b.current = "b2bb2c"; 
                            }}>B2B+B2C</button>
                            <button className={b2bb2c === "b2b" ? 'toggle-button active' : 'toggle-button'} onClick={() => { 
                                setb2bb2c("b2b"); 
                                b2b.current = "b2b"; 
                            }}>B2B</button>
                            <button className={b2bb2c === "b2c" ? 'toggle-button active' : 'toggle-button'} onClick={() => { 
                                setb2bb2c("b2c"); 
                                b2b.current = "b2c"; 
                            }}>B2C</button>
                        </div>
                        <div className='custom-select__outer-wrapper'>
                            <div className='custom-select__inner-wrapper'>
                                <FontAwesomeIcon icon={faCaretDown} className="dropdown-icon icon" />
                                <input type="text" className='custom-select-button' value={rkpi === "Companies" && dashboardId === INTENTDASHBOARDID ? "Companies With Intent" : rkpi} onFocus={() => setKPIDropdownActive(true)} onBlur={() => setKPIDropdownActive(false)} onMouseDown={isKPIDropdownActive ? () => setKPIDropdownActive(false) : () => setKPIDropdownActive(true)} readOnly />
                            </div>
                            <div className={isKPIDropdownActive ? "select-dropdown active" : "select-dropdown"}>
                                <div className="custom-select__option" onMouseDown={() => { setrkpi("Companies"); reqKPI.current = "Companies"; dashboardId === INTENTDASHBOARDID ? swapDashboards(COMPANIESDASHBOARDID) : void(0)}}>Companies</div>
                                <div className="custom-select__option" onMouseDown={() => { setrkpi("Companies"); reqKPI.current = "Companies"; dashboardId === COMPANIESDASHBOARDID ? swapDashboards(INTENTDASHBOARDID) : void(0)}}>Companies With Intent</div>
                                <div className="custom-select__option" onMouseDown={() => { setrkpi("Monthly Intent"); reqKPI.current = "Monthly Intent"; dashboardId === COMPANIESDASHBOARDID ? swapDashboards(INTENTDASHBOARDID) : void(0)}}>Monthly Intent</div>
                                <div className="custom-select__option" onMouseDown={() => { setrkpi("Monthly Intent per Company"); reqKPI.current = "Monthly Intent per Company"; dashboardId === COMPANIESDASHBOARDID ? swapDashboards(INTENTDASHBOARDID) : void(0)}}>Monthly Intent per Company</div>
                            </div>
                        </div>
                    </div>
                    <div className='sidebar__main'>
                        { dashboardId === INTENTDASHBOARDID && <ExpandableRow filterCount={tselected.length} title="Topics">
                            <div className="topic-dropdown__wrapper">
                                <div className='custom-select__outer-wrapper small'>
                                    <div className='custom-select__inner-wrapper small'>
                                        <FontAwesomeIcon icon={faCaretDown} className="dropdown-icon icon" />
                                        <input type="text" className='custom-select-button small' value={aa} onFocus={(e) => setAnyAllDropdownActive(true)} onBlur={(e) => setAnyAllDropdownActive(false)} onMouseDown={isAnyAllDropdownActive ? () => setAnyAllDropdownActive(false) : () => setAnyAllDropdownActive(true)} readOnly />
                                    </div>
                                    <div className={isAnyAllDropdownActive ? "select-dropdown active small bx" : "select-dropdown small bx"}>
                                        <div className="custom-select__option" onMouseDown={(e) => { setaa("Any"); anyAll.current = "Any"; }}>Any</div>
                                        <div className="custom-select__option" onMouseDown={(e) => { setaa("All"); anyAll.current = "All"; }}>All</div>
                                    </div>
                                </div>
                                <p className="dropdown-label">of the topics</p>
                            </div>
                            <SearchFilter listToSearch={topicsList} stateFilterList={tselected} stateFilterListSetter={settselected} refFilterList={topicsSelected} />
                        </ExpandableRow> }
                        <ExpandableRow filterCount={sselected.length} title="State">
                            <SearchFilter listToSearch={statesList} stateFilterList={sselected} stateFilterListSetter={setsselected} refFilterList={statesSelected} />
                        </ExpandableRow>
                        <ExpandableRow filterCount={getComputedRangeText(elv, ehv)} title="Employee Count">
                            <RangeFilter minRef={employeeLowerValue} maxRef={employeeHigherValue} minState={elv} minStateSetter={setelv} maxState={ehv} maxStateSetter={setehv} maxVal={100000} monetary={false} />
                        </ExpandableRow>
                        <ExpandableRow filterCount={getComputedRangeTextK(rlv, rhv)} title="Revenue (K)">
                            <RangeFilter minRef={revenueLowerValue} maxRef={revenueHigherValue} minState={rlv} minStateSetter={setrlv} maxState={rhv} maxStateSetter={setrhv} maxVal={5000000} monetary={true}/>
                        </ExpandableRow>
                        <ExpandableRow filterCount={iselected.length} title="Industry">
                            <SearchFilter listToSearch={industriesList} stateFilterList={iselected} stateFilterListSetter={setiselected} refFilterList={industriesSelected} />
                        </ExpandableRow>
                        <ExpandableRow 
                        filterCount={s2selected.length} 
                        title="SIC2 Description"
                        tooltip={{
                            tooltipTitle: "Standard Industrial Classification System",
                            tooltipLink: "https://www.sec.gov/corpfin/division-of-corporation-finance-standard-industrial-classification-sic-code-list",
                            tooltipLinkText: "https://www.sec.gov/corpfin/",
                            tooltipSubtitle: "2-Digit Industry Code"
                        }}>
                            <SearchFilter listToSearch={s2list} stateFilterList={s2selected} stateFilterListSetter={sets2selected} refFilterList={sic2Selected} />
                        </ExpandableRow>
                        <ExpandableRow 
                        filterCount={s4selected.length} 
                        title="SIC4 Description"
                        tooltip={{
                            tooltipTitle: "Standard Industrial Classification System",
                            tooltipLink: "https://www.sec.gov/corpfin/division-of-corporation-finance-standard-industrial-classification-sic-code-list",
                            tooltipLinkText: "https://www.sec.gov/corpfin/",
                            tooltipSubtitle: "4-Digit Industry Code"
                        }}>
                            <SearchFilter listToSearch={s4list} stateFilterList={s4selected} stateFilterListSetter={sets4selected} refFilterList={sic4Selected} />
                        </ExpandableRow>
                        <ExpandableRow 
                        filterCount={n2selected.length} 
                        title="NAICS2 Description" 
                        tooltip={{
                            tooltipTitle: "North American Industry Classification System",
                            tooltipLink: "https://www.census.gov/naics/#:~:text=The%20North%20American%20Industry%20Classification,to%20the%20U.S.%20business%20economy",
                            tooltipLinkText: "https://www.census.gov/naics/",
                            tooltipSubtitle: "2-Digit Industry Code"
                        }}>
                            <SearchFilter listToSearch={n2list} stateFilterList={n2selected} stateFilterListSetter={setn2selected} refFilterList={naics2Selected} />
                        </ExpandableRow>
                        <ExpandableRow 
                        filterCount={n4selected.length} 
                        title="NAICS4 Description"
                        tooltip={{
                            tooltipTitle: "North American Industry Classification System",
                            tooltipLink: "https://www.census.gov/naics/#:~:text=The%20North%20American%20Industry%20Classification,to%20the%20U.S.%20business%20economy",
                            tooltipLinkText: "https://www.census.gov/naics/",
                            tooltipSubtitle: "4-Digit Industry Code"
                        }}>
                            <SearchFilter listToSearch={n4list} stateFilterList={n4selected} stateFilterListSetter={setn4selected} refFilterList={naics4Selected} />
                        </ExpandableRow>
                        <ExpandableRow filterCount={tkselected.length} title="Top Keywords"
                        tooltip={{
                            tooltipTitle: "Contains Type Filter",
                            tooltipLink: "/",
                            tooltipLinkText: "",
                            tooltipSubtitle: 'This filter returns any results containing the entered text.'
                        }}>
                            <ContainsFilter stateFilterList={tkselected} stateFilterListSetter={settkselected} refFilterList={topKeywordsSelected} />
                        </ExpandableRow>
                        <ExpandableRow filterCount={urlsselected.length} title="Company URLs"
                        tooltip={{
                            tooltipTitle: "Contains Type Filter",
                            tooltipLink: "/",
                            tooltipLinkText: "",
                            tooltipSubtitle: 'This filter returns any results containing the entered text.'
                        }}>
                            <ContainsFilter stateFilterList={urlsselected} stateFilterListSetter={seturlsslected} refFilterList={companyURLSSelected} />
                        </ExpandableRow>
                        <ExpandableRow filterCount={namesselected.length} title="Company Names"
                        tooltip={{
                            tooltipTitle: "Contains Type Filter",
                            tooltipLink: "/",
                            tooltipLinkText: "",
                            tooltipSubtitle: 'This filter returns any results containing the entered text.'
                        }}>
                            <ContainsFilter stateFilterList={namesselected} stateFilterListSetter={setnamesselected} refFilterList={companyNamesSelected} />
                        </ExpandableRow>
                    </div>
                    <div className='sidebar__footer sidebar-row-item'>
                        <button id="reset" className="button">Reset</button>
                        <div id="apply" className={needsUpdate ? "variant-1 button actionable" : "variant-1 button inactive"}>
                            Apply
                        {needsUpdate && <FontAwesomeIcon className="icon refresh" icon={faArrowsRotate} />}
                        </div>
                        <button id="test" className="button" style={{ display: "none" }}>Test</button>
                    </div>
                </motion.section>
            }
            </AnimatePresence>
        </>
    )

}

export default Sidebar