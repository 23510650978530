import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import React from 'react';

/****************************************************************************************************
This component is responsible for any field that needs multi-selectability without the search
functionality present in searchFilter.js

optionList:: The list of options the user can select from. Is mapped into a dropdown
inputText:: The text in the readonly input that is unique to each dropdown
selectedList:: Contains the list that is show to the user, the list that you add to with the dropdown
setSelectedList:: Updates the list above
******************************************************************************************************/
const MultiSelect = ({ optionList, inputText, selectedList, setSelectedList}) => {

    const [isThisDropdownActive, setThisDropdownActive] = React.useState(false) // Controls whether or not the dropdown is shown to the user

    /****************************************************************************************************
    Removes values from the list of selected items.
    ******************************************************************************************************/
    const handleRemoveItem = (i) => {
        setSelectedList(selectedList.filter(item => (item !== i || item !== i)))
    };

    return(
        <>
            <div className='custom-select__outermost-wrapper'>
                <div className='custom-select__outer-wrapper'>
                    <div className='custom-select__inner-wrapper-modal'>
                        <FontAwesomeIcon icon={faCaretDown} className="dropdown-icon icon" />
                        <input type="text" className='custom-select-button multi' value={inputText} onFocus={() => setThisDropdownActive(true)} onBlur={() => setThisDropdownActive(false)} onMouseDown={isThisDropdownActive ? () => setThisDropdownActive(false) : () => setThisDropdownActive(true)} readOnly />
                    </div>
                    <div className={isThisDropdownActive ? "multi-select-dropdown active" : "multi-select-dropdown"}>
                        {
                            optionList.sort().map((option) =>
                                <div key={option} className={selectedList.includes(option) ? "custom-multi-select__option hidden" : "custom-multi-select__option"} onMouseDown={() => setSelectedList(selectedList.concat(option))}>{option}</div>
                            )
                        }
                    </div>
                </div>
                <div className={selectedList.length > 0 ? 'multi-select-selected__wrapper-m' : 'multi-select-selected__wrapper empty'}>
                        {
                            selectedList.sort().map((item) =>
                                <div key={item} className="search-multi-select-selected-item-m">
                                    <div className='search-multi-select-selected-item_main'>{item}</div>
                                    <FontAwesomeIcon className="icon white" icon={faTimes} onClick={() => handleRemoveItem(item)}/> 
                                </div>
                            )
                        }
                        <div className='clear-selected' onClick={() => setSelectedList([])}>Clear</div>
                </div>
            </div>
        </>
    )
}

export default MultiSelect