const jobFunctionsList = [
    "Governmental Affairs & Regulatory Law",
    "Radiology",
    "Physical Security",
    "Intellectual Property & Patent",
    "Anesthesiology",
    "Project Development",
    "Labor & Employment",
    "Pediatrics",
    "Quality Management",
    "Lawyer / Attorney",
    "Epidemiology",
    "Real Estate",
    "Safety",
    "Store Operations",
    "Infectious Disease",
    "Supply Chain",
    "Medicine",
    "Orthopedics",
    "Obstetrics / Gynecology",
    "Ophthalmology",
    "Neurology",
    "Tax",
    "Treasury",
    "Product Marketing",
    "Marketing Operations",
    "Revenue Operations",
    "Public Relations (PR)",
    "Social Media Marketing",
    "Search Engine Optimization / Pay Per Click",
    "Technical Marketing",
    "Strategic Communications",
    "Dermatology",
    "Clinical Systems",
    "Marketing Analytics / Insights",
    "Marketing",
    "Account Management",
    "Business Development",
    "Channel Sales",
    "Customer Retention & Development",
    "Customer Success",
    "Advertising",
    "Field / Outside Sales",
    "Brand Management",
    "Inside Sales",
    "Customer Experience",
    "Partnerships",
    "Customer Marketing",
    "Demand Generation",
    "Sales",
    "Digital Marketing",
    "Event Marketing",
    "Field Marketing",
    "Graphic Design",
    "Lead Generation",
    "Workforce Management",
    "eCommerce",
    "Content Marketing",
    "Marketing Communications",
    "IT Asset Management",
    "IT Audit / IT Compliance",
    "IT Operations",
    "IT Procurement",
    "IT Strategy",
    "IT Training",
    "Information Security",
    "Information Technology",
    "Compensation & Benefits",
    "Diversity & Inclusion",
    "Employee & Labor Relations",
    "Health & Safety",
    "Human Resource Information System",
    "Artificial Intelligence / Machine Learning",
    "Human Resources",
    "Bioengineering",
    "Learning & Development",
    "Biometrics",
    "Organizational Development",
    "Business Intelligence",
    "Recruiting & Talent Acquisition",
    "Chemical Engineering",
    "Talent Management",
    "Cloud / Mobility",
    "Data Science",
    "DevOps",
    "Quality Assurance",
    "Digital Transformation",
    "eDiscovery",
    "Retail / Store Systems",
    "Emerging Technology / Innovation",
    "Servers",
    "Litigation",
    "Storage & Disaster Recovery",
    "Privacy",
    "Telecommunications",
    "Virtualization",
    "Legal Counsel",
    "Legal Operations",
    "Project & Program Management",
    "Legal",
    "Infrastructure",
    "Networking",
    "Sales Training",
    "Sales Operations",
    "Sales Engineering",
    "Sales Enablement",
    "Accounting",
    "Compliance",
    "Finance",
    "Financial Planning & Analysis",
    "Financial Reporting",
    "Application Development",
    "Financial Strategy",
    "Business Service Management / ITSM",
    "Financial Systems",
    "Internal Audit & Control",
    "Collaboration & Web Apps",
    "Investor Relations",
    "Data Center",
    "Nursing",
    "Mergers & Acquisitions",
    "Data Warehouse",
    "Physical Therapy",
    "Database Administration",
    "Optometry",
    "Enterprise Architecture",
    "Nutrition & Dietetics",
    "HR / Financial / ERP Systems",
    "Medical Education & Training",
    "Help Desk / Desktop Services",
    "Product Development",
    "Medical Research",
    "Mobile Development",
    "Social Work",
    "Project Management",
    "Chiropractics",
    "Product Management",
    "First Responder",
    "Scrum Master / Agile Coach",
    "Medical Administration",
    "Research & Development",
    "Support / Technical Services",
    "Software Development",
    "Industrial Engineering",
    "Web Development",
    "Surgery",
    "UI / UX",
    "Oncology",
    "Public Health",
    "Clinical Operations",
    "Psychology",
    "Psychiatry",
    "Shared Services",
    "Sourcing / Procurement",
    "Risk",
    "Pharmacy",
    "Dentistry",
    "Test / Quality Assurance",
    "Doctors / Physicians",
    "Technology Operations",
    "Pathology",
    "Executive",
    "Finance Executive",
    "Human Resources Executive",
    "Sales Executive",
    "Operations Executive",
    "Call Center",
    "Information Technology Executive",
    "Construction",
    "Engineering & Technical Executive",
    "Corporate Strategy",
    "Marketing Executive",
    "Customer Service / Support",
    "Engineering & Technical",
    "Legal Executive",
    "Enterprise Resource Planning",
    "Acquisitions",
    "Medical & Health Executive",
    "Facilities Management",
    "Leasing",
    "Contracts",
    "Logistics",
    "Corporate Secretary",
    "Office Operations",
    "Ethics",
    "Operations",
    "Governance"
]

export default jobFunctionsList