import { Route, Routes } from 'react-router-dom';
import HomePage from './HomePage.js';

const AppWithRouterAccess = () => {
    return (
      <Routes>
          <Route path="/" exact element={<HomePage />}/>
      </Routes>
    )
}

export default AppWithRouterAccess;