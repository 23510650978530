/****************************************************************************************************
This component displays a representation of the ranges chosen within the rangeFilter.js

minState:: lower value on rangeFilter
maxState:: higher value on rangeFilter
maxVal:: tells the representation what a good maximum value is. It's not actually the highest but it's
an extreme above the avarage
******************************************************************************************************/
const RangeRepresentation = ({minState, maxState, maxVal}) => {

    /****************************************************************************************************
    Defines variables to create the length of the line and the position of the handles(?) When the values
    are greater than maxVal they're positioned at the end.
    ******************************************************************************************************/
    const ls = minState === "" ? 0 : (minState <= maxVal ? (minState/maxVal)*100 : 100)
    const us = maxState === "" ? 100 : (maxState <= maxVal ? (maxState/maxVal)*100 : 100)
    const length = us - ls;

    return(
        <div className='range-rep__wrapper'>
            <div style={{marginLeft: `${ls}%`}} className="range-lower__spotter"></div>
            <div style={{marginLeft: `${us}%`}} className="range-upper__spotter"></div>
            <div style={{width: `${length}%`, left: `${ls}%`, display: `${us > ls ? 'block' : 'none'}`}} className="range-indicator"></div>
            <div className="range-line"></div>
        </div>
    )
}

export default RangeRepresentation