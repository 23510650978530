import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus, faCircleInfo } from '@fortawesome/free-solid-svg-icons'

/****************************************************************************************************
Responsible for being the rows that expand and collapse in the sidebar, this component uses
props.children and so is a wrapper component.
******************************************************************************************************/
const ExpandableRow = (props) => {

    const [isSectionActive, setSectionActive] = React.useState(false) // Hold the boolean state for whether the particular section is active.

    /****************************************************************************************************
    Keeps the section from collapsing if you click inputs or anything else that generally needs clicked.
    ******************************************************************************************************/
    function setInactiveAndStopProp(e, setter) {
        e.preventDefault();
        if(e.currentTarget === e.target) {
            setter(false)
        }
    }

    return (
        <div className={isSectionActive ? 'sidebar-row-item expandable expanded' : 'sidebar-row-item expandable collapsed'} onClick={!isSectionActive ? () => setSectionActive(true) : (e) => setInactiveAndStopProp(e, setSectionActive)}>
            <div className='sidebar-row-item-info' onClick={() => setSectionActive(!isSectionActive)}>
                <span className="sidebar-row-item__title">{props.title}</span>
                {props.tooltip && 
                    <div className="tooltip-opener">
                        <FontAwesomeIcon icon={faCircleInfo} className="icon tooltip" />
                        <div className="tooltip-descriptor">
                            <h4 className="tooltip-title">{props.tooltip.tooltipTitle}</h4>
                            <p className="tooltip-sub">{props.tooltip.tooltipSubtitle}</p>
                            <a className="tooltip-link" href={props.tooltip.tooltipLink}>{props.tooltip.tooltipLinkText}</a>
                        </div>
                    </div>
                }
                <span className="sidebar-filter-count">{props.filterCount > 0 || (props.filterCount !== "" && props.filterCount !== 0) ? `(${props.filterCount})` : ""}</span>
                <FontAwesomeIcon icon={isSectionActive ? faMinus : faPlus} className="expander icon" onClick={() => setSectionActive(!isSectionActive)} />
            </div>
            <div className='expanded-content'>
                {props.children}
            </div>
        </div>
    )
}

export default ExpandableRow