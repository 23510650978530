import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faTimes } from '@fortawesome/free-solid-svg-icons'
import { matchSorter } from 'match-sorter'


/****************************************************************************************************
This component is responsible for any field that only sends contains data to looker which looks like
"string, string". This filter allows the user to search for values and then select them in the
dropdown adding them to the list below.

searchRef:: Responsible for tracking the value currently entered into the input field
listToSearch:: The list to populate the dropdown
stateFilterList:: Contains the list that is shown to the user, the list that you add to with the input
stateFilterListSetter:: Updates the list above
refFilterList:: Responsible for holding the list that is actually sent to looker. UseState has
performance issues so useRef is used instead
******************************************************************************************************/
const SearchFilter = ({ listToSearch, stateFilterList, stateFilterListSetter, refFilterList }) => {

    const [isThisInputActive, setThisInputActive] = React.useState(false) //Tracks whether the search bar is focused and whether the dropdown should be displayed.
    const [currentValue, setCurrentValue] = React.useState("")

    const refState = React.useRef('')

    /****************************************************************************************************
    When the list still has values, we use matchSort to perform a sift operation and order them quickly
    based upon our search function. When the search matches nothing, or all values are already selected
    the function returns null
    ******************************************************************************************************/
    function filter(sl, val) {
        if(sl.length > 0) {
            return (matchSorter(sl, val, {threshold: matchSorter.rankings.CONTAINS}))
        }
        console.log("Returning Null")
        return null
    }

    /****************************************************************************************************
    Removes items from the list of selected items, this happens when you click the "X" on a selected item
    ******************************************************************************************************/
    const handleRemoveItem = (i) => {
        refFilterList.current = stateFilterList.filter(item => item !== i)
        stateFilterListSetter(stateFilterList.filter(item => (item !== i || item !== i)))
    };

    return(
        <>
            <div className='search-filter__outer-wrapper'>
                <div className='search-filter__inner-wrapper'>
                <input type="text" className="search-input" ref={refState} onFocus={() => setThisInputActive(true)} onBlur={(e) => setThisInputActive(false)} onChange={(e) => { setCurrentValue(e.target.value); refState.current.value = e.target.value}}/>
                <div className='search-bar-end'>
                    <FontAwesomeIcon className="icon" icon={faMagnifyingGlass}/>  
                </div>
                </div>
                <div className={isThisInputActive ? "search-dropdown active" : "search-dropdown"}>
                { listToSearch ?
                    (filter(listToSearch, currentValue).map((item) =>
                        <div key={item} className={stateFilterList.includes(item) || stateFilterList.includes(`"${item}"`) ? "hidden" : ""} onClick={(e) => {stateFilterListSetter(stateFilterList.concat(e.target.id)); refFilterList.current=refFilterList.current.concat(e.target.id);setCurrentValue(""); refState.current.value = "" }} id={item}>
                            {item}
                        </div>
                    )):""
                }
                </div>
            </div>
            <div className={stateFilterList.length > 0 ? 'search-multi-select-selected__wrapper' : 'search-multi-select-selected__wrapper empty'}>
                {
                    stateFilterList.map((item) =>
                        <div key={item} className="search-multi-select-selected-item">
                            <div className='search-multi-select-selected-item_main'>{item}</div>
                            <FontAwesomeIcon className="icon white" icon={faTimes} onClick={() => handleRemoveItem(item)}/> 
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default SearchFilter