const industriesList = [
    "Accounting",
    "Airlines/Aviation",
    "Alternative Medicine",
    "Apparel & Fashion",
    "Architecture & Planning",
    "Arts and Crafts",
    "Automotive",
    "Biotechnology",
    "Building Materials",
    "Business Supplies and Equipment",
    "Ceramics & Concrete",
    "Chemicals",
    "Civic & Social Organization",
    "Civil Engineering",
    "Commercial Real Estate",
    "Computer Hardware",
    "Computer Network & Security",
    "Computer Software",
    "Construction",
    "Consumer Electronics",
    "Consumer Goods",
    "Consumer Services",
    "Cosmetics",
    "Defense & Space",
    "Design",
    "Education",
    "Electrical/Electronic Manufacturing",
    "Environmental Services",
    "Events Services",
    "Executive Office",
    "Facilities Services",
    "Farming",
    "Financial Services",
    "Food & Beverages",
    "Fund-Raising",
    "Furniture",
    "Gambling & Casinos",
    "Glass",
    "Government",
    "Graphic Design",
    "Health",
    "Hospital & Health Care",
    "Hospitality",
    "Human Resources",
    "Import and Export",
    "Individual & Family Services",
    "Industrial Automation",
    "Information Technology and Services",
    "Insurance",
    "International Affairs",
    "International Trade and Development",
    "Internet",
    "Legal Services",
    "Leisure",
    "Libraries",
    "Logistics and Supply Chain",
    "Luxury Goods & Jewelry",
    "Machinery",
    "Management Consulting",
    "Maritime",
    "Marketing and Advertising",
    "Mechanical or Industrial Engineering",
    "Media and Entertainment",
    "Medical Devices",
    "Military",
    "Mining & Metals",
    "Museums and Institutions",
    "Nanotechnology",
    "Newspapers",
    "Nonprofit Organizations",
    "Oil & Energy",
    "Online Media",
    "Outsourcing/Offshoring",
    "Packaging and Containers",
    "Paper & Forest Products",
    "Performing Arts",
    "Pharmaceuticals",
    "Photography",
    "Plastics",
    "Printing",
    "Professional Training & Coaching",
    "Program Development",
    "Public Relations and Communications",
    "Public Safety",
    "Publishing",
    "Real Estate",
    "Recreational Facilities and Services",
    "Religious Institutions",
    "Research",
    "Restaurants",
    "Retail",
    "Security and Investigations",
    "Sporting Goods",
    "Sports",
    "Staffing and Recruiting",
    "Telecommunications",
    "Textiles",
    "Tobacco",
    "Translation and Localization",
    "Transportation/Trucking/Railroad",
    "Travel & Tourism",
    "Utilities",
    "Veterinary",
    "Warehousing",
    "Wellness and Fitness",
    "Wholesale",
    "Writing and Editing"
]

export default industriesList